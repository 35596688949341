@import "iphone.css";
:root{
    --sprite2-url: url(../images/sprite-2x.png); /*var(--sprite2-url)*/
    --logo2: url(../images/logo2.png); /*var(--logo2)*/
    --sprite-url: url(../images/sprite.png);/*var(--sprite-url)*/
    --student-sprite-url: url(../images/sprite-student-portal.png);/*var(--student-sprite-url)*/
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    ;
    /* font: inherit; */
    vertical-align: baseline;
    font-family: 'Lato', sans-serif;
}

/* HTML5 display-role reset for older browsers */

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
    font-family: 'Lato', sans-serif;
}

body {
    line-height: 1;
    background: #f7f8f9 !important;
}

.entry-header {
    display: none;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

strong {}

a {
    text-decoration: none;
}

img {
    pointer-events: none;
}

a, span, div {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

input:focus {
    -webkit-appearance: none;
    position: relative;
}

.button:focus, .button:hover, button:focus, button:hover, input[type=button]:focus, input[type=button]:hover, input[type=reset]:focus, input[type=reset]:hover, input[type=submit]:focus, input[type=submit]:hover {
    background-color: inherit;
}

input {
    -webkit-appearance: none;
    -moz-appearance: none;
}

select {
    text-indent: 0.01px;
    text-overflow: '';
    height: 40px !important;
    padding-right: 10px;
}

html, body {
    height: 100%;
    width: 100%;
}

.modal-header {
    border-bottom: 0px !important;
}

.modal-title {
    font-size: 15px !important;
}

.modal-footer {
    border-top: 0px !important;
}

audio::-internal-media-controls-download-button {
    display: none;
}

audio::-webkit-media-controls-enclosure {
    overflow: hidden;
}

audio::-webkit-media-controls-panel {
    width: calc(100% + 30px);
    /* Adjust as needed */
}

@media only screen and (max-width: 767px) {
    video {
        /* background: #000; */
        color: #ccc;
        border-radius: 6px;
        border: 1px solid #cccccc;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
        max-width: 100%;
        margin-top: -1px !important;
        margin-left: 2px !important;
        width: 90% !important
    }
}

video {
    /* background: #000; */
    color: #ccc;
    border-radius: 6px;
    border: 1px solid #cccccc;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    max-width: 100% !important;
    margin-top: 0px;
    /* margin-left: 250px; */
    width: 100% !important
}

video::-webkit-media-controls {
    overflow: hidden !important
}

video::-webkit-media-controls-enclosure {
    width: calc(100% + 32px);
    margin-left: auto;
}

video::-webkit-media-controls-panel {
    /* Adjust as needed */
}

/*public css*/
.overrideP {
    font-size: 18px !important;
    font-weight: bold !important;
}
.noPad {
    padding: 0px !important;
}

.m-t-30 {
    margin-top: 30px;
}

.DivShow {
    display: block !important
}

span.searchBtn:focus, .search-sm-Box .btn.btn-primary:focus {
    outline: none;
}

/*topBar right*/

.topFirstBar {
    min-height: 60px;
    background: #fff;
}

.topFirstBar ul.topBarProfileIcons {
    list-style: none;
    float: right;
    margin-bottom: 0px
}

.topFirstBar ul.topBarProfileIcons li {
    display: inline-block;
    float: left;
}

.topFirstBar ul.topBarProfileIcons li a {
    min-width: 30px;
    min-height: 30px;
    display: block;
}

.topFirstBar .topBarProfileIcons .TopBartask {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 0px 1px;
    display: inline-block;
    height: 35px;
    width: 35px;
    margin-right: 20px;
    margin-top: 12px;
    background-size: 500px 600px;
    html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        ;
        font: inherit;
        vertical-align: baseline;
        font-family: 'Lato', sans-serif;
    }
    /* HTML5 display-role reset for older browsers */
    article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
        display: block;
        font-family: 'Lato', sans-serif;
    }
    .entry-header {
        display: none;
    }
    ol, ul {
        list-style: none;
    }
    blockquote, q {
        quotes: none;
    }
    blockquote:before, blockquote:after, q:before, q:after {
        content: '';
        content: none;
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }
    strong {}
    a {
        text-decoration: none;
    }
    img {
        pointer-events: none;
    }
    a, span, div {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    input:focus {
        -webkit-appearance: none;
        position: relative;
    }
    .button:focus, .button:hover, button:focus, button:hover, input[type=button]:focus, input[type=button]:hover, input[type=reset]:focus, input[type=reset]:hover, input[type=submit]:focus, input[type=submit]:hover {
        background-color: inherit;
    }
    input {
        -webkit-appearance: none;
    }
    select {
        -moz-appearance: none;
        text-indent: 0.01px;
        text-overflow: '';
        -webkit-appearance: none;
    }
    html, body {
        height: 100%;
    }
    /*public css*/
    .noPad {
        padding: 0px !important;
    }
    .m-t-30 {
        margin-top: 30px;
    }
    .DivShow {
        display: block !important;
    }
    span.searchBtn:focus, .search-sm-Box .btn.btn-primary:focus {
        outline: none;
    }
    /*topBar right*/
    .topFirstBar {
        min-height: 60px;
        background: #fff;
    }
    .topFirstBarul.topBarProfileIcons {
        list-style: none;
        float: right;
    }
    .topFirstBarul.topBarProfileIconsli {
        display: inline-block;
        float: left;
    }
    .topFirstBarul.topBarProfileIconslia {
        min-width: 30px;
        min-height: 30px;
        display: block;
    }
    .topFirstBar .topBarProfileIcons .TopBartask {
        background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 0px 1px;
        display: inline-block;
        height: 35px;
        width: 35px;
        margin-right: 20px;
        margin-top: 12px;
        background-size: 500px 600px;
    }
    .topFirstBar .topBarProfileIcons .TopBarhelp {
        background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -49px 1px;
        display: inline-block;
        height: 35px;
        width: 35px;
        margin-right: 20px;
        margin-top: 13px;
    }
    .topFirstBar .topBarProfileIcons .TopBarnotification {
        background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -99px 1px;
        display: inline-block;
        height: 35px;
        width: 35px;
        margin-right: 20px;
        margin-top: 13px;
        background-size: 500px 600px;
    }
    .topFirstBar .topBarProfileIcons .TopBarnotificationspan {
        background: #fff;
        border: 1px solid #0f6fb4;
        color: #0f6fb4;
        padding: 2px 4px;
    }
    .topFirstBar .topBarProfileIconsa.profileImgLink {
        margin-top: 11px;
        height: 47px;
        cursor: pointer;
    }
    .topFirstBar .topBarProfileIconsa.profileImgLinkspan {
        color: #1c1c1c;
        line-height: 35px;
    }
    .topFirstBar .topBarProfileIconsa.profileImgLinkspan.personImg {
        width: 40px;
        height: 40px;
        display: inline-block;
        border-radius: 100%;
        text-align: center;
        overflow: hidden;
        position: static;
        top: auto;
        left: auto;
        float: left;
        margin-right: 8px;
    }
    .topFirstBar .topBarProfileIconsa.profileImgLinkspan.personImgimg {
        width: 100%;
    }
    .topFirstBar .topBarProfileIconsa.profileImgLinkspan.pername {
        color: #1c1c1c;
    }
    .navbar {
        background: rgb(238, 238, 238);
        border-radius: 0 !important;
        height: 50px;
        margin-bottom: 0px;
    }
    .navbar .navbar-nav {
        display: inline-block;
        float: none;
        margin-top: -1px;
    }
    .navbar .navbar-collapse {
        text-align: center;
        padding-left: 0px;
        padding-right: 0px;
        margin-left: -15px !important;
        margin-right: -15px !important;
    }
    .navbar-nav>li>a {
        font-size: 13px !important;
    }
    .navbar-nav>li>a:hover {
        text-decoration: none;
    }
    .navbar-nav>li>a:hover {
        color: f7f7f8;
        ;
        background: #FFFFFF;
        text-decoration: none !important;
    }
    /*searchBar strip*/
    .searchCrtBar .showLabel {
        padding-top: 7px !important;
        width: 25%;
        text-align: right;
        color: #fff;
    }
    .searchCrtBar {
        height: 65px;
        background: rgb(15, 111, 180);
        box-sizing: border-box;
        padding-top: 16px;
        /*border-bottom: 1px solid #eeeeee;*/
        border-bottom: 1px solid #ddd;
        color: #fff;
    }
    .searchCrtBar .searchBtn:focus {
        outline: none;
        border: none;
    }
    .searchCrtBar .btn.btn-primary:focus {
        outline: none;
    }
    .searchCrtBar .btn.btn-primary {
        padding: 0px;
    }
    .searchCrtBar .btn.btn-primary:hover {
        background: #0f6fb4;
    }
    .searchCrtBarh3 {
        color: #fff;
        margin-top: 6px;
        padding-left: 5px;
    }
    .searchCrtBar .selctBox {
        width: 100%;
        display: inline-block;
        border-radius: 30px;
        background: transparent;
        border: none;
        box-shadow: none;
        z-index: 2;
        height: 31px !important
    }
    .searchCrtBar .row .slctCol .label {
        line-height: 32px;
    }
    .searchCrtBar .crtBtn {
        padding: 7px 15px 9px 31px !important;
        height: 38px;
        margin-top: -3px;
        background-color: #44a9f2;
        border-color: #44a9f2;
        float: right;
        line-height: 3px;
        width: 95%;
        position: relative;
        min-width: 120px;
    }
    .searchCrtBar .crtBtnspan {
        margin-right: 0px;
        position: absolute;
        left: 10px;
        background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll -273px -5px;
        width: 20px;
        height: 20px;
        top: 6px;
    }
    .searchCrtBar .backBtn {
        padding-left: 15px !important;
        padding-right: 15px !important;
        height: 38px;
        margin-top: -3px;
        background-color: #44a9f2;
        border-color: #44a9f2;
        line-height: 3px;
        position: relative;
        min-width: 120px;
    }
    .searchCrtBar .searchBtn {
        background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll -164px 1px;
        background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -164px -3px;
        display: inline-block;
        height: 27px;
        left: 5px;
        position: relative;
        top: 0px;
        width: 50px;
        background-size: 500px 600px;
    }
    .searchCrtBar .searchBtn:focus {
        outline: none;
        border: none;
    }
    .searchCrtBar .btn.btn-primary:focus {
        outline: none;
    }
    .searchCrtBar .btn.btn-primary {
        padding: 0px;
    }
    .searchCrtBar .btn.btn-primary:hover {
        background: #0f6fb4;
    }
    .BoxSelMain {
        position: relative;
        display: inline-block;
        width: 55%;
        background: #fff;
        border: 1px solid #ccc;
        border-radius: 20px;
        margin-right: 10px;
        float: right;
    }
    .BoxSelMainspan.tickyArrow {
        background: transparent var(--sprite2-url) no-repeat scroll -220px -9px;
        position: absolute;
        right: 9px;
        top: 8px;
        width: 29px;
        height: 26px;
        display: inline-block;
        background-size: 500px 600px;
    }
    /*filter left slide*/
    .container-fluid.noPad.listMainBoxWrapper {
        float: left;
        height: calc(100% - 152px);
        width: 100%;
        position: fixed;
        overflow: auto;
    }
    .filterBlock {
        height: calc(100% - 172px);
        width: 220px;
        background-color: transparent;
        padding-left: 24px;
        background: #f7f8f9;
        border: 1px solid #eeeeee;
        border-left: hidden;
        padding-top: 20px;
        overflow: auto;
        padding-bottom: 20px;
        float: left;
        width: 13.9%;
        position: fixed;
    }
    .filterBlockinput[type=checkbox].css-checkbox {
        position: absolute;
        z-index: -1000;
        left: -1000px;
        overflow: hidden;
        clip: rect(0 0 0 0);
        height: auto;
        width: 100%;
        margin: -1px;
        padding: 0;
        border: 0;
    }
    .filterBlockinput[type=checkbox].css-checkbox+label.css-label1 {
        padding-left: 30px;
        height: 24px;
        width: 100%;
        display: inline-block;
        background-repeat: no-repeat;
        background-position: -470px -3px;
        vertical-align: middle;
        cursor: pointer;
        line-height: 22px;
        color: #555555;
        background-size: 500px 600px;
    }
    .filterBlockinput[type=checkbox].css-checkbox:checked+label.css-label1 {
        background-position: -429px -37px;
    }
    .filterBlocklabel.css-label1 {
        background-image: var(--sprite2-url);
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .innerQuestionBoxinput[type=radio].css-checkbox {
        position: absolute;
        z-index: -1000;
        left: -1000px;
        overflow: hidden;
        clip: rect(0 0 0 0);
        height: 1px;
        width: 1px;
        margin: -1px;
        padding: 0;
        border: 0;
    }
    .innerQuestionBoxinput[type=radio].css-checkbox+label.css-label {
        padding-left: 32px;
        min-height: 35px;
        display: inline-block;
        background-repeat: no-repeat;
        background-position: -470px -92px;
        vertical-align: middle;
        cursor: pointer;
        background-size: 500px 600px;
    }
    .innerQuestionBoxinput[type=radio].css-checkbox:checked+label.css-label {
        background-position: -432px -126px;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    label.css-label {
        background-image: var(--sprite2-url);
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .filterBlock .fieldBox {
        margin: 10px 0px;
    }
    .filterBlockh3 {
        color: #1c1c1c;
        margin-bottom: 15px;
    }
    .filterBlock .limitFilter {
        height: 79px;
        overflow: hidden;
    }
    .filterBlock .limitFilter.seeAll {
        height: auto;
        overflow: auto;
    }
    .filterBlock .seeMore {
        color: #555555;
        margin-top: 15px;
        cursor: pointer;
    }
    .filterBlock .unSelAll {
        color: #0174c7;
        display: block;
        margin-top: 25px;
        cursor: pointer;
    }
    /*list view */
    .noFilter {
        width: 100% !important;
        margin-left: 0px !important;
    }
    .listViewBlock {
        width: 86.1%;
        float: left;
        padding-bottom: 40px;
        margin-left: 13.9%;
        background-color: white;
    }
    .listViewBlock .blockForlist {
        background: #fff;
        /* max-height: 550px; */
        /* overflow: auto; */
        /* height: 100%; */
        /* position: fixed; */
        /* width: 86%; */
    }
    /*.listToBesslide{ max-height: 550px;overflow: auto;}*/
    .listViewBlocktable {
        width: 99%;
    }
    .listViewBlocktable.table-hovertheadtrth, .listViewBlocktable.table-hovertbody tr td {
        padding-left: 15px;
        vertical-align: middle;
        color: #555555;
    }
    .listViewBlocktable.table-hovertheadtrth {
        color: #1c1c1c;
        height: 52px;
        border-bottom: 1px solid #e0e0e0;
    }
    .listViewBlocktable.table-hovertbodytr.questionRow {}
    .listViewBlocktable.table-hovertbodytr.questionRow .typeIcon {
        background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 0px -71px;
        display: inline-block;
        height: 49px;
        left: 0px;
        position: relative;
        top: 1px;
        width: 52px;
        margin-right: 15px;
        background-size: 500px 600px;
    }
    .listViewBlocktable.table-hovertbodytr.flashCard .typeIcon {
        background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 0px -150px;
        display: inline-block;
        height: 49px;
        left: 0px;
        position: relative;
        top: 4px;
        width: 52px;
        margin-right: 15px;
        background-size: 500px 600px;
    }
    .listViewBlocktable.table-hovertbodytr.learning .typeIcon {
        background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 0px -222px;
        display: inline-block;
        height: 49px;
        left: 0px;
        position: relative;
        top: 4px;
        width: 52px;
        margin-right: 15px;
        background-size: 500px 600px;
    }
    .listViewBlocktable.table-hovertbodytr .contentType {
        display: inline-block;
        position: relative;
        top: -5px;
    }
    .listViewBlocktable.table-hovertbodytr .contentTypeh4 {
        color: #0f6fb4;
        /*  */
    }
    .listViewBlocktable.table-hovertbodytr .contentTypep {
        color: #909090;
    }
    .listViewBlocktable.table-hovertbodytr .contentTypepstrong {
        color: #555555;
    }
    .listViewBlocktable.table-hovertbodytrtd .DropDivBlock {
        cursor: pointer;
        position: relative;
        text-align: center;
        padding-bottom: 10px;
        line-height: 40px;
    }
    .listViewBlocktable.table-hovertbodytrtd .DropDivBlockspan.bullets {
        width: 6px;
        height: 6px;
        display: inline-block;
        background: #0f6fb4;
        border-radius: 100%;
    }
    .listViewBlocktable.table-hovertbodytrtd .DropDivBlock .dropBoxDiv {
        position: absolute;
        width: 130px;
        height: 115px;
        left: -84px;
        top: 29px;
        background: #fff;
        box-shadow: 0px 0px 10px #ccc;
        display: none;
        z-index: 222;
    }
    .listViewBlocktable.table-hovertbodytrtd .DropDivBlock .dropBoxDivul {}
    .listViewBlocktable.table-hovertbodytrtd .DropDivBlock .dropBoxDivulli {
        height: 35px;
        display: block;
    }
    .listViewBlocktable.table-hovertbodytrtd .DropDivBlock .dropBoxDivulli a {
        display: block;
        padding: 7px 15px 5px 15px;
        color: #555555;
        border-bottom: 1px solid #ebebeb;
        text-align: left;
    }
    .listViewBlocktable.table-hovertbodytrtd .DropDivBlock .dropBoxDivulli:last-child a {
        border: none;
    }
    .listViewBlocktable.table-hovertbodytrtd .DropDivBlock .dropBoxDiv:before {
        content: '';
        position: absolute;
        right: 0px;
        top: -20px;
        border: 10px solid rgba(255, 255, 255, 0.1);
        display: block;
        border-bottom-color: rgb(184, 184, 184);
    }
    .listViewBlocktable.table-hovertbodytrtd .DropDivBlock .dropBoxDiv:after {
        content: '';
        content: '';
        position: absolute;
        right: 0px;
        top: -19px;
        border: 10px solid rgba(255, 255, 255, 0.1);
        display: block;
        border-bottom-color: rgb(255, 255, 255);
    }
    .listViewBlocktable.table-hovertbodytrtd .DropDivBlock:hover .dropBoxDiv {
        display: block;
    }
    .listViewBlock .table-hover>tbody>tr:hover {
        background-color: #f1f9ff;
        cursor: pointer;
    }
    .pagination>li>a:focus, .pagination>li>a:hover, .pagination>li>span:focus, .pagination>li>span:hover {
        z-index: 2;
        color: #555555;
        background-color: #f7f8f9;
        border-color: #f7f8f9;
    }
    .paginationspan.DirectionArrow {
        background: rgb(255, 255, 255) var(--sprite-url) repeat -176px -70px;
        display: inline-block;
        height: 22px;
        position: absolute;
        width: 18px;
        cursor: pointer;
        transform: rotate(180deg);
    }
    .paginationspan.DirectionArrow.left {
        left: -13px;
        transform: rotate(0deg);
        top: 8px;
    }
    .paginationspan.DirectionArrow.right {
        right: -13px;
        top: 13px;
    }
    .pagination>li>a, .pagination>li>span {
        position: relative;
        float: left;
        padding: 6px 12px;
        margin-left: -1px;
        line-height: 1.42857143;
        color: #555555;
        text-decoration: none;
        background-color: #f7f8f9;
        border: 1px solid #f7f8f9;
    }
    .pagination>.active>a, .pagination>.active>a:hover, .pagination>.active>a:focus {
        color: #337ab7;
        background: transparent;
        border: none;
        line-height: 24px;
    }
    .pagination>li>a {
        padding: 10px 5px;
    }
    .pagination>li:first-child>a, .pagination>li:last-child>a {
        padding: 10px 20px;
    }
    /*activity block*/
    .activityLogBlock {
        width: 430px;
        height: calc(100% - 225px);
        overflow: auto;
        background: #fff;
        box-shadow: -3px 3px 8px #ccc;
        right: 0px;
        padding: 0px 30px 30px 30px;
        float: left;
        margin-left: 3px;
        display: none;
        /* height:auto; */
        position: fixed;
    }
    .activityLogBlock .topRowHaedingAnch {
        position: relative;
    }
    /*.scrollBar{position: absolute;height: 100%;width: 10px;right: -2px;background: #fff;z-index: 999;opacity:1; transition:all ease 0.5s}  .questionBlock:hover .scrollBar{opacity:1; transition:all ease 0.5s}*/
    .activityLogBlock .topRowHaedingAnchh3 {
        display: inline-block;
        color: #1c1c1c;
    }
    .activityLogBlock .topRowHaedingAncha.seDeatil {
        margin: 0px 0px 0px 20px;
        display: inline-block;
    }
    .activityLogBlock .topRowHaedingAncha.seDeatilem {
        font-family: cursive;
        position: relative;
        top: 2px;
        display: inline-block;
        margin-left: 3px;
    }
    .activityLogBlock .topRowHaedingAnchspan.cross {
        background: rgb(255, 255, 255) var(--sprite2-url) repeat -234px -70px;
        display: inline-block;
        height: 27px;
        right: -21px;
        position: absolute;
        top: 13px;
        width: 23px;
        cursor: pointer;
        z-index: 999;
        background-size: 500px 600px;
    }
    .activityLogBlock .topRowHaedingAnch .actionBlockDiv {
        margin-top: 30px;
    }
    .activityLogBlock .topRowHaedingAnch .actionBlockDiv .detailEdit {
        background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 1px -296px;
        display: inline-block;
        height: 48px;
        left: 5px;
        position: relative;
        top: 0px;
        width: 48px;
        margin-right: 10px;
        cursor: pointer;
        background-size: 500px 600px;
    }
    .activityLogBlock .topRowHaedingAnch .actionBlockDiv .detailEdit:hover {
        background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll -120px -298px;
    }
    .activityLogBlock .topRowHaedingAnch .actionBlockDiv .detailPrint {
        background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 1px -539px;
        display: inline-block;
        height: 48px;
        left: 5px;
        position: relative;
        top: 0px;
        width: 48px;
        margin-right: 10px;
        cursor: pointer;
        background-size: 500px 600px;
    }
    .activityLogBlock .topRowHaedingAnch .actionBlockDiv .detailPrint:hover {
        background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -118px -539px;
        background-size: 500px 600px;
    }
    .activityLogBlock .topRowHaedingAnch .actionBlockDiv .detailDuplicate {
        background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 1px -377px;
        display: inline-block;
        height: 48px;
        left: 5px;
        position: relative;
        top: 0px;
        width: 48px;
        margin-right: 10px;
        cursor: pointer;
        background-size: 500px 600px;
    }
    .activityLogBlock .topRowHaedingAnch .actionBlockDiv .detailDuplicate:hover {
        background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -119px -377px;
        background-size: 500px 600px;
    }
    .activityLogBlock .actionBlockDiv .detailAdd {
        background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 160px -398px;
        display: inline-block;
        height: 48px;
        left: 5px;
        position: relative;
        top: 0px;
        width: 48px;
        margin-right: 10px;
        cursor: pointer;
        background-size: 500px 600px;
    }
    .activityLogBlock .actionBlockDiv .detailAdd:hover {
        background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 215px -398px;
        background-size: 500px 600px;
    }
    .activityLogBlock .topRowHaedingAnch .actionBlockDiv .detailDelete {
        background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 1px -459px;
        display: inline-block;
        height: 48px;
        left: 5px;
        position: relative;
        top: 0px;
        width: 48px;
        margin-right: 10px;
        cursor: pointer;
        background-size: 500px 600px;
    }
    .activityLogBlock .topRowHaedingAnch .actionBlockDiv .detailDelete:hover {
        background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -119px -459px;
        background-size: 500px 600px;
    }
    .nav>li>a {
        padding: 5px 13px !important;
    }
    .nav>li>a:focus, .nav>li>a:hover, .nav>li>a.active {
        border-bottom: 2px solid;
        color: #0f6fb4;
        background: #fff !important;
        /* color: inherit; background: transparent; */
    }
    .activityLogBlock .topRowHaedingAnch .questionBlock {
        margin-top: 10px;
        /* height: 100%; */
        /* max-height: 450px; */
        overflow: auto;
        padding-top: 0px;
    }
    .activityLogBlock .topRowHaedingAnch .questionBlockh3.noteHeading {
        margin: 15px 0px;
    }
    .activityLogBlock .topRowHaedingAnch .questionBlock .listNumQuestion {
        position: relative;
        border-top: 1px solid #e8e8e8;
        margin-top: 30px;
    }
    .activityLogBlock .topRowHaedingAnch .questionBlock .listNumQuestion div#IFRAME_DIV img {
        width: auto;
        max-width: 100%;
    }
    .activityLogBlock .topRowHaedingAnch .questionBlock .listNumQuestionh4 {
        color: #1c1c1c;
        margin: 20px 0px;
    }
    .activityLogBlock .topRowHaedingAnch .questionBlock .listNumQuestionp {
        color: #505050;
        line-height: 19px;
        width: 92%;
        cursor: pointer;
    }
    .activityLogBlock .topRowHaedingAnch .questionBlock .listNumQuestionspan.DirectionArrow {
        background: rgb(255, 255, 255) var(--sprite2-url) repeat -176px -70px;
        display: inline-block;
        height: 22px;
        right: 0px;
        position: absolute;
        top: 50%;
        width: 18px;
        cursor: pointer;
        transform: rotate(180deg);
        background-size: 500px 600px;
    }
    .activityLogBlock .learningSlideTab {
        position: relative;
        padding-top: 30px;
    }
    .activityLogBlock .learningSlideTabh3 {
        display: inline-block;
        color: #1c1c1c;
    }
    .activityLogBlock .learningSlideTaba.seDeatil {
        margin: 0px 0px 0px 20px;
        display: inline-block;
    }
    .activityLogBlock .learningSlideTaba.seDeatilem {
        font-family: cursive;
        position: relative;
        top: 2px;
        display: inline-block;
        margin-left: 3px;
    }
    .activityLogBlock .learningSlideTabspan.cross {
        background: rgb(255, 255, 255) var(--sprite-url) repeat -234px -70px;
        display: inline-block;
        height: 27px;
        right: -13px;
        position: absolute;
        top: 10px;
        width: 23px;
        cursor: pointer;
        z-index: 999;
    }
    .activityLogBlock .learningSlideTab .actionBlockDiv {
        margin-top: 30px;
    }
    .activityLogBlock .learningSlideTab .actionBlockDiv .detailEdit {
        background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll 1px -298px;
        display: inline-block;
        height: 48px;
        left: 5px;
        position: relative;
        top: 0px;
        width: 48px;
        margin-right: 10px;
        cursor: pointer;
    }
    .activityLogBlock .learningSlideTab .actionBlockDiv .detailEdit:hover {
        background: rgba(0, 0, 0, 0) url(../images/sprite.svg) repeat scroll -148px -362px;
        background-size: 900px;
    }
    .activityLogBlock .learningSlideTab .actionBlockDiv .detailPrint {
        background: rgba(0, 0, 0, 0) url(../images/sprite.svg) repeat scroll -4px -449px;
        display: inline-block;
        height: 48px;
        left: 5px;
        position: relative;
        top: 0px;
        width: 47px;
        background-size: 1570px;
        margin-right: 10px;
        cursor: pointer;
    }
    .activityLogBlock .learningSlideTab .actionBlockDiv .detailPrint:hover {
        background: rgba(0, 0, 0, 0) url(../images/sprite.svg) repeat scroll -98px -459px;
    }
    .activityLogBlock .learningSlideTab .actionBlockDiv .detailDuplicate {
        background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll 1px -462px;
        display: inline-block;
        height: 48px;
        left: 5px;
        position: relative;
        top: 0px;
        width: 48px;
        margin-right: 10px;
        cursor: pointer;
    }
    .activityLogBlock .learningSlideTab .actionBlockDiv .detailDuplicate:hover {
        background: rgba(0, 0, 0, 0) url(../images/sprite.svg) repeat scroll -149px -451px;
        background-size: 900px;
    }
    .activityLogBlock .learningSlideTab .actionBlockDiv .detailDelete {
        background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll 1px -542px;
        display: inline-block;
        height: 48px;
        left: 5px;
        position: relative;
        top: 0px;
        width: 48px;
        margin-right: 10px;
        cursor: pointer;
    }
    .activityLogBlock .learningSlideTab .actionBlockDiv .detailDelete:hover {
        background: rgba(0, 0, 0, 0) url(../images/sprite.svg) repeat scroll -150px -538px;
        background-size: 900px;
    }
    .activityLogBlock .learningSlideTab .questionBlock {
        margin-top: 10px;
        height: auto;
        max-height: 450px;
        overflow: auto;
        padding-bottom: 150px;
    }
    .activityLogBlock .learningSlideTab .questionBlockh3.noteHeading {
        margin: 15px 0px;
    }
    .activityLogBlock .learningSlideTab .questionBlock .listNumQuestion {
        position: relative;
        border-top: 1px solid #e8e8e8;
        margin-top: 20px;
    }
    .activityLogBlock .learningSlideTab .questionBlock .listNumQuestionh4 {
        color: #1c1c1c;
        margin: 20px 0px;
    }
    .activityLogBlock .learningSlideTab .questionBlock .listNumQuestionp {
        color: #505050;
        line-height: 19px;
        width: 92%;
        cursor: pointer;
    }
    .activityLogBlock .learningSlideTab .questionBlock .listNumQuestionspan.DirectionArrow {
        background: rgba(0, 0, 0, 0) url(../images/sprite.svg) repeat scroll -349px 0px;
        display: inline-block;
        height: 22px;
        right: 10px;
        position: absolute;
        top: 50%;
        width: 13px;
        background-size: 1570px;
        cursor: pointer;
    }
    .listViewBlock.pulLeftClass {
        width: calc(87.3% - 433px);
        margin-left: 13.9%;
    }
    .activityLogBlock .innerQuestionBox {
        display: none;
        overflow: auto;
        padding-bottom: 10px;
        /* position: fixed; */
        height: 100%;
        padding-right: 20px;
        padding-top: 25px;
    }
    .activityLogBlock .innerQuestionBox img {
        width: auto;
        max-width: 100%;
        display: block;
        margin: auto;
    }
    .activityLogBlock .innerQuestionBox label img {
        width: auto;
        max-width: 100%;
        display: block;
        margin: auto;
    }
    .activityLogBlock .innerQuestionBox font img {
        width: auto;
        max-width: 100%;
        display: block;
        margin: auto;
    }
    .activityLogBlock .innerQuestionBox font span {
        font-size: inherit !important
    }
    .activityLogBlock .innerQuestionBox font div {
        font-size: inherit !important
    }
    .activityLogBlock .innerQuestionBox font p {
        font-size: inherit !important;
        -webkit-margin-before: 1em;
    }
    .activityLogBlock .innerQuestionBox font {
        display: inline-block;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .activityLogBlock .innerQuestionBox table tr td {
        vertical-align: top
    }
    .activityLogBlock .innerQuestionBox table tr td:first-child {
        width: 30%
    }
    .activityLogBlock .innerQuestionBox ul {
        display: block;
        list-style-type: disc;
        left: 4%;
        position: relative;
        width: 93%;
        -webkit-margin-before: 1em;
        -webkit-margin-after: 1em;
        -webkit-margin-start: 0px;
        -webkit-margin-end: 0px;
        -webkit-padding-start: 20px;
    }
    @-moz-document url-prefix() {
        .activityLogBlock .innerQuestionBox ul {
            left: 6%;
            position: relative;
            width: 93%;
        }
    }
    .activityLogBlock .innerQuestionBox ol {
        display: block;
        list-style-type: decimal;
        left: 4%;
        position: relative;
        width: 93%;
        -webkit-margin-before: 1em;
        -webkit-margin-after: 1em;
        -webkit-margin-start: 0px;
        -webkit-margin-end: 0px;
        -webkit-padding-start: 20px;
    }
    @-moz-document url-prefix() {
        .activityLogBlock .innerQuestionBox ol {
            left: 6%;
            position: relative;
            width: 93%;
        }
    }
    .activityLogBlock .topRowHaedingAnch .innerQuestionBoxh3 {
        padding: 4px 0px 24px 22px;
        border-bottom: 1px solid #e0e0e0;
        display: block;
        position: relative;
        width: 90%;
    }
    .activityLogBlock .innerQuestionBoxh3span.backArrow {
        background: rgb(255, 255, 255) var(--sprite-url) repeat -176px -70px;
        display: inline-block;
        height: 22px;
        left: 0px;
        position: absolute;
        top: 4px;
        width: 13px;
        cursor: pointer;
        transform: rotate(0deg);
    }
    .activityLogBlock .innerQuestionBoxh4 {
        margin-bottom: 22px;
        margin-top: 24px;
    }
    .activityLogBlock .innerQuestionBoxp {
        color: #505050;
        line-height: 20px;
    }
    .fixIconDiv {
        padding-top: 25px;
    }
    /*footer*/
    .footer {
        width: 100%;
        height: 50px;
        background: #f1f1f2;
        position: fixed;
        bottom: 0px;
        border-top: 1px solid #e5e6e6;
        z-index: 9;
    }
    .footerp {
        color: #909090;
        padding: 17px 0px;
        display: inline-block;
    }
    .footerul {
        display: inline-block;
        float: right;
        margin-right: 30px;
    }
    .footerulli {
        display: inline-block;
        margin-left: 14px;
        margin-top: 15px;
    }
    .footerullia {
        color: #505050;
    }
    @media only screen and (min-width: 266px) and (max-width: 340px) {
        .listviewblockBox .col-xs-2 {
            padding-left: 5px;
        }
    }
    @media only screen and (min-width: 266px) and (max-width: 767px) {
        .navbar-toggle {
            position: absolute;
            top: -60px;
        }
        .navbar-toggle .icon-bar {
            background: #0f6fb4;
        }
        .logoClass {
            padding-left: 32px;
            width: 180px;
            margin-top: 6px;
        }
        .navbar .navbar-nav {
            width: 100%;
            margin: 0;
            border-bottom: 3px solid #0f6fb4;
        }
        .navbar .navbar-nav.nav>li {
            border-bottom: 1px solid #4288bb;
            text-align: left;
            padding-left: 10px;
        }
        .navbar .navbar-nav.nav>li.noBod {
            border-bottom: none;
        }
        .MenupersonImg {
            background: #fff;
            border-bottom: none !important;
            padding: 18px 0px 5px 0px;
            margin: 0px;
            height: 59px;
        }
        .MenupersonImg a {
            color: #1c1c1c !important;
        }
        .MenupersonImg span {
            display: inline-block;
            height: 35px;
            position: relative;
            bottom: 7px;
            width: 35px;
            overflow: hidden;
            border-radius: 50px;
            float: right;
        }
        .MenupersonImg span img {
            width: 100%;
        }
        .navbar {
            margin-bottom: -59px !important;
            background: #f1f9ff;
        }
        .navbar .navbar-collapse {
            top: -7px;
            background: #FFFFFF;
            position: absolute;
            z-index: 9999;
            width: 100%;
            padding: 0;
        }
        .MenuTopBartask {
            background: #fff;
            border-bottom: none !important;
            color: #1c1c1c !important;
            margin: 0px;
            padding-left: 55px !important;
            padding-top: 5px;
        }
        .MenuTopBartask a {
            color: #1c1c1c !important;
        }
        .MenuTopBartask span {
            background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll 0px 1px;
            display: inline-block;
            height: 32px;
            left: -27px;
            position: absolute;
            top: 7px;
            width: 30px;
        }
        .MenuTopBarhelp {
            background: #fff;
            margin: 0px;
            border-bottom: none !important;
            padding-bottom: 15px;
            color: #1c1c1c !important;
            padding-top: 7px;
        }
        .MenuTopBarhelp a {
            color: #1c1c1c !important;
        }
        .MenuTopBarhelp span {
            background: #f7f7f8 url(/static/media/sprite-2x.425102e0.png) repeat scroll -51px -2px;
            height: 31px;
            position: relative;
            bottom: 6px;
            left: 32px;
            width: 30px;
            background-size: 500px 600px;
            float: right;
            border-radius: 20px;
        }
        .srcFieldCol {
            display: none !important;
        }
        .ViewFilter-sm {
            width: 100%;
            height: 50px;
            background: #f7f7f8;
            border: 1px solid #e0e0e0;
            border-left: none;
            color: #0f6fb4;
        }
        .ViewFilter-sm span {
            position: relative;
        }
        .ViewFilter-sm span:before {
            content: '';
            background: transparent var(--sprite-url) no-repeat scroll -327px 0px;
            position: absolute;
            left: -30px;
            top: -7px;
            width: 29px;
            height: 33px;
            display: inline-block;
            -ms-transform: scale(0.7, 0.7);
            -webkit-transform: scale(0.7, 0.7);
            transform: scale(0.7, 0.7);
        }
        .ViewFilter-sm:focus {
            background: #efefef;
            outline: none;
            border: 1px solid #e0e0e0;
        }
        .ViewSearch-sm {
            width: 100%;
            height: 50px;
            background: #f7f7f8;
            border: 1px solid #e0e0e0;
            border-right: none;
            color: #0f6fb4;
        }
        .ViewSearch-sm span {
            position: relative;
        }
        .ViewSearch-sm span:before {
            content: '';
            background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -100px 0px;
            position: absolute;
            left: -33px;
            top: -4px;
            width: 30px;
            height: 32px;
            display: inline-block;
            background-size: 500px 600px;
            -ms-transform: scale(0.7, 0.7);
            -webkit-transform: scale(0.7, 0.7);
            transform: scale(0.7, 0.7);
        }
        .ViewSearch-sm:focus {
            background: #efefef;
            outline: none;
            border: 1px solid #e0e0e0;
        }
        .smText h3 {
            text-align: center;
        }
        .smText .HideCross {
            background: rgb(255, 255, 255) var(--sprite-url) repeat -228px -70px;
            display: inline-block;
            height: 30px;
            right: 0px;
            position: absolute;
            top: 0px;
            width: 30px;
            margin-right: 13px;
            margin-top: 15px;
        }
        .search-sm-Box span.srcHideBox {
            background: rgba(14, 34, 48, 0.1) var(--sprite-url) repeat -329px -80px;
            display: inline-block;
            height: 30px;
            right: 0px;
            position: absolute;
            top: 0px;
            width: 30px;
            background-size: 580px;
            margin-right: 20px;
            margin-top: 15px;
        }
        .search-sm-Box {
            position: fixed;
            background: rgba(0, 0, 0, 0.8);
            width: 100%;
            height: 100%;
            z-index: 9999999999;
            top: 5000px;
            left: 0px;
            bottom: 0px;
            right: 0px;
            padding: 24% 10% 0% 10%;
            transition: all ease 0.2s;
        }
        .search-sm-Box .searchBtn {
            background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll -164px 1px;
            display: inline-block;
            height: 27px;
            left: 5px;
            position: relative;
            top: 0px;
            width: 50px;
        }
        .search-sm-Box .btn.btn-primary {
            padding: 0px;
        }
        .search-sm-Box .btn.btn-primary:hover {
            background: #0f6fb4;
        }
        .sercFilBtnBox.classTostick {
            position: fixed;
            width: 100%;
            top: 0px;
            z-index: 9999;
            background: #f7f7f8;
        }
        .mobileListView {}
        .mobileListView .listviewblockBox {
            background: #fff;
            width: 100%;
            padding: 15px 0px;
            float: left;
            border-bottom: 1px solid #e0e0e0;
        }
        .mobileListView .listviewblockBox.questionType .typeIcon {
            background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll 0px -70px;
            display: inline-block;
            height: 49px;
            left: 0px;
            position: relative;
            top: 4px;
            width: 52px;
            margin-right: 15px;
        }
        .mobileListView .listviewblockBox.flashcardType .typeIcon {
            background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll 0px -150px;
            display: inline-block;
            height: 49px;
            left: 0px;
            position: relative;
            top: 4px;
            width: 52px;
            margin-right: 15px;
        }
        .mobileListView .listviewblockBox.learningType .typeIcon {
            background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll 0px -222px;
            height: 53px;
            width: 55px;
            display: inline-block;
            height: 49px;
            left: 0px;
            position: relative;
            top: 4px;
            width: 52px;
            margin-right: 15px;
        }
        .mobileListView .listviewblockBox.QuesRead .typeIcon {
            background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -313px -466px / 500px 600px;
            display: inline-block;
            height: 49px;
            left: 0;
            margin-right: 15px;
            position: relative;
            top: 1px;
            width: 52px;
            float: left;
        }
        .mobileListView .listviewblockBox.QuesWrite .typeIcon {
            background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -315px -536px / 500px 600px;
            display: inline-block;
            height: 49px;
            left: 0;
            margin-right: 15px;
            position: relative;
            top: 1px;
            width: 52px;
            float: left;
        }
        .mobileListView .listviewblockBox .contentType {}
        .mobileListView .listviewblockBox .contentType h4 {
            color: #0f6fb4;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-bottom: 2px;
        }
        .mobileListView .listviewblockBox .contentType p {
            margin-top: 7px;
            color: #1c1c1c;
        }
        .mobileListView .listviewblockBox .contentType p strong {
            color: #555555;
        }
        .mobileListView .listviewblockBox .contentType p span {}
        .filterBlock {
            position: fixed;
            margin-top: 0px;
            height: 100%;
            width: 100%;
            background-color: transparent;
            background: #fff;
            border: none;
            padding-top: 20px;
            overflow: auto;
            z-index: 999999999999;
            top: 5000px;
            transition: all ease 0.2s;
            overflow: auto;
            padding-bottom: 30px;
        }
        .filterBlock .ApplyFilter {
            position: absolute;
            bottom: 0px;
            left: 0px;
            width: 100%;
            height: 50px;
            background: #44a9f2;
            border: 1px solid #44a9f2;
            color: #fff;
        }
        .noFilter {
            width: 100% !important;
            margin-left: 0px !important;
        }
        .listViewBlock {
            margin-left: auto;
            width: 100% !important;
            float: left;
            padding-bottom: 70px;
            margin-left: 0px !important;
            background-color: white;
        }
        .sercFilBtnBox.classTostick {
            position: fixed;
            width: 100%;
            top: 0px;
            z-index: 9999;
            background: #f7f7f8;
        }
        .navbar-nav>li>a:hover, .navbar-nav>li>a.active {
            color: inherit;
            background: inherit;
        }
        .scroll-top-wrapper {
            position: fixed;
            opacity: 0;
            border: 1px solid #888b8d;
            visibility: hidden;
            overflow: hidden;
            text-align: center;
            z-index: 99999999;
            width: 40px;
            height: 40px;
            line-height: 48px;
            right: 30px;
            bottom: 30px;
            padding-top: 2px;
            border-radius: 10px;
            transition: all 0.5s ease-in-out;
            transform: rotate(90deg);
            background: rgb(241, 249, 255) var(--sprite-url) repeat -197px -77px;
        }
        .scroll-top-wrapper:hover {
            background: #f1f9ff url(../images/sprite.svg) repeat scroll -335px 5px;
            height: 40px;
            width: 40px;
            transform: rotate(270deg);
            border: 1px solid #b2b7bb;
            background-size: 1570px;
        }
        .scroll-top-wrapper.show {
            visibility: visible;
            cursor: pointer;
            opacity: 1.0;
            background: rgb(255, 255, 255) var(--sprite-url) repeat -168px -64px;
            height: 40px;
            width: 40px;
            border: 1px solid #888b8d;
            transform: rotate(90deg);
        }
        .scroll-top-wrapper i.fa {
            line-height: inherit;
        }
        .overFlowScroll-sm {
            height: auto;
            overflow: auto;
            float: left;
            width: 100%;
            max-height: 80%;
            padding-bottom: 30px;
        }
        .mobAlertDiv {
            padding-right: 10px;
        }
        .topFirstBar .topBarProfileIcons .TopBarnotification {
            margin-right: 0px;
        }
        .HiddenLessLap {
            display: none !important;
        }
        .activityLogBlock.questionActivityMainBox.DivShow {
            display: none !important;
        }
        .listviewblockBox:hover {
            background: #f1f9ff;
        }
        .TopZero {
            top: 0px !important;
        }
        /*.container-fluid.noPad.listMainBoxWrapper { position: inherit; }*/
    }
    @media only screen and (min-width: 550px) and (max-width: 767px) {
        .listviewblockBox .col-xs-2 {
            width: 12%;
        }
        .listviewblockBox .col-xs-5 {
            width: 43%;
        }
    }
    @media only screen and (min-width: 768px) and (max-width: 1199px) {
        .hidden-tab {
            display: none !important;
        }
        .logoClass {
            width: 180px;
            margin-top: 6px;
        }
        .crtBtnCol {
            display: block !important;
        }
        .srcFieldCol {
            display: none !important;
        }
        .ViewFilter-sm {
            width: 100%;
            height: 50px;
            background: #f7f7f8;
            border: 1px solid #e0e0e0;
            border-left: none;
            color: #0f6fb4;
        }
        .ViewFilter-sm span {
            position: relative;
        }
        .ViewFilter-sm span:before {
            content: '';
            background: transparent var(--sprite2-url) no-repeat scroll -324px 0px;
            position: absolute;
            left: -30px;
            top: -7px;
            width: 29px;
            height: 33px;
            display: inline-block;
            background-size: 500px 600px;
            -ms-transform: scale(0.7, 0.7);
            -webkit-transform: scale(0.7, 0.7);
            transform: scale(0.7, 0.7);
        }
        .ViewFilter-sm:focus {
            background: #efefef;
            outline: none;
            border: 1px solid #e0e0e0;
        }
        .ViewSearch-sm {
            width: 100%;
            height: 50px;
            background: #f7f7f8;
            border: 1px solid #e0e0e0;
            border-right: none;
            color: #0f6fb4;
        }
        .ViewSearch-sm span {
            position: relative;
        }
        .ViewSearch-sm span:before {
            content: '';
            background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -100px 0px;
            -ms-transform: scale(0.7, 0.7);
            -webkit-transform: scale(0.7, 0.7);
            transform: scale(0.7, 0.7);
            position: absolute;
            left: -33px;
            top: -4px;
            width: 30px;
            height: 32px;
            display: inline-block;
            html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
                margin: 0;
                padding: 0;
                border: 0;
                ;
                font: inherit;
                vertical-align: baseline;
                font-family: 'Lato', sans-serif;
            }
            /* HTML5 display-role reset for older browsers */
            article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
                display: block;
                font-family: 'Lato', sans-serif;
            }
            body {
                line-height: 1;
                background: #f7f8f9;
            }
            .entry-header {
                display: none;
            }
            ol, ul {
                list-style: none;
            }
            blockquote, q {
                quotes: none;
            }
            blockquote:before, blockquote:after, q:before, q:after {
                content: '';
                content: none;
            }
            table {
                border-collapse: collapse;
                border-spacing: 0;
            }
            strong {}
            a {
                text-decoration: none;
            }
            img {
                pointer-events: none;
            }
            a, span, div {
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            }
            input:focus {
                -webkit-appearance: none;
                position: relative;
            }
            .button:focus, .button:hover, button:focus, button:hover, input[type=button]:focus, input[type=button]:hover, input[type=reset]:focus, input[type=reset]:hover, input[type=submit]:focus, input[type=submit]:hover {
                background-color: inherit;
            }
            input {
                -webkit-appearance: none;
            }
            select {
                -moz-appearance: none;
                text-indent: 0.01px;
                text-overflow: '';
                -webkit-appearance: none;
            }
            html, body {
                height: 100%;
            }
            /*public css*/
            .noPad {
                padding: 0px !important;
            }
            .m-t-30 {
                margin-top: 30px;
            }
            .DivShow {
                display: block !important;
            }
            img span.searchBtn:focus, .search-sm-Box .btn.btn-primary:focus {
                outline: none;
            }
            /*topBar right*/
            .topFirstBar {
                min-height: 60px;
                background: #fff;
            }
            .topFirstBarul.topBarProfileIcons {
                list-style: none;
                float: right;
            }
            .topFirstBarul.topBarProfileIconsli {
                display: inline-block;
                float: left;
            }
            .topFirstBarul.topBarProfileIconslia {
                min-width: 30px;
                min-height: 30px;
                display: block;
            }
            .topFirstBar .topBarProfileIcons .TopBartask {
                background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll 0px 1px;
                display: inline-block;
                height: 35px;
                width: 35px;
                margin-right: 20px;
                margin-top: 12px;
                background-size: 500px 600px;
            }
            .topFirstBar .topBarProfileIcons .TopBarhelp {
                background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll -49px 1px;
                display: inline-block;
                height: 35px;
                width: 35px;
                margin-right: 20px;
                margin-top: 13px;
            }
            .topFirstBar .topBarProfileIcons .TopBarnotification {
                background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -99px 1px;
                display: inline-block;
                height: 35px;
                width: 35px;
                margin-right: 20px;
                margin-top: 13px;
                background-size: 500px 600px;
            }
            .topFirstBar .topBarProfileIcons .TopBarnotificationspan {
                background: #fff;
                border: 1px solid #0f6fb4;
                color: #0f6fb4;
                padding: 2px 4px;
            }
            .topFirstBar .topBarProfileIconsa.profileImgLink {
                margin-top: 11px;
            }
            .topFirstBar .topBarProfileIconsa.profileImgLinkspan {
                color: #1c1c1c;
                line-height: 35px;
            }
            .topFirstBar .topBarProfileIconsa.profileImgLinkspan.personImg {
                width: 40px;
                height: 40px;
                display: inline-block;
                border-radius: 100%;
                text-align: center;
                overflow: hidden;
                position: static;
                top: auto;
                left: auto;
                float: left;
                margin-right: 8px;
            }
            .topFirstBar .topBarProfileIconsa.profileImgLinkspan.personImgimg {
                width: 100%;
            }
            .topFirstBar .topBarProfileIconsa.profileImgLinkspan.pername {
                color: #1c1c1c;
            }
            .navbar {
                background: #0f6fb4;
                border-radius: 0;
                height: 32px;
                margin-bottom: 0px;
            }
            .navbar .navbar-nav {
                display: inline-block;
                float: none;
                margin-top: -1px;
            }
            .navbar .navbar-collapse {
                text-align: center;
                padding-left: 0px;
                padding-right: 0px;
                margin-left: -15px !important;
                margin-right: -15px !important;
            }
            .navbar-nav>li>a {
                color: #fff;
                font-size: 13px !important;
            }
            .navbar-nav>li>a:hover {
                text-decoration: none;
            }
            .navbar-nav>li>a:hover {
                color: #0f6fb4;
                background: #f7f7f8;
            }
            /*searchBar strip*/
            .searchCrtBar .showLabel {
                padding-top: 7px !important;
                width: 25%;
                text-align: right;
                color: #fff;
            }
            .searchCrtBar {
                height: 65px;
                background: rgb(15, 111, 180);
                box-sizing: border-box;
                padding-top: 16px;
                /*border-bottom: 1px solid #eeeeee;*/
                border-bottom: 1px solid #ddd;
                color: #fff;
            }
            .searchCrtBar .searchBtn:focus {
                outline: none;
                border: none;
            }
            .searchCrtBar .btn.btn-primary:focus {
                outline: none;
            }
            .searchCrtBar .btn.btn-primary {
                padding: 0px;
            }
            .searchCrtBar .btn.btn-primary:hover {
                background: #0f6fb4;
            }
            .searchCrtBarh3 {
                color: #fff;
                margin-top: 6px;
                padding-left: 5px;
            }
            .searchCrtBar .selctBox {
                height: 31px !important;
                width: 100%;
                display: inline-block;
                border-radius: 30px;
                background: transparent;
                border: none;
                box-shadow: none;
                z-index: 2;
            }
            .searchCrtBar .slctCol .label {
                line-height: 32px;
            }
            .slctCol {
                width: 150px;
                padding: 0;
                margin-right: 22px;
                padding-left: 0px !important;
                padding-right: 0px !important;
            }
            .searchCrtBar .crtBtn {
                padding: 7px 15px 9px 31px !important;
                height: 38px;
                margin-top: -3px;
                background-color: #44a9f2;
                border-color: #44a9f2;
                float: right;
                line-height: 3px;
                width: 95%;
                position: relative;
                min-width: 120px;
            }
            .searchCrtBar .crtBtnspan {
                margin-right: 0px;
                position: absolute;
                left: 10px;
                background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll -273px -5px;
                width: 20px;
                height: 20px;
                top: 6px;
            }
            .searchCrtBar .backBtn {
                padding-left: 15px !important;
                padding-right: 15px !important;
                height: 38px;
                margin-top: -3px;
                background-color: #44a9f2;
                border-color: #44a9f2;
                line-height: 3px;
                position: relative;
                min-width: 120px;
            }
            .searchCrtBar .searchBtn {
                background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll -164px 1px;
                background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -164px -3px;
                display: inline-block;
                height: 27px;
                left: 5px;
                position: relative;
                top: 0px;
                width: 50px;
                background-size: 500px 600px;
            }
            .searchCrtBar .searchBtn:focus {
                outline: none;
                border: none;
            }
            .searchCrtBar .btn.btn-primary:focus {
                outline: none;
            }
            .searchCrtBar .btn.btn-primary {
                padding: 0px;
            }
            .searchCrtBar .btn.btn-primary:hover {
                background: #0f6fb4;
            }
            .BoxSelMain {
                position: relative;
                display: inline-block;
                width: 55%;
                background: #fff;
                border: 1px solid #ccc;
                border-radius: 20px;
                margin-right: 10px;
                float: right;
            }
            .BoxSelMainspan.tickyArrow {
                background: transparent var(--sprite2-url) no-repeat scroll -220px -9px;
                position: absolute;
                right: 9px;
                top: 8px;
                width: 29px;
                height: 26px;
                display: inline-block;
                background-size: 500px 600px;
            }
            /*filter left slide*/
            .container-fluid.noPad.listMainBoxWrapper {
                float: left;
                height: 100%;
                width: 100%;
                overflow: auto;
            }
            .filterBlock {
                /*margin-top: -1px;*/
                height: calc(100% - 172px);
                width: 220px;
                background-color: transparent;
                padding-left: 24px;
                background: #f7f8f9;
                border: 1px solid #eeeeee;
                border-left: hidden;
                padding-top: 20px;
                overflow: auto;
                padding-bottom: 20px;
                float: left;
                width: 13.9%;
                position: fixed;
            }
            .filterBlockinput[type=checkbox].css-checkbox {
                position: absolute;
                z-index: -1000;
                left: -1000px;
                overflow: hidden;
                clip: rect(0 0 0 0);
                height: auto;
                width: 100%;
                margin: -1px;
                padding: 0;
                border: 0;
            }
            .filterBlockinput[type=checkbox].css-checkbox+label.css-label1 {
                padding-left: 30px;
                height: 24px;
                width: 100%;
                display: inline-block;
                background-repeat: no-repeat;
                background-position: -470px -3px;
                vertical-align: middle;
                cursor: pointer;
                line-height: 22px;
                color: #555555;
                background-size: 500px 600px;
            }
            .filterBlockinput[type=checkbox].css-checkbox:checked+label.css-label1 {
                background-position: -429px -37px
            }
            .filterBlocklabel.css-label1 {
                background-image: var(--sprite2-url);
                -webkit-touch-callout: none;
                -webkit-user-select: none;
                -khtml-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
            }
            .innerQuestionBoxinput[type=radio].css-checkbox {
                position: absolute;
                z-index: -1000;
                left: -1000px;
                overflow: hidden;
                clip: rect(0 0 0 0);
                height: 1px;
                width: 1px;
                margin: -1px;
                padding: 0;
                border: 0;
            }
            .innerQuestionBoxinput[type=radio].css-checkbox+label.css-label {
                padding-left: 32px;
                min-height: 35px;
                display: inline-block;
                background-repeat: no-repeat;
                background-position: -470px -92px;
                vertical-align: middle;
                cursor: pointer;
                background-size: 500px 600px;
            }
            .innerQuestionBoxinput[type=radio].css-checkbox:checked+label.css-label {
                background-position: -432px -126px;
                -khtml-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
            }
            label.css-label {
                background-image: var(--sprite2-url);
                -webkit-touch-callout: none;
                -webkit-user-select: none;
                -khtml-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
            }
            .filterBlock .fieldBox {
                margin: 10px 0px;
            }
            .filterBlockh3 {
                color: #1c1c1c;
                margin-bottom: 15px;
            }
            .filterBlock .limitFilter {
                height: 79px;
                overflow: hidden;
            }
            .filterBlock .limitFilter.seeAll {
                height: auto;
                overflow: auto;
            }
            .filterBlock .seeMore {
                color: #555555;
                margin-top: 15px;
                cursor: pointer;
            }
            .filterBlock .unSelAll {
                color: #0174c7;
                display: block;
                margin-top: 25px;
                cursor: pointer;
            }
            /*list view */
            .noFilter {
                width: 100% !important;
                margin-left: 0px !important;
            }
            .listViewBlock {
                width: 86.1%;
                float: left;
                padding-bottom: 40px;
                margin-left: 13.9%;
                background-color: white;
            }
            .listViewBlock .blockForlist {
                background: #fff;
                /* max-height: 550px; */
                /* overflow: auto; */
                /* height: 100%; */
                /* position: fixed; */
                /* width: 86%; */
            }
            /*.listToBesslide{ max-height: 550px;overflow: auto;}*/
            .listViewBlocktable {
                width: 99%;
            }
            .listViewBlocktable.table-hovertheadtrth, .listViewBlocktable.table-hovertbodytrtd {
                padding-left: 15px;
                vertical-align: middle;
                color: #555555;
            }
            .listViewBlocktable.table-hovertheadtrth {
                color: #1c1c1c;
                height: 52px;
                border-bottom: 1px solid #e0e0e0;
            }
            .listViewBlocktable.table-hovertbodytr.questionRow {}
            .listViewBlocktable.table-hovertbodytr.questionRow .typeIcon {
                background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 0px -71px;
                display: inline-block;
                height: 49px;
                left: 0px;
                position: relative;
                top: 1px;
                width: 52px;
                margin-right: 15px;
                background-size: 500px 600px;
            }
            .listViewBlocktable.table-hovertbodytr.flashCard .typeIcon {
                background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 0px -150px;
                display: inline-block;
                height: 49px;
                left: 0px;
                position: relative;
                top: 4px;
                width: 52px;
                margin-right: 15px;
                background-size: 500px 600px;
            }
            .listViewBlocktable.table-hovertbodytr.learning .typeIcon {
                background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 0px -222px;
                display: inline-block;
                height: 49px;
                left: 0px;
                position: relative;
                top: 4px;
                width: 52px;
                margin-right: 15px;
                background-size: 500px 600px;
            }
            .listViewBlocktable.table-hovertbodytr .contentType {
                display: inline-block;
                position: relative;
                top: -5px;
            }
            .listViewBlocktable.table-hovertbodytr .contentTypeh4 {
                color: #0f6fb4;
                /*  */
            }
            .listViewBlocktable.table-hovertbodytr .contentTypep {
                color: #909090;
            }
            .listViewBlocktable.table-hovertbodytr .contentTypepstrong {
                color: #555555;
            }
            .listViewBlocktable.table-hovertbodytrtd .DropDivBlock {
                cursor: pointer;
                position: relative;
                text-align: center;
                padding-bottom: 10px;
                line-height: 40px;
            }
            .listViewBlocktable.table-hovertbodytrtd .DropDivBlockspan.bullets {
                width: 6px;
                height: 6px;
                display: inline-block;
                background: #0f6fb4;
                border-radius: 100%;
            }
            .listViewBlocktable.table-hovertbodytrtd .DropDivBlock .dropBoxDiv {
                position: absolute;
                width: 130px;
                height: 115px;
                left: -84px;
                top: 29px;
                background: #fff;
                box-shadow: 0px 0px 10px #ccc;
                display: none;
                z-index: 222;
            }
            .listViewBlocktable.table-hovertbodytrtd .DropDivBlock .dropBoxDivul {}
            .listViewBlocktable.table-hovertbodytrtd .DropDivBlock .dropBoxDivulli {
                height: 35px;
                display: block;
            }
            .listViewBlocktable.table-hovertbodytrtd .DropDivBlock .dropBoxDivullia {
                display: block;
                padding: 7px 15px 5px 15px;
                color: #555555;
                border-bottom: 1px solid #ebebeb;
                text-align: left;
            }
            .listViewBlocktable.table-hovertbodytrtd .DropDivBlock .dropBoxDivulli:last-childa {
                border: none;
            }
            .listViewBlocktable.table-hovertbodytrtd .DropDivBlock .dropBoxDiv:before {
                content: '';
                position: absolute;
                right: 0px;
                top: -20px;
                border: 10px solid rgba(255, 255, 255, 0.1);
                display: block;
                border-bottom-color: rgb(184, 184, 184);
            }
            .listViewBlocktable.table-hovertbodytrtd .DropDivBlock .dropBoxDiv:after {
                content: '';
                content: '';
                position: absolute;
                right: 0px;
                top: -19px;
                border: 10px solid rgba(255, 255, 255, 0.1);
                display: block;
                border-bottom-color: rgb(255, 255, 255);
            }
            .listViewBlocktable.table-hovertbodytrtd .DropDivBlock:hover .dropBoxDiv {
                display: block;
            }
            .listViewBlock .table-hover>tbody>tr:hover {
                background-color: #f1f9ff;
                cursor: pointer;
            }
            .pagination>li>a:focus, .pagination>li>a:hover, .pagination>li>span:focus, .pagination>li>span:hover {
                z-index: 2;
                color: #555555;
                background-color: #f7f8f9;
                border-color: #f7f8f9;
            }
            .paginationspan.DirectionArrow {
                background: rgb(255, 255, 255) var(--sprite-url) repeat -176px -70px;
                display: inline-block;
                height: 22px;
                position: absolute;
                width: 18px;
                cursor: pointer;
                transform: rotate(180deg);
            }
            .paginationspan.DirectionArrow.left {
                left: -13px;
                transform: rotate(0deg);
                top: 8px;
            }
            .paginationspan.DirectionArrow.right {
                right: -13px;
                top: 13px;
            }
            .pagination>li>a, .pagination>li>span {
                position: relative;
                float: left;
                padding: 6px 12px;
                margin-left: -1px;
                line-height: 1.42857143;
                color: #555555;
                text-decoration: none;
                background-color: #f7f8f9;
                border: 1px solid #f7f8f9;
            }
            .pagination>.active>a, .pagination>.active>a:hover, .pagination>.active>a:focus {
                color: #337ab7;
                background: transparent;
                border: none;
                line-height: 24px;
            }
            .pagination>li>a {
                padding: 10px 5px;
            }
            .pagination>li:first-child>a, .pagination>li:last-child>a {
                padding: 10px 20px;
            }
            /*activity block*/
            .activityLogBlock {
                width: 430px;
                height: calc(100% - 225px);
                overflow: auto;
                background: #fff;
                box-shadow: -3px 3px 8px #ccc;
                right: 0px;
                padding: 0px 30px 30px 30px;
                float: left;
                margin-left: 3px;
                display: none;
                /* height:auto; */
                position: fixed;
            }
            .activityLogBlock .topRowHaedingAnch {
                position: relative;
            }
            /*.scrollBar{position: absolute;height: 100%;width: 10px;right: -2px;background: #fff;z-index: 999;opacity:1; transition:all ease 0.5s}  .questionBlock:hover .scrollBar{opacity:1; transition:all ease 0.5s}*/
            .activityLogBlock .topRowHaedingAnchh3 {
                display: inline-block;
                color: #1c1c1c;
            }
            .activityLogBlock .topRowHaedingAncha.seDeatil {
                margin: 0px 0px 0px 20px;
                display: inline-block;
            }
            .activityLogBlock .topRowHaedingAncha.seDeatilem {
                font-family: cursive;
                position: relative;
                top: 2px;
                display: inline-block;
                margin-left: 3px;
            }
            .activityLogBlock .topRowHaedingAnchspan.cross {
                background: rgb(255, 255, 255) var(--sprite2-url) repeat -234px -70px;
                display: inline-block;
                height: 27px;
                right: -21px;
                position: absolute;
                top: 13px;
                width: 23px;
                cursor: pointer;
                z-index: 999;
                background-size: 500px 600px;
            }
            .activityLogBlock .topRowHaedingAnch .actionBlockDiv {
                margin-top: 30px;
            }
            .activityLogBlock .topRowHaedingAnch .actionBlockDiv .detailEdit {
                background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 1px -296px;
                display: inline-block;
                height: 48px;
                left: 5px;
                position: relative;
                top: 0px;
                width: 48px;
                margin-right: 10px;
                cursor: pointer;
                background-size: 500px 600px;
            }
            .activityLogBlock .topRowHaedingAnch .actionBlockDiv .detailEdit:hover {
                background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll -120px -298px;
            }
            .activityLogBlock .topRowHaedingAnch .actionBlockDiv .detailPrint {
                background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 1px -539px;
                display: inline-block;
                height: 48px;
                left: 5px;
                position: relative;
                top: 0px;
                width: 48px;
                margin-right: 10px;
                cursor: pointer;
                background-size: 500px 600px;
            }
            .activityLogBlock .topRowHaedingAnch .actionBlockDiv .detailPrint:hover {
                background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -118px -539px;
                background-size: 500px 600px;
            }
            .activityLogBlock .topRowHaedingAnch .actionBlockDiv .detailDuplicate {
                background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 1px -377px;
                display: inline-block;
                height: 48px;
                left: 5px;
                position: relative;
                top: 0px;
                width: 48px;
                margin-right: 10px;
                cursor: pointer;
                background-size: 500px 600px;
            }
            .activityLogBlock .topRowHaedingAnch .actionBlockDiv .detailDuplicate:hover {
                background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -119px -377px;
                background-size: 500px 600px;
            }
            .activityLogBlock .actionBlockDiv .detailAdd {
                background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 160px -398px;
                display: inline-block;
                height: 48px;
                left: 5px;
                position: relative;
                top: 0px;
                width: 48px;
                margin-right: 10px;
                cursor: pointer;
                background-size: 500px 600px;
            }
            .activityLogBlock .actionBlockDiv .detailAdd:hover {
                background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 215px -398px;
                background-size: 500px 600px;
            }
            .activityLogBlock .topRowHaedingAnch .actionBlockDiv .detailDelete {
                background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 1px -459px;
                display: inline-block;
                height: 48px;
                left: 5px;
                position: relative;
                top: 0px;
                width: 48px;
                margin-right: 10px;
                cursor: pointer;
                background-size: 500px 600px;
            }
            .activityLogBlock .topRowHaedingAnch .actionBlockDiv .detailDelete:hover {
                background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -119px -459px;
                background-size: 500px 600px;
            }
            .nav>li>a {
                padding: 5px 13px !important;
            }
            .nav>li>a:focus, .nav>li>a:hover, .nav>li>a.active {
                border-bottom: 2px solid;
                color: #0f6fb4;
                background: #fff !important;
                /* color: inherit; background: transparent; */
            }
            .activityLogBlock .topRowHaedingAnch .questionBlock {
                margin-top: 10px;
                /* height: 100%; */
                /* max-height: 450px; */
                overflow: auto;
                padding-top: 0px;
            }
            .activityLogBlock .topRowHaedingAnch .questionBlockh3.noteHeading {
                margin: 15px 0px;
            }
            .activityLogBlock .topRowHaedingAnch .questionBlock .listNumQuestion {
                position: relative;
                border-top: 1px solid #e8e8e8;
                margin-top: 30px;
            }
            .activityLogBlock .topRowHaedingAnch .questionBlock .listNumQuestion div#IFRAME_DIV img {
                width: auto;
                max-width: 100%;
            }
            .activityLogBlock .topRowHaedingAnch .questionBlock .listNumQuestionh4 {
                color: #1c1c1c;
                margin: 20px 0px;
            }
            .activityLogBlock .topRowHaedingAnch .questionBlock .listNumQuestionp {
                color: #505050;
                line-height: 19px;
                width: 92%;
                cursor: pointer;
            }
            .activityLogBlock .topRowHaedingAnch .questionBlock .listNumQuestionspan.DirectionArrow {
                background: rgb(255, 255, 255) var(--sprite2-url) repeat -176px -70px;
                display: inline-block;
                height: 22px;
                right: 0px;
                position: absolute;
                top: 50%;
                width: 18px;
                cursor: pointer;
                transform: rotate(180deg);
                background-size: 500px 600px;
            }
            .activityLogBlock .learningSlideTab {
                position: relative;
                padding-top: 30px;
            }
            .activityLogBlock .learningSlideTabh3 {
                display: inline-block;
                color: #1c1c1c;
            }
            .activityLogBlock .learningSlideTaba.seDeatil {
                margin: 0px 0px 0px 20px;
                display: inline-block;
            }
            .activityLogBlock .learningSlideTaba.seDeatilem {
                font-family: cursive;
                position: relative;
                top: 2px;
                display: inline-block;
                margin-left: 3px;
            }
            .activityLogBlock .learningSlideTabspan.cross {
                background: rgb(255, 255, 255) var(--sprite-url) repeat -234px -70px;
                display: inline-block;
                height: 27px;
                right: -13px;
                position: absolute;
                top: 10px;
                width: 23px;
                cursor: pointer;
                z-index: 999;
            }
            .activityLogBlock .learningSlideTab .actionBlockDiv {
                margin-top: 30px;
            }
            .activityLogBlock .learningSlideTab .actionBlockDiv .detailEdit {
                background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll 1px -298px;
                display: inline-block;
                height: 48px;
                left: 5px;
                position: relative;
                top: 0px;
                width: 48px;
                margin-right: 10px;
                cursor: pointer;
            }
            .activityLogBlock .learningSlideTab .actionBlockDiv .detailEdit:hover {
                background: rgba(0, 0, 0, 0) url(../images/sprite.svg) repeat scroll -148px -362px;
                background-size: 900px;
            }
            .activityLogBlock .learningSlideTab .actionBlockDiv .detailPrint {
                background: rgba(0, 0, 0, 0) url(../images/sprite.svg) repeat scroll -4px -449px;
                display: inline-block;
                height: 48px;
                left: 5px;
                position: relative;
                top: 0px;
                width: 47px;
                background-size: 1570px;
                margin-right: 10px;
                cursor: pointer;
            }
            .activityLogBlock .learningSlideTab .actionBlockDiv .detailPrint:hover {
                background: rgba(0, 0, 0, 0) url(../images/sprite.svg) repeat scroll -98px -459px;
            }
            .activityLogBlock .learningSlideTab .actionBlockDiv .detailDuplicate {
                background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll 1px -462px;
                display: inline-block;
                height: 48px;
                left: 5px;
                position: relative;
                top: 0px;
                width: 48px;
                margin-right: 10px;
                cursor: pointer;
            }
            .activityLogBlock .learningSlideTab .actionBlockDiv .detailDuplicate:hover {
                background: rgba(0, 0, 0, 0) url(../images/sprite.svg) repeat scroll -149px -451px;
                background-size: 900px;
            }
            .activityLogBlock .learningSlideTab .actionBlockDiv .detailDelete {
                background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll 1px -542px;
                display: inline-block;
                height: 48px;
                left: 5px;
                position: relative;
                top: 0px;
                width: 48px;
                margin-right: 10px;
                cursor: pointer;
            }
            .activityLogBlock .learningSlideTab .actionBlockDiv .detailDelete:hover {
                background: rgba(0, 0, 0, 0) url(../images/sprite.svg) repeat scroll -150px -538px;
                background-size: 900px;
            }
            .activityLogBlock .learningSlideTab .questionBlock {
                margin-top: 10px;
                height: auto;
                max-height: 450px;
                overflow: auto;
                padding-bottom: 150px;
            }
            .activityLogBlock .learningSlideTab .questionBlockh3.noteHeading {
                margin: 15px 0px;
            }
            .activityLogBlock .learningSlideTab .questionBlock .listNumQuestion {
                position: relative;
                border-top: 1px solid #e8e8e8;
                margin-top: 20px;
            }
            .activityLogBlock .learningSlideTab .questionBlock .listNumQuestionh4 {
                color: #1c1c1c;
                margin: 20px 0px;
            }
            .activityLogBlock .learningSlideTab .questionBlock .listNumQuestionp {
                color: #505050;
                line-height: 19px;
                width: 92%;
                cursor: pointer;
            }
            .activityLogBlock .learningSlideTab .questionBlock .listNumQuestionspan.DirectionArrow {
                background: rgba(0, 0, 0, 0) url(../images/sprite.svg) repeat scroll -349px 0px;
                display: inline-block;
                height: 22px;
                right: 10px;
                position: absolute;
                top: 50%;
                width: 13px;
                background-size: 1570px;
                cursor: pointer;
            }
            .listViewBlock.pulLeftClass {
                width: calc(87.3% - 433px);
                margin-left: 13.9%;
            }
            .activityLogBlock .innerQuestionBox {
                display: none;
                overflow: auto;
                padding-bottom: 10px;
                /* position: fixed; */
                height: 100%;
                padding-right: 20px;
                padding-top: 25px;
            }
            .activityLogBlock .topRowHaedingAnch .innerQuestionBoxh3 {
                padding: 4px 0px 24px 22px;
                border-bottom: 1px solid #e0e0e0;
                display: block;
                position: relative;
                width: 90%;
            }
            .activityLogBlock .innerQuestionBoxh3span.backArrow {
                background: rgb(255, 255, 255) var(--sprite-url) repeat -176px -70px;
                display: inline-block;
                height: 22px;
                left: 0px;
                position: absolute;
                top: 4px;
                width: 13px;
                cursor: pointer;
                transform: rotate(0deg);
            }
            .activityLogBlock .innerQuestionBoxh4 {
                margin-bottom: 22px;
                margin-top: 24px;
            }
            .activityLogBlock .innerQuestionBoxp {
                color: #505050;
                line-height: 20px;
            }
            .activityLogBlock .innerQuestionBox img {
                width: auto;
                max-width: 100%;
            }
            .activityLogBlock .innerQuestionBox label img {
                width: auto;
                max-width: 100%;
            }
            .activityLogBlock .innerQuestionBox font img {
                width: auto;
                max-width: 100%;
            }
            .activityLogBlock .innerQuestionBox font span {
                font-size: inherit !important
            }
            .activityLogBlock .innerQuestionBox font div {
                font-size: inherit !important
            }
            .activityLogBlock .innerQuestionBox font p {
                font-size: inherit !important;
                -webkit-margin-before: 1em;
            }
            .activityLogBlock .innerQuestionBox font {
                display: inline-block;
                padding-top: 5px;
                padding-bottom: 5px;
            }
            .activityLogBlock .innerQuestionBox table tr td {
                vertical-align: top
            }
            .activityLogBlock .innerQuestionBox table tr td:first-child {
                width: 30%
            }
            .activityLogBlock .innerQuestionBox ul {
                display: block;
                list-style-type: disc;
                left: 4%;
                position: relative;
                width: 93%;
                -webkit-margin-before: 1em;
                -webkit-margin-after: 1em;
                -webkit-margin-start: 0px;
                -webkit-margin-end: 0px;
                -webkit-padding-start: 20px;
            }
            @-moz-document url-prefix() {
                .activityLogBlock .innerQuestionBox ul {
                    left: 6%;
                    position: relative;
                    width: 93%;
                }
            }
            .activityLogBlock .innerQuestionBox ol {
                display: block;
                list-style-type: decimal;
                left: 4%;
                position: relative;
                width: 93%;
                -webkit-margin-before: 1em;
                -webkit-margin-after: 1em;
                -webkit-margin-start: 0px;
                -webkit-margin-end: 0px;
                -webkit-padding-start: 20px;
            }
            @-moz-document url-prefix() {
                .activityLogBlock .innerQuestionBox ol {
                    left: 6%;
                    position: relative;
                    width: 93%;
                }
            }
            .fixIconDiv {
                padding-top: 25px;
            }
            /*footer*/
            .footer {
                width: 100%;
                height: 50px;
                background: #f1f1f2;
                position: fixed;
                bottom: 0px;
                border-top: 1px solid #e5e6e6;
                z-index: 9;
            }
            .footerp {
                color: #909090;
                padding: 17px 0px;
                display: inline-block;
            }
            .footerul {
                display: inline-block;
                float: right;
                margin-right: 30px;
            }
            .footerulli {
                display: inline-block;
                margin-left: 14px;
                margin-top: 15px;
            }
            .footerullia {
                color: #505050;
            }
            @media only screen and (min-width: 266px) and (max-width: 340px) {
                .listviewblockBox .col-xs-2 {
                    padding-left: 5px;
                }
            }
            @media only screen and (min-width: 266px) and (max-width: 767px) {
                .navbar-toggle {
                    position: absolute;
                    top: -60px;
                }
                .navbar-toggle .icon-bar {
                    background: #0f6fb4;
                }
                .logoClass {
                    padding-left: 32px;
                    width: 180px;
                    margin-top: 6px;
                }
                .navbar .navbar-nav {
                    width: 100%;
                    margin: 0;
                    border-bottom: 3px solid #0f6fb4;
                }
                .navbar .navbar-nav.nav>li {
                    border-bottom: 1px solid #4288bb;
                    text-align: left;
                    padding-left: 10px;
                }
                .navbar .navbar-nav.nav>li.noBod {
                    border-bottom: none;
                }
                .MenupersonImg {
                    background: #fff;
                    border-bottom: none !important;
                    padding: 18px 0px 5px 0px;
                    margin: 0px;
                    height: 59px;
                }
                .MenupersonImg a {
                    color: #1c1c1c !important;
                }
                .MenupersonImg span {
                    display: inline-block;
                    height: 35px;
                    position: relative;
                    bottom: 7px;
                    width: 35px;
                    overflow: hidden;
                    border-radius: 50px;
                    float: right;
                }
                .MenupersonImg span img {
                    width: 100%;
                }
                .navbar {
                    margin-bottom: -59px !important;
                    background: #f1f9ff;
                }
                .navbar .navbar-collapse {
                    top: -7px;
                    background: #FFFFFF;
                    position: absolute;
                    z-index: 9999;
                    width: 100%;
                    padding: 0;
                }
                .MenuTopBartask {
                    background: #fff;
                    border-bottom: none !important;
                    color: #1c1c1c !important;
                    margin: 0px;
                    padding-left: 55px !important;
                    padding-top: 5px;
                }
                .MenuTopBartask a {
                    color: #1c1c1c !important;
                }
                .MenuTopBartask span {
                    background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll 0px 1px;
                    display: inline-block;
                    height: 32px;
                    left: -27px;
                    position: absolute;
                    top: 7px;
                    width: 30px;
                }
                .MenuTopBarhelp {
                    background: #fff;
                    margin: 0px;
                    border-bottom: none !important;
                    padding-bottom: 15px;
                    color: #1c1c1c !important;
                    padding-top: 7px;
                }
                .MenuTopBarhelp a {
                    color: #1c1c1c !important;
                }
                .MenuTopBarhelp span {
                    background: #f7f7f8 url(/static/media/sprite-2x.425102e0.png) repeat scroll -51px -2px;
                    height: 31px;
                    position: relative;
                    bottom: 6px;
                    left: 32px;
                    width: 30px;
                    background-size: 500px 600px;
                    float: right;
                    border-radius: 20px;
                }
                .srcFieldCol {
                    display: none !important;
                }
                .ViewFilter-sm {
                    width: 100%;
                    height: 50px;
                    background: #f7f7f8;
                    border: 1px solid #e0e0e0;
                    border-left: none;
                    color: #0f6fb4;
                }
                .ViewFilter-sm span {
                    position: relative;
                }
                .ViewFilter-sm span:before {
                    content: '';
                    background: transparent var(--sprite-url) no-repeat scroll -327px 0px;
                    position: absolute;
                    left: -30px;
                    top: -7px;
                    width: 29px;
                    height: 33px;
                    display: inline-block;
                    -ms-transform: scale(0.7, 0.7);
                    -webkit-transform: scale(0.7, 0.7);
                    transform: scale(0.7, 0.7);
                }
                .ViewFilter-sm:focus {
                    background: #efefef;
                    outline: none;
                    border: 1px solid #e0e0e0;
                }
                .ViewSearch-sm {
                    width: 100%;
                    height: 50px;
                    background: #f7f7f8;
                    border: 1px solid #e0e0e0;
                    border-right: none;
                    color: #0f6fb4;
                }
                .ViewSearch-sm span {
                    position: relative;
                }
                .ViewSearch-sm span:before {
                    content: '';
                    background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll -100px 0px;
                    position: absolute;
                    left: -33px;
                    top: -4px;
                    width: 30px;
                    height: 32px;
                    display: inline-block;
                    -ms-transform: scale(0.7, 0.7);
                    -webkit-transform: scale(0.7, 0.7);
                    transform: scale(0.7, 0.7);
                }
                .ViewSearch-sm:focus {
                    background: #efefef;
                    outline: none;
                    border: 1px solid #e0e0e0;
                }
                .smText h3 {
                    text-align: center;
                }
                .smText .HideCross {
                    background: rgb(255, 255, 255) var(--sprite-url) repeat -228px -70px;
                    display: inline-block;
                    height: 30px;
                    right: 0px;
                    position: absolute;
                    top: 0px;
                    width: 30px;
                    margin-right: 13px;
                    margin-top: 15px;
                }
                .search-sm-Box span.srcHideBox {
                    background: rgba(14, 34, 48, 0.1) var(--sprite-url) repeat -329px -80px;
                    display: inline-block;
                    height: 30px;
                    right: 0px;
                    position: absolute;
                    top: 0px;
                    width: 30px;
                    background-size: 580px;
                    margin-right: 20px;
                    margin-top: 15px;
                }
                .search-sm-Box {
                    position: fixed;
                    background: rgba(0, 0, 0, 0.8);
                    width: 100%;
                    height: 100%;
                    z-index: 9999999999;
                    top: 5000px;
                    left: 0px;
                    bottom: 0px;
                    right: 0px;
                    padding: 24% 10% 0% 10%;
                    transition: all ease 0.2s;
                }
                .search-sm-Box .searchBtn {
                    background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll -164px 1px;
                    display: inline-block;
                    height: 27px;
                    left: 5px;
                    position: relative;
                    top: 0px;
                    width: 50px;
                }
                .search-sm-Box .btn.btn-primary {
                    padding: 0px;
                }
                .search-sm-Box .btn.btn-primary:hover {
                    background: #0f6fb4;
                }
                .sercFilBtnBox.classTostick {
                    position: fixed;
                    width: 100%;
                    top: 0px;
                    z-index: 9999;
                    background: #f7f7f8;
                }
                .mobileListView {}
                .mobileListView .listviewblockBox {
                    background: #fff;
                    width: 100%;
                    padding: 15px 0px;
                    float: left;
                    border-bottom: 1px solid #e0e0e0;
                }
                .mobileListView .listviewblockBox.questionType .typeIcon {
                    background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll 0px -70px;
                    display: inline-block;
                    height: 49px;
                    left: 0px;
                    position: relative;
                    top: 4px;
                    width: 52px;
                    margin-right: 15px;
                }
                .mobileListView .listviewblockBox.flashcardType .typeIcon {
                    background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll 0px -150px;
                    display: inline-block;
                    height: 49px;
                    left: 0px;
                    position: relative;
                    top: 4px;
                    width: 52px;
                    margin-right: 15px;
                }
                .mobileListView .listviewblockBox.learningType .typeIcon {
                    background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll 0px -222px;
                    height: 53px;
                    width: 55px;
                    display: inline-block;
                    height: 49px;
                    left: 0px;
                    position: relative;
                    top: 4px;
                    width: 52px;
                    margin-right: 15px;
                }
                .mobileListView .listviewblockBox.QuesRead .typeIcon {
                    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -313px -466px / 500px 600px;
                    display: inline-block;
                    height: 49px;
                    left: 0;
                    margin-right: 15px;
                    position: relative;
                    top: 1px;
                    width: 52px;
                    float: left;
                }
                .mobileListView .listviewblockBox.QuesWrite .typeIcon {
                    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -315px -536px / 500px 600px;
                    display: inline-block;
                    height: 49px;
                    left: 0;
                    margin-right: 15px;
                    position: relative;
                    top: 1px;
                    width: 52px;
                    float: left;
                }
                .mobileListView .listviewblockBox .contentType {}
                .mobileListView .listviewblockBox .contentType h4 {
                    color: #0f6fb4;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    padding-bottom: 2px;
                }
                .mobileListView .listviewblockBox .contentType p {
                    margin-top: 7px;
                    color: #1c1c1c;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    width: 100%;
                    white-space: nowrap;
                }
                .mobileListView .listviewblockBox .contentType p strong {
                    color: #555555;
                }
                .mobileListView .listviewblockBox .contentType p span {}
                .filterBlock {
                    position: fixed;
                    margin-top: 0px;
                    height: 100%;
                    width: 100%;
                    background-color: transparent;
                    background: #fff;
                    border: none;
                    padding-top: 20px;
                    overflow: auto;
                    z-index: 999999999999;
                    top: 5000px;
                    transition: all ease 0.2s;
                    overflow: auto;
                    padding-bottom: 30px;
                }
                .filterBlock .ApplyFilter {
                    position: absolute;
                    bottom: 0px;
                    left: 0px;
                    width: 100%;
                    height: 50px;
                    background: #44a9f2;
                    border: 1px solid #44a9f2;
                    color: #fff;
                }
                .noFilter {
                    width: 100% !important;
                    margin-left: 0px !important;
                }
                .listViewBlock {
                    margin-left: auto;
                    width: 100% !important;
                    float: left;
                    padding-bottom: 70px;
                    margin-left: 0px !important;
                    background-color: white;
                }
                .sercFilBtnBox.classTostick {
                    position: fixed;
                    width: 100%;
                    top: 0px;
                    z-index: 9999;
                    background: #f7f7f8;
                }
                .navbar-nav>li>a:hover, .navbar-nav>li>a.active {
                    color: inherit;
                    background: inherit;
                }
                .scroll-top-wrapper {
                    position: fixed;
                    opacity: 0;
                    border: 1px solid #888b8d;
                    visibility: hidden;
                    overflow: hidden;
                    text-align: center;
                    z-index: 99999999;
                    width: 40px;
                    height: 40px;
                    line-height: 48px;
                    right: 30px;
                    bottom: 30px;
                    padding-top: 2px;
                    border-radius: 10px;
                    transition: all 0.5s ease-in-out;
                    transform: rotate(90deg);
                    background: rgb(241, 249, 255) var(--sprite-url) repeat -197px -77px;
                }
                .scroll-top-wrapper:hover {
                    background: #f1f9ff url(../images/sprite.svg) repeat scroll -335px 5px;
                    height: 40px;
                    width: 40px;
                    transform: rotate(270deg);
                    border: 1px solid #b2b7bb;
                    background-size: 1570px;
                }
                .scroll-top-wrapper.show {
                    visibility: visible;
                    cursor: pointer;
                    opacity: 1.0;
                    background: rgb(255, 255, 255) var(--sprite-url) repeat -168px -64px;
                    height: 40px;
                    width: 40px;
                    border: 1px solid #888b8d;
                    transform: rotate(90deg);
                }
                .scroll-top-wrapper i.fa {
                    line-height: inherit;
                }
                .overFlowScroll-sm {
                    height: auto;
                    overflow: auto;
                    float: left;
                    width: 100%;
                    max-height: 80%;
                    padding-bottom: 30px;
                }
                .mobAlertDiv {
                    padding-right: 10px;
                }
                .topFirstBar .topBarProfileIcons .TopBarnotification {
                    margin-right: 0px;
                }
                .HiddenLessLap {
                    display: none !important;
                }
                .activityLogBlock.questionActivityMainBox.DivShow {
                    display: none !important;
                }
                .listviewblockBox:hover {
                    background: #f1f9ff;
                }
                .TopZero {
                    top: 0px !important;
                }
                /*.container-fluid.noPad.listMainBoxWrapper {
            position:inherit;
        }*/
            }
            @media only screen and (min-width: 550px) and (max-width: 767px) {
                .listviewblockBox .col-xs-2 {
                    width: 12%;
                }
                .listviewblockBox .col-xs-5 {
                    width: 43%;
                }
            }
            @media only screen and (min-width: 768px) and (max-width: 1199px) {
                .hidden-tab {
                    display: none !important;
                }
                .logoClass {
                    width: 180px;
                    margin-top: 6px;
                }
                .crtBtnCol {
                    display: block !important;
                }
                .srcFieldCol {
                    display: none !important;
                }
                .ViewFilter-sm {
                    width: 100%;
                    height: 50px;
                    background: #f7f7f8;
                    border: 1px solid #e0e0e0;
                    border-left: none;
                    color: #0f6fb4;
                }
                .ViewFilter-sm span {
                    position: relative;
                }
                .ViewFilter-sm span:before {
                    content: '';
                    background: transparent var(--sprite-url) no-repeat scroll -327px 0px;
                    position: absolute;
                    left: -30px;
                    top: -7px;
                    width: 29px;
                    height: 33px;
                    display: inline-block;
                    -ms-transform: scale(0.7, 0.7);
                    -webkit-transform: scale(0.7, 0.7);
                    transform: scale(0.7, 0.7);
                }
                .ViewFilter-sm:focus {
                    background: #efefef;
                    outline: none;
                    border: 1px solid #e0e0e0;
                }
                .ViewSearch-sm {
                    width: 100%;
                    height: 50px;
                    background: #f7f7f8;
                    border: 1px solid #e0e0e0;
                    border-right: none;
                    color: #0f6fb4;
                }
                .ViewSearch-sm span {
                    position: relative;
                }
                .ViewSearch-sm span:before {
                    content: '';
                    background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll -100px 0px;
                    position: absolute;
                    left: -33px;
                    top: -4px;
                    width: 30px;
                    height: 32px;
                    display: inline-block;
                    -ms-transform: scale(0.7, 0.7);
                    -webkit-transform: scale(0.7, 0.7);
                    transform: scale(0.7, 0.7);
                }
                .ViewSearch-sm:focus {
                    background: #efefef;
                    outline: none;
                    border: 1px solid #e0e0e0;
                }
                .smText h3 {
                    text-align: center;
                }
                .smText .HideCross {
                    background: rgb(255, 255, 255) var(--sprite-url) repeat -228px -70px;
                    display: inline-block;
                    height: 30px;
                    right: 0px;
                    position: absolute;
                    top: 0px;
                    width: 30px;
                    margin-right: 13px;
                    margin-top: 15px;
                }
                .search-sm-Box span.srcHideBox {
                    background: rgba(14, 34, 48, 0.1) var(--sprite-url) repeat -329px -80px;
                    display: inline-block;
                    height: 30px;
                    right: 0px;
                    position: absolute;
                    top: 0px;
                    width: 30px;
                    background-size: 580px;
                    margin-right: 20px;
                    margin-top: 15px;
                }
                .search-sm-Box {
                    position: fixed;
                    background: rgba(0, 0, 0, 0.8);
                    width: 100%;
                    height: 100%;
                    z-index: 9999999999;
                    top: 5000px;
                    left: 0px;
                    bottom: 0px;
                    right: 0px;
                    padding: 24% 10% 0% 10%;
                    transition: all ease 0.2s;
                }
                .search-sm-Box .searchBtn {
                    background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll -164px 1px;
                    display: inline-block;
                    height: 27px;
                    left: 5px;
                    position: relative;
                    top: 0px;
                    width: 50px;
                }
                .search-sm-Box .btn.btn-primary {
                    padding: 0px;
                }
                .search-sm-Box .btn.btn-primary:hover {
                    background: #0f6fb4;
                }
                .sercFilBtnBox.classTostick {
                    position: fixed;
                    width: 100%;
                    top: 0px;
                    z-index: 9999;
                    background: #f7f7f8;
                }
                .filterBlock {
                    position: fixed;
                    margin-top: 0px;
                    height: 100%;
                    width: 100%;
                    background-color: transparent;
                    background: #fff;
                    border: none;
                    padding-top: 20px;
                    overflow: auto;
                    z-index: 999999999999;
                    top: 5000px;
                    transition: all ease 0.2s;
                    overflow: auto;
                    padding-bottom: 30px;
                }
                .filterBlock .ApplyFilter {
                    position: absolute;
                    bottom: 0px;
                    left: 0px;
                    width: 100%;
                    height: 50px;
                    background: #44a9f2;
                    border: 1px solid #44a9f2;
                    color: #fff;
                }
                .listViewBlock {
                    margin-left: auto;
                    width: 100% !important;
                    float: left;
                    padding-bottom: 70px;
                    margin-left: 0px !important;
                    background-color: white;
                }
                .sercFilBtnBox.classTostick {
                    position: fixed;
                    width: 100%;
                    top: 0px;
                    z-index: 9999;
                    background: #f7f7f8;
                }
                .scroll-top-wrapper {
                    position: fixed;
                    opacity: 0;
                    border: 1px solid #888b8d;
                    visibility: hidden;
                    overflow: hidden;
                    text-align: center;
                    z-index: 99999999;
                    width: 40px;
                    height: 40px;
                    line-height: 48px;
                    right: 30px;
                    bottom: 30px;
                    padding-top: 2px;
                    border-radius: 10px;
                    transition: all 0.5s ease-in-out;
                    transform: rotate(90deg);
                    background: rgb(241, 249, 255) var(--sprite-url) repeat -197px -77px;
                }
                .scroll-top-wrapper:hover {
                    background: #f1f9ff url(../images/sprite.svg) repeat scroll -335px 5px;
                    height: 40px;
                    width: 40px;
                    transform: rotate(270deg);
                    border: 1px solid #b2b7bb;
                    background-size: 1570px;
                }
                .scroll-top-wrapper.show {
                    visibility: visible;
                    cursor: pointer;
                    opacity: 1.0;
                    background: rgb(241, 249, 255) var(--sprite-url) repeat -197px -77px;
                    background-size: 580px;
                    height: 40px;
                    width: 40px;
                    border: 1px solid #888b8d;
                    transform: rotate(90deg);
                }
                .scroll-top-wrapper i.fa {
                    line-height: inherit;
                }
                .overFlowScroll-sm {
                    height: auto;
                    overflow: auto;
                    float: left;
                    width: 100%;
                    max-height: 80%;
                    padding-bottom: 30px;
                }
                .listViewBlock table.table-hover tbody tr td .DropDivBlock {
                    display: none;
                }
                .activityLogBlock.questionActivityMainBox.DivShow {
                    display: none !important;
                }
                .row.hidden-md.hidden-lg.sercFilBtnBox {
                    display: block !important;
                }
                .HiddenLessLap {
                    display: none !important;
                }
                .smText {
                    display: block !important;
                }
                .filterBlock .ApplyFilter {
                    display: block !important;
                }
                .TopZero {
                    top: 0px !important;
                }
                /*.container-fluid.noPad.listMainBoxWrapper {
            position:inherit;
            overflow: inherit;
        }*/
            }
            @media only screen and (min-width: 1024px) and (max-width: 1279px) {
                .crtBtnCol {
                    display: block !important;
                }
                .ViewFilter-sm {
                    width: 100%;
                    height: 50px;
                    background: #f7f7f8;
                    border: 1px solid #e0e0e0;
                    border-left: none;
                    color: #0f6fb4;
                }
                .ViewFilter-sm span {
                    position: relative;
                }
                .ViewFilter-sm span:before {
                    content: '';
                    background: transparent var(--sprite-url) no-repeat scroll -327px 0px;
                    position: absolute;
                    left: -30px;
                    top: -7px;
                    width: 29px;
                    height: 33px;
                    display: inline-block;
                    -ms-transform: scale(0.7, 0.7);
                    -webkit-transform: scale(0.7, 0.7);
                    transform: scale(0.7, 0.7);
                }
                .ViewFilter-sm:focus {
                    background: #efefef;
                    outline: none;
                    border: 1px solid #e0e0e0;
                }
                .ViewSearch-sm {
                    width: 100%;
                    height: 50px;
                    background: #f7f7f8;
                    border: 1px solid #e0e0e0;
                    border-right: none;
                    color: #0f6fb4;
                }
                .ViewSearch-sm span {
                    position: relative;
                }
                .ViewSearch-sm span:before {
                    content: '';
                    background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll -100px 0px;
                    position: absolute;
                    left: -33px;
                    top: -4px;
                    width: 30px;
                    height: 32px;
                    display: inline-block;
                    -ms-transform: scale(0.7, 0.7);
                    -webkit-transform: scale(0.7, 0.7);
                    transform: scale(0.7, 0.7);
                }
                .ViewSearch-sm:focus {
                    background: #efefef;
                    outline: none;
                    border: 1px solid #e0e0e0;
                }
                .smText h3 {
                    text-align: center;
                }
                .smText .HideCross {
                    background: rgb(255, 255, 255) var(--sprite-url) repeat -228px -70px;
                    display: inline-block;
                    height: 30px;
                    right: 0px;
                    position: absolute;
                    top: 0px;
                    width: 30px;
                    margin-right: 13px;
                    margin-top: 15px;
                }
                .search-sm-Box span.srcHideBox {
                    background: rgba(14, 34, 48, 0.1) var(--sprite-url) repeat -329px -80px;
                    display: inline-block;
                    height: 30px;
                    right: 0px;
                    position: absolute;
                    top: 0px;
                    width: 30px;
                    background-size: 580px;
                    margin-right: 20px;
                    margin-top: 15px;
                }
                .search-sm-Box {
                    position: fixed;
                    background: rgba(0, 0, 0, 0.8);
                    width: 100%;
                    height: 100%;
                    z-index: 9999999999;
                    top: 5000px;
                    left: 0px;
                    bottom: 0px;
                    right: 0px;
                    padding: 24% 10% 0% 10%;
                    transition: all ease 0.2s;
                }
                .search-sm-Box .searchBtn {
                    background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll -164px 1px;
                    display: inline-block;
                    height: 27px;
                    left: 5px;
                    position: relative;
                    top: 0px;
                    width: 50px;
                }
                .search-sm-Box .btn.btn-primary {
                    padding: 0px;
                }
                .search-sm-Box .btn.btn-primary:hover {
                    background: #0f6fb4;
                }
                .sercFilBtnBox.classTostick {
                    position: fixed;
                    width: 100%;
                    top: 0px;
                    z-index: 9999;
                    background: #f7f7f8;
                }
                .filterBlock .ApplyFilter {
                    display: block !important;
                }
            }
            @media only screen and (min-width: 1200px) and (max-width: 1280px) {
                .ApplyFilter {
                    display: none;
                }
            }
            @media only screen and (min-width: 1200px) and (max-width: 1400px) {
                .filterBlock {
                    width: 18%;
                }
                .noFilter {
                    width: 100% !important;
                    margin-left: 0px !important;
                }
                .listViewBlock {
                    width: 82%;
                    margin-left: 18%;
                    background-color: white;
                }
                .filterBlock.collapsableDiv .FunnelFilterIcon {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0px;
                    right: 0px;
                    bottom: 0px;
                    top: 0px;
                    background: #f1f1f2 var(--sprite-url) no-repeat scroll -322px 13px;
                    cursor: pointer;
                }
                .filterBlock.collapsableDiv .removeFilterDisplay {
                    display: none
                }
                .filterBlock.collapsableDiv {
                    width: 3.2%;
                    overflow: hidden;
                    padding: 0px;
                    position: fixed;
                }
                .listViewBlock.pulLeftClass {
                    width: calc(98.2% - 433px);
                    margin-left: 3.2%;
                }
            }
            @media only screen and (min-width: 1280px) and (max-width: 1400px) {
                .slctCol {
                    width: 150px;
                    padding: 0;
                    padding-right: 22px;
                }
                .crtBtnCol {
                    width: 120px;
                    padding-left: 0px;
                }
                .srcFieldCol {
                    width: 280px;
                }
            }
            @media screen\0 {
                .BoxSelMain span.tickyArrow {
                    background: transparent var(--sprite-url) no-repeat scroll 0px -170px;
                }
            }
            @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
                ;
            }
            /* scroll bar */
            background-size: 500px 600px;
            background-size: 500px 600px;
        }
        .ViewSearch-sm:focus {
            background: #efefef;
            outline: none;
            border: 1px solid #e0e0e0;
        }
        .smText h3 {
            text-align: center;
        }
        .smText .HideCross {
            background: rgb(255, 255, 255) var(--sprite-url) repeat -228px -70px;
            display: inline-block;
            height: 30px;
            right: 0px;
            position: absolute;
            top: 0px;
            width: 30px;
            margin-right: 13px;
            margin-top: 15px;
        }
        .search-sm-Box span.srcHideBox {
            background: rgba(14, 34, 48, 0.1) var(--sprite-url) repeat -329px -80px;
            display: inline-block;
            height: 30px;
            right: 0px;
            position: absolute;
            top: 0px;
            width: 30px;
            background-size: 580px;
            margin-right: 20px;
            margin-top: 15px;
        }
        .search-sm-Box {
            position: fixed;
            background: rgba(0, 0, 0, 0.8);
            width: 100%;
            height: 100%;
            z-index: 9999999999;
            top: 5000px;
            left: 0px;
            bottom: 0px;
            right: 0px;
            padding: 24% 10% 0% 10%;
            transition: all ease 0.2s;
        }
        .search-sm-Box .searchBtn {
            background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll -164px 1px;
            display: inline-block;
            height: 27px;
            left: 5px;
            position: relative;
            top: 0px;
            width: 50px;
        }
        .search-sm-Box .btn.btn-primary {
            padding: 0px;
        }
        .search-sm-Box .btn.btn-primary:hover {
            background: #0f6fb4;
        }
        .sercFilBtnBox.classTostick {
            position: fixed;
            width: 100%;
            top: 0px;
            z-index: 9999;
            background: #f7f7f8;
        }
        .filterBlock {
            position: fixed;
            margin-top: 0px;
            height: 100%;
            width: 100%;
            background-color: transparent;
            background: #fff;
            border: none;
            padding-top: 20px;
            overflow: auto;
            z-index: 999999999999;
            top: 5000px;
            transition: all ease 0.2s;
            overflow: auto;
            padding-bottom: 30px;
        }
        .filterBlock .ApplyFilter {
            position: absolute;
            bottom: 0px;
            left: 0px;
            width: 100%;
            height: 50px;
            background: #44a9f2;
            border: 1px solid #44a9f2;
            color: #fff;
        }
        .listViewBlock {
            margin-left: auto;
            width: 100% !important;
            float: left;
            padding-bottom: 70px;
            margin-left: 0px !important;
            background-color: white;
        }
        .sercFilBtnBox.classTostick {
            position: fixed;
            width: 100%;
            top: 0px;
            z-index: 9999;
            background: #f7f7f8;
        }
        .scroll-top-wrapper {
            position: fixed;
            opacity: 0;
            border: 1px solid #888b8d;
            visibility: hidden;
            overflow: hidden;
            text-align: center;
            z-index: 99999999;
            width: 40px;
            height: 40px;
            line-height: 48px;
            right: 30px;
            bottom: 30px;
            padding-top: 2px;
            border-radius: 10px;
            transition: all 0.5s ease-in-out;
            transform: rotate(90deg);
            background: rgb(241, 249, 255) var(--sprite-url) repeat -197px -77px;
        }
        .scroll-top-wrapper:hover {
            background: #f1f9ff url(../images/sprite.svg) repeat scroll -335px 5px;
            height: 40px;
            width: 40px;
            transform: rotate(270deg);
            border: 1px solid #b2b7bb;
            background-size: 1570px;
        }
        .scroll-top-wrapper.show {
            visibility: visible;
            cursor: pointer;
            opacity: 1.0;
            background: rgb(241, 249, 255) var(--sprite-url) repeat -197px -77px;
            background-size: 580px;
            height: 40px;
            width: 40px;
            border: 1px solid #888b8d;
            transform: rotate(90deg);
        }
        .scroll-top-wrapper i.fa {
            line-height: inherit;
        }
        .overFlowScroll-sm {
            height: auto;
            overflow: auto;
            float: left;
            width: 100%;
            max-height: 80%;
            padding-bottom: 30px;
        }
        .listViewBlock table.table-hover tbody tr td .DropDivBlock {
            display: none;
        }
        .activityLogBlock.questionActivityMainBox.DivShow {
            display: none !important;
        }
        .row.hidden-md.hidden-lg.sercFilBtnBox {
            display: block !important;
        }
        .HiddenLessLap {
            display: none !important;
        }
        .smText {
            display: block !important;
        }
        .filterBlock .ApplyFilter {
            display: block !important;
        }
        .TopZero {
            top: 0px !important;
        }
        /*.container-fluid.noPad.listMainBoxWrapper { position: inherit; overflow: inherit; }*/
    }
    @media only screen and (min-width: 1024px) and (max-width: 1279px) {
        .crtBtnCol {
            display: block !important;
        }
        .ViewFilter-sm {
            width: 100%;
            height: 50px;
            background: #f7f7f8;
            border: 1px solid #e0e0e0;
            border-left: none;
            color: #0f6fb4;
        }
        .ViewFilter-sm span {
            position: relative;
        }
        .ViewFilter-sm span:before {
            content: '';
            background: transparent var(--sprite2-url) no-repeat scroll -324px 0px;
            position: absolute;
            left: -30px;
            top: -7px;
            width: 29px;
            height: 33px;
            display: inline-block;
            background-size: 500px 600px;
            -ms-transform: scale(0.7, 0.7);
            -webkit-transform: scale(0.7, 0.7);
            transform: scale(0.7, 0.7);
        }
        .ViewFilter-sm:focus {
            background: #efefef;
            outline: none;
            border: 1px solid #e0e0e0;
        }
        .ViewSearch-sm {
            width: 100%;
            height: 50px;
            background: #f7f7f8;
            border: 1px solid #e0e0e0;
            border-right: none;
            color: #0f6fb4;
        }
        .ViewSearch-sm span {
            position: relative;
        }
        .ViewSearch-sm span:before {
            content: '';
            background: var(--sprite2-url) repeat -325px -65px;
            position: absolute;
            left: -33px;
            top: -4px;
            width: 30px;
            height: 32px;
            display: inline-block;
            background-size: 500px 600px;
            -ms-transform: scale(0.7, 0.7);
            -webkit-transform: scale(0.7, 0.7);
            transform: scale(0.7, 0.7);
        }
        .ViewSearch-sm:focus {
            background: #efefef;
            outline: none;
            border: 1px solid #e0e0e0;
        }
        .smText h3 {
            text-align: center;
        }
        .smText .HideCross {
            background: rgb(255, 255, 255) var(--sprite2-url) repeat -228px -70px;
            display: inline-block;
            height: 30px;
            right: 0px;
            position: absolute;
            top: 0px;
            width: 30px;
            margin-right: 13px;
            margin-top: 15px;
            background-size: 500px 600px;
        }
        .search-sm-Box span.srcHideBox {
            background: rgba(14, 34, 48, 0.1) var(--sprite2-url) repeat -785px -65px;
            display: inline-block;
            height: 30px;
            right: 0px;
            position: absolute;
            top: 0px;
            width: 30px;
            margin-right: 20px;
            margin-top: 15px;
            background-size: 500px 600px;
        }
        .search-sm-Box {
            position: fixed;
            background: rgba(0, 0, 0, 0.8);
            width: 100%;
            height: 100%;
            z-index: 9999999999;
            top: 5000px;
            left: 0px;
            bottom: 0px;
            right: 0px;
            padding: 24% 10% 0% 10%;
            transition: all ease 0.2s;
        }
        .search-sm-Box .searchBtn {
            background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -164px -3px;
            display: inline-block;
            height: 27px;
            left: 5px;
            position: relative;
            top: 0px;
            width: 50px;
            background-size: 500px 600px;
        }
        .search-sm-Box .btn.btn-primary {
            padding: 0px;
        }
        .search-sm-Box .btn.btn-primary:hover {
            background: #0f6fb4;
        }
        .sercFilBtnBox.classTostick {
            position: fixed;
            width: 100%;
            top: 0px;
            z-index: 9999;
            background: #f7f7f8;
        }
        .filterBlock .ApplyFilter {
            display: block !important;
        }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1280px) {
        .ApplyFilter {
            display: none;
        }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1400px) {
        .filterBlock {
            width: 18%;
            /*margin-top: -1px;*/
        }
        .noFilter {
            width: 100% !important;
            margin-left: 0px !important;
        }
        .listViewBlock {
            width: 82%;
            margin-left: 18%;
            background-color: white;
        }
        .filterBlock.collapsableDiv .FunnelFilterIcon {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0px;
            right: 0px;
            bottom: 0px;
            top: 0px;
            background: #f1f1f2 var(--sprite-url) no-repeat scroll -322px 13px;
            cursor: pointer;
        }
        .filterBlock.collapsableDiv .removeFilterDisplay {
            display: none
        }
        .filterBlock.collapsableDiv {
            width: 3.2%;
            overflow: hidden;
            padding: 0px;
            position: fixed;
        }
        .listViewBlock.pulLeftClass {
            width: calc(98.2% - 433px);
            margin-left: 3.2%;
        }
    }
    @media only screen and (min-width: 1280px) and (max-width: 1400px) {
        .slctCol {
            width: 150px;
            padding: 0;
            padding-right: 22px;
        }
        .crtBtnCol {
            width: 120px;
            padding-left: 0px;
        }
        .srcFieldCol {
            width: 280px;
        }
    }
    @media screen\0 {
        .BoxSelMain span.tickyArrow {
            background: transparent var(--sprite-url) no-repeat scroll 0px -170px;
        }
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
        ;
    }
    /* scroll bar */
    background-size: 500px 600px;
    background-size: 500px 600px;
}

.topFirstBar .topBarProfileIcons .TopBartask {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 0px 1px;
    display: inline-block;
    height: 35px;
    width: 35px;
    margin-right: 20px;
    margin-top: 13px;
    background-size: 500px 600px;
}

.topFirstBar .topBarProfileIcons .TopBarhelp {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -49px 1px;
    display: inline-block;
    height: 35px;
    width: 35px;
    margin-right: 20px;
    margin-top: 13px;
    background-size: 500px 600px;
}

.topFirstBar .topBarProfileIcons .TopBarnotification {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -99px 1px;
    display: inline-block;
    height: 35px;
    width: 35px;
    margin-right: 20px;
    margin-top: 13px;
    background-size: 500px 600px;
}

.topFirstBar .topBarProfileIcons .TopBarnotification span {
    background: #fff;
    border: 1px solid #0f6fb4;
    color: #0f6fb4;
    padding: 2px 4px;
}

.topFirstBar .topBarProfileIcons div {
    margin-top: 13px;
}

.topFirstBar .topBarProfileIcons div.profileImgLink {
    margin-top: 11px;
    height: 49px;
    cursor: pointer;
}

.topFirstBar .topBarProfileIcons div.profileImgLink span {
    color: #337ab7;
    line-height: 38px;
}

.topFirstBar .topBarProfileIcons div.profileImgLink p {
    color: #337ab7;
    margin-top: -5px;
    margin-left: -6px
}

.topFirstBar .topBarProfileIcons div.profileImgLink span.caret {
    border-top-width: 5px;
}

.topFirstBar .topBarProfileIcons div.profileImgLink span.personImg {
    width: 34px;
    height: 40px;
    display: inline-block;
    border-radius: 100%;
    text-align: center;
    overflow: hidden;
    position: static;
    top: auto;
    left: auto;
    float: left;
    margin-right: 6px;
}

.topFirstBar .topBarProfileIcons div.profileImgLink span.personImg img {
    width: 100%;
}

.topFirstBar .topBarProfileIcons div.profileImgLink span.pername {
    color: #1c1c1c;
}

.navbar {
    background: #FFFFFF;
    border-radius: 0;
    height: 50px;
    margin-bottom: 0px !important;
}

.navbar .navbar-nav {
    display: inline-block;
    float: none;
    margin-top: -1px;
}

.navbar .navbar-collapse {
    text-align: center;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px !important;
    margin-right: -15px !important;
}

.navbar-nav>li>a {
    color: #0f6fb4;
    font-size: 13px !important;
    /* padding-top : 10px !important; */
    padding-bottom: 5px !important;
}

.navbar-nav>li>a:hover {
    text-decoration: none;
}

.navbar-nav>li>a:hover:after {
    content: "";
    /* This is necessary for the pseudo element to work. */
    display: block;
    /* This will put the pseudo element on its own line. */
    margin: 0 auto;
    /* This will center the border. */
    width: 100%;
    /* Change this to whatever width you want. */
    padding-top: 5px;
    /* This creates some space between the element and the border. */
    border-bottom: 2px solid #0f6fb4;
    /* This creates the border. Replace black with whatever color you want. */
}

/*searchBar strip*/

.searchCrtBar .showLabel {
    padding-top: 7px !important;
    width: 25%;
    text-align: right;
    color: #fff;
}

.searchCrtBar {
    height: 57px;
    background: rgb(15, 111, 180);
    box-sizing: border-box;
    border-bottom: 1px solid #ddd;
}

.searchCrtBar .searchBtn:focus {
    outline: none;
    border: none;
}

.searchCrtBar .btn.btn-primary:focus {
    outline: none;
}

.searchCrtBar .btn.btn-primary {
    padding: 0px;
}

.searchCrtBar .btn.btn-primary:hover {
    background: #0f6fb4;
}

.searchCrtBar h3 {
    color: #fff;
    margin-top: 6px;
    padding-left: 10px;
    display: inline-block;
}

.searchCrtBar .selctBox {
    height: 31px !important;
    width: 100%;
    display: inline-block;
    border-radius: 30px;
    background: transparent;
    border: none;
    box-shadow: none;
    z-index: 2;
}

.searchCrtBar .form-group label {
    line-height: 32px;
}

.searchCrtBar .crtBtn {
    padding: 7px 15px 9px 31px !important;
    height: 38px;
    margin-top: 7px;
    background-color: #44a9f2;
    border-color: #44a9f2;
    float: right;
    line-height: 3px;
    width: 95%;
    position: relative;
    min-width: 120px;
}

.searchCrtBar .crtBtn span {
    margin-right: 0px;
    position: absolute;
    left: 5px;
    background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll -273px -5px;
    width: 20px;
    height: 20px;
    top: 6px;
}

.searchCrtBar .backBtn {
    padding-left: 15px !important;
    padding-right: 15px !important;
    height: 38px;
    margin-top: -3px;
    background-color: #44a9f2;
    border-color: #44a9f2;
    line-height: 3px;
    position: relative;
    min-width: 120px;
}

.searchCrtBar .searchBtn {
    background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll -164px 1px;
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -164px -3px;
    display: inline-block;
    height: 27px;
    left: 5px;
    position: relative;
    top: 0px;
    width: 50px;
    background-size: 500px 600px;
}

.searchCrtBar .searchBtn:focus {
    outline: none;
    border: none;
}

.searchCrtBar .btn.btn-primary:focus {
    outline: none;
}

.searchCrtBar .btn.btn-primary {
    padding: 0px;
}

.searchCrtBar .btn.btn-primary:hover {
    background: #0f6fb4;
}

.BoxSelMain {
    position: relative;
    display: inline-block;
    width: 55%;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 20px;
    margin-right: 10px;
    float: right;
}

.BoxSelMain span.tickyArrow {
    background: transparent var(--sprite2-url) no-repeat scroll -220px -9px;
    position: absolute;
    right: 9px;
    top: 8px;
    width: 29px;
    height: 26px;
    display: inline-block;
    background-size: 500px 600px;
}

/*filter left slide*/

.container-fluid.noPad.listMainBoxWrapper {
    float: left;
    height: calc(100% - 125px);
    width: 100%;
    position: fixed;
    overflow: auto;
}

@media only screen and (max-width: 1199px) {
    .container-fluid.noPad.listMainBoxWrapper {
        position: relative !important;
    }
}

.filterBlockBox {
    border: 0px !important
}

.filterBlock {
    /*margin-top: -1px;*/
    height: calc(100% - 172px);
    width: 220px;
    background-color: transparent;
    padding-left: 24px;
    background: #f7f8f9;
    border: 1px solid #eeeeee;
    border-left: hidden;
    padding-top: 0px;
    overflow: auto;
    padding-bottom: 20px;
    float: left;
    width: 13.9%;
    position: fixed;
}

.filterBlock input[type=checkbox].css-checkbox {
    position: absolute;
    z-index: -1000;
    left: -1000px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 24px;
    width: 100%;
    margin: -1px;
    padding: 0;
    border: 0;
}

.filterBlock input[type=checkbox].css-checkbox+label.css-label1 {
    padding-left: 30px;
    height: auto !important;
    width: 94%;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -470px -3px;
    vertical-align: middle;
    cursor: pointer;
    line-height: 22px;
    color: #555555;
    background-size: 500px 600px;
}

.filterBlock input[type=checkbox].css-checkbox:checked+label.css-label1 {
    background-position: -429px -37px
}

.filterBlock label.css-label1 {
    background-image: var(--sprite2-url);
    background-size: 500px 600px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.innerQuestionBox input[type=radio].css-checkbox {
    position: absolute;
    z-index: -1000;
    left: -1000px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

.innerQuestionBox input[type=radio].css-checkbox+label.css-label {
    padding-left: 32px;
    min-height: 24px;
    /* display: flex; */
    align-items: center;
    background-repeat: no-repeat;
    background-position: -470px -92px;
    vertical-align: middle;
    cursor: pointer;
    background-size: 500px 600px;
}

.innerQuestionBox input[type=radio].css-checkbox:checked+label.css-label {
    background-position: -432px -126px;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

label.css-label {
    background-image: var(--sprite2-url);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.filterBlock .fieldBox {
    margin: 0px 0px;
}

td:has(.fieldBox) {
    min-width: 13em;
}

.filterBlock h3 {
    color: #1c1c1c;
    margin-bottom: 5px;
    margin-top: 8px;
}

.filterBlock .limitFilter {
    height: 79px;
    overflow: hidden;
    /*border-bottom: 1px solid #e8e8e8;  border-top: 1px solid #e8e8e8;*/
}

.filterBlock .limitFilter.seeAll {
    height: auto;
    overflow: auto;
}

.filterBlock .seeMore {
    color: #555555;
    margin-top: 5px;
    margin-bottom: 15px;
    cursor: pointer;
}

.filterBlock .unSelAll {
    color: #0174c7;
    display: block;
    margin-top: 25px;
    cursor: pointer;
}

/*list view */

.noFilter {
    width: 100% !important;
    margin-left: 0px !important;
}

.listViewBlock {
    width: 86%;
    float: left;
    padding-bottom: 40px;
    margin-left: 14%;
    background-color: white;
}

.listViewBlock .blockForlist {
    background: #fff;
    /* max-height: 550px; */
    /* overflow: auto; */
    /* height: 100%; */
    /* position: fixed; */
    /* width: 86%; */
}

/*.listToBesslide{ max-height: 550px;overflow: auto;}*/

.listViewBlock table {
    /*width: 99%;*/
    width: 98%;
    margin-left: 1%;
}

.listViewBlock table.table-hover thead tr th, .listViewBlock table.table-hover tbody tr td {
    padding-left: 15px;
    vertical-align: middle;
    color: #555555;
}

.listViewBlock table.table-hover thead tr th {
    color: #1c1c1c;
    height: 52px;
    border-bottom: 1px solid #e0e0e0;
    cursor: pointer;
}

.listViewBlock table.table-hover tbody tr.questionRow {}

.listViewBlock table.table-hover tbody tr.questionRow .typeIcon {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 0px -71px;
    display: inline-block;
    height: 49px;
    left: 0px;
    position: relative;
    top: 1px;
    width: 52px;
    margin-right: 15px;
    background-size: 500px 600px;
}

.listViewBlock table.table-hover tbody tr.flashCard .typeIcon {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 0px -150px;
    display: inline-block;
    height: 49px;
    left: 0px;
    position: relative;
    top: 4px;
    width: 52px;
    margin-right: 15px;
    background-size: 500px 600px;
}

.listViewBlock table.table-hover tbody tr.learning .typeIcon {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 0px -222px;
    display: inline-block;
    height: 49px;
    left: 0px;
    position: relative;
    top: 4px;
    width: 52px;
    margin-right: 15px;
    background-size: 500px 600px;
}

.listViewBlock table.table-hover tbody tr.video .typeIcon {
    background: rgba(0, 0, 0, 0) url(../images/video.png);
    display: inline-block;
    height: 49px;
    left: 0px;
    position: relative;
    top: 4px;
    width: 52px;
    margin-right: 15px;
    background-size: 120% 120%;
    background-repeat: no-repeat;
    background-position: center;
}

.listViewBlock table.table-hover tbody tr .contentType {
    display: inline-block;
    position: relative;
    top: -5px;
    width: calc(89% - 60px);
    max-width: 400px;
}

.listViewBlock table.table-hover tbody tr .contentType h4 {
    color: #0f6fb4;
    margin-top: 0px;
    margin-bottom: 0px;
    /*  */
}

.listViewBlock table.table-hover tbody tr .contentType p {
    color: #909090;
    margin: 0 0;
}

.listViewBlock table.table-hover tbody tr .contentTypeQues {
    display: inline-block !important;
    position: relative;
    top: 1px;
    width: 88%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    min-height: 48px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    position: relative;
    padding-right: 15px;
}

.listViewBlock table.table-hover tbody tr .contentTypeQues div {
    color: #0f6fb4;
    margin-top: 0px;
    margin-bottom: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 70px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    position: relative;
}

.listViewBlock table.table-hover tbody tr .contentTypeQues h4 {
    color: #0f6fb4;
    margin-top: 0px;
    margin-bottom: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    max-height: 46px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    position: relative;
    width: 94%
}

.listViewBlock table.table-hover tbody tr .contentTypeQues h4 span {
    color: #0f6fb4 !important
}

.listViewBlock table.table-hover tbody tr .contentTypeQues p {
    color: #909090;
    margin: 0 0;
}

.listViewBlock table.table-hover tbody tr td .DropDivBlock {
    cursor: pointer;
    position: relative;
    text-align: center;
    padding-bottom: 10px;
    line-height: 40px;
}

.listViewBlock table.table-hover tbody tr td .DropDivBlock span.bullets {
    margin-left: 2px;
    width: 6px;
    height: 6px;
    display: inline-block;
    background: #0f6fb4;
    border-radius: 100%;
}

.listViewBlock table.table-hover tbody tr td .DropDivBlock .dropBoxDiv {
    position: absolute;
    width: 155px;
    left: -115px;
    top: 40px;
    background: #fff;
    box-shadow: 0px 0px 10px #ccc;
    display: none;
    z-index: 222;
}

.listViewBlock table.table-hover tbody tr td .DropDivBlock .dropBoxDiv ul {
    margin-top: 0px;
    margin-bottom: 5px;
}

.listViewBlock table.table-hover tbody tr td .DropDivBlock .dropBoxDiv ul li {
    height: 35px;
    display: block;
}

.listViewBlock table.table-hover tbody tr td .DropDivBlock .dropBoxDiv ul li a {
    display: block;
    padding: 0px 15px 0px 15px;
    color: #555555;
    border-bottom: 1px solid #ebebeb;
    text-align: left;
}

.listViewBlock table.table-hover tbody tr td .DropDivBlock .dropBoxDiv ul li:last-child a {
    border: none;
}

.listViewBlock table.table-hover tbody tr td .DropDivBlock .dropBoxDiv:before {
    content: '';
    position: absolute;
    right: 0px;
    top: -20px;
    border: 10px solid rgba(255, 255, 255, 0.1);
    display: block;
    border-bottom-color: rgb(184, 184, 184);
}

.listViewBlock table.table-hover tbody tr td .DropDivBlock .dropBoxDiv:after {
    content: '';
    content: '';
    position: absolute;
    right: 0px;
    top: -19px;
    border: 10px solid rgba(255, 255, 255, 0.1);
    display: block;
    border-bottom-color: rgb(255, 255, 255);
}

.listViewBlock table.table-hover tbody tr td .DropDivBlock:hover .dropBoxDiv {
    display: block;
}

.listViewBlock .table-hover>tbody>tr:hover {
    background-color: #f1f9ff;
    cursor: pointer;
}

.pagination>li>a:focus, .pagination>li>a:hover, .pagination>li>span:focus, .pagination>li>span:hover {
    z-index: 2;
    color: #555555;
    background-color: #f7f8f9;
    border-color: #f7f8f9;
}

.pagination span.DirectionArrow {
    background: #f7f8f9 var(--sprite-url) repeat -176px -70px;
    display: inline-block;
    height: 22px;
    position: absolute;
    width: 18px;
    cursor: pointer;
    transform: rotate(180deg);
}

.pagination span.DirectionArrow.left {
    left: -13px;
    transform: rotate(0deg);
    top: 8px;
}

.pagination span.DirectionArrow.right {
    right: -13px;
    top: 13px;
}

.pagination>li>a, .pagination>li>span {
    position: relative;
    float: left;
    padding: 11px 10px !important;
    margin-left: -1px;
    line-height: 1.42857143;
    color: #555555;
    text-decoration: none;
    background-color: #f7f8f9;
    border: 1px solid #f7f8f9;
}

.pagination>.active>a, .pagination>.active>a:hover, .pagination>.active>a:focus {
    color: #337ab7;
    background: transparent;
    border: none;
    line-height: 24px;
}

.pagination>li>a {
    /*padding: 10px 5px;*/
}

.pagination>li:first-child>a, .pagination>li:last-child>a {
    /*padding: 10px 20px */
}

/*activity block*/

.activityLogBlock {
    width: 430px;
    height: calc(100% - 171px);
    overflow: auto;
    background: #fff;
    box-shadow: -3px 3px 8px #ccc;
    right: 0px;
    padding: 0px 30px 30px 30px;
    float: left;
    margin-left: 3px;
    display: none;
    /* height:auto; */
    position: fixed;
}

.activityLogBlock .topRowHaedingAnch {
    position: relative;
}

/*.scrollBar{position: absolute;height: 100%;width: 10px;right: -2px;background: #fff;z-index: 999;opacity:1; transition:all ease 0.5s}
.questionBlock:hover .scrollBar{opacity:1; transition:all ease 0.5s}*/

.activityLogBlock .topRowHaedingAnch h3 {
    display: inline-block;
    color: #1c1c1c;
    margin-top: -10px;
    margin-bottom: -10px;
}

.activityLogBlock .topRowHaedingAnch a.seDeatil {
    margin: 0px 0px 0px 20px;
    display: inline-block;
}

.activityLogBlock .topRowHaedingAnch a.seDeatil em {
    font-family: cursive;
    position: relative;
    top: 2px;
    display: inline-block;
    margin-left: 3px;
}

.activityLogBlock .topRowHaedingAnch span.cross {
    background: rgb(255, 255, 255) var(--sprite2-url) repeat -234px -70px;
    display: inline-block;
    height: 27px;
    right: -21px;
    position: absolute;
    top: 13px;
    width: 23px;
    cursor: pointer;
    z-index: 999;
    background-size: 500px 600px;
}

.activityLogBlock .topRowHaedingAnch .actionBlockDiv {
    margin-top: 30px;
}

.activityLogBlock .topRowHaedingAnch .actionBlockDiv .detailEdit {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 1px -296px;
    display: inline-block;
    height: 48px;
    left: 5px;
    position: relative;
    top: 0px;
    width: 48px;
    margin-right: 10px;
    cursor: pointer;
    background-size: 500px 600px;
}

.activityLogBlock .topRowHaedingAnch .actionBlockDiv .detailEdit:hover {
    background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll -120px -298px;
}

.activityLogBlock .topRowHaedingAnch .actionBlockDiv .detailPrint {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 1px -539px;
    display: inline-block;
    height: 48px;
    left: 5px;
    position: relative;
    top: 0px;
    width: 48px;
    margin-right: 10px;
    cursor: pointer;
    background-size: 500px 600px;
}

.activityLogBlock .topRowHaedingAnch .actionBlockDiv .detailPrint:hover {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -118px -539px;
    background-size: 500px 600px;
}

.activityLogBlock .topRowHaedingAnch .actionBlockDiv .detailDuplicate {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 1px -377px;
    display: inline-block;
    height: 48px;
    left: 5px;
    position: relative;
    top: 0px;
    width: 48px;
    margin-right: 10px;
    cursor: pointer;
    background-size: 500px 600px;
}

.activityLogBlock .topRowHaedingAnch .actionBlockDiv .detailDuplicate:hover {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -119px -377px;
    background-size: 500px 600px;
}

.activityLogBlock .actionBlockDiv .detailAdd {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 160px -398px;
    display: inline-block;
    height: 48px;
    left: 5px;
    position: relative;
    top: 0px;
    width: 48px;
    margin-right: 10px;
    cursor: pointer;
    background-size: 500px 600px;
}

.activityLogBlock .actionBlockDiv .detailAdd:hover {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 215px -398px;
    background-size: 500px 600px;
}

.activityLogBlock .topRowHaedingAnch .actionBlockDiv .detailDelete {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 1px -459px;
    display: inline-block;
    height: 48px;
    left: 5px;
    position: relative;
    top: 0px;
    width: 48px;
    margin-right: 10px;
    cursor: pointer;
    background-size: 500px 600px;
}

.activityLogBlock .topRowHaedingAnch .actionBlockDiv .detailDelete:hover {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -119px -459px;
    background-size: 500px 600px;
}

.nav>li>a:focus, .nav>li>a:hover, .nav>li>a.active {
    background: #fff !important;
}

.nav>li>a:focus:after, .nav>li>a.active:after, .navbar-nav>li>a:hover:after {
    content: "";
    /* This is necessary for the pseudo element to work. */
    display: block;
    /* This will put the pseudo element on its own line. */
    margin: 0 auto;
    /* This will center the border. */
    width: 100%;
    /* Change this to whatever width you want. */
    padding-top: 5px;
    /* This creates some space between the element and the border. */
    border-bottom: 2px solid #0f6fb4;
    /* This creates the border. Replace black with whatever color you want. */
}

.activityLogBlock .topRowHaedingAnch .questionBlock {
    margin-top: 10px;
    /* height: 100%; */
    /* max-height: 450px; */
    overflow: auto;
    padding-top: 0px;
}

.activityLogBlock .topRowHaedingAnch .questionBlock h3.noteHeading {
    margin: 15px 0px;
}

.activityLogBlock .topRowHaedingAnch .questionBlock .listNumQuestion {
    position: relative;
    border-top: 1px solid #e8e8e8;
    margin-top: 30px;
}

.activityLogBlock .topRowHaedingAnch .questionBlock .listNumQuestion div#IFRAME_DIV img {
    width: auto;
    max-width: 100%;
}

.activityLogBlock .topRowHaedingAnch .questionBlock .listNumQuestion h4 {
    color: #1c1c1c;
    margin: 20px 0px;
}

.activityLogBlock .topRowHaedingAnch .questionBlock .listNumQuestion p {
    color: #505050;
    line-height: 19px;
    width: 92%;
    cursor: pointer;
    padding-top: 10px;
}

.activityLogBlock .topRowHaedingAnch .questionBlock .listNumQuestion span.DirectionArrow {
    background: rgb(255, 255, 255) var(--sprite2-url) repeat -176px -70px;
    display: inline-block;
    height: 22px;
    right: 0px;
    position: absolute;
    top: 50%;
    width: 18px;
    cursor: pointer;
    transform: rotate(180deg);
    background-size: 500px 600px;
}

.activityLogBlock .learningSlideTab {
    position: relative;
    padding-top: 30px;
}

.activityLogBlock .learningSlideTab h3 {
    display: inline-block;
    color: #1c1c1c;
    margin-top: -20px;
    margin-bottom: -10px;
}

.activityLogBlock .learningSlideTab h5 {
    margin-top: 16px !important;
}

.activityLogBlock .learningSlideTab a.seDeatil {
    margin: 0px 0px 0px 20px;
    display: inline-block;
}

.activityLogBlock .learningSlideTab a.seDeatil em {
    font-family: cursive;
    position: relative;
    top: 2px;
    display: inline-block;
    margin-left: 3px;
}

.activityLogBlock .learningSlideTab span.cross {
    background: rgb(255, 255, 255) var(--sprite-url) repeat -234px -70px;
    display: inline-block;
    height: 27px;
    right: -13px;
    position: absolute;
    top: 10px;
    width: 23px;
    cursor: pointer;
    z-index: 999;
}

.activityLogBlock .learningSlideTab .actionBlockDiv {
    margin-top: 30px;
}

.activityLogBlock .learningSlideTab .actionBlockDiv .detailEdit {
    background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll 1px -298px;
    display: inline-block;
    height: 48px;
    left: 5px;
    position: relative;
    top: 0px;
    width: 48px;
    margin-right: 10px;
    cursor: pointer;
}

.activityLogBlock .learningSlideTab .actionBlockDiv .detailEdit:hover {
    background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll -120px -298px;
    background-size: 500px 600px;
}

.activityLogBlock .learningSlideTab .actionBlockDiv .detailPrint {
    background: rgba(0, 0, 0, 0) url(../images/sprite.svg) repeat scroll -4px -449px;
    display: inline-block;
    height: 48px;
    left: 5px;
    position: relative;
    top: 0px;
    width: 47px;
    background-size: 500px 600px;
    margin-right: 10px;
    cursor: pointer;
}

.activityLogBlock .learningSlideTab .actionBlockDiv .detailPrint:hover {
    background: rgba(0, 0, 0, 0) url(../images/sprite.svg) repeat scroll -98px -459px;
}

.activityLogBlock .learningSlideTab .actionBlockDiv .detailDuplicate {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 1px -377px;
    display: inline-block;
    height: 48px;
    left: 5px;
    position: relative;
    top: 0px;
    width: 48px;
    margin-right: 10px;
    cursor: pointer;
    background-size: 500px 600px;
}

.activityLogBlock .learningSlideTab .actionBlockDiv .detailDuplicate:hover {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -119px -377px;
    background-size: 500px 600px;
}

.activityLogBlock .learningSlideTab .actionBlockDiv .detailDelete {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 1px -459px;
    display: inline-block;
    height: 48px;
    left: 5px;
    position: relative;
    top: 0px;
    width: 48px;
    margin-right: 10px;
    cursor: pointer;
    background-size: 500px 600px;
}

.activityLogBlock .learningSlideTab .actionBlockDiv .detailDelete:hover {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -119px -459px;
    background-size: 500px 600px;
}

.activityLogBlock .learningSlideTab .questionBlock {
    margin-top: 10px;
    height: auto;
    max-height: 450px;
    overflow: auto;
    padding-bottom: 150px;
}

.activityLogBlock .learningSlideTab .questionBlock h3.noteHeading {
    margin: 15px 0px;
}

.activityLogBlock .learningSlideTab .questionBlock .listNumQuestion {
    position: relative;
    border-top: 1px solid #e8e8e8;
    margin-top: 20px;
}

.activityLogBlock .learningSlideTab .questionBlock .listNumQuestion h4 {
    color: #1c1c1c;
    margin: 20px 0px;
}

.activityLogBlock .learningSlideTab .questionBlock .listNumQuestion p {
    color: #505050;
    line-height: 19px;
    width: 92%;
    cursor: pointer;
}

.activityLogBlock .learningSlideTab .questionBlock .listNumQuestion span.DirectionArrow {
    background: rgba(0, 0, 0, 0) url(../images/sprite.svg) repeat scroll -349px 0px;
    display: inline-block;
    height: 22px;
    right: 10px;
    position: absolute;
    top: 50%;
    width: 13px;
    background-size: 1570px;
    cursor: pointer;
}

.listViewBlock.pulLeftClass {
    width: calc(87.3% - 433px);
    margin-left: 13.9%;
}

.activityLogBlock .innerQuestionBox {
    display: none;
    overflow: auto;
    padding-bottom: 10px;
    /* position: fixed; */
    height: 100%;
    padding-right: 20px;
    padding-top: 25px;
}

.activityLogBlock .topRowHaedingAnch .innerQuestionBox h3 {
    padding: 4px 0px 24px 22px;
    border-bottom: 1px solid #e0e0e0;
    display: block;
    position: relative;
    width: 90%;
}

.activityLogBlock .innerQuestionBox table tr td {
    vertical-align: top
}

.activityLogBlock .innerQuestionBox h3 span.backArrow {
    background: rgb(255, 255, 255) var(--sprite-url) repeat -176px -70px;
    display: inline-block;
    height: 22px;
    left: 0px;
    position: absolute;
    top: -1px;
    width: 13px;
    cursor: pointer;
    transform: rotate(0deg);
}

.activityLogBlock .innerQuestionBox h4 {
    margin-bottom: 2px;
    margin-top: 24px;
}

.activityLogBlock .innerQuestionBox p {
    color: #505050;
    line-height: 20px;
}

.activityLogBlock .innerQuestionBox img {
    width: auto;
    max-width: 100%;
}

.activityLogBlock .innerQuestionBox label img {
    width: auto;
    max-width: 100%;
}

.activityLogBlock .innerQuestionBox font img {
    width: auto;
    max-width: 100%;
}

.activityLogBlock .innerQuestionBox font span {
    font-size: inherit !important
}

.activityLogBlock .innerQuestionBox font div {
    font-size: inherit !important
}

.activityLogBlock .innerQuestionBox font p {
    font-size: inherit !important;
    -webkit-margin-before: 1em;
}

.activityLogBlock .innerQuestionBox font {
    display: inline-block;
    padding-top: 5px;
    padding-bottom: 5px;
}

.activityLogBlock .innerQuestionBox table tr td {
    vertical-align: top
}

.activityLogBlock .innerQuestionBox table tr td:first-child {
    width: 30%
}

.activityLogBlock .innerQuestionBox ul {
    display: block;
    list-style-type: disc;
    left: 4%;
    position: relative;
    width: 93%;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 20px;
}

@-moz-document url-prefix() {
    .activityLogBlock .innerQuestionBox ul {
        left: 6%;
        position: relative;
        width: 93%;
    }
}

.activityLogBlock .innerQuestionBox ol {
    display: block;
    list-style-type: decimal;
    left: 4%;
    position: relative;
    width: 93%;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 20px;
}

@-moz-document url-prefix() {
    .activityLogBlock .innerQuestionBox ol {
        left: 6%;
        position: relative;
        width: 93%;
    }
}

.fixIconDiv {
    padding-top: 25px;
}

/*footer*/

.footer {
    width: 100%;
    height: 50px;
    background: #f1f1f2;
    position: fixed;
    bottom: 0px;
    border-top: 1px solid #e5e6e6;
    z-index: 9;
}

.footer p {
    color: #909090;
    padding: 17px 0px;
    display: inline-block;
}

.footer ul {
    display: inline-block;
    float: right;
    margin-right: 30px;
}

.footer ul li {
    display: inline-block;
    margin-left: 14px;
    margin-top: 15px;
}

.footer ul li a {
    color: #505050;
}

@media only screen and (min-width: 266px) and (max-width: 340px) {
    .listviewblockBox .col-xs-2 {
        padding-left: 5px;
    }
}

@media only screen and (min-width: 266px) and (max-width: 767px) {
    .navbar-toggle {
        position: absolute;
        top: -60px;
    }
    .navbar-toggle .icon-bar {
        background: #0f6fb4;
    }
    .logoClass {
        padding-left: 32px;
        width: 180px;
        margin-top: 6px;
    }
    .navbar .navbar-nav {
        width: 100%;
        margin: 0;
        border-bottom: 3px solid #0f6fb4;
    }
    .navbar .navbar-nav.nav>li {
        border-bottom: 1px solid #4288bb;
        text-align: left;
        padding-left: 0px;
    }
    .navbar .navbar-nav.nav>li.noBod {
        border-bottom: none
    }
    .MenupersonImg {
        background: #0f6fb4;
        border-bottom: 1px solid #4288bb !important;
        padding: 18px 0px 5px 0px;
        margin: 0px;
        height: 41px;
    }
    .MenupersonImg a {
        color: #fff !important;
        top: -17px;
    }
    .MenupersonImg span {
        display: inline-block;
        height: 35px;
        position: relative;
        bottom: 7px;
        width: 35px;
        overflow: hidden;
        border-radius: 50px;
        float: right;
    }
    .MenupersonImg span img {
        width: 100%;
    }
    .navbar {
        margin-bottom: -59px !important;
        background: #f1f9ff;
    }
    .navbar .navbar-collapse {
        top: -7px;
        background: rgb(238, 238, 238);
        position: absolute;
        z-index: 9999;
        width: 100%;
        padding: 0;
    }
    .MenuTopBartask {
        background: #fff;
        border-bottom: none !important;
        color: #1c1c1c !important;
        margin: 0px;
        padding-left: 55px !important;
        padding-top: 5px;
        background-size: 500px 600px;
    }
    .MenuTopBartask a {
        color: #1c1c1c !important;
    }
    .MenuTopBartask span {
        background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -2px -2px;
        display: inline-block;
        height: 32px;
        left: -27px;
        position: absolute;
        top: 7px;
        width: 30px;
        background-size: 500px 600px;
    }
    .MenuTopBarhelp {
        background: #0f6fb4;
        margin: 0px;
        border-bottom: 1px solid #4288bb !important;
        padding-bottom: -15px;
        color: #1c1c1c !important;
        padding-top: -2px;
    }
    .MenuTopBarhelp a {
        color: #ffffff !important;
        top: 0px;
    }
    .MenuTopBarhelp span {
        background: #f7f7f8 url(/static/media/sprite-2x.425102e0.png) repeat scroll -51px -2px;
        height: 31px;
        position: relative;
        bottom: 6px;
        left: 32px;
        width: 30px;
        background-size: 500px 600px;
        float: right;
        border-radius: 20px;
    }
    .MenuTopBarhelp a:active {
        background: #333333;
    }
    .srcFieldCol {
        display: none !important;
    }
    .ViewFilter-sm {
        width: 100%;
        height: 50px;
        background: #f7f7f8;
        border: 1px solid #e0e0e0;
        border-left: none;
        color: #0f6fb4;
    }
    .ViewFilter-sm span {
        position: relative
    }
    .ViewFilter-sm span:before {
        content: '';
        background: transparent var(--sprite2-url) no-repeat scroll -371px 2px;
        position: absolute;
        left: -30px;
        top: -7px;
        width: 29px;
        height: 33px;
        display: inline-block;
        background-size: 500px 600px;
        -ms-transform: scale(0.7, 0.7);
        -webkit-transform: scale(0.7, 0.7);
        transform: scale(0.7, 0.7);
    }
    .ViewFilter-sm:focus {
        background: #efefef;
        outline: none;
        border: 1px solid #e0e0e0;
    }
    .ViewSearch-sm {
        width: 100%;
        height: 50px;
        background: #f7f7f8;
        border: 1px solid #e0e0e0;
        border-right: none;
        color: #0f6fb4;
    }
    .ViewSearch-sm span {
        position: relative
    }
    .ViewSearch-sm span:before {
        content: '';
        background: transparent var(--sprite2-url) no-repeat scroll -370px -35px;
        position: absolute;
        left: -33px;
        top: -4px;
        width: 30px;
        height: 32px;
        display: inline-block;
        background-size: 500px 600px;
        -ms-transform: scale(0.7, 0.7);
        -webkit-transform: scale(0.7, 0.7);
        transform: scale(0.7, 0.7);
    }
    .ViewSearch-sm:focus {
        background: #efefef;
        outline: none;
        border: 1px solid #e0e0e0;
    }
    .smText h3 {
        text-align: center;
    }
    .smText .HideCross {
        background: rgb(255, 255, 255) var(--sprite-url) repeat -228px -70px;
        display: inline-block;
        height: 30px;
        right: 0px;
        position: absolute;
        top: 0px;
        width: 30px;
        margin-right: 13px;
        margin-top: 15px;
        cursor: pointer;
    }
    .search-sm-Box span.srcHideBox {
        background: rgba(14, 34, 48, 0.1) var(--sprite2-url) repeat -775px -75px;
        display: inline-block;
        height: 30px;
        right: 0px;
        position: absolute;
        top: 0px;
        width: 30px;
        margin-right: 20px;
        margin-top: 15px;
        background-size: 500px 600px;
        cursor: pointer;
    }
    .search-sm-Box {
        position: fixed;
        background: rgba(0, 0, 0, 0.8);
        width: 100%;
        height: 100%;
        z-index: 9999999999;
        top: 5000px;
        left: 0px;
        bottom: 0px;
        right: 0px;
        padding: 24% 10% 0% 10%;
        transition: all ease 0.2s;
    }
    .search-sm-Box .searchBtn {
        background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -164px -3px;
        display: inline-block;
        height: 27px;
        left: 5px;
        position: relative;
        top: 0px;
        width: 50px;
        background-size: 500px 600px;
        cursor: pointer;
    }
    .search-sm-Box .btn.btn-primary {
        padding: 0px;
    }
    .search-sm-Box .btn.btn-primary:hover {
        background: #0f6fb4;
    }
    .sercFilBtnBox.classTostick {
        position: fixed;
        width: 100%;
        top: 0px;
        z-index: 9999;
        background: #f7f7f8;
    }
    .mobileListView {}
    .mobileListView .listviewblockBox {
        background: #fff;
        width: 100%;
        padding: 15px 0px;
        float: left;
        border-bottom: 1px solid #e0e0e0;
    }
    .mobileListView .listviewblockBox.questionType .typeIcon {
        background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 0px -72px;
        display: inline-block;
        height: 49px;
        left: 0px;
        position: relative;
        top: 4px;
        width: 52px;
        margin-right: 15px;
        background-size: 500px 600px;
    }
    .mobileListView .listviewblockBox.flashcardType .typeIcon {
        background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 0px -150px;
        display: inline-block;
        height: 49px;
        left: 0px;
        position: relative;
        top: 4px;
        width: 52px;
        margin-right: 15px;
        background-size: 500px 600px;
    }
    .mobileListView .listviewblockBox.learningType .typeIcon {
        background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 0px -222px;
        height: 53px;
        width: 55px;
        display: inline-block;
        height: 49px;
        left: 0px;
        position: relative;
        top: 4px;
        width: 52px;
        margin-right: 15px;
        background-size: 500px 600px;
    }
    .mobileListView .listviewblockBox.subUSHistory .typeIcon {
        background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -220px -536px / 500px 600px;
        display: inline-block;
        height: 49px;
        left: 0;
        margin-right: 15px;
        position: relative;
        top: 4px;
        width: 52px;
    }
    .mobileListView .listviewblockBox.subGlobHistory .typeIcon {
        background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -220px -316px / 500px 600px;
        display: inline-block;
        height: 49px;
        left: 0;
        margin-right: 15px;
        position: relative;
        top: 4px;
        width: 52px;
    }
    .mobileListView .listviewblockBox.subLivEnvi .typeIcon {
        background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -220px -246px / 500px 600px;
        display: inline-block;
        height: 49px;
        left: 0;
        margin-right: 15px;
        position: relative;
        top: 1px;
        width: 52px;
    }
    .mobileListView .listviewblockBox.subGeometry .typeIcon {
        background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -220px -384px / 500px 600px;
        display: inline-block;
        height: 49px;
        left: 0;
        margin-right: 15px;
        position: relative;
        top: 4px;
        width: 52px;
    }
    .mobileListView .listviewblockBox.subErthSci .typeIcon {
        background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -220px -167px / 500px 600px;
        display: inline-block;
        height: 49px;
        left: 0;
        margin-right: 15px;
        position: relative;
        top: 4px;
        width: 52px;
    }
    .mobileListView .listviewblockBox.subAlgebra .typeIcon {
        background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -220px -466px / 500px 600px;
        display: inline-block;
        height: 49px;
        left: 0;
        margin-right: 15px;
        position: relative;
        top: 1px;
        width: 52px;
    }
    .mobileListView .listviewblockBox.QuesRead .typeIcon {
        background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -313px -466px / 500px 600px;
        display: inline-block;
        height: 49px;
        left: 0;
        margin-right: 15px;
        position: relative;
        top: 1px;
        width: 52px;
        float: left;
    }
    .mobileListView .listviewblockBox.QuesWrite .typeIcon {
        background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -315px -536px / 500px 600px;
        display: inline-block;
        height: 49px;
        left: 0;
        margin-right: 15px;
        position: relative;
        top: 1px;
        width: 52px;
        float: left;
    }
    .mobileListView .listviewblockBox .contentType {}
    .mobileListView .listviewblockBox .contentType h4 {
        color: #0f6fb4;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-bottom: 2px;
        margin-top: 0px;
        margin-bottom: 0px;
    }
    .mobileListView .listviewblockBox .contentType p {
        margin: 0px;
        color: #1c1c1c;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
    }
    .mobileListView .listviewblockBox .contentType p strong {
        color: #555555;
    }
    .mobileListView .listviewblockBox .contentType p span {}
    .filterBlock {
        position: fixed;
        /*margin-top: -1px;*/
        height: 100%;
        width: 100%;
        background-color: transparent;
        background: #fff;
        border: none;
        padding-top: 20px;
        overflow: auto;
        z-index: 999999999999;
        top: 5000px;
        transition: all ease 0.2s;
        overflow: hidden;
        padding-bottom: 30px;
    }
    .filterBlock .ApplyFilter {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 50px;
        background: #44a9f2;
        border: 1px solid #44a9f2;
        color: #fff;
    }
    .listViewBlock {
        margin-left: auto;
        width: 100% !important;
        float: left;
        padding-bottom: 70px;
        margin-left: 0px !important;
        background-color: white;
    }
    .sercFilBtnBox.classTostick {
        position: fixed;
        width: 100%;
        top: 0px;
        z-index: 9999;
        background: #f7f7f8;
    }
    .navbar-nav>li>a:hover, .navbar-nav>li>a.active {
        color: inherit;
        background: inherit;
    }
    .scroll-top-wrapper {
        position: fixed;
        opacity: 0;
        border: 1px solid #888b8d;
        visibility: hidden;
        overflow: hidden;
        text-align: center;
        z-index: 99999999;
        width: 40px;
        height: 40px;
        line-height: 48px;
        right: 30px;
        bottom: 30px;
        padding-top: 2px;
        border-radius: 10px;
        transition: all 0.5s ease-in-out;
        transform: rotate(90deg);
        background: rgb(241, 249, 255) var(--sprite-url) repeat -197px -77px;
    }
    .scroll-top-wrapper:hover {
        background: #f1f9ff url(../images/sprite.svg) repeat scroll -335px 5px;
        height: 40px;
        width: 40px;
        transform: rotate(270deg);
        border: 1px solid #b2b7bb;
        background-size: 1570px;
    }
    .scroll-top-wrapper.show {
        visibility: visible;
        cursor: pointer;
        opacity: 1.0;
        background: rgb(255, 255, 255) var(--sprite-url) repeat -168px -64px;
        height: 40px;
        width: 40px;
        border: 1px solid #888b8d;
        transform: rotate(90deg);
    }
    .scroll-top-wrapper i.fa {
        line-height: inherit;
    }
    .overFlowScroll-sm {
        height: auto;
        overflow: auto;
        float: left;
        width: 100%;
        max-height: calc(80% - 40px);
        padding-bottom: 30px;
    }
    .mobAlertDiv {
        padding-right: 10px;
    }
    .topFirstBar .topBarProfileIcons .TopBarnotification {
        margin-right: 0px;
        margin-top: 8px;
    }
    .HiddenLessLap {
        display: none !important;
    }
    .activityLogBlock.questionActivityMainBox.DivShow {
        display: none !important;
    }
    .listviewblockBox:hover {
        background: #f1f9ff;
    }
    .TopZero {
        top: 0px !important;
    }
    /*.container-fluid.noPad.listMainBoxWrapper { position: inherit; overflow: inherit; }*/
}

@media only screen and (min-width: 550px) and (max-width: 767px) {
    .listviewblockBox .col-xs-2 {
        width: 12%
    }
    .listviewblockBox .col-xs-5 {
        width: 43%
    }
}

@media only screen and (min-width: 1400px) {
    .BoxSelMain {
        width: 45%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
    .hidden-tab {
        display: none !important;
    }
    .logoClass {
        width: 180px;
        margin-top: 6px;
    }
    .crtBtnCol {
        display: block !important;
    }
    .srcFieldCol {
        display: none !important;
    }
    .ViewFilter-sm {
        width: 100%;
        height: 50px;
        background: #f7f7f8;
        border: 1px solid #e0e0e0;
        border-left: none;
        color: #0f6fb4;
    }
    .ViewFilter-sm span {
        position: relative
    }
    .ViewFilter-sm span:before {
        content: '';
        background: transparent var(--sprite2-url) no-repeat scroll -324px 0px;
        position: absolute;
        left: -30px;
        top: -7px;
        width: 29px;
        height: 33px;
        display: inline-block;
        background-size: 500px 600px;
        -ms-transform: scale(0.7, 0.7);
        -webkit-transform: scale(0.7, 0.7);
        transform: scale(0.7, 0.7);
    }
    .ViewFilter-sm:focus {
        background: #efefef;
        outline: none;
        border: 1px solid #e0e0e0;
    }
    .ViewSearch-sm {
        width: 100%;
        height: 50px;
        background: #f7f7f8;
        border: 1px solid #e0e0e0;
        border-right: none;
        color: #0f6fb4;
    }
    .ViewSearch-sm span {
        position: relative
    }
    .ViewSearch-sm span:before {
        content: '';
        background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -326px -64px;
        position: absolute;
        left: -33px;
        top: -4px;
        width: 30px;
        height: 32px;
        display: inline-block;
        -ms-transform: scale(0.7, 0.7);
        -webkit-transform: scale(0.7, 0.7);
        transform: scale(0.7, 0.7);
        html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
            margin: 0;
            padding: 0;
            border: 0;
            ;
            font: inherit;
            vertical-align: baseline;
            font-family: 'Lato', sans-serif;
        }
        /* HTML5 display-role reset for older browsers */
        article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
            display: block;
            font-family: 'Lato', sans-serif;
        }
        body {
            line-height: 1;
            background: #f7f8f9;
        }
        .entry-header {
            display: none;
        }
        ol, ul {
            list-style: none;
        }
        blockquote, q {
            quotes: none;
        }
        blockquote:before, blockquote:after, q:before, q:after {
            content: '';
            content: none;
        }
        table {
            border-collapse: collapse;
            border-spacing: 0;
        }
        strong {}
        a {
            text-decoration: none;
        }
        img {
            pointer-events: none;
        }
        a, span, div {
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        }
        input:focus {
            -webkit-appearance: none;
            position: relative;
        }
        .button:focus, .button:hover, button:focus, button:hover, input[type=button]:focus, input[type=button]:hover, input[type=reset]:focus, input[type=reset]:hover, input[type=submit]:focus, input[type=submit]:hover {
            background-color: inherit;
        }
        input {
            -webkit-appearance: none;
        }
        select {
            -moz-appearance: none;
            text-indent: 0.01px;
            text-overflow: '';
            -webkit-appearance: none;
        }
        html, body {
            height: 100%;
        }
        /*public css*/
        .noPad {
            padding: 0px !important;
        }
        .m-t-30 {
            margin-top: 30px;
        }
        .DivShow {
            display: block !important;
        }
        span.searchBtn:focus, .search-sm-Box .btn.btn-primary:focus {
            outline: none;
        }
        /*topBar right*/
        .topFirstBar {
            min-height: 60px;
            background: #fff;
        }
        .topFirstBarul.topBarProfileIcons {
            list-style: none;
            float: right;
        }
        .topFirstBarul.topBarProfileIconsli {
            display: inline-block;
            float: left;
        }
        .topFirstBarul.topBarProfileIconslia {
            min-width: 30px;
            min-height: 30px;
            display: block;
        }
        .topFirstBar .topBarProfileIcons .TopBartask {
            background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll 0px 1px;
            display: inline-block;
            height: 35px;
            width: 35px;
            margin-right: 20px;
            margin-top: 12px;
            background-size: 500px 600px;
        }
        .topFirstBar .topBarProfileIcons .TopBarhelp {
            background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll -49px 1px;
            display: inline-block;
            height: 35px;
            width: 35px;
            margin-right: 20px;
            margin-top: 13px;
        }
        .topFirstBar .topBarProfileIcons .TopBarnotification {
            background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -99px 1px;
            display: inline-block;
            height: 35px;
            width: 35px;
            margin-right: 20px;
            margin-top: 13px;
            background-size: 500px 600px;
        }
        .topFirstBar .topBarProfileIcons .TopBarnotificationspan {
            background: #fff;
            border: 1px solid #0f6fb4;
            color: #0f6fb4;
            padding: 2px 4px;
        }
        .topFirstBar .topBarProfileIconsa.profileImgLink {
            margin-top: 11px;
        }
        .topFirstBar .topBarProfileIconsa.profileImgLinkspan {
            color: #1c1c1c;
            line-height: 35px;
        }
        .topFirstBar .topBarProfileIconsa.profileImgLinkspan.personImg {
            width: 40px;
            height: 40px;
            display: inline-block;
            border-radius: 100%;
            text-align: center;
            overflow: hidden;
            position: static;
            top: auto;
            left: auto;
            float: left;
            margin-right: 8px;
        }
        .topFirstBar .topBarProfileIconsa.profileImgLinkspan.personImgimg {
            width: 100%;
        }
        .topFirstBar .topBarProfileIconsa.profileImgLinkspan.pername {
            color: #1c1c1c;
        }
        .navbar {
            background: #0f6fb4;
            border-radius: 0;
            height: 50px;
            margin-bottom: 0px;
        }
        .navbar .navbar-nav {
            display: inline-block;
            float: none;
            margin-top: -1px;
        }
        .navbar .navbar-collapse {
            text-align: center;
            padding-left: 0px;
            padding-right: 0px;
            margin-left: -15px !important;
            margin-right: -15px !important;
        }
        .navbar-nav>li>a {
            color: #fff;
        }
        .navbar-nav>li>a:hover {
            text-decoration: underline;
            color: #0f6fb4;
        }
        .navbar-nav>li>a:hover {
            color: #0f6fb4;
            background: #f7f7f8;
        }
        /*searchBar strip*/
        .searchCrtBar {
            height: 65px;
            background: rgb(15, 111, 180);
            box-sizing: border-box;
            padding-top: 16px;
            /* border-bottom: 1px solid #eeeeee; */
            border-bottom: 1px solid #ddd
        }
        .searchCrtBar .showLabel {
            padding-top: 7px !important;
            width: 25%;
            text-align: right;
            color: #fff;
        }
        .searchCrtBar .searchBtn:focus {
            outline: none;
            border: none;
        }
        .searchCrtBar .btn.btn-primary:focus {
            outline: none;
        }
        .searchCrtBar .btn.btn-primary {
            padding: 0px;
        }
        .searchCrtBar .btn.btn-primary:hover {
            background: #0f6fb4;
        }
        .searchCrtBarh3 {
            color: #fff;
            margin-top: 6px;
            padding-left: 5px;
        }
        .searchCrtBar .selctBox {
            width: 100%;
            display: inline-block;
            border-radius: 30px;
            background: transparent;
            border: none;
            box-shadow: none;
            z-index: 2;
            height: 31px !important
        }
        .searchCrtBar .form-grouplabel {
            line-height: 32px;
        }
        .searchCrtBar .crtBtn {
            padding: 7px 15px 9px 31px !important;
            height: 38px;
            margin-top: -3px;
            background-color: #44a9f2;
            border-color: #44a9f2;
            float: right;
            line-height: 3px;
            width: 95%;
            position: relative;
            min-width: 120px;
        }
        .searchCrtBar .crtBtnspan {
            margin-right: 0px;
            position: absolute;
            left: 10px;
            background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll -273px -5px;
            width: 20px;
            height: 20px;
            top: 6px;
        }
        .searchCrtBar .backBtn {
            padding-left: 15px !important;
            padding-right: 15px !important;
            height: 38px;
            margin-top: -3px;
            background-color: #44a9f2;
            border-color: #44a9f2;
            line-height: 3px;
            position: relative;
            min-width: 120px;
        }
        .searchCrtBar .searchBtn {
            background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll -164px 1px;
            background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -164px -3px;
            display: inline-block;
            height: 27px;
            left: 5px;
            position: relative;
            top: 0px;
            width: 50px;
            background-size: 500px 600px;
        }
        .searchCrtBar .searchBtn:focus {
            outline: none;
            border: none;
        }
        .searchCrtBar .btn.btn-primary:focus {
            outline: none;
        }
        .searchCrtBar .btn.btn-primary {
            padding: 0px;
        }
        .searchCrtBar .btn.btn-primary:hover {
            background: #0f6fb4;
        }
        .BoxSelMain {
            position: relative;
            display: inline-block;
            width: 55%;
            background: #fff;
            border: 1px solid #ccc;
            border-radius: 20px;
            margin-right: 10px;
            float: right;
        }
        .BoxSelMainspan.tickyArrow {
            background: transparent var(--sprite2-url) no-repeat scroll -220px -9px;
            position: absolute;
            right: 9px;
            top: 8px;
            width: 29px;
            height: 26px;
            display: inline-block;
            background-size: 500px 600px;
        }
        /*filter left slide*/
        .container-fluid.noPad.listMainBoxWrapper {
            float: left;
            height: 100%;
            width: 100%;
            /*position: fixed;*/
            overflow: auto;
        }
        .filterBlock {
            height: 100%;
            width: 220px;
            background-color: transparent;
            padding-left: 24px;
            background: #f7f8f9;
            border: 1px solid #eeeeee;
            border-left: hidden;
            padding-top: 20px;
            overflow: auto;
            padding-bottom: 20px;
            float: left;
            width: 13.9%;
            position: fixed;
        }
        .filterBlockinput[type=checkbox].css-checkbox {
            position: absolute;
            z-index: -1000;
            left: -1000px;
            overflow: hidden;
            clip: rect(0 0 0 0);
            height: auto;
            width: 100%;
            margin: -1px;
            padding: 0;
            border: 0;
        }
        .filterBlockinput[type=checkbox].css-checkbox+label.css-label1 {
            padding-left: 30px;
            height: 24px;
            width: 100%;
            display: inline-block;
            background-repeat: no-repeat;
            background-position: -470px -3px;
            vertical-align: middle;
            cursor: pointer;
            line-height: 22px;
            color: #555555;
            background-size: 500px 600px;
        }
        .filterBlockinput[type=checkbox].css-checkbox:checked+label.css-label1 {
            background-position: -429px -37px;
        }
        .filterBlocklabel.css-label1 {
            background-image: var(--sprite2-url);
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }
        .innerQuestionBoxinput[type=radio].css-checkbox {
            position: absolute;
            z-index: -1000;
            left: -1000px;
            overflow: hidden;
            clip: rect(0 0 0 0);
            height: 1px;
            width: 1px;
            margin: -1px;
            padding: 0;
            border: 0;
        }
        .innerQuestionBoxinput[type=radio].css-checkbox+label.css-label {
            padding-left: 32px;
            min-height: 35px;
            display: inline-block;
            background-repeat: no-repeat;
            background-position: -470px -92px;
            vertical-align: middle;
            cursor: pointer;
            background-size: 500px 600px;
        }
        .innerQuestionBoxinput[type=radio].css-checkbox:checked+label.css-label {
            background-position: -432px -126px;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }
        label.css-label {
            background-image: var(--sprite2-url);
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }
        .filterBlock .fieldBox {
            margin: 10px 0px;
        }
        .filterBlockh3 {
            color: #1c1c1c;
            margin-bottom: 15px;
        }
        .filterBlock .limitFilter {
            height: 78px;
            overflow: hidden;
        }
        .filterBlock .limitFilter.seeAll {
            height: auto;
            overflow: auto;
        }
        .filterBlock .seeMore {
            color: #555555;
            margin-top: 15px;
            cursor: pointer;
        }
        .filterBlock .unSelAll {
            color: #0174c7;
            display: block;
            margin-top: 25px;
            cursor: pointer;
        }
        /*list view */
        .noFilter {
            width: 100% !important;
            margin-left: 0px !important;
        }
        .listViewBlock {
            width: 86.1%;
            float: left;
            padding-bottom: 40px;
            margin-left: 13.9%;
            background-color: white;
        }
        .listViewBlock .blockForlist {
            background: #fff;
            /* max-height: 550px; */
            /* overflow: auto; */
            /* height: 100%; */
            /* position: fixed; */
            /* width: 86%; */
        }
        /*.listToBesslide{ max-height: 550px;overflow: auto;}*/
        .listViewBlocktable {
            width: 99%;
        }
        .listViewBlocktable.table-hovertheadtrth, .listViewBlock table.table-hover tbody tr td {
            padding-left: 15px;
            vertical-align: middle;
            color: #555555;
        }
        .listViewBlocktable.table-hovertheadtrth {
            color: #1c1c1c;
            height: 52px;
            border-bottom: 1px solid #e0e0e0;
        }
        .listViewBlocktable.table-hovertbodytr.questionRow {}
        .listViewBlocktable.table-hovertbodytr.questionRow .typeIcon {
            background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 0px -71px;
            display: inline-block;
            height: 49px;
            left: 0px;
            position: relative;
            top: 1px;
            width: 52px;
            margin-right: 15px;
            background-size: 500px 600px;
        }
        .listViewBlocktable.table-hovertbodytr.flashCard .typeIcon {
            background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 0px -150px;
            display: inline-block;
            height: 49px;
            left: 0px;
            position: relative;
            top: 4px;
            width: 52px;
            margin-right: 15px;
            background-size: 500px 600px;
        }
        .listViewBlocktable.table-hovertbodytr.learning .typeIcon {
            background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 0px -222px;
            display: inline-block;
            height: 49px;
            left: 0px;
            position: relative;
            top: 4px;
            width: 52px;
            margin-right: 15px;
            background-size: 500px 600px;
        }
        .listViewBlocktable.table-hovertbodytr .contentType {
            display: inline-block;
            position: relative;
            top: -5px;
        }
        .listViewBlocktable.table-hovertbodytr .contentTypeh4 {
            color: #0f6fb4;
            /*  */
        }
        .listViewBlocktable.table-hovertbodytr .contentTypep {
            color: #909090;
        }
        .listViewBlocktable.table-hovertbodytr .contentTypep strong {
            color: #555555;
        }
        .listViewBlocktable.table-hovertbodytrtd .DropDivBlock {
            cursor: pointer;
            position: relative;
            text-align: center;
            padding-bottom: 10px;
            line-height: 40px;
        }
        .listViewBlocktable.table-hovertbodytrtd .DropDivBlock span.bullets {
            width: 6px;
            height: 6px;
            display: inline-block;
            background: #0f6fb4;
            border-radius: 100%;
        }
        .listViewBlocktable.table-hovertbodytrtd .DropDivBlock .dropBoxDiv {
            position: absolute;
            width: 130px;
            height: 115px;
            left: -84px;
            top: 29px;
            background: #fff;
            box-shadow: 0px 0px 10px #ccc;
            display: none;
            z-index: 222;
        }
        .listViewBlocktable.table-hovertbodytrtd .DropDivBlock .dropBoxDiv ul {
            ;
        }
        .listViewBlocktable.table-hovertbodytrtd .DropDivBlock .dropBoxDiv ul li {
            height: 35px;
            display: block;
        }
        .listViewBlocktable.table-hovertbodytrtd .DropDivBlock .dropBoxDiv ul li a {
            display: block;
            padding: 7px 15px 5px 15px;
            color: #555555;
            border-bottom: 1px solid #ebebeb;
            text-align: left;
        }
        .listViewBlocktable.table-hovertbodytrtd .DropDivBlock .dropBoxDiv ul li:last-child a {
            border: none;
        }
        .listViewBlocktable.table-hovertbodytrtd .DropDivBlock .dropBoxDiv:before {
            content: '';
            position: absolute;
            right: 0px;
            top: -20px;
            border: 10px solid rgba(255, 255, 255, 0.1);
            display: block;
            border-bottom-color: rgb(184, 184, 184);
        }
        .listViewBlocktable.table-hovertbodytrtd .DropDivBlock .dropBoxDiv:after {
            content: '';
            content: '';
            position: absolute;
            right: 0px;
            top: -19px;
            border: 10px solid rgba(255, 255, 255, 0.1);
            display: block;
            border-bottom-color: rgb(255, 255, 255);
        }
        .listViewBlocktable.table-hovertbodytrtd .DropDivBlock:hover .dropBoxDiv {
            display: block;
        }
        .listViewBlock .table-hover>tbody>tr:hover {
            background-color: #f1f9ff;
            cursor: pointer;
        }
        .pagination>li>a:focus, .pagination>li>a:hover, .pagination>li>span:focus, .pagination>li>span:hover {
            z-index: 2;
            color: #555555;
            background-color: #f7f8f9;
            border-color: #f7f8f9;
        }
        .paginationspan.DirectionArrow {
            background: rgb(255, 255, 255) var(--sprite-url) repeat -176px -70px;
            display: inline-block;
            height: 22px;
            position: absolute;
            width: 18px;
            cursor: pointer;
            transform: rotate(180deg);
        }
        .paginationspan.DirectionArrow.left {
            left: -13px;
            transform: rotate(0deg);
            top: 8px;
        }
        .paginationspan.DirectionArrow.right {
            right: -13px;
            top: 13px;
        }
        .pagination>li>a, .pagination>li>span {
            position: relative;
            float: left;
            padding: 6px 12px;
            margin-left: -1px;
            line-height: 1.42857143;
            color: #555555;
            text-decoration: none;
            background-color: #f7f8f9;
            border: 1px solid #f7f8f9;
        }
        .pagination>.active>a, .pagination>.active>a:hover, .pagination>.active>a:focus {
            color: #337ab7;
            background: transparent;
            border: none;
            line-height: 24px;
        }
        .pagination>li>a {
            padding: 10px 5px;
        }
        .pagination>li:first-child>a, .pagination>li:last-child>a {
            padding: 10px 20px;
        }
        /*activity block*/
        .activityLogBlock {
            width: 430px;
            height: calc(100% - 225px);
            overflow: auto;
            background: #fff;
            box-shadow: -3px 3px 8px #ccc;
            right: 0px;
            padding: 0px 30px 30px 30px;
            float: left;
            margin-left: 3px;
            display: none;
            /* height:auto; */
            position: fixed;
        }
        .activityLogBlock .topRowHaedingAnch {
            position: relative;
        }
        /*.scrollBar{position: absolute;height: 100%;width: 10px;right: -2px;background: #fff;z-index: 999;opacity:1; transition:all ease 0.5s}  .questionBlock:hover .scrollBar{opacity:1; transition:all ease 0.5s}*/
        .activityLogBlock .topRowHaedingAnchh3 {
            display: inline-block;
            color: #1c1c1c;
        }
        .activityLogBlock .topRowHaedingAncha.seDeatil {
            margin: 0px 0px 0px 20px;
            display: inline-block;
        }
        .activityLogBlock .topRowHaedingAncha.seDeatilem {
            font-family: cursive;
            position: relative;
            top: 2px;
            display: inline-block;
            margin-left: 3px;
        }
        .activityLogBlock .topRowHaedingAnchspan.cross {
            background: rgb(255, 255, 255) var(--sprite2-url) repeat -234px -70px;
            display: inline-block;
            height: 27px;
            right: -21px;
            position: absolute;
            top: 13px;
            width: 23px;
            cursor: pointer;
            z-index: 999;
            background-size: 500px 600px;
        }
        .activityLogBlock .topRowHaedingAnch .actionBlockDiv {
            margin-top: 30px;
        }
        .activityLogBlock .topRowHaedingAnch .actionBlockDiv .detailEdit {
            background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 1px -296px;
            display: inline-block;
            height: 48px;
            left: 5px;
            position: relative;
            top: 0px;
            width: 48px;
            margin-right: 10px;
            cursor: pointer;
            background-size: 500px 600px;
        }
        .activityLogBlock .topRowHaedingAnch .actionBlockDiv .detailEdit:hover {
            background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll -120px -298px;
        }
        .activityLogBlock .topRowHaedingAnch .actionBlockDiv .detailPrint {
            background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 1px -539px;
            display: inline-block;
            height: 48px;
            left: 5px;
            position: relative;
            top: 0px;
            width: 48px;
            margin-right: 10px;
            cursor: pointer;
            background-size: 500px 600px;
        }
        .activityLogBlock .topRowHaedingAnch .actionBlockDiv .detailPrint:hover {
            background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -118px -539px;
            background-size: 500px 600px;
        }
        .activityLogBlock .actionBlockDiv .detailAdd {
            background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 160px -398px;
            display: inline-block;
            height: 48px;
            left: 5px;
            position: relative;
            top: 0px;
            width: 48px;
            margin-right: 10px;
            cursor: pointer;
            background-size: 500px 600px;
        }
        .activityLogBlock .actionBlockDiv .detailAdd:hover {
            background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 215px -398px;
            background-size: 500px 600px;
        }
        .activityLogBlock .topRowHaedingAnch .actionBlockDiv .detailDuplicate {
            background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 1px -377px;
            display: inline-block;
            height: 48px;
            left: 5px;
            position: relative;
            top: 0px;
            width: 48px;
            margin-right: 10px;
            cursor: pointer;
            background-size: 500px 600px;
        }
        .activityLogBlock .topRowHaedingAnch .actionBlockDiv .detailDuplicate:hover {
            background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -119px -377px;
            background-size: 500px 600px;
        }
        .activityLogBlock .topRowHaedingAnch .actionBlockDiv .detailDelete {
            background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 1px -459px;
            display: inline-block;
            height: 48px;
            left: 5px;
            position: relative;
            top: 0px;
            width: 48px;
            margin-right: 10px;
            cursor: pointer;
            background-size: 500px 600px;
        }
        .activityLogBlock .topRowHaedingAnch .actionBlockDiv .detailDelete:hover {
            background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -119px -459px;
            background-size: 500px 600px;
        }
        .nav>li>a:focus, .nav>li>a:hover, .nav>li>a.active {
            border-bottom: 2px solid;
            color: #0f6fb4;
            background: #fff !important;
            /* color: inherit; background: transparent; */
        }
        .activityLogBlock .topRowHaedingAnch .questionBlock {
            margin-top: 10px;
            /* height: 100%; */
            /* max-height: 450px; */
            overflow: auto;
            padding-top: 0px;
        }
        .activityLogBlock .topRowHaedingAnch .questionBlockh3.noteHeading {
            margin: 15px 0px;
        }
        .activityLogBlock .topRowHaedingAnch .questionBlock .listNumQuestion {
            position: relative;
            border-top: 1px solid #e8e8e8;
            margin-top: 30px;
        }
        .activityLogBlock .topRowHaedingAnch .questionBlock .listNumQuestion div#IFRAME_DIV img {
            width: auto;
            max-width: 100%;
        }
        .activityLogBlock .topRowHaedingAnch .questionBlock .listNumQuestionh4 {
            color: #1c1c1c;
            margin: 20px 0px;
        }
        .activityLogBlock .topRowHaedingAnch .questionBlock .listNumQuestionp {
            color: #505050;
            line-height: 19px;
            width: 92%;
            cursor: pointer;
        }
        .activityLogBlock .topRowHaedingAnch .questionBlock .listNumQuestionspan.DirectionArrow {
            background: rgb(255, 255, 255) var(--sprite2-url) repeat -176px -70px;
            display: inline-block;
            height: 22px;
            right: 0px;
            position: absolute;
            top: 50%;
            width: 18px;
            cursor: pointer;
            transform: rotate(180deg);
            background-size: 500px 600px;
        }
        .activityLogBlock .learningSlideTab {
            position: relative;
            padding-top: 30px;
        }
        .activityLogBlock .learningSlideTabh3 {
            display: inline-block;
            color: #1c1c1c;
        }
        .activityLogBlock .learningSlideTaba.seDeatil {
            margin: 0px 0px 0px 20px;
            display: inline-block;
        }
        .activityLogBlock .learningSlideTaba.seDeatilem {
            font-family: cursive;
            position: relative;
            top: 2px;
            display: inline-block;
            margin-left: 3px;
        }
        .activityLogBlock .learningSlideTabspan.cross {
            background: rgb(255, 255, 255) var(--sprite-url) repeat -234px -70px;
            display: inline-block;
            height: 27px;
            right: -13px;
            position: absolute;
            top: 10px;
            width: 23px;
            cursor: pointer;
            z-index: 999;
        }
        .activityLogBlock .learningSlideTab .actionBlockDiv {
            margin-top: 30px;
        }
        .activityLogBlock .learningSlideTab .actionBlockDiv .detailEdit {
            background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll 1px -298px;
            display: inline-block;
            height: 48px;
            left: 5px;
            position: relative;
            top: 0px;
            width: 48px;
            margin-right: 10px;
            cursor: pointer;
        }
        .activityLogBlock .learningSlideTab .actionBlockDiv .detailEdit:hover {
            background: rgba(0, 0, 0, 0) url(../images/sprite.svg) repeat scroll -148px -362px;
            background-size: 900px;
        }
        .activityLogBlock .learningSlideTab .actionBlockDiv .detailPrint {
            background: rgba(0, 0, 0, 0) url(../images/sprite.svg) repeat scroll -4px -449px;
            display: inline-block;
            height: 48px;
            left: 5px;
            position: relative;
            top: 0px;
            width: 47px;
            background-size: 1570px;
            margin-right: 10px;
            cursor: pointer;
        }
        .activityLogBlock .learningSlideTab .actionBlockDiv .detailPrint:hover {
            background: rgba(0, 0, 0, 0) url(../images/sprite.svg) repeat scroll -98px -459px;
        }
        .activityLogBlock .learningSlideTab .actionBlockDiv .detailDuplicate {
            background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll 1px -462px;
            display: inline-block;
            height: 48px;
            left: 5px;
            position: relative;
            top: 0px;
            width: 48px;
            margin-right: 10px;
            cursor: pointer;
        }
        .activityLogBlock .learningSlideTab .actionBlockDiv .detailDuplicate:hover {
            background: rgba(0, 0, 0, 0) url(../images/sprite.svg) repeat scroll -149px -451px;
            background-size: 900px;
        }
        .activityLogBlock .learningSlideTab .actionBlockDiv .detailDelete {
            background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll 1px -542px;
            display: inline-block;
            height: 48px;
            left: 5px;
            position: relative;
            top: 0px;
            width: 48px;
            margin-right: 10px;
            cursor: pointer;
        }
        .activityLogBlock .learningSlideTab .actionBlockDiv .detailDelete:hover {
            background: rgba(0, 0, 0, 0) url(../images/sprite.svg) repeat scroll -150px -538px;
            background-size: 900px;
        }
        .activityLogBlock .learningSlideTab .questionBlock {
            margin-top: 10px;
            height: auto;
            max-height: 450px;
            overflow: auto;
            padding-bottom: 150px;
        }
        .activityLogBlock .learningSlideTab .questionBlockh3.noteHeading {
            margin: 15px 0px;
        }
        .activityLogBlock .learningSlideTab .questionBlock .listNumQuestion {
            position: relative;
            border-top: 1px solid #e8e8e8;
            margin-top: 20px;
        }
        .activityLogBlock .learningSlideTab .questionBlock .listNumQuestionh4 {
            color: #1c1c1c;
            margin: 20px 0px;
        }
        .activityLogBlock .learningSlideTab .questionBlock .listNumQuestionp {
            color: #505050;
            line-height: 19px;
            width: 92%;
            cursor: pointer;
        }
        .activityLogBlock .learningSlideTab .questionBlock .listNumQuestionspan.DirectionArrow {
            background: rgba(0, 0, 0, 0) url(../images/sprite.svg) repeat scroll -349px 0px;
            display: inline-block;
            height: 22px;
            right: 10px;
            position: absolute;
            top: 50%;
            width: 13px;
            background-size: 1570px;
            cursor: pointer;
        }
        .listViewBlock.pulLeftClass {
            width: calc(87.3% - 433px);
            margin-left: 13.9%;
        }
        .activityLogBlock .innerQuestionBox {
            display: none;
            overflow: auto;
            padding-bottom: 10px;
            /* position: fixed; */
            height: 100%;
            padding-right: 20px;
            padding-top: 25px;
        }
        .activityLogBlock .innerQuestionBox table tr td {
            vertical-align: top
        }
        .activityLogBlock .topRowHaedingAnch .innerQuestionBoxh3 {
            padding: 4px 0px 24px 22px;
            border-bottom: 1px solid #e0e0e0;
            display: block;
            position: relative;
            width: 90%;
        }
        .activityLogBlock .innerQuestionBoxh3span.backArrow {
            background: rgb(255, 255, 255) var(--sprite-url) repeat -176px -70px;
            display: inline-block;
            height: 22px;
            left: 0px;
            position: absolute;
            top: 4px;
            width: 13px;
            cursor: pointer;
            transform: rotate(0deg);
        }
        .activityLogBlock .innerQuestionBoxh4 {
            margin-bottom: 22px;
            margin-top: 24px;
        }
        .activityLogBlock .innerQuestionBoxp {
            color: #505050;
            line-height: 20px;
        }
        .activityLogBlock .innerQuestionBox img {
            width: auto;
            max-width: 100%;
        }
        .activityLogBlock .innerQuestionBox label img {
            width: auto;
            max-width: 100%;
        }
        .activityLogBlock .innerQuestionBox font img {
            width: auto;
            max-width: 100%;
        }
        .activityLogBlock .innerQuestionBox font span {
            font-size: inherit !important
        }
        .activityLogBlock .innerQuestionBox font div {
            font-size: inherit !important
        }
        .activityLogBlock .innerQuestionBox font p {
            font-size: inherit !important;
            -webkit-margin-before: 1em;
        }
        .activityLogBlock .innerQuestionBox font {
            display: inline-block;
            padding-top: 5px;
            padding-bottom: 5px;
        }
        .activityLogBlock .innerQuestionBox table tr td {
            vertical-align: top
        }
        .activityLogBlock .innerQuestionBox table tr td:first-child {
            width: 30%
        }
        .activityLogBlock .innerQuestionBox ul {
            display: block;
            list-style-type: disc;
            left: 4%;
            position: relative;
            width: 93%;
            -webkit-margin-before: 1em;
            -webkit-margin-after: 1em;
            -webkit-margin-start: 0px;
            -webkit-margin-end: 0px;
            -webkit-padding-start: 20px;
        }
        @-moz-document url-prefix() {
            .activityLogBlock .innerQuestionBox ul {
                left: 6%;
                position: relative;
                width: 93%;
            }
        }
        .activityLogBlock .innerQuestionBox ol {
            display: block;
            list-style-type: decimal;
            left: 4%;
            position: relative;
            width: 93%;
            -webkit-margin-before: 1em;
            -webkit-margin-after: 1em;
            -webkit-margin-start: 0px;
            -webkit-margin-end: 0px;
            -webkit-padding-start: 20px;
        }
        @-moz-document url-prefix() {
            .activityLogBlock .innerQuestionBox ol {
                left: 6%;
                position: relative;
                width: 93%;
            }
        }
        .fixIconDiv {
            padding-top: 25px;
        }
        /*footer*/
        .footer {
            width: 100%;
            height: 50px;
            background: #f1f1f2;
            position: fixed;
            bottom: 0px;
            border-top: 1px solid #e5e6e6;
            z-index: 9;
        }
        .footerp {
            color: #909090;
            padding: 17px 0px;
            display: inline-block;
        }
        .footerul {
            display: inline-block;
            float: right;
            margin-right: 30px;
        }
        .footerulli {
            display: inline-block;
            margin-left: 14px;
            margin-top: 15px;
        }
        .footerullia {
            color: #505050;
        }
        @media only screen and (min-width: 266px) and (max-width: 340px) {
            .listviewblockBox .col-xs-2 {
                padding-left: 5px;
            }
        }
        @media only screen and (min-width: 266px) and (max-width: 767px) {
            .navbar-toggle {
                position: absolute;
                top: -60px;
            }
            .navbar-toggle .icon-bar {
                background: #0f6fb4;
            }
            .logoClass {
                padding-left: 32px;
                width: 180px;
                margin-top: 6px;
            }
            .navbar .navbar-nav {
                width: 100%;
                margin: 0;
                border-bottom: 3px solid #0f6fb4;
            }
            .navbar .navbar-nav.nav>li {
                border-bottom: 1px solid #4288bb;
                text-align: left;
                padding-left: 10px;
            }
            .navbar .navbar-nav.nav>li.noBod {
                border-bottom: none;
            }
            .MenupersonImg {
                background: #fff;
                border-bottom: none !important;
                padding: 18px 0px 5px 0px;
                margin: 0px;
                height: 59px;
            }
            .MenupersonImg a {
                color: #1c1c1c !important;
            }
            .MenupersonImg span {
                display: inline-block;
                height: 35px;
                position: relative;
                bottom: 7px;
                width: 35px;
                overflow: hidden;
                border-radius: 50px;
                float: right;
            }
            .MenupersonImg span img {
                width: 100%;
            }
            .navbar {
                margin-bottom: -59px !important;
                background: #f1f9ff;
            }
            .navbar .navbar-collapse {
                top: -7px;
                background: rgb(238, 238, 238);
                position: absolute;
                z-index: 9999;
                width: 100%;
                padding: 0;
            }
            .MenuTopBartask {
                background: #fff;
                border-bottom: none !important;
                color: #1c1c1c !important;
                margin: 0px;
                padding-left: 55px !important;
                padding-top: 5px;
            }
            .MenuTopBartask a {
                color: #1c1c1c !important;
            }
            .MenuTopBartask span {
                background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll 0px 1px;
                display: inline-block;
                height: 32px;
                left: -27px;
                position: absolute;
                top: 7px;
                width: 30px;
            }
            .MenuTopBarhelp {
                background: #fff;
                margin: 0px;
                border-bottom: none !important;
                padding-bottom: 15px;
                color: #1c1c1c !important;
                padding-left: 55px !important;
                padding-top: 7px;
            }
            .MenuTopBarhelp a {
                color: #1c1c1c !important;
            }
            .MenuTopBarhelp span {
                background: #f7f7f8 url(/static/media/sprite-2x.425102e0.png) repeat scroll -51px -2px;
                height: 31px;
                position: relative;
                bottom: 6px;
                left: 32px;
                width: 30px;
                background-size: 500px 600px;
                float: right;
                border-radius: 20px;
            }
            .srcFieldCol {
                display: none !important;
            }
            .ViewFilter-sm {
                width: 100%;
                height: 50px;
                background: #f7f7f8;
                border: 1px solid #e0e0e0;
                border-left: none;
                color: #0f6fb4;
            }
            .ViewFilter-sm span {
                position: relative;
            }
            .ViewFilter-sm span:before {
                content: '';
                background: transparent var(--sprite-url) no-repeat scroll -327px 0px;
                position: absolute;
                left: -30px;
                top: -7px;
                width: 29px;
                height: 33px;
                display: inline-block;
                -ms-transform: scale(0.7, 0.7);
                -webkit-transform: scale(0.7, 0.7);
                transform: scale(0.7, 0.7);
            }
            .ViewFilter-sm:focus {
                background: #efefef;
                outline: none;
                border: 1px solid #e0e0e0;
            }
            .ViewSearch-sm {
                width: 100%;
                height: 50px;
                background: #f7f7f8;
                border: 1px solid #e0e0e0;
                border-right: none;
                color: #0f6fb4;
            }
            .ViewSearch-sm span {
                position: relative;
            }
            .ViewSearch-sm span:before {
                content: '';
                background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll -100px 0px;
                position: absolute;
                left: -33px;
                top: -4px;
                width: 30px;
                height: 32px;
                display: inline-block;
                -ms-transform: scale(0.7, 0.7);
                -webkit-transform: scale(0.7, 0.7);
                transform: scale(0.7, 0.7);
            }
            .ViewSearch-sm:focus {
                background: #efefef;
                outline: none;
                border: 1px solid #e0e0e0;
            }
            .smText h3 {
                text-align: center;
            }
            .smText .HideCross {
                background: rgb(255, 255, 255) var(--sprite-url) repeat -228px -70px;
                display: inline-block;
                height: 30px;
                right: 0px;
                position: absolute;
                top: 0px;
                width: 30px;
                margin-right: 13px;
                margin-top: 15px;
            }
            .search-sm-Box span.srcHideBox {
                background: rgba(14, 34, 48, 0.1) var(--sprite-url) repeat -329px -80px;
                display: inline-block;
                height: 30px;
                right: 0px;
                position: absolute;
                top: 0px;
                width: 30px;
                background-size: 580px;
                margin-right: 20px;
                margin-top: 15px;
            }
            .search-sm-Box {
                position: fixed;
                background: rgba(0, 0, 0, 0.8);
                width: 100%;
                height: 100%;
                z-index: 9999999999;
                top: 5000px;
                left: 0px;
                bottom: 0px;
                right: 0px;
                padding: 24% 10% 0% 10%;
                transition: all ease 0.2s;
            }
            .search-sm-Box .searchBtn {
                background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll -164px 1px;
                display: inline-block;
                height: 27px;
                left: 5px;
                position: relative;
                top: 0px;
                width: 50px;
            }
            .search-sm-Box .btn.btn-primary {
                padding: 0px;
            }
            .search-sm-Box .btn.btn-primary:hover {
                background: #0f6fb4;
            }
            .sercFilBtnBox.classTostick {
                position: fixed;
                width: 100%;
                top: 0px;
                z-index: 9999;
                background: #f7f7f8;
            }
            .mobileListView {}
            .mobileListView .listviewblockBox {
                background: #fff;
                width: 100%;
                padding: 15px 0px;
                float: left;
                border-bottom: 1px solid #e0e0e0;
            }
            .mobileListView .listviewblockBox.questionType .typeIcon {
                background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll 0px -70px;
                display: inline-block;
                height: 49px;
                left: 0px;
                position: relative;
                top: 4px;
                width: 52px;
                margin-right: 15px;
            }
            .mobileListView .listviewblockBox.flashcardType .typeIcon {
                background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll 0px -150px;
                display: inline-block;
                height: 49px;
                left: 0px;
                position: relative;
                top: 4px;
                width: 52px;
                margin-right: 15px;
            }
            .mobileListView .listviewblockBox.learningType .typeIcon {
                background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll 0px -222px;
                height: 53px;
                width: 55px;
                display: inline-block;
                height: 49px;
                left: 0px;
                position: relative;
                top: 4px;
                width: 52px;
                margin-right: 15px;
            }
            .mobileListView .listviewblockBox.QuesRead .typeIcon {
                background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -313px -466px / 500px 600px;
                display: inline-block;
                height: 49px;
                left: 0;
                margin-right: 15px;
                position: relative;
                top: 1px;
                width: 52px;
                float: left;
            }
            .mobileListView .listviewblockBox.QuesWrite .typeIcon {
                background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -315px -536px / 500px 600px;
                display: inline-block;
                height: 49px;
                left: 0;
                margin-right: 15px;
                position: relative;
                top: 1px;
                width: 52px;
                float: left;
            }
            .mobileListView .listviewblockBox .contentType {}
            .mobileListView .listviewblockBox .contentType h4 {
                color: #0f6fb4;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding-bottom: 2px;
            }
            .mobileListView .listviewblockBox .contentType p {
                margin-top: 7px;
                color: #1c1c1c;
                text-overflow: ellipsis;
                overflow: hidden;
                width: 100%;
                white-space: nowrap;
            }
            .mobileListView .listviewblockBox .contentType p strong {
                color: #555555;
            }
            .mobileListView .listviewblockBox .contentType p span {}
            .filterBlock {
                position: fixed;
                margin-top: 0px;
                height: 100%;
                width: 100%;
                background-color: transparent;
                background: #fff;
                border: none;
                padding-top: 20px;
                overflow: auto;
                z-index: 999999999999;
                top: 5000px;
                transition: all ease 0.2s;
                overflow: auto;
                padding-bottom: 30px;
            }
            .filterBlock .ApplyFilter {
                position: absolute;
                bottom: 0px;
                left: 0px;
                width: 100%;
                height: 50px;
                background: #44a9f2;
                border: 1px solid #44a9f2;
                color: #fff;
            }
            .listViewBlock {
                margin-left: auto;
                width: 100% !important;
                float: left;
                padding-bottom: 70px;
                margin-left: 0px !important;
                background-color: white !important;
            }
            .sercFilBtnBox.classTostick {
                position: fixed;
                width: 100%;
                top: 0px;
                z-index: 9999;
                background: #f7f7f8;
            }
            .navbar-nav>li>a:hover, .navbar-nav>li>a.active {
                color: inherit;
                background: inherit;
            }
            .scroll-top-wrapper {
                position: fixed;
                opacity: 0;
                border: 1px solid #888b8d;
                visibility: hidden;
                overflow: hidden;
                text-align: center;
                z-index: 99999999;
                width: 40px;
                height: 40px;
                line-height: 48px;
                right: 30px;
                bottom: 30px;
                padding-top: 2px;
                border-radius: 10px;
                transition: all 0.5s ease-in-out;
                transform: rotate(90deg);
                background: rgb(241, 249, 255) var(--sprite-url) repeat -197px -77px;
            }
            .scroll-top-wrapper:hover {
                background: #f1f9ff url(../images/sprite.svg) repeat scroll -335px 5px;
                height: 40px;
                width: 40px;
                transform: rotate(270deg);
                border: 1px solid #b2b7bb;
                background-size: 1570px;
            }
            .scroll-top-wrapper.show {
                visibility: visible;
                cursor: pointer;
                opacity: 1.0;
                background: rgb(255, 255, 255) var(--sprite-url) repeat -168px -64px;
                height: 40px;
                width: 40px;
                border: 1px solid #888b8d;
                transform: rotate(90deg);
            }
            .scroll-top-wrapper i.fa {
                line-height: inherit;
            }
            .overFlowScroll-sm {
                height: auto;
                overflow: auto;
                float: left;
                width: 100%;
                max-height: 80%;
                padding-bottom: 30px;
            }
            .mobAlertDiv {
                padding-right: 10px;
            }
            .topFirstBar .topBarProfileIcons .TopBarnotification {
                margin-right: 0px;
            }
            .HiddenLessLap {
                display: none !important;
            }
            .activityLogBlock.questionActivityMainBox.DivShow {
                display: none !important;
            }
            .listviewblockBox:hover {
                background: #f1f9ff;
            }
            .TopZero {
                top: 0px !important;
            }
            /*.container-fluid.noPad.listMainBoxWrapper {
            position: inherit;
        }*/
        }
        @media only screen and (min-width: 550px) and (max-width: 767px) {
            .listviewblockBox .col-xs-2 {
                width: 12%;
            }
            .listviewblockBox .col-xs-5 {
                width: 43%;
            }
        }
        @media only screen and (min-width: 768px) and (max-width: 1199px) {
            .hidden-tab {
                display: none !important;
            }
            .logoClass {
                width: 180px;
                margin-top: 6px;
            }
            .crtBtnCol {
                display: block !important;
            }
            .srcFieldCol {
                display: none !important;
            }
            .ViewFilter-sm {
                width: 100%;
                height: 50px;
                background: #f7f7f8;
                border: 1px solid #e0e0e0;
                border-left: none;
                color: #0f6fb4;
            }
            .ViewFilter-sm span {
                position: relative;
            }
            .ViewFilter-sm span:before {
                content: '';
                background: transparent var(--sprite-url) no-repeat scroll -327px 0px;
                position: absolute;
                left: -30px;
                top: -7px;
                width: 29px;
                height: 33px;
                display: inline-block;
                -ms-transform: scale(0.7, 0.7);
                -webkit-transform: scale(0.7, 0.7);
                transform: scale(0.7, 0.7);
            }
            .ViewFilter-sm:focus {
                background: #efefef;
                outline: none;
                border: 1px solid #e0e0e0;
            }
            .ViewSearch-sm {
                width: 100%;
                height: 50px;
                background: #f7f7f8;
                border: 1px solid #e0e0e0;
                border-right: none;
                color: #0f6fb4;
            }
            .ViewSearch-sm span {
                position: relative;
            }
            .ViewSearch-sm span:before {
                content: '';
                background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll -100px 0px;
                position: absolute;
                left: -33px;
                top: -4px;
                width: 30px;
                height: 32px;
                display: inline-block;
                -ms-transform: scale(0.7, 0.7);
                -webkit-transform: scale(0.7, 0.7);
                transform: scale(0.7, 0.7);
            }
            .ViewSearch-sm:focus {
                background: #efefef;
                outline: none;
                border: 1px solid #e0e0e0;
            }
            .smText h3 {
                text-align: center;
            }
            .smText .HideCross {
                background: rgb(255, 255, 255) var(--sprite-url) repeat -228px -70px;
                display: inline-block;
                height: 30px;
                right: 0px;
                position: absolute;
                top: 0px;
                width: 30px;
                margin-right: 13px;
                margin-top: 15px;
            }
            .search-sm-Box span.srcHideBox {
                background: rgba(14, 34, 48, 0.1) var(--sprite-url) repeat -329px -80px;
                display: inline-block;
                height: 30px;
                right: 0px;
                position: absolute;
                top: 0px;
                width: 30px;
                background-size: 580px;
                margin-right: 20px;
                margin-top: 15px;
            }
            .search-sm-Box {
                position: fixed;
                background: rgba(0, 0, 0, 0.8);
                width: 100%;
                height: 100%;
                z-index: 9999999999;
                top: 5000px;
                left: 0px;
                bottom: 0px;
                right: 0px;
                padding: 24% 10% 0% 10%;
                transition: all ease 0.2s;
            }
            .search-sm-Box .searchBtn {
                background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll -164px 1px;
                display: inline-block;
                height: 27px;
                left: 5px;
                position: relative;
                top: 0px;
                width: 50px;
            }
            .search-sm-Box .btn.btn-primary {
                padding: 0px;
            }
            .search-sm-Box .btn.btn-primary:hover {
                background: #0f6fb4;
            }
            .sercFilBtnBox.classTostick {
                position: fixed;
                width: 100%;
                top: 0px;
                z-index: 9999;
                background: #f7f7f8;
            }
            .filterBlock {
                position: fixed;
                margin-top: 0px;
                height: 100%;
                width: 100%;
                background-color: transparent;
                background: #fff;
                border: none;
                padding-top: 20px;
                overflow: auto;
                z-index: 999999999999;
                top: 5000px;
                transition: all ease 0.2s;
                overflow: auto;
                padding-bottom: 30px;
            }
            .filterBlock .ApplyFilter {
                position: absolute;
                bottom: 0px;
                left: 0px;
                width: 100%;
                height: 50px;
                background: #44a9f2;
                border: 1px solid #44a9f2;
                color: #fff;
            }
            .listViewBlock {
                margin-left: auto;
                width: 100% !important;
                float: left;
                padding-bottom: 70px;
                margin-left: 0px !important;
                background-color: white !important;
            }
            .sercFilBtnBox.classTostick {
                position: fixed;
                width: 100%;
                top: 0px;
                z-index: 9999;
                background: #f7f7f8;
            }
            .scroll-top-wrapper {
                position: fixed;
                opacity: 0;
                border: 1px solid #888b8d;
                visibility: hidden;
                overflow: hidden;
                text-align: center;
                z-index: 99999999;
                width: 40px;
                height: 40px;
                line-height: 48px;
                right: 30px;
                bottom: 30px;
                padding-top: 2px;
                border-radius: 10px;
                transition: all 0.5s ease-in-out;
                transform: rotate(90deg);
                background: rgb(241, 249, 255) var(--sprite-url) repeat -197px -77px;
            }
            .scroll-top-wrapper:hover {
                background: #f1f9ff url(../images/sprite.svg) repeat scroll -335px 5px;
                height: 40px;
                width: 40px;
                transform: rotate(270deg);
                border: 1px solid #b2b7bb;
                background-size: 1570px;
            }
            .scroll-top-wrapper.show {
                visibility: visible;
                cursor: pointer;
                opacity: 1.0;
                background: rgb(241, 249, 255) var(--sprite-url) repeat -197px -77px;
                background-size: 580px;
                height: 40px;
                width: 40px;
                border: 1px solid #888b8d;
                transform: rotate(90deg);
            }
            .scroll-top-wrapper i.fa {
                line-height: inherit;
            }
            .overFlowScroll-sm {
                height: auto;
                overflow: auto;
                float: left;
                width: 100%;
                max-height: 80%;
                padding-bottom: 30px;
            }
            .listViewBlock table.table-hover tbody tr td .DropDivBlock {
                display: none;
            }
            .activityLogBlock.questionActivityMainBox.DivShow {
                display: none !important;
            }
            .row.hidden-md.hidden-lg.sercFilBtnBox {
                display: block !important;
            }
            .HiddenLessLap {
                display: none !important;
            }
            .smText {
                display: block !important;
            }
            .filterBlock .ApplyFilter {
                display: block !important;
            }
            .TopZero {
                top: 0px !important;
            }
            /*.container-fluid.noPad.listMainBoxWrapper {
            position: inherit;
            overflow: inherit;
        }*/
        }
        @media only screen and (min-width: 1024px) and (max-width: 1279px) {
            .crtBtnCol {
                display: block !important;
            }
            .ViewFilter-sm {
                width: 100%;
                height: 50px;
                background: #f7f7f8;
                border: 1px solid #e0e0e0;
                border-left: none;
                color: #0f6fb4;
            }
            .ViewFilter-sm span {
                position: relative;
            }
            .ViewFilter-sm span:before {
                content: '';
                background: transparent var(--sprite-url) no-repeat scroll -327px 0px;
                position: absolute;
                left: -30px;
                top: -7px;
                width: 29px;
                height: 33px;
                display: inline-block;
                -ms-transform: scale(0.7, 0.7);
                -webkit-transform: scale(0.7, 0.7);
                transform: scale(0.7, 0.7);
            }
            .ViewFilter-sm:focus {
                background: #efefef;
                outline: none;
                border: 1px solid #e0e0e0;
            }
            .ViewSearch-sm {
                width: 100%;
                height: 50px;
                background: #f7f7f8;
                border: 1px solid #e0e0e0;
                border-right: none;
                color: #0f6fb4;
            }
            .ViewSearch-sm span {
                position: relative;
            }
            .ViewSearch-sm span:before {
                content: '';
                background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll -100px 0px;
                position: absolute;
                left: -33px;
                top: -4px;
                width: 30px;
                height: 32px;
                display: inline-block;
                -ms-transform: scale(0.7, 0.7);
                -webkit-transform: scale(0.7, 0.7);
                transform: scale(0.7, 0.7);
            }
            .ViewSearch-sm:focus {
                background: #efefef;
                outline: none;
                border: 1px solid #e0e0e0;
            }
            .smText h3 {
                text-align: center;
            }
            .smText .HideCross {
                background: rgb(255, 255, 255) var(--sprite-url) repeat -228px -70px;
                display: inline-block;
                height: 30px;
                right: 0px;
                position: absolute;
                top: 0px;
                width: 30px;
                margin-right: 13px;
                margin-top: 15px;
            }
            .search-sm-Box span.srcHideBox {
                background: rgba(14, 34, 48, 0.1) var(--sprite-url) repeat -329px -80px;
                display: inline-block;
                height: 30px;
                right: 0px;
                position: absolute;
                top: 0px;
                width: 30px;
                background-size: 580px;
                margin-right: 20px;
                margin-top: 15px;
            }
            .search-sm-Box {
                position: fixed;
                background: rgba(0, 0, 0, 0.8);
                width: 100%;
                height: 100%;
                z-index: 9999999999;
                top: 5000px;
                left: 0px;
                bottom: 0px;
                right: 0px;
                padding: 24% 10% 0% 10%;
                transition: all ease 0.2s;
            }
            .search-sm-Box .searchBtn {
                background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll -164px 1px;
                display: inline-block;
                height: 27px;
                left: 5px;
                position: relative;
                top: 0px;
                width: 50px;
            }
            .search-sm-Box .btn.btn-primary {
                padding: 0px;
            }
            .search-sm-Box .btn.btn-primary:hover {
                background: #0f6fb4;
            }
            .sercFilBtnBox.classTostick {
                position: fixed;
                width: 100%;
                top: 0px;
                z-index: 9999;
                background: #f7f7f8;
            }
            .filterBlock .ApplyFilter {
                display: block !important;
            }
        }
        @media only screen and (min-width: 1200px) and (max-width: 1400px) {
            .filterBlock {
                width: 18%;
            }
            .noFilter {
                width: 100% !important;
                margin-left: 0px !important;
            }
            .listViewBlock {
                width: 82%;
                margin-left: 18%;
                background-color: white !important;
            }
            .filterBlock.collapsableDiv .FunnelFilterIcon {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0px;
                right: 0px;
                bottom: 0px;
                top: 0px;
                background: #f1f1f2 var(--sprite-url) no-repeat scroll -322px 13px;
                cursor: pointer;
            }
            .filterBlock.collapsableDiv .removeFilterDisplay {
                display: none
            }
            .filterBlock.collapsableDiv {
                width: 3.2%;
                overflow: hidden;
                padding: 0px;
                position: fixed;
            }
            .listViewBlock.pulLeftClass {
                width: calc(98.2% - 433px);
                margin-left: 3.2%;
            }
        }
        @media only screen and (min-width: 1280px) and (max-width: 1400px) {
            .slctCol {
                width: 150px;
                padding: 0;
                padding-right: 22px;
            }
            .crtBtnCol {
                width: 120px;
                padding-left: 0px;
            }
            .srcFieldCol {
                width: 280px;
            }
        }
        @media screen\0 {
            .BoxSelMain span.tickyArrow {
                background: transparent var(--sprite-url) no-repeat scroll 0px -170px;
            }
        }
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {}
        /* scroll bar */
    }
    .ViewSearch-sm:focus {
        background: #efefef;
        outline: none;
        border: 1px solid #e0e0e0;
    }
    .smText h3 {
        text-align: center;
    }
    .smText .HideCross {
        background: rgb(255, 255, 255) var(--sprite-url) repeat -228px -70px;
        display: inline-block;
        height: 30px;
        right: 0px;
        position: absolute;
        top: 0px;
        width: 30px;
        margin-right: 13px;
        margin-top: 15px;
        cursor: pointer;
    }
    .search-sm-Box span.srcHideBox {
        background: rgba(14, 34, 48, 0.1) var(--sprite-url) repeat -329px -80px;
        display: inline-block;
        height: 30px;
        right: 0px;
        position: absolute;
        top: 0px;
        width: 30px;
        background-size: 580px;
        margin-right: 20px;
        margin-top: 15px;
        cursor: pointer;
    }
    .search-sm-Box {
        position: fixed;
        background: rgba(0, 0, 0, 0.8);
        width: 100%;
        height: 100%;
        z-index: 9999999999;
        top: 5000px;
        left: 0px;
        bottom: 0px;
        right: 0px;
        padding: 24% 10% 0% 10%;
        transition: all ease 0.2s;
    }
    .search-sm-Box .searchBtn {
        background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll -164px 1px;
        display: inline-block;
        height: 27px;
        left: 5px;
        position: relative;
        top: 0px;
        width: 50px;
    }
    .search-sm-Box .btn.btn-primary {
        padding: 0px;
    }
    .search-sm-Box .btn.btn-primary:hover {
        background: #0f6fb4;
    }
    .sercFilBtnBox.classTostick {
        position: fixed;
        width: 100%;
        top: 0px;
        z-index: 9999;
        background: #f7f7f8;
    }
    .filterBlock {
        position: fixed;
        margin-top: 0px;
        height: 100%;
        width: 100%;
        background-color: transparent;
        background: #fff;
        border: none;
        padding-top: 20px;
        overflow: auto;
        z-index: 999999999999;
        top: 5000px;
        transition: all ease 0.2s;
        overflow: auto;
        padding-bottom: 30px;
    }
    .filterBlock .ApplyFilter {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 50px;
        background: #44a9f2;
        border: 1px solid #44a9f2;
        color: #fff;
    }
    .listViewBlock {
        margin-left: auto;
        width: 100% !important;
        float: left;
        padding-bottom: 70px;
        margin-left: 0px !important;
        background-color: white !important;
    }
    .sercFilBtnBox.classTostick {
        position: fixed;
        width: 100%;
        top: 0px;
        z-index: 9999;
        background: #f7f7f8;
    }
    .scroll-top-wrapper {
        position: fixed;
        opacity: 0;
        border: 1px solid #888b8d;
        visibility: hidden;
        overflow: hidden;
        text-align: center;
        z-index: 99999999;
        width: 40px;
        height: 40px;
        line-height: 48px;
        right: 30px;
        bottom: 30px;
        padding-top: 2px;
        border-radius: 10px;
        transition: all 0.5s ease-in-out;
        transform: rotate(90deg);
        background: rgb(241, 249, 255) var(--sprite-url) repeat -197px -77px;
    }
    .scroll-top-wrapper:hover {
        background: #f1f9ff url(../images/sprite.svg) repeat scroll -335px 5px;
        height: 40px;
        width: 40px;
        transform: rotate(270deg);
        border: 1px solid #b2b7bb;
        background-size: 1570px;
    }
    .scroll-top-wrapper.show {
        visibility: visible;
        cursor: pointer;
        opacity: 1.0;
        background: rgb(241, 249, 255) var(--sprite-url) repeat -197px -77px;
        background-size: 580px;
        height: 40px;
        width: 40px;
        border: 1px solid #888b8d;
        transform: rotate(90deg);
    }
    .scroll-top-wrapper i.fa {
        line-height: inherit;
    }
    .overFlowScroll-sm {
        height: auto;
        overflow: auto;
        float: left;
        width: 100%;
        max-height: 80%;
        padding-bottom: 30px;
    }
    .listViewBlock table.table-hover tbody tr td .DropDivBlock {
        display: none;
    }
    .activityLogBlock.questionActivityMainBox.DivShow {
        display: none !important;
    }
    .row.hidden-md.hidden-lg.sercFilBtnBox {
        display: block !important
    }
    .HiddenLessLap {
        display: none !important;
    }
    .smText {
        display: block !important;
    }
    .filterBlock .ApplyFilter {
        display: block !important
    }
    .TopZero {
        top: 0px !important;
    }
    /*.container-fluid.noPad.listMainBoxWrapper {
    position: inherit;
    overflow: inherit;
}*/
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
    .crtBtnCol {
        display: block !important;
    }
    .ViewFilter-sm {
        width: 100%;
        height: 50px;
        background: #f7f7f8;
        border: 1px solid #e0e0e0;
        border-left: none;
        color: #0f6fb4;
    }
    .ViewFilter-sm span {
        position: relative
    }
    .ViewFilter-sm span:before {
        content: '';
        background: transparent var(--sprite2-url) no-repeat scroll -324px 0px;
        position: absolute;
        left: -30px;
        top: -7px;
        width: 29px;
        height: 33px;
        display: inline-block;
        background-size: 500px 600px;
        -ms-transform: scale(0.7, 0.7);
        -webkit-transform: scale(0.7, 0.7);
        transform: scale(0.7, 0.7);
    }
    .ViewFilter-sm:focus {
        background: #efefef;
        outline: none;
        border: 1px solid #e0e0e0;
    }
    .ViewSearch-sm {
        width: 100%;
        height: 50px;
        background: #f7f7f8;
        border: 1px solid #e0e0e0;
        border-right: none;
        color: #0f6fb4;
    }
    .ViewSearch-sm span {
        position: relative
    }
    .ViewSearch-sm span:before {
        content: '';
        background: var(--sprite2-url) repeat -325px -65px;
        position: absolute;
        left: -33px;
        top: -4px;
        width: 30px;
        height: 32px;
        display: inline-block;
        background-size: 500px 600px;
        -ms-transform: scale(0.7, 0.7);
        -webkit-transform: scale(0.7, 0.7);
        transform: scale(0.7, 0.7);
    }
    .ViewSearch-sm:focus {
        background: #efefef;
        outline: none;
        border: 1px solid #e0e0e0;
    }
    .smText h3 {
        text-align: center;
    }
    .smText .HideCross {
        background: rgb(255, 255, 255) var(--sprite2-url) repeat -228px -70px;
        display: inline-block;
        height: 30px;
        right: 0px;
        position: absolute;
        top: 0px;
        width: 30px;
        margin-right: 13px;
        margin-top: 15px;
        background-size: 500px 600px;
    }
    .search-sm-Box span.srcHideBox {
        background: rgba(14, 34, 48, 0.1) var(--sprite2-url) repeat -785px -65px;
        display: inline-block;
        height: 30px;
        right: 0px;
        position: absolute;
        top: 0px;
        width: 30px;
        margin-right: 20px;
        margin-top: 15px;
        background-size: 500px 600px;
    }
    .search-sm-Box {
        position: fixed;
        background: rgba(0, 0, 0, 0.8);
        width: 100%;
        height: 100%;
        z-index: 9999999999;
        top: 5000px;
        left: 0px;
        bottom: 0px;
        right: 0px;
        padding: 24% 10% 0% 10%;
        transition: all ease 0.2s;
    }
    .search-sm-Box .searchBtn {
        background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -164px -3px;
        display: inline-block;
        height: 27px;
        left: 5px;
        position: relative;
        top: 0px;
        width: 50px;
        background-size: 500px 600px;
    }
    .search-sm-Box .btn.btn-primary {
        padding: 0px;
    }
    .search-sm-Box .btn.btn-primary:hover {
        background: #0f6fb4;
    }
    .sercFilBtnBox.classTostick {
        position: fixed;
        width: 100%;
        top: 0px;
        z-index: 9999;
        background: #f7f7f8;
    }
    .filterBlock .ApplyFilter {
        display: block !important
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1280px) {
    .ApplyFilter {
        display: none;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .filterBlock {
        width: 18%;
    }
    .noFilter {
        width: 100% !important;
        margin-left: 0px !important;
    }
    .listViewBlock {
        width: 81.8%;
        margin-left: 18.2%;
        background-color: white !important;
    }
    .filterBlock.collapsableDiv .FunnelFilterIcon {
        position: relative;
        width: 100%;
        height: 100%;
        left: 0px;
        right: 0px;
        bottom: 0px;
        top: 0px;
        background: #f1f1f2 var(--sprite-url) no-repeat scroll -322px 13px;
        cursor: pointer;
    }
    .filterBlock.collapsableDiv .removeFilterDisplay {
        display: none
    }
    .filterBlock.collapsableDiv {
        width: 3.2%;
        overflow: hidden;
        padding: 0px;
        position: fixed;
    }
    .listViewBlock.pulLeftClass {
        width: calc(98.2% - 433px);
        margin-left: 3.2%;
    }
}

@media only screen and (min-width: 1280px) and (max-width: 1400px) {
    .slctCol {
        width: 150px;
        padding: 0;
        padding-right: 22px;
    }
    .crtBtnCol {
        width: 120px;
        padding-left: 0px;
    }
    .srcFieldCol {
        width: 280px;
    }
}

@media screen\0 {
    .BoxSelMain span.tickyArrow {
        background: transparent var(--sprite-url) no-repeat scroll 0px -170px
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {}

/* scroll bar */

@media only screen and (min-width: 1200px) and (max-width: 1280px) {
    .filterBlock .ApplyFilter {
        display: none !important;
    }
}

.viewing {
    background-color: #f1f9ff;
}

/*create Activity*/

/**/

html, body {
    width: 100%;
    height: 100%;
}

a:hover {
    text-decoration: none !important;
}

.toolTipCus+.tooltip>.tooltip-inner {
    background-color: #f1f9ff;
    color: #505050;
    border: 1px solid #cacaca;
    text-align: left;
    width: 115%;
    padding: 8px 17px 8px 17px;
}

.toolTipCus+.tooltip>.tooltip-arrow {
    border-top-color: #cacaca;
}

button:focus {
    outline: none;
}

.stepBulletsMain {
    margin-top: 10px;
}

ul.stepBulletsMain li {
    display: inline-block;
    width: 32%;
}

ul.stepBulletsMain li a {
    color: #b7b7b7;
    display: block;
    position: relative;
    text-align: center;
}

ul.stepBulletsMain li a p {}

ul.stepBulletsMain li a span {
    background: #fff;
    position: relative;
    width: 20px;
    height: 20px;
    display: block;
    border-radius: 100%;
    border: 1px solid #ccc;
    margin: 0 auto;
    padding-top: 3px;
    z-index: 1;
    margin-bottom: 6px;
}

ul.stepBulletsMain li a span:before {
    content: '';
}

ul.stepBulletsMain li a span:after {
    content: '';
}

ul.stepBulletsMain li a:before {
    content: '';
    width: 48%;
    height: 2px;
    background: #ccc;
    position: absolute;
    top: 9px;
    left: 4px;
}

ul.stepBulletsMain li a:after {
    content: '';
    width: 48%;
    height: 2px;
    background: #ccc;
    position: absolute;
    top: 9px;
    right: -7px;
}

ul.stepBulletsMain li:first-child a:before {
    content: '';
    display: none;
}

ul.stepBulletsMain li:last-child a:after {
    content: '';
    display: none;
}

ul.stepBulletsMain li.active a {
    color: #44a9f2;
}

ul.stepBulletsMain li.active a span {
    border-color: #44a9f2;
}

ul.stepBulletsMain li.active a:after {
    content: '';
    background: #44a9f2;
}

ul.stepBulletsMain li.active a:before {
    content: '';
    background: #44a9f2;
}

/* Bcrumb */

.row .BcrumNsteps {
    background: rgb(15, 111, 180);
    color: #fff;
    margin-top: 5px;
    /*height: 49px;*/
    /* height: 47px; */
}

.row .BcrumNsteps .breadCrumbs ul {
    margin-top: -5px;
}

.BcrumNsteps {
    background: rgb(15, 111, 180);
    color: #fff;
    padding-top: 10px;
}

.breadCrumbHeading h3 {
    padding-top: 5px;
}

.BcrumNsteps .breadCrumbs {
    padding-left: 8px;
    margin-top: -7px;
    width: max-content;
}

.BcrumNsteps .breadCrumbs ul {
    padding: 5px 0px;
}

.BcrumNsteps .breadCrumbs ul li {
    display: inline-block;
}

.BcrumNsteps .breadCrumbs ul li span.currentPage {
    color: #fff;
}

.BcrumNsteps .breadCrumbs ul li a {
    color: #fff;
    position: relative;
    margin-right: 23px;
    display: block;
}

.BcrumNsteps .breadCrumbs ul li a:after {
    content: "»";
    /* content: "▶"; */
    /* background: transparent var(--sprite2-url) no-repeat scroll -234px -11px; */
    position: inherit;
    right: -10px;
    top: 0px;
    width: 19px;
    height: 16px;
    /* display: inline-block; */
    transform: rotate(270deg);
    background-size: 500px 600px;
}

/*mainFormBox*/

.mainFormBox {
    background: #fff;
    padding: 20px 20px 20px 20px;
    border: 1px solid #c6c6c7;
    margin: 10px;
}

.mainFormBox h3 {
    color: #1c1c1c;
    padding: 25px 0px;
    border-bottom: 1px solid #d3d3d3;
    margin: 0px 20px 30px 20px;
}

.mainFormBox .fieldBg {
    background: #fbfbfb;
}

.mainFormBox .form-group {
    margin-top: 10px;
    width: 95%;
    float: left;
}

.mainFormBox .form-group a.Activity-toolTip {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -467px -185px;
    display: inline-block;
    height: 25px;
    width: 5.5%;
    margin-top: 7px;
    background-size: 500px 600px;
    margin-left: 2%;
}

.form-group a.Activity-toolTip {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -467px -185px;
    display: inline-block;
    height: 25px;
    width: 5.5%;
    margin-top: 7px;
    background-size: 500px 600px;
    margin-left: 2%;
}

/*.mainFormBox  .tooltip-inner {max-width: 200px;padding: 8px 17px 8px 17px;color: #fff;text-align: center;background-color: #000;border-radius: 4px;}
.ui-widget.ui-widget-content { background: #f1f9ff; color: #505050;  border: 1px solid #cacaca; box-shadow: none; position: relative; padding: 13px 10px; }
.ui-widget.ui-widget-content:before { content: ''; border: 11px solid transparent; border-bottom-color: #cacaca; position: absolute; top: -22px; left: -1px; }
.ui-widget.ui-widget-content:after { content: ''; border: 10px solid transparent; border-bottom-color: #f1f9ff; position: absolute; top: -19px; left: 0px; }
.ui-tooltip-content {  line-height: 18px; }*/

.mainFormBox label {
    float: left;
    padding: 8px 0px 2px 0px;
    width: 34%;
    color: #505050;
}

.mainFormBox .selectWidth {
    width: 57%;
    position: relative;
    z-index: 2;
    float: left;
}

.mainFormBox .selectWidth .form-control {
    background: transparent;
    height: 40px;
    border: 1px solid #e1e1e1;
    color: #909090;
}

.mainFormBox .editTextArea {
    resize: none
}

.mainFormBox .selectWidth .form-control[disabled] {
    color: #909090;
    cursor: not-allowed;
}

.mainFormBox .selectWidth .form-control.keyWords {}

.mainFormBox .selectArrow {
    background: transparent var(--sprite2-url) no-repeat scroll -234px -11px;
    position: absolute;
    right: 20px;
    top: 13px;
    width: 19px;
    height: 16px;
    display: inline-block;
    background-size: 500px 600px;
}

.mainFormBox .nav-tabs {
    float: left;
    border-bottom: 0;
    height: 100%;
    width: 30%;
    border: 1px solid #eaeaea;
    text-align: left;
    min-height: 358px;
}

.mainFormBox li {
    float: none;
    margin: 0;
}

.mainFormBox .nav-tabs>li.active>a, .nav-tabs>li.active>a:hover, .nav-tabs>li.active>a:focus {
    border: 0;
}

.mainFormBox .tab-content {
    margin-left: 0px;
    float: left;
    width: 70%;
    border: 1px solid #eaeaea;
    background: #fbfbfb;
    border-left: 0px;
    height: 358px;
    overflow: hidden;
}

.mainFormBox .tab-pane {
    display: none;
    background-color: #fbfbfb;
    padding: 1.6rem;
    overflow-y: auto;
}

.mainFormBox .active {
    display: block;
    padding: 0px;
}

.mainFormBox .StdTabsMain {
    overflow: hidden;
    height: 358px;
}

/*learningActBox-step2*/

.learningActBoxStep2 {
    background: #fff;
    padding: 20px 20px 10px 20px;
    border: 1px solid #c6c6c7;
    margin: 25px 10px 0px 10px;
    display: none;
}

.learningActBoxStep2 h3 {
    color: #1c1c1c;
    padding: 25px 0px;
    border-bottom: 1px solid #d3d3d3;
    margin: 0px 20px 30px 20px;
}

/*.learningActBoxStep2 .fieldBg { }*/

.learningActBoxStep2 .form-group {
    margin-top: 10px;
    width: 95%;
    float: left;
}

.learningActBoxStep2 label {
    float: left;
    padding: 14px 0px 2px 0px;
    width: 34%;
    color: #505050;
}

.learningActBoxStep2 .selectWidth {
    width: 57%;
    position: relative;
    z-index: 2;
    float: left;
}

.learningActBoxStep2 .selectWidth .form-control {
    background: transparent;
    height: 48px;
    border: 1px solid #e1e1e1;
    background: #fbfbfb;
    color: #909090;
}

.learningActBoxStep2 .selectWidth .form-control[disabled] {
    color: #909090;
    cursor: not-allowed;
}

/* Optional scoped theme for tag-it which mimics the zendesk widget. */

ul.tagit {
    border-style: solid;
    border-width: 1px;
    border-color: #e1e1e1;
    background: inherit;
    height: 48px;
}

ul.tagit li.tagit-choice {
    -moz-border-radius: 6px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    border: 1px solid #CAD8F3;
    background: none;
    background-color: #DEE7F8;
}

ul.tagit li.tagit-choice .tagit-label:not(a) {
    color: #555;
}

ul.tagit li.tagit-choice a.tagit-close {
    text-decoration: none;
}

ul.tagit li.tagit-choice .tagit-close {
    right: .4em;
}

ul.tagit li.tagit-choice .ui-icon {
    display: none;
}

ul.tagit li.tagit-choice .tagit-close .text-icon {
    display: inline;
    font-family: arial, sans-serif;
    line-height: 16px;
    color: #777;
}

ul.tagit li.tagit-choice:hover, ul.tagit li.tagit-choice.remove {
    background-color: #bbcef1;
    border-color: #6d95e0;
}

ul.tagit li.tagit-choice a.tagLabel:hover, ul.tagit li.tagit-choice a.tagit-close .text-icon:hover {
    color: #222;
}

ul.tagit input[type="text"] {
    background: none;
    color: #909090;
}

.ui-widget {}

/* Forked from a jQuery UI theme, so that we don't require the jQuery UI CSS as a dependency. */

.tagit-autocomplete.ui-autocomplete {
    position: absolute;
    cursor: default;
}

* html .tagit-autocomplete.ui-autocomplete {
    width: 1px;
}

/* without this, the menu expands to 100% in IE6 */

.tagit-autocomplete.ui-menu {
    list-style: none;
    padding: 2px;
    margin: 0;
    display: block;
    float: left;
}

.tagit-autocomplete.ui-menu .ui-menu {
    margin-top: -3px;
}

.tagit-autocomplete.ui-menu .ui-menu-item {
    margin: 0;
    padding: 10px 0;
    zoom: 1;
    float: left;
    clear: left;
    width: 100%;
}

.tagit-autocomplete.ui-menu .ui-menu-item a {
    text-decoration: none;
    display: block;
    padding: .2em .4em;
    line-height: 1.5;
    zoom: 1;
}

.tagit-autocomplete .ui-menu .ui-menu-item a.ui-state-hover, .tagit-autocomplete .ui-menu .ui-menu-item a.ui-state-active {
    margin: -1px;
}

.tagit-autocomplete.ui-widget-content {
    border: 1px solid #aaaaaa;
    background: #ffffff 50% 50% repeat-x;
    color: #222222;
}

.tagit-autocomplete.ui-corner-all, .tagit-autocomplete .ui-corner-all {
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    -khtml-border-radius: 4px;
    border-radius: 4px;
}

.tagit-autocomplete .ui-state-hover, .tagit-autocomplete .ui-state-focus {
    border: 1px solid #999999;
    background: #dadada;
    color: #212121;
}

.tagit-autocomplete .ui-state-active {
    border: 1px solid #aaaaaa;
}

.tagit-autocomplete .ui-widget-content {
    border: 1px solid #aaaaaa;
}

.tagit .ui-helper-hidden-accessible {
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
}

ul.tagit {
    /* padding: 1px 5px; */
    overflow: auto;
    /* margin-left: inherit; */
    /* usually we don't want the regular ul margins. */
    /* margin-right: inherit; */
}

ul.tagit li {
    display: block;
    float: left;
    margin: 2px 5px 2px 0;
}

ul.tagit li.tagit-choice {
    position: relative;
    line-height: inherit;
}

input.tagit-hidden-field {
    display: none;
}

ul.tagit li.tagit-choice-read-only {
    padding: .2em .5em .2em .5em;
}

ul.tagit li.tagit-choice-editable {
    padding: .2em 18px .2em .5em;
}

ul.tagit li.tagit-new {
    padding: 0;
    margin: 0px;
    height: 46px;
}

ul.tagit li.tagit-choice a.tagit-label {
    cursor: pointer;
    text-decoration: none;
}

ul.tagit li.tagit-choice .tagit-close {
    cursor: pointer;
    position: absolute;
    right: .1em;
    top: 50%;
    margin-top: -8px;
    line-height: 17px;
}

/* used for some custom themes that don't need image icons */

ul.tagit li.tagit-choice input {
    display: block;
    float: left;
    margin: 2px 5px 2px 0;
}

ul.tagit input[type="text"] {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    margin: 0;
    padding: 0;
    width: inherit;
    background-color: inherit;
    outline: none;
    height: 100%;
}

.myform {
    padding: 20px 0px;
}

.myform div.line {
    clear: both;
    min-height: 50px;
    margin-bottom: 15px;
}

.myform label {
    display: block;
    margin-bottom: 5px;
}

#events_container {}

.left {
    float: left;
}

.right {
    float: right;
}

.clear {
    clear: both;
}

.multiselect {
    width: 460px;
    height: 200px;
}

#switcher {
    margin-top: 20px;
}

#header p, #header h1, form {
    margin: 0;
    padding: 0;
}

#header h1 {
    margin-bottom: .2em;
}

.weak, .weak a, .weak a:visited {
    color: gray;
}

#tag-icon {
    float: left;
    margin-right: 1.4em;
    position: relative;
    top: .1em;
}

#title-jquery, #title-tag-it {
    display: block;
}

#title-tag-it {}

#title-jquery {
    position: relative;
    left: .2em;
    top: .1em;
}

#feature-list {
    margin-top: 2.5em;
}

#disqus-container {
    margin-top: 2em;
}

#disqus-container h3 {
    font-family: inherit;
}

.keyWords {
    height: 48px;
    border: 1px solid #e1e1e1;
    color: #909090;
}

.appendDiv {}

.appendDiv span.keywordTag {
    display: inline-block;
    padding: 7px 12px;
    border: 1px solid #d7d7d7;
    border-radius: 15px;
    color: #0f6fb4;
    margin-top: 10px;
    margin-right: 10px;
}

.appendDiv span.keywordTag span.text-cross-icon {
    cursor: pointer;
    padding: 0px 3px;
}

#keywordsDrop {
    cursor: default;
}

/*.mainFormBox .bgFieldColor { background: #fbfbfb; }*/

.next-step {
    background: #44a9f2;
    color: #fff;
    border: none;
    padding: 15px 0px;
    width: 148px;
    border-radius: 4px;
    border: 1px solid;
    margin-bottom: 40px;
}

.next-step:hover, .next-step:focus {
    background: #fff;
    color: #44a9f2;
    border: 1px solid
}

.learningActBoxStep2 .sepText {
    color: #909090;
    margin: 19px 0px 0px 0px;
    text-align: center;
}

.learningActBoxStep2 .uploadifive-button {
    height: 40px !important;
    padding: 4px 0px;
    color: #0f6fb4;
    border: 1px solid;
    background: #fff;
    border-radius: 3px;
    text-transform: inherit;
    width: 35% !important;
    float: right;
}

.learningActBoxStep2 .uploadifive-button:hover {
    background-color: #0f6fb4;
    color: #fff;
}

.learningActBoxStep2 .btnExisting {
    height: 40px;
    color: #0f6fb4;
    border: 1px solid;
    background: #fff;
    border-radius: 3px;
    width: 62%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.learningActBoxStep2 .btnExisting:hover {
    background-color: #0f6fb4;
    color: #fff;
}

.uploadifive-queue {
    display: block;
    float: left;
    width: 100%;
    width: 100%;
    float: left;
}

/*addQuestionBlock*/

.addQuestionBlock {
    float: left;
    width: 100%;
    display: none;
}

.addQuestionBlock .filterBlock {
    padding-left: 5px;
    width: 14%;
}

.addQuestionBlock .filterBlock .searchBtn {
    background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll -164px 1px;
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -168px -2px;
    display: inline-block;
    height: 27px;
    left: 5px;
    position: relative;
    top: 0px;
    width: 39px;
    background-size: 500px 600px;
}

.addQuestionBlock .filterBlock .searchBtn:focus {
    outline: none;
    border: none;
}

.addQuestionBlock .filterBlock .btn.btn-primary:focus {
    outline: none;
}

.addQuestionBlock .filterBlock .btn.btn-primary {
    padding: 0px;
    overflow: hidden;
    margin-bottom: 25px;
    margin-right: 13px;
}

.addQuestionBlock .filterBlock .btn.btn-primary:hover {
    background: #0f6fb4;
}

.addQuestionBlock .selectQuestionBox {
    width: 43%;
    float: left;
    height: calc(100% - 167px);
    background: #fff;
    margin-left: 14%;
    border: 1px solid #e0e0e0;
    border-top: none;
    border-left: none;
    position: fixed;
}

.addQuestionBlock .filterChecks {
    padding-top: 60px
}

.addQuestionBlock .filterBlock .filterSearchLeft {
    position: fixed;
    left: 0.4%;
    background: #f7f8f9;
    padding-top: 21px;
    width: 13%;
    margin-top: -21px;
    padding-left: 10px;
}

.addQuestionBlock .selectQuestionBox h2 {
    color: #1c1c1c;
    padding: 15px;
    padding-left: 65px;
    position: relative;
}

.addQuestionBlock .selectQuestionBox h2:before {
    content: '';
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -454px -222px;
    display: inline-block;
    height: 40px;
    width: 40px;
    background-size: 500px 600px;
    position: absolute;
    left: 19px;
    top: 3px;
    -ms-transform: scale(0.7, 0.7);
    -webkit-transform: scale(0.7, 0.7);
    transform: scale(0.7, 0.7);
}

.addQuestionBlock .questionSelectedBox ul.quesBcrumb {
    padding: 10px;
    border-bottom: 1px solid #e0e0e0;
}

.addQuestionBlock .selectQuestionBox ul.quesBcrumb {
    padding: 10px;
    border-bottom: 1px solid #e0e0e0;
}

.addQuestionBlock .selectQuestionBox ul.quesBcrumb li {
    color: #0f6fb4;
    display: inline-block;
}

.addQuestionBlock .selectQuestionBox ul.quesBcrumb li:after {
    content: ' | ';
    color: #909090;
    padding: 0px 10px;
}

.addQuestionBlock .selectQuestionBox ul.quesBcrumb li:last-child {
    color: #909090;
}

.addQuestionBlock .selectQuestionBox ul.quesBcrumb li:last-child:after {
    content: '';
}

.addQuestionBlock .selectQuestionBox .boxForQuestionSelect {
    margin-top: -10px;
    float: left;
    width: 100%;
    padding: 0px 20px 120px 20px;
    overflow: auto;
    height: calc(100% - 144px);
    position: absolute;
}

.addQuestionBlock .selectQuestionBox .boxForQuestionSelect p {
    width: calc(100% - 50px);
    float: left;
    line-height: 19px;
}

.addQuestionBlock .boxForQuestionSelect p.quesText span.viewFullPara {
    position: absolute;
    right: 0px;
    bottom: -3px;
    color: #0f6fb4;
    cursor: pointer;
    padding-left: 15px;
    background: #fff;
}

.addQuestionBlock .quesText input[type=radio].css-checkbox {
    position: absolute;
    z-index: -1000;
    left: -1000px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

.addQuestionBlock .quesText input[type=radio].css-checkbox+label.css-label {
    padding-left: 32px;
    min-height: 35px;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -470px -92px;
    vertical-align: middle;
    cursor: pointer;
    background-size: 500px 600px;
}

.addQuestionBlock .quesText input[type=radio].css-checkbox:checked+label.css-label {
    background-position: -432px -126px;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.addQuestionBlock .selectQuestionBox .boxForQuestionSelect .quesText {
    position: relative;
    font-weight: 600;
}

.addQuestionBlock .selectQuestionBox .boxForQuestionSelect .quesText.right {
    position: relative;
    font-weight: 600;
    margin-left: 6px;
    left: -15px;
    padding-left: 10px;
}

.addQuestionBlock .selectQuestionBox .boxForQuestionSelect .quesText.left {
    position: relative;
    font-weight: 600;
    margin-left: -10px;
}

.addQuestionBlock .selectQuestionBox .boxForQuestionSelect .quesText h5 {
    font-size: 15px !important;
}

.addQuestionBlock .selectQuestionBox .boxForQuestionSelect .quesText font {
    font-size: 15px !important;
}

.addQuestionBlock .boxForQuestionSelect .quesText.seeFullQues {
    max-height: 100%;
    height: auto;
    display: flex;
}

.addQuestionBlock .selectQuestionBox .boxForQuestionSelect ul.keyCourse {
    padding-left: 30px;
    float: left;
    clear: left;
    font-weight: 300;
    color: #007f00;
    margin-top: -30px;
}

.addQuestionBlock .selectQuestionBox .QuestionChoiceBox {
    float: left;
    padding-top: 10px;
    border-bottom: 1px solid #bababa;
}

.addQuestionBlock .selectQuestionBox .QuestionChoiceBox:last-child {
    border-bottom: none;
}

/*.addQuestionBlock .selectQuestionBox .QuestionChoiceBox:hover { background-color: #f1f9ff; cursor: pointer; }*/

/*.addQuestionBlock .selectQuestionBox .QuestionChoiceBox .dropBoxDiv { position: absolute; width: 350px; left:100px; text-align: left; background: #fff; color: black; box-shadow: 0px 0px 10px #ccc; display: none; z-index: 222; padding: 5px; border-radius: 5px;}*/

/*.addQuestionBlock .selectQuestionBox .QuestionChoiceBox .dropBoxDiv { position: absolute; width: 250px; right: 30px; text-align: left; background: #f7f7f8; color: black; box-shadow: 0px 0px 10px #ccc; display: none; z-index: 222; padding: 5px; border-radius: 5px;}*/

/*.addQuestionBlock .selectQuestionBox .QuestionChoiceBox .dropBoxDiv .fieldBox {border-bottom: 1px solid #cccccc; padding: 3px}
.addQuestionBlock .selectQuestionBox .QuestionChoiceBox .dropBoxDiv .answer {background-color: #f4faf4}
.addQuestionBlock .selectQuestionBox .QuestionChoiceBox .dropBoxDiv .fieldBox:last-child {border-bottom: none;}
.addQuestionBlock .selectQuestionBox .QuestionChoiceBox .choices a:hover .dropBoxDiv { display: block;}
.addQuestionBlock .selectQuestionBox .QuestionChoiceBox .dropBoxDiv input[type=radio].css-checkbox + label.css-label { padding-left: 32px; height: 35px; display: inline-block; line-height: 21px; background-repeat: no-repeat; background-position: -470px -92px; vertical-align: middle; cursor: pointer;  background-size: 500px 600px; }*/

.addQuestionBlock .selectQuestionBox .QuestionChoiceBox .choices {
    background: var(--sprite2-url) repeat -320px -61px;
    display: inline-block;
    height: 45px;
    width: 45px;
    background-size: 500px 600px;
    border: 1px solid #337ab7;
    position: relative;
    right: -38px;
    margin-bottom: -15px;
    border-radius: 39px;
    -ms-transform: scale(0.7, 0.7);
    -webkit-transform: scale(0.7, 0.7);
    transform: scale(0.7, 0.7);
    top: -20px;
    cursor: pointer;
}

.addQuestionBlock .selectQuestionBox .boxForQuestionSelect ul.keyCourse li {
    display: inline-block;
}

.addQuestionBlock .selectQuestionBox .boxForQuestionSelect ul.keyCourse li:after {
    content: ' | ';
    padding: 0px 10px;
    color: #1c1c1c;
}

.addQuestionBlock .selectQuestionBox .boxForQuestionSelect ul.keyCourse li:last-child:after {
    content: '';
}

.addQuestionBlock .selectQuestionBox .boxForQuestionSelect ul.keyCourse ul {
    color: black;
    font-size: 13px;
}

#QuestionPreviewModal input[type=radio].css-checkbox {
    position: absolute;
    z-index: -1000;
    left: -1000px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

#QuestionPreviewModal input[type=radio].css-checkbox+label.css-label {
    padding-left: 32px;
    min-height: 24px;
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
    background-position: -470px -92px;
    vertical-align: middle;
    cursor: pointer;
    background-size: 500px 600px;
}

#QuestionPreviewModal input[type=radio].css-checkbox:checked+label.css-label {
    background-position: -432px -126px;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#QuestionPreviewModal .modal-body {
    padding-top: 0px;
}

#QuestionPreviewModal .modal-dialog {
    width: 768px;
    margin: 150px auto !important;
}

#QuestionPreviewModal table {
    margin-top: 29px;
}

#QuestionPreviewModal table tr td {
    vertical-align: top;
}

#QuestionPreviewModal table tr td:first-child {
    width: 30%
}

#questionPreviewBox {
    background: #fff;
    padding: 20px 20px 10px 20px;
    border: 1px solid #c6c6c7;
    margin: 10px;
}

#questionPreviewBox label {
    font-size: 14px !important
}

#questionPreviewBox img {
    width: auto;
    max-width: 100%;
}

#questionPreviewBox table tr td:first-child {
    width: 30%
}

#questionPreviewBox .FlashCardChoiceBox {
    border-bottom: 1px solid #bababa;
}

#questionPreviewBox .FlashCardChoiceBox:last-child {
    border-bottom: none;
}

.boxForQuestionSelect input[type=checkbox].css-checkbox {
    position: absolute;
    z-index: -1000;
    left: -1000px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: auto;
    width: 100%;
    margin: -1px;
    padding: 0;
    border: 0;
}

.boxForQuestionSelect input[type=checkbox].css-checkbox+label.css-label1 {
    padding-left: 30px;
    height: 24px;
    width: 25px;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -470px -3px;
    vertical-align: middle;
    cursor: pointer;
    line-height: 22px;
    color: #555555;
    background-size: 500px 600px;
    float: left;
}

.boxForQuestionSelect label.css-label1 {
    background-image: var(--sprite2-url);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.boxForQuestionSelect input[type=checkbox].css-checkbox:checked+label.css-label1 {
    padding-left: 30px;
    height: 24px;
    width: 25px;
    display: inline-block;
    background-repeat: no-repeat;
    vertical-align: middle;
    cursor: pointer;
    line-height: 22px;
    color: #555555;
    background-size: 500px 600px;
    float: left;
    background-position: -429px -37px;
}

.lastBlockEmptyLeft {
    height: 50px;
    width: 100%;
    border: 1px solid #e0e0e0;
    position: absolute;
    left: 0;
    bottom: 0px;
    background: #fff;
    border-right: 0px;
    border-left: 0px;
}

.addQuestionBlock .questionSelectedBox {
    width: 43%;
    float: left;
    height: calc(100% - 167px);
    background: #fff;
    margin-left: 57%;
    border: 1px solid #e0e0e0;
    border-top: none;
    border-left: none;
    position: fixed;
}

.addQuestionBlock .questionSelectedBox h2 {
    color: #1c1c1c;
    padding: 15px;
    padding-left: 65px;
    border-bottom: 1px solid #e0e0e0;
}

.addQuestionBlock .questionSelectedBox h2:before {
    content: '';
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -454px -222px;
    display: inline-block;
    height: 40px;
    width: 40px;
    background-size: 500px 600px;
    position: absolute;
    left: 19px;
    top: 3px;
    -ms-transform: scale(0.7, 0.7);
    -webkit-transform: scale(0.7, 0.7);
    transform: scale(0.7, 0.7);
}

.addQuestionBlock .questionSelectedBox h3 {
    display: inline-block;
    color: #1c1c1c;
    padding-left: 30px;
    margin-top: 7px;
}

.addQuestionBlock .questionSelectedBox .inputNumber {
    width: 60px;
    display: inline-block;
    float: right;
    margin-right: 35px;
    background: #fbfbfb;
    text-align: center;
}

.addQuestionBlock .questionSelectedBox .headindAndClearPrev {
    padding: 14px 0px 10px 0px;
    height: 67px;
    box-sizing: border-box;
    border-bottom: 1px solid #e0e0e0;
}

.addQuestionBlock .questionSelectedBox .headindAndClearPrev .clearAllBtn {
    background: none;
    border: none;
    color: #0f6fb4;
    padding: 9px 5px;
    float: right;
    margin-right: 15px;
    margin-top: 5px;
}

.addQuestionBlock .questionSelectedBox .headindAndClearPrev .BtnPrev {
    border: 1px solid #42a3e9;
    background: #fff;
    color: #0f6fb4;
    padding: 11px 20px;
    float: right;
    margin-right: 35px;
    border-radius: 4px;
}

.addQuestionBlock .questionSelectedBox .boxForQuestionSelect {
    float: left;
    width: calc(100% - 15px);
    padding: 0px 20px 20px 20px;
    overflow: auto;
    height: calc(100% - 144px);
    position: absolute;
}

.addQuestionBlock .questionSelectedBox .boxForQuestionSelect p {
    width: calc(100% - 44px);
    float: left;
    line-height: 19px;
}

.addQuestionBlock .questionSelectedBox .boxForQuestionSelect .quesText {
    position: relative;
    font-weight: 600;
}

.addQuestionBlock .questionSelectedBox .boxForQuestionSelect .quesText.left {
    position: relative;
    font-weight: 600;
    left: -8px;
    padding-right: 9px;
}

.addQuestionBlock .questionSelectedBox .boxForQuestionSelect .quesText.right {
    position: relative;
    font-weight: 600;
    left: -10px;
    padding-left: 10px;
}

.addQuestionBlock .questionSelectedBox .boxForQuestionSelect .quesText.seeFullQues {
    max-height: 100%;
    height: auto;
    display: flex;
}

.addQuestionBlock .questionSelectedBox .boxForQuestionSelect ul.keyCourse {
    padding-left: 30px;
    float: left;
    clear: left;
    font-weight: 300;
    color: #007f00;
    margin-top: -30px
}

.addQuestionBlock .questionSelectedBox .QuestionChoiceBox {
    float: left;
    padding-top: 10px;
    border-bottom: 1px solid #bababa;
}

.addQuestionBlock .questionSelectedBox .QuestionChoiceBox:last-child {
    border-bottom: none;
}

/*.addQuestionBlock .questionSelectedBox .QuestionChoiceBox:hover { background-color: #f1f9ff; cursor: pointer; }*/

/*.addQuestionBlock .questionSelectedBox .QuestionChoiceBox .dropBoxDiv { position: absolute; min-width: 150px; left: 300px; background: #fff; box-shadow: 0px 0px 10px #ccc; display: none; z-index: 222; padding: 5px; border-radius: 5px; margin-top: 40px}*/

/*.addQuestionBlock .questionSelectedBox .QuestionChoiceBox .dropBoxDiv input[type=radio].css-checkbox + label.css-label { padding-left: 32px; height: 35px; display: inline-block; line-height: 21px; background-repeat: no-repeat; background-position: -470px -92px; vertical-align: middle; cursor: pointer;  background-size: 500px 600px; }*/

.addQuestionBlock .questionSelectedBox .QuestionChoiceBox .choices {
    background: var(--sprite2-url) repeat -320px -61px;
    display: inline-block;
    height: 45px;
    width: 45px;
    background-size: 500px 600px;
    border: 1px solid #337ab7;
    position: relative;
    right: -38px;
    margin-bottom: -15px;
    border-radius: 39px;
    -ms-transform: scale(0.7, 0.7);
    -webkit-transform: scale(0.7, 0.7);
    transform: scale(0.7, 0.7);
    top: -20px;
    cursor: pointer;
}

.addQuestionBlock .questionSelectedBox .boxForQuestionSelect ul.keyCourse li {
    display: inline-block;
}

.addQuestionBlock .questionSelectedBox .boxForQuestionSelect ul.keyCourse li:after {
    content: ' | ';
    padding: 0px 10px;
    color: #1c1c1c;
}

.addQuestionBlock .questionSelectedBox .boxForQuestionSelect ul.keyCourse li:last-child:after {
    content: '';
}

.addQuestionBlock .questionSelectedBox .boxForQuestionSelect ul.keyCourse ul {
    color: black;
    font-size: 13px
}

.addQuestionBlock .questionSelectedBox .btn-saveExitBtn {
    background: #44a9f2;
    padding: 6px 10px;
    display: inline-block;
    color: #fff;
    border-radius: 4px;
    margin-right: 10px;
    margin-top: 5px;
}

.addQuestionBlock .questionSelectedBox .btn-cancel {
    color: #505050;
    background-color: #fff;
    border-color: #ccc;
    padding: 6px 10px;
    display: inline-block;
    border-radius: 4px;
    margin-right: 10px;
    margin-top: 5px;
}

.addQuestionBlock .questionSelectedBox .btn-saveAssign {
    background: #44a9f2;
    padding: 6px 10px;
    display: inline-block;
    color: #fff;
    border-radius: 4px;
    margin-right: 10px;
    margin-top: 5px;
}

/*preview Modal*/

#PreviewModal h3 {
    margin: 25px 0px 5px 0px;
}

#PreviewModal span.keywordTag {
    display: inline-block;
    padding: 7px 12px;
    border: 1px solid #d7d7d7;
    border-radius: 15px;
    color: #0f6fb4;
    margin-top: 10px;
    margin-right: 10px;
}

#PreviewModal span.keywordTag span.text-cross-icon {
    cursor: pointer;
    padding: 0px 3px;
}

#PreviewModal .quesText {
    float: left;
}

#PreviewModal .QuestionChoiceBox {
    padding-top: 5px;
    border-bottom: 1px solid #ccc
}

#PreviewModal ul.keyCourse {
    float: left;
    margin-top: 15px;
}

#PreviewModal ul.keyCourse li {
    display: inline-block;
}

#PreviewModal ul.keyCourse li:after {
    content: ' | ';
    padding: 0px 10px;
    color: #1c1c1c;
}

#PreviewModal ul.keyCourse li:last-child:after {
    content: '';
}

#PreviewModal .modal-dialog {
    width: 768px;
    margin: 150px auto !important;
}

#PreviewModal .fieldBox {
    float: left;
    padding-bottom: 4px;
    margin-top: 4px;
    width: 100%;
    padding-left: 20px
}

#PreviewModal .fieldBox:last-child {
    border-bottom: 1px solid #fff;
}

.addQuestionBlock .questionSelectedBox .countResFix a {
    display: none;
}

/*courseAndStudent */

.courseAndStudent {
    background: #fff;
    padding: 20px 20px 10px 20px;
    border: 1px solid #c6c6c7;
    /*margin: 20px 10px 20px 10px;*/
}

.courseAndStudent h3 {
    color: #1c1c1c;
    padding: 25px 0px;
    border-bottom: 1px solid #d3d3d3;
    margin: 0px 20px 30px 20px;
}

.courseAndStudent .fieldBg {
    background: #fbfbfb;
}

.courseAndStudent .form-group {
    margin-top: 10px;
    width: 95%;
    float: left;
}

.courseAndStudent .form-group a.Activity-toolTip {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -467px -185px;
    display: inline-block;
    height: 25px;
    width: 4%;
    margin-top: 13px;
    background-size: 500px 600px;
    margin-left: 2%;
}

a.Activity-toolTip {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -467px -185px;
    display: inline-block;
    height: 25px;
    width: 4%;
    margin-top: -29px;
    background-size: 500px 600px;
    margin-left: 2%;
}

.courseAndStudent .ui-widget.ui-widget-content {
    background: #f1f9ff;
    color: #505050;
    border: 1px solid #cacaca;
    box-shadow: none;
    position: relative;
    padding: 13px 10px;
}

.courseAndStudent .ui-widget.ui-widget-content:before {
    content: '';
    border: 11px solid transparent;
    border-bottom-color: #cacaca;
    position: absolute;
    top: -22px;
    left: -1px;
}

.courseAndStudent .ui-widget.ui-widget-content:after {
    content: '';
    border: 10px solid transparent;
    border-bottom-color: #f1f9ff;
    position: absolute;
    top: -19px;
    left: 0px;
}

.courseAndStudent .ui-tooltip-content {
    line-height: 18px;
}

.courseAndStudent label {
    float: left;
    padding: 14px 0px 2px 0px;
    width: 36%;
    color: #505050;
}

.container-fluid .courseAndStudent .selectWidth {
    position: relative;
    z-index: 2;
    float: left;
}

.courseAndStudent .selectWidth.bgFieldColor {
    background: #fbfbfb;
}

.courseAndStudent .selectWidth .form-control {
    background: transparent;
    height: 40px;
    border: 1px solid #e1e1e1;
    color: #909090;
}

.courseAndStudent .selectWidth .form-control[disabled] {
    color: #909090;
    cursor: not-allowed;
}

.courseAndStudent .selectArrow {
    background: transparent var(--sprite2-url) no-repeat scroll -234px -11px;
    position: absolute;
    right: 20px;
    top: 13px;
    width: 19px;
    height: 16px;
    display: inline-block;
    background-size: 500px 600px;
    z-index: -1;
}

.courseAndStudent .list-group {
    width: 100%;
}

.courseAndStudent .list-group-item {
    height: 50px;
}

.courseAndStudent .tabs-left ul li {
    width: 100%;
    border-bottom: 1px solid #eaeaea;
}

.courseAndStudent .tabs-left ul li a {
    margin: 0px;
    padding: 8px;
    color: #0f6fb4;
    border-radius: 0px;
    margin-bottom: -1px;
}

.courseAndStudent .tabs-left ul li.active a, .courseAndStudent .tabs-left ul li a:hover {
    background: #0f6fb4;
    color: #fff;
}

.courseAndStudent .tabs-left ul li a span {
    float: right;
    margin-top: 2px;
    font-family: cursive;
    cursor: pointer;
}

.courseAndStudent .tabs-left ul li a span.removeTabBtn {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -289px -76px;
    display: inline-block;
    height: 15px;
    width: 15px;
    background-size: 500px 600px;
}

.courseAndStudent .boxForselectStd {
    height: 200px;
    float: left;
    overflow: auto;
    width: 100%;
    padding-left: 15px;
}

.courseAndStudent .boxForselectStd .fieldBox {
    padding: 0px 0px 35px 0px;
}

.courseAndStudent input[type=checkbox].css-checkbox {
    position: absolute;
    z-index: -1000;
    left: -1000px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: auto;
    width: 100%;
    margin: -1px;
    padding: 0;
    border: 0;
}

.courseAndStudent input[type=checkbox].css-checkbox+label.css-label1 {
    padding-left: 30px;
    height: 24px;
    width: 100%;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -470px -3px;
    vertical-align: middle;
    cursor: pointer;
    line-height: 22px;
    color: #555555;
    background-size: 500px 600px;
    padding-top: 0px;
}

.courseAndStudent input[type=checkbox].css-checkbox:checked+label.css-label1 {
    background-position: -429px -37px;
}

.courseAndStudent label.css-label1 {
    background-image: var(--sprite2-url);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.courseAndStudent .srchForStd {
    margin-top: 16px;
    height: auto;
    padding: 0px 10px 17px 10px;
    border-bottom: 1px solid #eaeaea;
    margin-bottom: 15px;
}

.courseAndStudent .srchForStd .form-control {
    height: 34px;
    background: #fff;
}

.courseAndStudent .srchForStd .searchBtn {
    background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll -164px 1px;
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -168px -2px;
    display: inline-block;
    height: 27px;
    left: 5px;
    position: relative;
    top: 0px;
    width: 39px;
    background-size: 500px 600px;
}

.courseAndStudent .srchForStd .searchBtn:focus {
    outline: none;
    border: none;
}

.courseAndStudent .srchForStd .btn.btn-primary:focus {
    outline: none;
}

.courseAndStudent .srchForStd .btn.btn-primary {
    padding: 0px;
    overflow: hidden;
}

.courseAndStudent .srchForStd .btn.btn-primary:hover {
    background: #0f6fb4;
}

.courseAndStudent .boxForDueDate .labelTextLeft {
    float: left;
    width: 40%;
    padding-left: 16px;
    margin-top: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.courseAndStudent .boxForDueDate .calenderField {
    float: left;
    width: 60%;
    text-align: right;
}

.courseAndStudent .boxForDueDate .calenderField .form-control {
    height: 35px;
    margin: 10px auto;
    width: 80%;
}

.courseAndStudent .boxForDueDate {
    border-top: 1px solid #eaeaea;
    float: left;
    width: 100%;
    margin-top: 17px;
    background: #fff;
}

.filterBlock .seeMoreFilter {
    color: #555555;
    margin-top: 15px;
    cursor: pointer;
}

.fixedHeight {
    color: red;
    max-height: 150px;
    margin-bottom: 10px;
    overflow-x: hidden;
    overflow-y: auto;
}

.footer {
    z-index: 200;
    left: 0px;
}

.limitstdName {
    white-space: nowrap;
    width: 76%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media screen\0 {
    select::-ms-expand {
        display: none;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
    .addQuestionBlock .selectQuestionBox {
        width: 50%;
        left: 0px;
        margin: 0px;
    }
    .addQuestionBlock .questionSelectedBox {
        width: 50%;
        right: 0px;
        margin: 0px;
    }
    .addQuestionBlock .questionSelectedBox .boxForQuestionSelect {
        height: calc(100% - 227px);
        width: 100%;
    }
    .addQuestionBlock .filterBlock {
        padding-left: 5px;
        width: 100%;
        left: 0;
    }
    .addQuestionBlock .filterBlock .filterSearchLeft {
        display: none;
    }
    .addQuestionBlock .filterChecks {
        padding-left: 20px;
        padding-top: 0px
    }
    .filterBlock .unSelAll {
        float: left;
        padding-left: 15px;
    }
    .addQuestionBlock .selectQuestionBox, .addQuestionBlock .questionSelectedBox {
        height: calc(100% - 270px);
    }
}

@media only screen and (max-width: 1024px) {
    .headingMainField {
        margin-bottom: 24px;
        width: 100% !important;
        float: left;
    }
    .listViewBlock table.table-hover tbody tr .contentType {
        display: inline-block;
        position: relative;
        top: -5px;
        width: calc(89% - 60px);
        max-width: 233px !important;
        white-space: nowrap;
    }
    .listViewBlock table.table-hover tbody tr .contentTypeQues {
        display: inline-block;
        position: relative;
        top: -5px;
        width: 88%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        max-height: inherit;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        position: relative;
        padding-right: 15px;
    }
    .BcrumNsteps .breadCrumbs ul li em {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
    }
    .BcrumNsteps .breadCrumbs ul li {
        display: inline-block;
    }
    .addQuestionBlock .selectQuestionBox {
        width: 100%;
        margin: 0px;
        left: 0px;
        position: fixed;
    }
    .addQuestionBlock .questionSelectedBox {
        position: fixed;
        top: 1500px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        width: 100%;
        height: 100%;
        margin: 0px;
        padding-right: 12px;
    }
    .addQuestionBlock .questionSelectedBox.showSelQuesTab {
        top: 224px;
    }
    .questionSelectedBox .lastBlockEmptyLeft {
        position: fixed;
        bottom: 48px;
        left: 0px;
        width: 98%;
    }
    .addQuestionBlock .questionSelectedBox .countResFix {
        position: fixed;
        bottom: 60px;
        z-index: 9;
        left: 20px;
    }
    .addQuestionBlock .questionSelectedBox .countResFix a {
        display: inline-block;
        float: right;
        cursor: pointer;
    }
    .addQuestionBlock .questionSelectedBox .inputNumber {
        margin-right: 5px;
    }
    .ViewFilter-sm {
        width: 100%;
        height: 50px;
        background: #f7f7f8;
        border: 1px solid #e0e0e0;
        border-left: none;
        color: #0f6fb4;
        border-top: none;
        border-right: none;
    }
    .ViewFilter-sm span {
        position: relative
    }
    .ViewFilter-sm span:before {
        content: '';
        background: transparent var(--sprite2-url) no-repeat scroll -324px 0px;
        position: absolute;
        left: -30px;
        top: -10px;
        width: 29px;
        height: 33px;
        display: inline-block;
        background-size: 500px 600px;
        -ms-transform: scale(0.7, 0.7);
        -webkit-transform: scale(0.7, 0.7);
        transform: scale(0.7, 0.7);
    }
    .ViewFilter-sm:focus {
        background: #efefef;
        outline: none;
        border: 1px solid #e0e0e0;
    }
    .ViewSearch-sm {
        width: 100%;
        height: 50px;
        background: #f7f7f8;
        border: 1px solid #e0e0e0;
        border-right: none;
        color: #0f6fb4;
        border-top: none;
        border-right: none;
    }
    .ViewSearch-sm span {
        position: relative
    }
    .ViewSearch-sm span:before {
        content: '';
        background: var(--sprite2-url) repeat -325px -65px;
        position: absolute;
        left: -33px;
        top: -8px;
        width: 30px;
        height: 32px;
        display: inline-block;
        background-size: 500px 600px;
        -ms-transform: scale(0.7, 0.7);
        -webkit-transform: scale(0.7, 0.7);
        transform: scale(0.7, 0.7);
    }
    .ViewSearch-sm:focus {
        background: #efefef;
        outline: none;
        border: 1px solid #e0e0e0;
    }
    .smText h3 {
        text-align: center;
    }
    .smText .HideCross {
        background: rgb(255, 255, 255) var(--sprite2-url) repeat -228px -70px;
        display: inline-block;
        height: 30px;
        right: 0px;
        position: absolute;
        top: 0px;
        width: 30px;
        margin-right: 13px;
        margin-top: 15px;
        background-size: 500px 600px;
    }
    .search-sm-Box span.srcHideBox {
        background: rgba(14, 34, 48, 0.1) var(--sprite2-url) repeat -785px -65px;
        display: inline-block;
        height: 30px;
        right: 0px;
        position: absolute;
        top: 0px;
        width: 30px;
        margin-right: 20px;
        margin-top: 15px;
        background-size: 500px 600px;
    }
    .search-sm-Box {
        position: fixed;
        background: rgba(0, 0, 0, 0.8);
        width: 100%;
        height: 100%;
        z-index: 9999999999;
        top: 5000px;
        left: 0px;
        bottom: 0px;
        right: 0px;
        padding: 24% 10% 0% 10%;
        transition: all ease 0.2s;
    }
    .search-sm-Box .searchBtn {
        background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -164px -3px;
        display: inline-block;
        height: 27px;
        left: 5px;
        position: relative;
        top: 0px;
        width: 50px;
        background-size: 500px 600px;
    }
    .search-sm-Box .btn.btn-primary {
        padding: 0px;
    }
    .search-sm-Box .btn.btn-primary:hover {
        background: #0f6fb4;
    }
    .sercFilBtnBox.classTostick {
        position: fixed;
        width: 100%;
        top: 0px;
        z-index: 9999;
        background: #f7f7f8;
    }
    .filterBlock .ApplyFilter {
        display: block !important
    }
    .addQuestionBlock .questionSelectedBox .boxForQuestionSelect {
        height: calc(100% - 420px);
        width: 100%;
    }
    .addQuestionBlock .filterBlock {
        padding-left: 5px;
        width: 100%;
        left: 0;
    }
    .addQuestionBlock .filterBlock .filterSearchLeft {
        display: none;
    }
    .addQuestionBlock .filterChecks {
        padding-left: 20px;
        padding-top: 0px
    }
    .filterBlock .unSelAll {
        float: left;
        padding-left: 15px;
    }
    .learningActBoxStep2 .uploadifive-button {
        width: 44% !important;
    }
    .learningActBoxStep2 .btnExisting {
        width: 54%;
    }
    .addQuestionBlock .selectQuestionBox .boxForQuestionSelect {
        height: calc(100% - 190px);
    }
    .mainFormBox .form-group, .learningActBoxStep2 .form-group, .courseAndStudent .form-group {
        width: 100%;
        padding-left: 20px;
    }
    .overFlowScroll-sm {
        /*height: calc(100% - 130px)*/
    }
}

@media only screen and (min-width: 992px) and (max-width: 1000px) {
    .mainFormBox .selectWidth {
        width: 57%;
    }
    .mainFormBox .form-group a.Activity-toolTip {
        margin-left: 2%;
        width: 5.5%;
        max-width: 5.5%;
    }
    .courseAndStudent .selectWidth {
        width: 57%;
    }
    .courseAndStudent .form-group a.Activity-toolTip {
        margin-left: 2%;
        width: 5.5%;
        max-width: 5.5%;
    }
}

@media only screen and (max-width: 1600px) and (min-width: 1001px) {
    .courseAndStudent .selectWidth {
        max-width: 240px;
        width: 57%;
    }
    .mainFormBox .form-group a.Activity-toolTip {
        margin-left: 2%;
        width: 5.5%;
        max-width: 25px;
    }
    .courseAndStudent .form-group a.Activity-toolTip {
        margin-left: 2%;
        width: 5.5%;
        max-width: 25px;
    }
}

@media only screen and (max-width: 1420px) and (min-width: 1001px) {
    .mainFormBox .selectWidth {
        max-width: 235px;
    }
}

/*
@media only screen and (max-width: 767px) {
    body { display: none; }
}*/

@media only screen and (min-width: 992px) and (max-width: 1033px) {
    .container-fluid .courseAndStudent .selectWidth {
        max-width: 57%;
    }
}

/* Custom CSS */

.collapseBtn {
    float: right;
    margin-top: -48px;
}

.questionInfoPanel {
    border-bottom: 1px solid #c6c6c7;
    padding: 15px;
    position: absolute;
    width: 100%;
    z-index: 100;
    background: #fff;
    box-shadow: 0px 2px 1px #c6c6c7;
}

.questionInfoPanel .selectArrow {
    background: transparent var(--sprite2-url) no-repeat scroll -234px -11px;
    position: absolute;
    right: 20px;
    top: 31px;
    width: 19px;
    height: 16px;
    display: inline-block;
    background-size: 500px 600px;
}

.select-question-heading-container {
    border-bottom: 1px solid #e0e0e0;
}

.select-question-search {
    display: inline-block !important;
    padding: 7px 0px 0px 0px;
    float: right;
    margin-right: 30px;
}

.select-question-search-h2 {
    width: 260px;
    display: inline-block;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.questionSelectedBox .select-question-search-h2 {
    width: 100%;
}

.removeDisplay {
    display: none !important;
}

:lang(ar) .headblue {
    direction: rtl;
    text-align: center;
}

:lang(ar) {
    direction: rtl;
}

:lang(ar) table {
    direction: ltr;
}

:lang(en) {
    direction: ltr;
}

.centered-text .fdef {
    text-align: center !important;
}

.questionMainBlock {
    background: #fff;
    margin-bottom: 100px;
    margin-top: 20px;
    border: 1px solid #c6c6c7;
}

.questionMainBlock .quesAndIcon {
    margin-top: 15px
}

.questionMainBlock .fieldBox {
    float: left;
    width: 100%
}

.questionMainBlock .actionBlockDiv {
    margin-top: 12px;
    margin-bottom: 20px;
}

.questionMainBlock .actionBlockDiv .detailEdit {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 1px -296px;
    display: inline-block;
    height: 48px;
    left: 5px;
    position: relative;
    top: 0px;
    width: 48px;
    margin-right: 10px;
    cursor: pointer;
    background-size: 500px 600px;
}

.questionMainBlock .actionBlockDiv .detailEdit:hover {
    background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll -120px -298px;
}

.questionMainBlock .actionBlockDiv .detailPrint {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 1px -539px;
    display: inline-block;
    height: 48px;
    left: 5px;
    position: relative;
    top: 0px;
    width: 48px;
    margin-right: 10px;
    cursor: pointer;
    background-size: 500px 600px;
}

.questionMainBlock .actionBlockDiv .detailPrint:hover {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -118px -539px;
    background-size: 500px 600px;
}

.questionMainBlock .actionBlockDiv .detailDuplicate {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 1px -377px;
    display: inline-block;
    height: 48px;
    left: 5px;
    position: relative;
    top: 0px;
    width: 48px;
    margin-right: 10px;
    cursor: pointer;
    background-size: 500px 600px;
}

.questionMainBlock .actionBlockDiv .detailDuplicate:hover {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -119px -377px;
    background-size: 500px 600px;
}

.questionMainBlock .actionBlockDiv .detailDelete {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 1px -459px;
    display: inline-block;
    height: 48px;
    left: 5px;
    position: relative;
    top: 0px;
    width: 48px;
    margin-right: 10px;
    cursor: pointer;
    background-size: 500px 600px;
}

.questionMainBlock .actionBlockDiv .detailDelete:hover {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -119px -459px;
    background-size: 500px 600px;
}

.questionMainBlock h3 {
    color: #1c1c1c;
    margin: 30px 0px 20px 0px;
    font-weight: bold;
    float: left;
    width: 100%
}

.questionMainBlock p {
    color: #505050;
    line-height: 22px;
    padding: 10px 0px;
    float: left;
    width: 100%;
}

.questionMainBlock input[type=radio].css-checkbox {
    position: absolute;
    z-index: -1000;
    left: -1000px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

.questionMainBlock input[type=radio].css-checkbox+label.css-label {
    padding-left: 32px;
    min-height: 23px;
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
    background-position: -470px -92px;
    vertical-align: middle;
    cursor: pointer;
    background-size: 500px 600px;
    margin: 0px
}

.questionMainBlock input[type=radio].css-checkbox:checked+label.css-label {
    background-position: -432px -126px;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.questionMainBlock label.css-label {
    background-image: var(--sprite2-url);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.questionMainBlock table tr td {
    vertical-align: top;
}

.questionMainBlock table tr td:first-child {
    width: 30%
}

.questionMainBlock label {
    font-size: 14px !important
}

.questionMainBlock img {
    width: auto;
    max-width: 100%;
}

.questionMainBlock label img {
    width: auto;
    max-width: 100%;
}

.questionMainBlock font img {
    width: auto;
    max-width: 100%;
}

.questionMainBlock font span {
    font-size: inherit !important
}

.questionMainBlock font div {
    font-size: inherit !important
}

.questionMainBlock font p {
    font-size: inherit !important
}

/*.questionMainBlock font {display: inline-block; padding-top:5px; padding-bottom:5px;}*/

.questionMainBlock .quesAndIcon img {
    width: auto;
    max-width: 100%
}

.questionMainBlock .quesAndIcon ul {
    display: block;
    list-style-type: disc;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 20px;
}

.lastQuesRedirect {
    position: fixed;
    bottom: 50px;
    float: left;
    width: calc(100% - 30px);
    background: #f1f9ff;
    height: 60px;
    border: 1px solid #c6c6c7;
    margin-left: -1px;
}

.lastQuesRedirect .prevQues {
    margin: 19px 0px 0px 7px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.lastQuesRedirect .prevQues .caret {
    border-width: 6px;
    transform: rotate(90deg);
    margin-right: 14px;
    margin-top: -4px;
}

.lastQuesRedirect .nextQues {
    margin: 19px 7px 0px 0px;
    display: block;
    float: right;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.lastQuesRedirect .nextQues .caret {
    border-width: 6px;
    transform: rotate(-90deg);
    margin-left: 14px;
    margin-top: -1px;
}

.lastQuesRedirect ul {
    margin-top: 19px;
    color: #505050;
}

.lastQuesRedirect ul li {
    display: inline-block;
    margin: 0px 3px;
}

.lastQuesRedirect ul li.num {
    color: #0f6fb4;
}

/* Course View - Start*/

@media only screen and (min-width: 1199px) {
    .CourseContainer {
        padding-left: 15% !important;
        padding-right: 15% !important
    }
}

.CourseView {
    background: #fff;
    border: 1px solid #c6c6c7;
    padding: 25px;
    margin: 20px 0px 80px 0px;
}

.CourseView .courseInfo {
    margin: -60px 0px 0px 0px;
}

.CourseView h3 {
    color: #1c1c1c;
    margin-top: 10px;
    float: left;
    clear: both;
}

.CourseView .edtBtn {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -376px -75px;
    display: inline-block;
    height: 30px;
    width: 30px;
    background-size: 500px 600px;
    border: 1px solid #c6c6c7;
    position: relative;
    top: 20px;
    margin-bottom: -15px;
    border-radius: 3px;
    border-radius: 3px;
    cursor: pointer;
}

.CourseView .panelBox {
    border: 1px solid #ccc;
    padding-bottom: 15px;
    margin-top: 25px;
    float: left;
    width: 100%;
}

#courseAssign .panelBox {
    margin-top: 5px;
}

#courseAssign h1 {
    margin-top: 10px;
}

#courseAssign .panelBox .questionRow input[type=checkbox].css-checkbox+label.css-label1 {
    margin-top: 14px;
}

#courseAssign .panelBox .flashCard input[type=checkbox].css-checkbox+label.css-label1 {
    margin-top: 14px;
}

#courseAssign .panelBox .learning input[type=checkbox].css-checkbox+label.css-label1 {
    margin-top: 14px;
}

.CourseView .panelBox .activityRow {
    border-bottom: 1px solid #bababa;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px
}

.CourseView .panelBox h3 {
    float: left;
    width: 100%;
    height: 60px;
    background: #f1f9ff;
    border-bottom: 1px solid #ccc;
    padding: 20px 0px 0px 19px;
    margin-top: 0;
    margin-bottom: 15px;
}

.CourseView .panelBox p {
    padding: 10px 10px;
    width: auto;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.CourseView .panelBox p.lableValue {
    color: #0f6fb4;
    padding-top:35px;
}

.CourseView .panelBox p.lableValue a {
    color: #094b7a;
    display: inline-block;
    margin-left: 65px;
}

.CourseView .actionBlockDiv {
    margin-top: 0px;
    display: inline;
    float: right;
}

.CourseView .actionBlockDiv .detailShare {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -444px -332px;
    display: inline-block;
    height: 48px;
    left: 5px;
    position: relative;
    top: 0px;
    width: 48px;
    margin-right: 10px;
    cursor: pointer;
    background-size: 500px 600px;
}

.CourseView .actionBlockDiv .detailShare:hover {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -386px -333px;
    background-size: 500px 600px;
}

.CourseView .actionBlockDiv .detailEdit {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 1px -296px;
    display: inline-block;
    height: 48px;
    left: 5px;
    position: relative;
    top: 0px;
    width: 48px;
    margin-right: 10px;
    cursor: pointer;
    background-size: 500px 600px;
}

.CourseView .actionBlockDiv .detailEdit:hover {
    background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll -120px -298px;
}

.CourseView .actionBlockDiv .detailPrint {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 1px -539px;
    display: inline-block;
    height: 48px;
    left: 5px;
    position: relative;
    top: 0px;
    width: 48px;
    margin-right: 10px;
    cursor: pointer;
    background-size: 500px 600px;
}

.CourseView .actionBlockDiv .detailPrint:hover {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -118px -539px;
    background-size: 500px 600px;
}

.CourseView .actionBlockDiv .detailDuplicate {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 1px -377px;
    display: inline-block;
    height: 48px;
    left: 5px;
    position: relative;
    top: 0px;
    width: 48px;
    margin-right: 10px;
    cursor: pointer;
    background-size: 500px 600px;
}

.CourseView .actionBlockDiv .detailDuplicate:hover {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -119px -377px;
    background-size: 500px 600px;
}

.CourseView .actionBlockDiv .detailDelete {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 1px -459px;
    display: inline-block;
    height: 48px;
    left: 5px;
    position: relative;
    top: 0px;
    width: 48px;
    margin-right: 10px;
    cursor: pointer;
    background-size: 500px 600px;
}

.CourseView .actionBlockDiv .detailDelete:hover {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -119px -459px;
    background-size: 500px 600px;
}

.CourseView a.courseBtnAssign {
    display: inline;
    color: #0f6fb4;
    border: 1px solid #0f6fb4;
    padding: 10px 20px;
    border-radius: 20px;
    float: right;
    margin-right: 10px;
    margin-top: 5px;
}

.CourseView a.courseBtnStd {
    display: inline;
    color: #0f6fb4;
    border: 1px solid #0f6fb4;
    padding: 10px 20px;
    border-radius: 20px;
    float: right;
    margin-top: 5px;
    margin-right: 7px;
}

.CourseView p {
    color: #505050;
    line-height: 22px;
    padding: 10px 0px;
    float: left;
    /* margin: 0 0 0px; */
}

.CourseView .panelBox .questionRow .typeIcon {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 0px -71px;
    display: inline-block;
    height: 49px;
    left: 10px;
    position: relative;
    top: -4px;
    width: 52px;
    margin-right: 15px;
    background-size: 500px 600px;
}

.CourseView .panelBox .flashCard .typeIcon {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 0px -150px;
    display: inline-block;
    height: 49px;
    left: 10px;
    position: relative;
    top: -1px;
    width: 52px;
    margin-right: 15px;
    background-size: 500px 600px;
}

.CourseView .panelBox .learning .typeIcon {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 0px -222px;
    display: inline-block;
    height: 49px;
    left: 10px;
    position: relative;
    top: -1px;
    width: 52px;
    margin-right: 15px;
    background-size: 500px 600px;
}

#ShareCourseDialog .modal-dialog {
    width: 500px;
    margin: 150px auto !important;
}

#ActivityViewFromCourse .activityLogBlock {
    padding: 0px;
    width: inherit;
    height: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
    right: inherit;
    float: inherit;
    margin-left: inherit;
    display: block;
    position: relative;
}

#ActivityViewFromCourse .learningSlideTab {
    padding-top: 0px
}

#ActivityViewFromCourse h3.noteHeading {
    margin: 0px
}

#ActivityViewFromCourse .QuestionChoiceBox {
    padding-top: 5px;
    border-bottom: 1px solid #ccc
}

#ActivityViewFromCourse .modal-dialog {
    width: 768px;
    margin: 150px auto !important;
}

#ActivityViewFromCourse .fieldBox {
    float: left;
    padding-bottom: 4px;
    margin-top: 4px;
    width: 100%;
    padding-left: 20px
}

@media only screen and (max-width: 767px) {
    #ActivityViewFromCourse .modal-dialog {
        width: 100%;
        margin: 150px auto !important;
    }
    #ActivityViewFromCourse img {
        width: auto;
        max-width: 100%;
    }
}

.grey-background {
    background: #f7f8f9;
}

#sortable ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
}

.CourseView.editPage .panelBox {
    background: #fff;
    float: none;
}

.CourseView.editPage {
    float: left;
    width: 100%;
    min-height: 600px;
    height: auto;
    margin: 20px 0px 60px 0px
}

.CourseView.editPage .ui-sortable-handle {
    cursor: pointer;
}

.CourseView.editPage .editTextArea {
    margin: 4px auto 10px auto;
    max-width: 97.9%;
    background: #f7f8f9;
    border-color: #e0e0e0;
    color: #909090;
}

.CourseView.editPage .editTextArea.textEditTop {
    margin: 4px auto 10px auto;
    max-width: 97.9%;
    width: 97% !important;
    background: #f7f8f9;
    border-color: #e0e0e0;
    color: #909090;
    width: 100% !important;
    margin: 0 !important;
    max-width: 100% !important;
    margin-bottom: 8px !important;
}

.CourseView.editPage .panelBox h3 {
    padding: 0px 0px 0px 0px;
}

.CourseView.editPage .selectWidth {
    position: relative;
    z-index: 2;
    float: left;
    background: #fff;
    padding: 0;
    margin-top: 9px;
    width: 100%
}

.CourseView.editPage .selectWidth .form-control {
    background: #f7f8f9;
    height: 41px;
    border: 1px solid #e1e1e1;
    padding-right: 10px;
    width: 100%;
    color: #909090;
}

.CourseView.editPage .selectWidth .form-control[disabled] {
    color: #909090;
    cursor: not-allowed;
}

.CourseView.editPage .selectWidth .form-control.keyWords {}

.CourseView.editPage .selectArrow {
    background: transparent var(--sprite2-url) no-repeat scroll -234px -11px;
    position: absolute;
    right: 11px;
    top: 12px;
    width: 19px;
    height: 16px;
    display: inline-block;
    background-size: 500px 600px;
}

.CourseView.editPage .panelBox p.lableValue {
    color: #0f6fb4;
    padding-left: 0px;
}

.CourseView.editPage .addActivityBtn {
    background: #fff;
    color: #0f6fb4;
    border: 1px solid #0f6fb4;
    padding: 10px 25px;
    border-radius: 4px;
    margin-left: 19px;
}

.CourseView.editPage .addUnitBtn {
    background: #fff;
    color: #0f6fb4;
    border: 1px solid #0f6fb4;
    padding: 10px 25px;
    border-radius: 4px;
    margin-left: 0px;
    margin-top: 25px;
}

.CourseView.editPage .btn-cancelBtn {
    background: #fff;
    color: #0f6fb4;
    border: 1px solid #0f6fb4;
    padding: 10px 25px;
    border-radius: 4px;
    margin-left: 10px;
    margin-top: 25px;
    display: inline-block;
}

.CourseView.editPage .btn-saveBtn {
    background: #44a9f2;
    color: #fff;
    border: 1px;
    padding: 10px 25px;
    border-radius: 4px;
    margin-left: 10px;
    margin-top: 25px;
    display: inline-block;
}

.CourseView.editPage .DrgBtn {
    cursor: move;
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -230px -120px / 500px 600px;
    display: inline-block;
    width: 30px;
    height: 30px;
    float: right;
    margin-top: 15px;
}

.CourseView.editPage .DltBtn {
    cursor: pointer;
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -170px -118px / 500px 600px;
    display: inline-block;
    width: 30px;
    height: 30px;
    float: right;
    margin-top: 13px;
    margin-right: 15px;
}

.CourseView.editPage .courseActionButton {
    position: relative;
    z-index: 150;
}

.headingMainField {
    margin-bottom: 24px;
    width: 100%;
    float: left;
}

a.setBtn {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -324px -114px / 500px 600px;
    display: inline-block;
    width: 35px;
    height: 35px;
    float: right;
    margin-top: 5px;
}

@media only screen and (max-width: 767px) {
    .respFixBot {
        position: fixed;
        left: 0px;
        bottom: 1px;
        width: 100%;
        height: 60px;
        background: #f1f1f2;
        z-index: 100;
        padding-top: 6px;
        text-align: center
    }
    .CourseView .courseInfo {
        margin: -25px 0px 0px 0px;
    }
    .CourseView .panelBox {
        border: 1px solid #ccc;
        padding-bottom: 15px;
        margin-top: 0px;
        float: left;
        width: 100%;
    }
    .CourseView .panelBox .questionRow {
        margin-left: -30px;
    }
    .CourseView .panelBox .flashCard {
        margin-left: -30px;
    }
    .CourseView .panelBox .learning {
        margin-left: -30px;
    }
    .CourseView .panelBox p.pull-right {
        margin-top: -43px;
        margin-right: -32px;
    }
    .CourseView .panelBox p.lableValue {
        padding-top: 0px;
    }
    .CourseView a.courseBtnStd {
        margin-right: 27px;
    }
    .CourseView .actionBlockDiv {
        margin-top: 0px;
        display: inline;
        float: left;
        margin-left: 3%;
    }
    .CourseView .actionBlockDiv .detailShare {
        left: 0px;
        margin-right: 0px;
    }
    .CourseView .actionBlockDiv .detailEdit {
        left: 0px;
        margin-right: 0px;
    }
    .CourseView .actionBlockDiv .detailPrint {
        left: 0px;
        margin-right: 0px;
    }
    .CourseView .actionBlockDiv .detailDuplicate {
        left: 0px;
        margin-right: 0px;
    }
    .CourseView .actionBlockDiv .detailDelete {
        left: 0px;
        margin-right: 10px;
    }
    .CourseView {
        margin: 20px 0px 140px 0px;
    }
}

@media only screen and (max-width: 570px) {
    .CourseView a.courseBtnStd {
        padding: 5px 10px;
        margin-top: 13px;
        margin-right: 7px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 22%;
    }
    .CourseView a.courseBtnAssign {
        padding: 5px 10px;
        margin-right: 10px;
        margin-top: 13px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 22%;
    }
    #ShareCourseDialog .modal-dialog {
        width: 100%;
        margin: 150px auto !important;
    }
}

@media only screen and (max-width: 450px) {
    .CourseView .actionBlockDiv .detailShare {
        background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -454px -540px;
        background-size: 500px 600px;
        width: 38px;
    }
    .CourseView .actionBlockDiv .detailShare:hover {
        background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -401px -540px;
        background-size: 500px 600px;
    }
    .CourseView .actionBlockDiv .detailDuplicate {
        background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -454px -434px;
        background-size: 500px 600px;
        width: 38px;
    }
    .CourseView .actionBlockDiv .detailDuplicate:hover {
        background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -400px -434px;
        background-size: 500px 600px;
    }
    .CourseView .actionBlockDiv .detailDelete {
        background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -454px -381px;
        background-size: 500px 600px;
        width: 38px;
    }
    .CourseView .actionBlockDiv .detailDelete:hover {
        background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -400px -381px;
        background-size: 500px 600px;
    }
    .CourseView .actionBlockDiv .detailEdit {
        display: none;
    }
}

/* Course View - End*/

/*Subjects name specific CSS start*/

.listViewBlock table.table-hover tbody tr.subAlgebra .typeIcon {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -220px -466px / 500px 600px;
    display: inline-block;
    height: 49px;
    left: 0;
    margin-right: 15px;
    position: relative;
    top: 1px;
    width: 52px;
}

.listViewBlock table.table-hover tbody tr.subUSHistory .typeIcon {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -220px -536px / 500px 600px;
    display: inline-block;
    height: 49px;
    left: 0;
    margin-right: 15px;
    position: relative;
    top: 4px;
    width: 52px;
}

.listViewBlock table.table-hover tbody tr.subGlobHistory .typeIcon {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -220px -316px / 500px 600px;
    display: inline-block;
    height: 49px;
    left: 0;
    margin-right: 15px;
    position: relative;
    top: 4px;
    width: 52px;
}

.listViewBlock table.table-hover tbody tr.subLivEnvi .typeIcon {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -220px -246px / 500px 600px;
    display: inline-block;
    height: 49px;
    left: 0;
    margin-right: 15px;
    position: relative;
    top: 1px;
    width: 52px;
}

.listViewBlock table.table-hover tbody tr.subGeometry .typeIcon {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -220px -384px / 500px 600px;
    display: inline-block;
    height: 49px;
    left: 0;
    margin-right: 15px;
    position: relative;
    top: 4px;
    width: 52px;
}

.listViewBlock table.table-hover tbody tr.subErthSci .typeIcon {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -220px -167px / 500px 600px;
    display: inline-block;
    height: 49px;
    left: 0;
    margin-right: 15px;
    position: relative;
    top: 4px;
    width: 52px;
}

/*Subjects name specific CSS end*/

/*activity view modal css*/

#shareListDialog {
    z-index: 9999999;
    padding: 0 !important;
}

#shareListDialog .nav-tabs {
    padding-bottom: 5px;
    margin-bottom: 20px;
}

#shareListDialog .shareTiltleTop {}

#shareListDialog .modal-content {
    max-width: 450px;
    width: 100%;
    margin: 0 auto;
}

#shareListDialog .shareTiltleTop a {
    border: none;
    color: #909090;
    cursor:
}

#shareListDialog .shareTiltleTop a:after {
    content: '';
    width: 2px;
    height: 15px;
    background: #cbcbcb;
    display: inline-block;
    position: absolute;
    right: 0px;
    top: 13px;
}

#shareListDialog .shareTiltleTop:last-child a:after {
    content: '';
    background: none;
}

#shareListDialog .shareTiltleTop.active a {
    border: none;
    color: #0f6fb4;
}

#shareListDialog .shareTiltleTop a:hover {
    background: none;
}

#shareListDialog .colTab .selectWidth {
    width: 100%;
    position: relative;
    z-index: 2;
}

#shareListDialog .colTab .selectWidth .form-control {
    background: transparent;
    height: 48px;
    border: 1px solid #e1e1e1;
    color: #909090;
}

#shareListDialog .colTab .selectWidth .form-control[disabled] {
    color: #909090;
    cursor: not-allowed;
}

#shareListDialog .colTab .selectWidth .form-control {
    background: transparent;
    height: 48px;
    border: 1px solid #e1e1e1;
    margin-top: 15px;
    color: #909090;
}

#shareListDialog .colTab .fieldBox {
    padding: 7px 0px;
    border-bottom: 1px solid #e0e0e0;
}

#shareListDialog .colTab .fieldBox:last-child {
    border-bottom: none;
}

#shareListDialog .colTab button.collbSrch {
    background: #fff;
    border: 1px solid #0f6fb4;
    color: #0f6fb4;
    padding: 10px 20px;
    border-radius: 4px;
    float: right;
    margin-top: 15px;
}

#shareListDialog .colTab .srcBoxChecMain {
    height: 140px;
    overflow: auto;
    margin: 15px 0px 0px 0px;
    display: none
}

#shareListDialog input[type=checkbox].css-checkbox {
    position: absolute;
    z-index: -1000;
    left: -1000px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: auto;
    width: 100%;
    margin: -1px;
    padding: 0;
    border: 0;
}

#shareListDialog input[type=checkbox].css-checkbox+label.css-label1 {
    padding-left: 30px;
    height: 24px;
    width: 100%;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -470px -3px;
    vertical-align: middle;
    cursor: pointer;
    line-height: 22px;
    color: #555555;
    background-size: 500px 600px;
}

#shareListDialog input[type=checkbox].css-checkbox:checked+label.css-label1 {
    background-position: -429px -37px;
}

#shareListDialog label.css-label1 {
    background-image: var(--sprite2-url);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#shareListDialog .CSWTab {}

#shareListDialog .CSWTab h3 {
    color: #1c1c1c;
    margin: 40px 0px 20px 0px;
}

#shareListDialog .CSWTab .stipHeading {
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 5px;
    padding-top: 10px
}

#shareListDialog .CSWTab .stipHeading .tName {
    width: 60%;
    float: left;
    color: #1c1c1c;
}

#shareListDialog .CSWTab .stipHeading .read {
    width: 20%;
    float: left;
    color: #1c1c1c;
}

#shareListDialog .CSWTab .stipHeading .write {
    width: 20%;
    float: left;
    color: #1c1c1c;
}

#shareListDialog .CSWTab .CSWCheckPan {
    height: 200px;
    overflow: auto;
}

#shareListDialog .CSWTab .CSWCheckPan .blockBox {
    padding-bottom: 29px;
    border-top: 1px solid #e0e0e0;
    padding-top: 12px;
}

#shareListDialog .CSWTab .CSWCheckPan .blockBox:first-child {
    border-top: none;
}

#shareListDialog .CSWTab .CSWCheckPan .tNamePan {
    width: 60%;
    float: left;
    color: #1c1c1c;
}

#shareListDialog .CSWTab .CSWCheckPan .readCheck {
    width: 20%;
    float: left;
}

#shareListDialog .CSWTab .CSWCheckPan .writeCheck {
    width: 20%;
    float: left;
}

#shareListDialog .ubShareSet {
    background: #44a9f2;
    color: #fff;
}

@media only screen and (max-width: 470px) {
    #shareListDialog .shareTiltleTop {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 33%;
    }
    #shareListDialog .shareTiltleTop a {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .respWd70 {
        width: 70%;
    }
    .respWd70 p.lableValue a {
        margin-left: 2px;
        position: relative;
        top: -5px;
    }
    .respWd70 em {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 68%;
        display: inline-block;
    }
    .respWd30 {
        width: 30%;
        padding-right: 0;
    }
    .respWd30 p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media only screen and (max-width: 400px) {
    .respWd70 em {
        width: 48%;
    }
    .CourseView .panelBox p.lableValue a {
        margin-left: 0px;
    }
    /*Subjects name specific CSS end*/
    ul li a[disabled], ul li a[disabled]:active, ul li a[disabled]:hover, ul li a[disabled]:visited {
        text-decoration: none;
        color: #888b8d !important;
        cursor: default !important;
    }
}

.selectedCount {
    padding: 6px 10px;
    border: 1px solid lightgray;
    margin-left: 10px;
}

/************** Manage Activities CSS fixes ****************/

.subGlobHistory.h4, tr.subAlgebra.h4, tr.subUSHistory.h4, tr.subGeometry.h4, tr.subErthSci.h4, tr.subLivEnvi.h4, tr.learning h4, tr.flashCard h4, tr.questionRow h4, tr.learning td div[elipsis="true"], tr.flashCard td div[elipsis="true"], tr.questionRow td div[elipsis="true"] {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#SearchTipModal .modal-dialog {
    width: 800px;
    margin: 150px auto !important;
}

.catTip {
    background-image: url(../images/activities/cat.png);
    height: 200px;
    -ms-transform: scale(0.8, 0.8);
    -webkit-transform: scale(0.8, 0.8);
    transform: scale(0.8, 0.8);
    border: 1px solid #e0e0e0;
}

.subcatTip {
    background-image: url(../images/activities/subcat.png);
    height: 200px;
    -ms-transform: scale(0.8, 0.8);
    -webkit-transform: scale(0.8, 0.8);
    transform: scale(0.8, 0.8);
    border: 1px solid #e0e0e0;
}

.subjectTip {
    background-image: url(../images/activities/subject.png);
    height: 200px;
    -ms-transform: scale(0.8, 0.8);
    -webkit-transform: scale(0.8, 0.8);
    transform: scale(0.8, 0.8);
    border: 1px solid #e0e0e0;
}

.randomSelection {
    margin: auto;
    vertical-align: middle;
}

.randomSelection .selectArrow {
    background: transparent var(--sprite2-url) no-repeat scroll -234px -11px;
    margin-left: -30px;
    width: 19px;
    height: 14px;
    display: inline-block;
    background-size: 500px 600px;
}

.randomSelection input {
    width: 50px;
    border: 1px solid gray;
    padding-left: 8px;
    margin: auto;
    margin-bottom: 15px;
    margin-left: 10px;
    display: inline-block;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
}

.randomSelection p {
    width: initial !important;
}

.randomSelection a {
    margin: auto;
    display: block;
    padding: 4px;
    width: 54%;
    border: 1px solid gray;
    background: white;
    color: dimgrey;
    border-radius: 4px;
    text-align: center;
}

.randomSelection select {
    margin-right: 10px;
    border-color: gray;
    padding-left: 5px;
    padding-right: 5px;
    width: 150px;
    display: inline-block;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
}

.randomSelection .inputs {
    margin: auto;
    text-align: center;
}

.randomSelection .flash-inputs {
    margin: auto;
    width: 52px;
}

.width_100 {
    width: 100%;
}

.selQuestions ul {
    padding: 8px;
    height: 39px;
    border-bottom: 1px solid #e0e0e0;
}

.selQuestions ul li {
    color: #0f6fb4;
    display: inline-block;
}

/*.selQuestions ul li:after {
    content: ' | ';
    color: #909090;
    padding: 0px 10px;
}*/

.selQuestions ul li:last-child:after {
    content: '';
}

.selectedHeadingActivity {
    float: left;
    margin-top: 9px;
    padding-right: 10px;
    padding-left: 10px;
    color: #007f00;
    font-size: 18px !important;
}

.addQuestionBlock .selectQuestionBox ul.quesBcrumb li a[disabled], .addQuestionBlock .selectQuestionBox ul.quesBcrumb li a[disabled]:hover, .addQuestionBlock .selectQuestionBox ul.quesBcrumb li a[disabled]:active, .addQuestionBlock .selectQuestionBox ul.quesBcrumb li a[disabled]:visited {
    text-decoration: none;
    color: #888b8d;
    cursor: default;
}

.addQuestionBlock .questionSelectedBox ul.quesBcrumb li a[disabled], .addQuestionBlock .selectQuestionBox ul.quesBcrumb li a[disabled]:hover, .addQuestionBlock .selectQuestionBox ul.quesBcrumb li a[disabled]:active, .addQuestionBlock .selectQuestionBox ul.quesBcrumb li a[disabled]:visited {
    text-decoration: none;
    color: #888b8d;
    cursor: default;
}

/************** Manage Activities CSS fixes - end ****************/

/*highlighting rows css for view activity start*/

.highlightRow {
    background-color: #f1f9ff
}

/*highlighting rows css for view activity end*/

/* Upload button CSS - START */

.fileContainer {
    overflow: hidden;
    position: relative;
    color: #0f6fb4;
    border: 1px solid;
    background: #fff;
    border-radius: 3px;
    text-transform: inherit;
    width: 38% !important;
    height: 37px !important;
    padding: 8px 0px 2px 0px;
    text-align: center;
}

.fileContainer:hover {
    background-color: #0f6fb4;
    color: #fff;
}

.fileContainer [type=file] {
    cursor: inherit;
    display: block;
    ;
    filter: alpha(opacity=0);
    min-height: 100%;
    min-width: 100%;
    opacity: 0;
    position: absolute;
    right: 0;
    text-align: right;
    top: 0;
}

/* Upload button CSS - END */

/****************************** NEW FONT SIZES *****************************************/

table tr th {
    font-weight: bold !important;
}

ul.nav li a {
    font-weight: bold !important;
    letter-spacing: 0.5px;
}

/* ul.nav li a.questionEditorFontWeight {
    font-weight: unset !important;
} */

.dropBoxDiv a {
    font-size: 13px !important;
}

.filterBlock .limitFilter label {
    font-size: 13px !important;
}

body, div, p, nav, article, a, label {
    font-size: 14px !important;
    font-family: 'Lato', sans-serif;
    font-weight: 400 !important;
    color: #505050;
}

h5 {
    font-size: 15px !important;
    margin-top: 3px !important;
    margin-bottom: 5px !important;
    line-height: inherit !important;
}

h4 {
    font-size: 15px !important;
    font-weight: 600 !important;
}

h3 {
    font-size: 16px !important;
}

h2 {
    font-size: 18px !important;
}

h1 {
    font-size: 20px !important;
}

.subGlobHistory.h4, tr.subAlgebra.h4, tr.subUSHistory.h4, tr.subGeometry.h4, tr.subErthSci.h4, tr.subLivEnvi.h4, tr.learning h4, tr.flashCard h4, tr.questionRow h4 {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/****************************** NEW FONT SIZES END *****************************************/

.modal-header span {
    font-size: 26px !important;
}

.alert {
    z-index: 400;
    width: 50%;
    position: fixed;
    top: 115px;
    text-align: center;
    font-size: 14px !important;
}

.close {
    opacity: 1 !important;
}

/********* Flashcard and Question expand collapse button *********/

.glyphicon.btn-lg.collapseBtn.glyphicon-menu-down:before {
    content: "Show Info";
    padding: 5px
}

.glyphicon.btn-lg.collapseBtn.glyphicon-menu-up:before {
    content: "Hide Info";
    padding: 5px
}

#expColBtn {
    display: inline-block;
    height: 30px;
    margin-top: -41px;
    width: 90px;
    color: #555555;
    border: 1px solid gray;
    background: white;
    padding: 6px;
    margin-right: 10px;
    font-family: Verdana;
    text-align: center;
    font-size: 11px !important;
}

/*Top Menu dropbox start*/

.topFirstBar .topBarProfileIcons div.profileImgLink .dropBoxDiv {
    position: absolute;
    width: 170px;
    height: auto;
    left: calc(100% - 180px);
    top: 36px;
    background: #fff;
    box-shadow: 0px 0px 10px #ccc;
    z-index: 222;
}

.topFirstBar .topBarProfileIcons div.profileImgLink .dropBoxDiv ul {
    margin-top: 5px;
    margin-bottom: 0;
}

.topFirstBar .topBarProfileIcons div.profileImgLink .dropBoxDiv ul li {
    height: 35px;
    display: block;
    width: 100%
}

.topFirstBar .topBarProfileIcons div.profileImgLink .dropBoxDiv ul li a {
    display: block;
    padding: 7px 15px 5px 9px;
    color: #555555;
    font-size: 10px !important;
    border-bottom: 1px solid #ebebeb;
    text-align: left;
}

.topFirstBar .topBarProfileIcons div.profileImgLink .dropBoxDiv ul li:last-child a {
    border: none;
}

.topFirstBar .topBarProfileIcons div.profileImgLink .dropBoxDiv:before {
    content: '';
    position: absolute;
    right: 0px;
    top: -20px;
    border: 10px solid rgba(255, 255, 255, 0.1);
    display: block;
    border-bottom-color: rgb(184, 184, 184);
}

.topFirstBar .topBarProfileIcons div.profileImgLink .dropBoxDiv:after {
    content: '';
    content: '';
    position: absolute;
    right: 0px;
    top: -19px;
    border: 10px solid rgba(255, 255, 255, 0.1);
    display: block;
    border-bottom-color: rgb(255, 255, 255);
}

.topFirstBar .topBarProfileIcons div.profileImgLink:hover .dropBoxDiv {
    display: block;
}

/*Top Menu Dropbox end*/

/********************** Assignment CSS ******************/

#assignmentData table tr {
    border-bottom: 1px solid #ccc;
    line-height: 28px
}

.WHSign {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -386px -272px;
    display: inline-block;
    height: 48px;
    left: 5px;
    position: relative;
    top: 0px;
    width: 48px;
    margin-right: 10px;
    cursor: pointer;
    background-size: 500px 600px;
}

.WHSign .tooltiptext {
    visibility: hidden;
    width: 300px;
    background-color: #fff;
    color: #929090;
    text-align: left;
    border-radius: 6px;
    padding: 5px 0;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: 50px;
    left: -500%;
    border: 1px solid;
    padding: 5px;
    font-size: 19px !important;
    line-height: 23px;
}

.WHSign:hover .tooltiptext {
    visibility: visible;
}

.Activity-toolTip .tooltiptext {
    visibility: hidden;
    width: 300px;
    background-color: #fff;
    color: #929090;
    text-align: left;
    border-radius: 6px;
    padding: 5px 0;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    border: 1px solid;
    padding: 5px;
    font-size: 15px !important;
    line-height: 23px;
    margin-left: 29px;
}

.Activity-toolTip:hover .tooltiptext {
    visibility: visible;
}

.Activity-toolTip.toolTipStyleForCreateAssignmentOnly .tooltiptext {
    width: 220px;
}

.save {
    background: #44a9f2;
    color: #fff;
    border: none;
    padding: 15px 0px;
    width: 148px;
    border-radius: 4px;
    border: 1px solid;
    margin-bottom: 10px;
    margin-top: 30px;
}

.save:hover, .save:focus {
    background: #fff;
    color: #44a9f2;
    border: 1px solid
}

input[type=checkbox].css-checkbox {
    position: absolute;
    z-index: -1000;
    left: -1000px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: auto;
    width: 100%;
    margin: -1px;
    padding: 0;
    border: 0;
}

input[type=checkbox].css-checkbox+label.css-label1 {
    padding-left: 30px;
    height: 24px;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -470px -4px;
    vertical-align: middle;
    cursor: pointer;
    font-weight: normal;
    line-height: 22px;
    color: #555555;
    font-size: 15px;
    background-size: 500px 600px;
    float: left
}

input[type=checkbox].css-checkbox:checked+label.css-label1 {
    background-position: -429px -37px;
}

label.css-label1 {
    background-image: var(--sprite2-url);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.CourseView .panelBox p.panPara {
    position: relative;
    padding-left: 50px !important;
}

label.css-label1.panParaCheck {
    position: absolute;
    left: 20px;
}

.actionBlockDiv.ques {
    float: right
}

@media only screen and (max-width: 767px) {
    .respWd70 {
        width: 70%;
    }
    .respWd70 p.lableValue a {
        margin-left: 2px;
        position: relative;
        top: -5px;
    }
    .respWd70 em {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 68%;
        display: inline-block;
    }
    .respWd30 {
        width: 30%;
        padding-right: 0;
    }
    .respWd30 p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media only screen and (max-width: 400px) {
    .respWd70 em {
        width: 48%;
    }
    .CourseView .panelBox p.lableValue a {
        margin-left: 0px;
    }
}

/*CSS Class To disable Links*/

.disableLink {
    pointer-events: none;
    cursor: default;
    display: none !important;
}

/*End*/

/*For Notifications - STARTS*/

.notiPersonImg {
    width: 40px;
    height: 40px;
    display: block;
    border-radius: 100%;
    text-align: center;
    overflow: hidden;
    position: static;
    top: auto;
    left: auto;
    float: left;
    margin-top: 3px;
}

.cd-main-content {
    text-align: center;
}

.cd-panel-header h2 {
    font-weight: bold;
    padding-left: 5%;
    padding-top: 0px;
    font-size: 32px;
    font-size: 2rem;
}

.cd-main-content .cd-btn {
    -moz-osx-font-smoothing: grayscale;
    -moz-transition: all 0.2s;
}

.no-touch .cd-main-content .cd-btn:hover {}

.cd-panel-container .notifiStrip {
    border-bottom: 1px solid #ddd;
    padding: 10px 20px 15px 20px;
}

.cd-panel-container .notifiStrip:last-child {
    border-bottom: none;
}

.cd-panel-container .notifiStrip .stripText {
    margin: 0px;
    color: #2f2f2f;
}

.cd-panel-container .notifiStrip .stripText strong {
    color: #0f6fb4;
}

.cd-panel-container .notifiStrip .assignPers {
    margin: 0px;
    line-height: 8px;
    font-weight: 600;
    color: #2f2f2f;
}

.cd-panel-container .notifiStrip .assignTime {
    margin: 0px;
    line-height: 18px;
    color: #828383;
    font-size: 14px;
}

.cd-panel {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    visibility: hidden;
    -webkit-transition: visibility 0s 0.6s;
    -moz-transition: visibility 0s 0.6s;
    transition: visibility 0s 0.6s;
}

.cd-panel::after {
    /* overlay layer */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    cursor: pointer;
    -webkit-transition: background 0.3s 0.3s;
    -moz-transition: background 0.3s 0.3s;
    transition: background 0.3s 0.3s;
}

.cd-panel.is-visible {
    visibility: visible;
    -webkit-transition: visibility 0s 0s;
    -moz-transition: visibility 0s 0s;
    transition: visibility 2s 0s;
    background: rgba(244, 249, 253, 0.6);
    z-index: 9;
}

.cd-panel.is-visible::after {
    background: rgba(0, 0, 0, 0.6);
    -webkit-transition: background 0.3s 0s;
    -moz-transition: background 0.3s 0s;
    transition: background 0.3s 0s;
}

.cd-panel.is-visible .cd-panel-close::before {
    -webkit-animation: cd-close-1 0.6s 0.3s;
    -moz-animation: cd-close-1 0.6s 0.3s;
    animation: cd-close-1 0.6s 0.3s;
}

.cd-panel.is-visible .cd-panel-close::after {
    -webkit-animation: cd-close-2 0.6s 0.3s;
    -moz-animation: cd-close-2 0.6s 0.3s;
    animation: cd-close-2 0.6s 0.3s;
}

.cd-panel-header {
    position: fixed;
    width: 425px;
    height: 80px;
    line-height: 50px;
    background: rgba(255, 255, 255, 0.96);
    z-index: 2;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08);
    /* -webkit-transition: top 0.3s 0s; */
    -moz-transition: top 0.3s 0s;
    /* transition: top 0.3s 0s; */
}

.cd-panel-header h2 {
    font-weight: 400;
    padding-left: 5%;
    padding-top: 13px;
    font-size: 22px;
}

.from-right .cd-panel-header, .from-left .cd-panel-header {
    top: 152px;
    right: -2000px;
}

.from-right .cd-panel-header {
    right: 0;
    display: none;
}

.from-left .cd-panel-header {
    left: 0;
}

.is-visible .cd-panel-header {
    top: 0px;
    -moz-transition: 0s;
    display: block;
    box-shadow: -3px 0px 5px #cccccc;
}

@media only screen and (max-width: 768px) {
    .cd-panel-header {
        width: 100% !important;
    }
    .cd-panel-container {
        width: 100% !important;
    }
}

.cd-panel-close {
    position: absolute;
    top: 23px;
    right: 23px;
    height: 30px;
    width: 30px;
    display: inline-block;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -299px -85px;
    background-size: 400px 450px;
}

.cd-panel-close::before, .cd-panel-close::after {
    /* close icon created in CSS */
    position: absolute;
    top: 22px;
    left: 20px;
    height: 3px;
    width: 20px;
    background-color: #424f5c;
    /* this fixes a bug where pseudo elements are slighty off position */
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.cd-panel-close::before {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.cd-panel-close::after {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.no-touch .cd-panel-close:hover {}

.no-touch .cd-panel-close:hover::before, .no-touch .cd-panel-close:hover::after {
    background-color: #ffffff;
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    transition-duration: 0.3s;
}

.no-touch .cd-panel-close:hover::before {
    -webkit-transform: rotate(220deg);
    -moz-transform: rotate(220deg);
    -ms-transform: rotate(220deg);
    -o-transform: rotate(220deg);
    transform: rotate(220deg);
}

.no-touch .cd-panel-close:hover::after {
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
}

.cd-panel-container {
    position: fixed;
    width: 425px;
    height: calc(100% - 160px);
    top: 202px;
    background: #fff;
    z-index: 1;
    -moz-transition-property: -moz-transform;
    -moz-transition-duration: 0.3s;
    -moz-transition-delay: 0.3s;
    box-shadow: -3px 0px 5px #cccccc;
}

.from-right .cd-panel-container {
    -webkit-transform: translate3d(100%, 0, 0);
    -moz-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    -o-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    right: -2000px;
}

.from-left .cd-panel-container {
    left: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    -moz-transform: translate3d(-100%, 0, 0);
    -ms-transform: translate3d(-100%, 0, 0);
    -o-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
}

.is-visible .cd-panel-container {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -moz-transition-delay: 0s;
    top: 80px;
    right: 0;
    float: right;
    height: calc(100% - 130px);
    overflow: scroll;
}

.cd-panel-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 45px);
    padding: 10px 5% 70px 5%;
    overflow: auto;
    /* smooth scrolling on touch devices */
    -webkit-overflow-scrolling: touch;
}

.cd-panel-content p {
    font-size: 14px;
    font-size: 0.875rem;
    color: #424f5c;
    line-height: 1.4;
    margin: 2em 0;
}

.cd-panel-content p:first-of-type {
    margin-top: 0;
}

@media only screen and (min-width: 768px) {
    .cd-panel-content p {
        font-size: 16px;
        font-size: 1rem;
        line-height: 1.6;
    }
}

/*For Notifications - END*/

/****************************  ASSIGNMENTS CSS  ******************************/

/*that is the new icon link (first Icon)*/

.listViewBlock table.table-hover tbody tr.booksRow .typeIcon {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -121px -220px / 500px 600px;
    display: inline-block;
    height: 49px;
    left: 0;
    margin-right: 15px;
    position: relative;
    top: 1px;
    width: 52px;
}

/*the icon for showing new row*/

span.subRowToggle {
    background: transparent var(--sprite2-url) repeat scroll -314px -176px / 500px 600px;
    display: inline-block;
    height: 27px;
    left: 5px;
    position: relative;
    top: -13px;
    width: 50px;
}

/*the icon for hiding new row*/

span.subRowToggle.minus {
    background: transparent var(--sprite2-url) repeat scroll -313px -232px / 500px 600px;
}

/*css for new row*/

.listViewBlock .innerTableQues thead tr th, .listViewBlock .innerTableQues tbody tr td {
    color: #555555;
    padding-left: 15px;
    vertical-align: middle;
    padding-bottom: 5px;
    padding-top: 5px;
}

.listViewBlock .innerTableQues tbody tr {
    border-bottom: 1px solid #ddd;
}

.listViewBlock .innerTableQues tbody tr:last-child {
    border-bottom: none;
}

.listViewBlock .innerTableQues tbody tr td .dueDate {
    color: #db5c35
}

.listViewBlock .innerTableQues tbody tr td .reviewPend {
    display: block;
    color: #db5c35;
    border: 1px solid;
    border-radius: 10px;
    padding: 1px;
    font-size: 12px;
    width: 67%;
    text-align: center;
    margin: 2px 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.listViewBlock .innerTableQues thead tr th {
    border-bottom: 1px solid #e0e0e0;
    color: #1c1c1c;
    font-size: 18px;
    font-weight: 400;
    height: 52px;
}

.listViewBlock .innerTableQues tbody tr.questionRow {}

.listViewBlock .innerTableQues tbody tr.questionRow .typeIcon {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 0 -71px / 500px 600px;
    display: inline-block;
    height: 49px;
    left: 0;
    margin-right: 15px;
    position: relative;
    top: 1px;
    width: 52px;
}

.listViewBlock .innerTableQues tbody tr.flashCard .typeIcon {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 0 -150px / 500px 600px;
    display: inline-block;
    height: 49px;
    left: 0;
    margin-right: 15px;
    position: relative;
    top: 4px;
    width: 52px;
}

.listViewBlock .innerTableQues tbody tr.learning .typeIcon {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 0 -222px / 500px 600px;
    display: inline-block;
    height: 49px;
    left: 0;
    margin-right: 15px;
    position: relative;
    top: 4px;
    width: 52px;
}

.listViewBlock .innerTableQues tbody tr .contentType {
    display: inline-block;
    position: relative;
    top: -5px;
}

.listViewBlock .innerTableQues tbody tr .contentType h4 {
    color: #0f6fb4;
    font-size: 16px;
}

.listViewBlock .innerTableQues tbody tr .contentType p {
    color: #505050;
    font-size: 13px;
    font-weight: bold;
}

.listViewBlock .innerTableQues tbody tr .contentType p strong {
    color: #007f00;
    font-size: 13px;
    nt-weight: 400;
}

.listViewBlock .innerTableQues tbody tr td .DropDivBlock {
    cursor: pointer;
    padding-bottom: 10px;
    position: relative;
    text-align: center;
    line-height: 40px;
}

.listViewBlock .innerTableQues tbody tr td .DropDivBlock span.bullets {
    background: #0f6fb4 none repeat scroll 0 0;
    border-radius: 100%;
    display: inline-block;
    height: 6px;
    width: 6px;
}

.listViewBlock .innerTableQues tbody tr td .DropDivBlock .dropBoxDiv {
    background: #fff none repeat scroll 0 0;
    box-shadow: 0 0 10px #ccc;
    display: none;
    height: 115px;
    left: -189px;
    position: absolute;
    top: 29px;
    width: 230px;
    z-index: 2;
}

.listViewBlock .innerTableQues tbody tr td .DropDivBlock .dropBoxDiv ul {}

.listViewBlock .innerTableQues tbody tr td .DropDivBlock .dropBoxDiv ul li {
    display: block;
    height: 35px;
}

.listViewBlock .innerTableQues tbody tr td .DropDivBlock .dropBoxDiv ul li a {
    border-bottom: 1px solid #ebebeb;
    color: #555555;
    display: block;
    padding: 7px 15px 5px;
    text-align: left;
}

.listViewBlock .innerTableQues tbody tr td .DropDivBlock .dropBoxDiv ul li:last-child a {
    border: medium none;
}

.listViewBlock .innerTableQues tbody tr td .DropDivBlock .dropBoxDiv::before {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    border-color: rgba(255, 255, 255, 0.1) rgba(255, 255, 255, 0.1) rgb(184, 184, 184);
    border-image: none;
    border-style: solid;
    border-width: 10px;
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: -20px;
}

.listViewBlock .innerTableQues tbody tr td .DropDivBlock .dropBoxDiv::after {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    border-color: rgba(255, 255, 255, 0.1) rgba(255, 255, 255, 0.1) rgb(255, 255, 255);
    border-image: none;
    border-style: solid;
    border-width: 10px;
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: -19px;
}

.listViewBlock .innerTableQues tbody tr td .DropDivBlock:hover .dropBoxDiv {
    display: block;
}

.listViewBlock .innerTableQues>tbody>tr:hover {
    background-color: #f1f9ff;
    cursor: pointer;
}

/*css for 100% completed */

/*css for less then 100%*/

.listViewBlock table.table-hover tbody tr .contentType p {
    color: #505050;
    font-size: 13px;
    font-weight: bold;
}

.listViewBlock table.table-hover tbody tr .contentTypeQues p {
    color: #505050;
    font-size: 13px;
    font-weight: bold;
}

.listViewBlock table.table-hover tbody tr .contentTypeQues p font {
    color: #0f6fb4;
    font-size: 14px !important;
}

.listViewBlock table.table-hover tbody tr .contentTypeQues font {
    color: #0f6fb4;
    font-size: 14px !important;
}

/*css for highlighted date*/

.listViewBlock table.table-hover tbody tr td .dueDate {
    color: #db5c35;
}

/* css for wating for review text*/

.listViewBlock table.table-hover tbody tr td .reviewPend {
    display: block;
    color: #db5c35;
    border: 1px solid;
    border-radius: 10px;
    padding: 1px;
    font-size: 12px;
    /* width: 67%; */
    width: 160px;
    text-align: center;
    margin: 2px 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.reviewPend {
    float: right;
    display: block;
    color: #db5c35;
    border: 1px solid;
    border-radius: 10px;
    padding: 1px;
    font-size: 12px;
    /* width: 67%; */
    width: 160px;
    text-align: center;
    margin: 2px 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* that css is for assignment dots hover div  */

.listViewBlock table.table-hover tbody tr td .DropDivBlock .dropBoxDiv {
    height: auto;
    margin-bottom: 50px;
    line-height: 40px;
}

.DropDivBlock {
    line-height: 40px;
}

.DropDivBlock span.bullets {
    margin-left: 2px;
    width: 6px;
    height: 6px;
    display: inline-block;
    background: #0f6fb4;
    border-radius: 100%;
}

/*Question Page ICON Start*/

table.table-hover tbody tr.QuesRead .contentType {
    width: calc(100% - 70px);
    margin-top: 10px;
}

table.table-hover tbody tr.QuesRead .typeIcon {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -313px -466px / 500px 600px;
    display: inline-block;
    height: 49px;
    left: 0;
    margin-right: 15px;
    position: relative;
    top: 1px;
    width: 52px;
    float: left;
}

table.table-hover tbody tr.QuesWrite .contentType {
    width: calc(100% - 70px);
    margin-top: 10px;
}

table.table-hover tbody tr.QuesWrite .typeIcon {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -315px -536px / 500px 600px;
    display: inline-block;
    height: 49px;
    left: 0;
    margin-right: 15px;
    position: relative;
    top: 1px;
    width: 52px;
    float: left;
}

span.glyphicon.glyphicon-star {
    color: #f69c00;
    margin-right: 3px;
}

.listViewBlock table.table-hover tbody tr.QuesRead .contentType p.headText, .listViewBlock table.table-hover tbody tr.QuesWrite .contentType p.headText {
    color: #0f6fb4;
    font-size: 16px;
    white-space: inherit;
}

.QuestionView {
    width: 600px !important;
    z-index: 999;
}

@media only screen and (max-width: 850px) {
    .QuestionView {
        width: 100% !important;
    }
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) {
    .QuestionTable {
        width: calc(98.2% - 603px) !important;
    }
}

@media only screen and (min-width: 1401px) {
    .QuestionTable {
        width: calc(87.3% - 603px) !important;
    }
}

/*Question Page ICON END*/

/*Teacher Profile CSS Start*/

.profilePage {}

.profilePage .col-md-3 {
    padding-left: 50px;
}

.profilePage .wrapperProfileBox {
    background: #fff;
    padding: 20px 0px 10px 0px;
    border: 1px solid #c6c6c7;
    margin: 25px 10px 80px 10px;
    overflow-x: hidden;
    overflow-y: auto;
}

.profilePage .profilePic {
    width: 100px;
    height: 100px;
}

.profilePage .chngPicBtn {
    margin-top: 33px;
    overflow: hidden;
    position: relative;
    color: #0f6fb4;
    border: 1px solid;
    background: #fff;
    border-radius: 20px;
    text-transform: inherit;
    width: 117px !important;
    height: 37px !important;
    padding: 7px 0px 2px 0px;
    text-align: center;
    cursor: pointer;
}

.profilePage .chngPicBtn:hover {
    background-color: #0f6fb4;
    color: #fff;
}

.profilePage .chngPicBtn [type=file] {
    cursor: inherit;
    display: block;
    ;
    filter: alpha(opacity=0);
    min-height: 100%;
    min-width: 100%;
    opacity: 0;
    position: absolute;
    right: 0;
    text-align: right;
    top: 0;
}

.profilePage .reset {
    /* background: rgba(0, 0, 0, 0) url(/static/media/sprite-2x.425102e0.png) -319px -241px / 320px 320px repeat scroll !important; */
    /* background: #fff url(../images/reset.png); */
    background: #fff;
    margin-top: 33px;
    overflow: hidden;
    position: relative;
    color: orangered;
    border: 1px solid;
    border-radius: 20px;
    text-transform: inherit;
    width: 113px !important;
    height: 37px !important;
    padding: 0px 0px 2px 0px;
    text-align: center;
    cursor: pointer;
}

.profilePage .reset:hover {
    background-color: orangered;
    color: #fff;
}

.profilePage .resetImageDiv {
    margin-top: 17px;
    display: inline-block;
    vertical-align: middle;
    padding: 10px;
    border: 1px solid red;
    cursor: pointer;
}

@media only screen and (max-width: 415px) {
    .profilePage .hamza {
        left: -55px;
    }
}

@media only screen and (max-width: 415px) {
    .profilePage .resetImageDiv {
        padding: 1px;
    }
}

.profilePage .resetImageDiv:hover {
    background-color: #ffc0cb80;
    /* border-color: #ffc0cb80; */
}

.profilePage .resetImageDiv:hover .resetImage {
    /* background: #ffc0cb00 url(../images/reset.png)  0px 0px/50px 50px no-repeat; */
    background-color: #ffc0cb00;
}

.profilePage .resetImage {
    vertical-align: middle;
    display: inline-block;
    background: #fff url(../images/reset.png) 0px 0px/50px 50px no-repeat;
    width: 50px !important;
    height: 50px !important;
    padding: 0px 0px 2px 0px;
    text-align: center;
}

.profilePage .resetText {
    font-family: monospace;
    margin-left: 5px;
    color: red;
    font-weight: bold;
    /* color: white; */
}

.profilePage .hoverMessageHeading {
    color: #18A;
    display: contents;
    text-transform: uppercase;
    font-family: monospace
}

.profilePage .actionButtonclass span {
    font-size: 20px !important;
}

.resetHeader {
    background-color: #d9534f;
    color: white;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 5px;
}

.resetHeader .close {
    text-shadow: none;
    color: white;
}

.resetHeaderBody {
    text-align: center
}

.labelTextValue {}

.lblText {}

.profileRowBox {
    min-height: 70px;
    box-sizing: border-box;
    padding-top: 25px;
    border-bottom: 1px solid #e0e0e0;
}

.profileRowBox:last-child {
    border-bottom: none;
}

.profileRowBox .clrGrey {
    color: #909090
}

.profileRowBox .clrblue {
    color: #0f6fb4
}

.profilePage span.keywordTag {
    display: inline-block;
    padding: 7px 12px;
    border: 1px solid #d7d7d7;
    border-radius: 15px;
    color: #0f6fb4;
    margin-left: 10px;
    margin-bottom: 5px;
}

.profilePage span.keywordTag span.text-cross-icon {
    cursor: pointer;
    padding: 0px 3px;
}

.profilePage .edtBtn {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -376px -75px;
    display: inline-block;
    height: 30px;
    width: 30px;
    background-size: 500px 600px;
    border: 1px solid #c6c6c7;
    position: relative;
    right: -10px;
    top: -6px;
    margin-bottom: -15px;
    border-radius: 3px;
}

@media only screen and (max-width: 415px) {
    .profilePage .edtBtn {
        right: -195px;
        top: -180px;
    }
}

.profilePage .btn-saveBtn {
    background: #44a9f2;
    padding: 6px 10px;
    display: inline-block;
    color: #fff;
    border-radius: 4px;
    margin-right: 10px;
    margin-top: 5px;
}

.profilePage .btn-cancel {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
    padding: 6px 10px;
    display: inline-block;
    border-radius: 4px;
    margin-right: 10px;
    margin-top: 5px;
}

.profilePage .selectWidth {
    width: 240px;
    position: relative;
    z-index: 2;
    float: left;
}

.profilePage .form-group {
    margin-bottom: 0px;
    margin-top: -15px;
    display: none;
}

.profilePage .selectWidth .form-control {
    background: #fbfbfb;
    height: 48px;
    border: 1px solid #e1e1e1;
    color: #909090;
    font-size: 14px;
}

.profilePage .form-group a.Activity-toolTip {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -467px -185px;
    display: inline-block;
    height: 25px;
    width: 25px;
    margin-top: 13px;
    background-size: 500px 600px;
    margin-left: 11px;
}

.profilePage .labelTextValue a.Activity-toolTip {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -467px -185px;
    display: inline-block;
    height: 25px;
    width: 25px;
    background-size: 500px 600px;
    margin-bottom: -10px;
    position: relative;
    top: -4px;
}

.fixedHeight {
    max-height: 150px;
    margin-bottom: 10px;
    overflow-x: hidden;
    overflow-y: auto;
}

@media only screen and (max-width: 767px) {
    .profilePage .col-md-3 {
        padding-left: 15px;
    }
    .labelTextValue {
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .defaultKeywords {
        margin-top: 10px
    }
    .profilePage .wrapperProfileBox {
        padding: 20px 10px 10px 10px;
        margin: 25px 0px 80px 0px;
    }
    .profilePage .row {
        margin: 0px -10px;
    }
}

/*Teacher Profile CSS End*/

/*Manage class Table CSS starts*/

.BcrumNsteps h3 {
    font-size: 20px;
    color: #1c1c1c;
    margin-top: 20px;
    padding-left: 5px;
    font-weight: 400;
}

ul.topBcumbsLinks {
    margin-top: 10px;
}

ul.topBcumbsLinks li {
    color: #909090;
    display: inline-block;
}

ul.topBcumbsLinks li:first-child {
    color: #0f6fb4;
    margin-right: 5px
}

ul.topBcumbsLinks li:last-child {
    margin-left: 5px;
}

button.crtNewClass {
    background: #fff;
    height: 40px;
    width: 150px;
    border: 1px solid #0f6fb4;
    color: #0f6fb4;
    border-radius: 3px;
}

.font10 {
    font-size: 10px !important;
}

.manageClassTable {
    margin-top: 15px
}

.manageClassTable .selectWidth {
    width: 100% !important
}

.manageClassTable .selectWidth .form-control {
    background: transparent;
    height: 35px;
    border: 1px solid #e1e1e1;
    color: #909090;
    font-size: 14px;
}

.manageClassTable .selectArrow {
    background: transparent var(--sprite2-url) no-repeat scroll -234px -11px;
    position: absolute;
    right: 13px;
    top: 9px;
    width: 19px;
    height: 16px;
    display: inline-block;
    background-size: 500px 600px;
    z-index: -1;
}

.manageClassTable tr td, .manageClassTable tr th, .manageClassTable tr th {
    height: 65px;
    vertical-align: middle !important;
    padding-left: 20px !important
}

.manageClassTable .ViewBtn {
    background: #fff;
    height: 35px;
    width: 80px;
    border: 1px solid #0f6fb4;
    color: #0f6fb4;
    border-radius: 3px;
}

.manageClassTable.table>thead>tr>th {
    border-bottom: none;
}

@media only screen and (max-width: 1015px) {
    .respScrollTable {
        width: 100%;
        overflow: auto
    }
    .respScrollTable table {
        width: 1015px;
    }
    .introRow .selectWidth {
        max-width: 49%;
    }
    ul.topBcumbsLinks li {
        margin-bottom: 15px;
    }
    .mainFormBox {
        margin: 25px 10px 30px 10px;
    }
}

@media only screen and (max-width: 590px) {
    .BcrumNsteps h3 {
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
    }
    button.crtNewClass {
        width: 100%
    }
}

@media only screen and (max-width: 390px) {
    ul.topBcumbsLinks li span {
        white-space: nowrap;
        width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
    }
}

.BcrumNsteps h3 {
    font-size: 20px;
    color: #1c1c1c;
    margin-top: 20px;
    padding-left: 5px;
    font-weight: 400;
}

ul.topBcumbsLinks {
    margin-top: 10px;
}

ul.topBcumbsLinks li {
    color: #909090;
    display: inline-block;
}

ul.topBcumbsLinks li:first-child {
    color: #0f6fb4;
    margin-right: 5px
}

ul.topBcumbsLinks li:last-child {
    margin-left: 5px;
}

button.crtNewClass, button.removeAllBtn {
    background: #fff;
    height: 40px;
    width: 150px;
    border: 1px solid #0f6fb4;
    color: #0f6fb4;
}

.font10 {
    font-size: 10px !important;
}

#manageStudentBox {
    margin-top: 20px;
    margin-bottom: 80px
}

#manageStudentBox .active {
    color: #0f6fb4 !important;
    font-weight: 500 !important;
}

#manageStudentBox ul {
    margin-top: 10px;
}

#manageStudentBox ul li {
    color: #909090;
    display: inline-block;
}

#manageStudentBox ul li span {
    font-size: 15px !important;
    cursor: pointer;
}

#manageStudentBox ul li:first-child {
    margin-right: 5px
}

#manageStudentBox ul li:last-child {
    margin-left: 5px;
}

.manageClassTable {
    margin-top: 15px
}

.manageClassTable .selectWidth {
    width: 100% !important
}

.manageClassTable .selectWidth .form-control {
    background: transparent;
    height: 35px;
    border: 1px solid #e1e1e1;
    color: #909090;
    font-size: 14px;
}

.manageClassTable .selectArrow {
    background: transparent var(--sprite2-url) no-repeat scroll -234px -11px;
    position: absolute;
    right: 13px;
    top: 9px;
    width: 19px;
    height: 16px;
    display: inline-block;
    background-size: 500px 600px;
    z-index: -1;
}

.introRow .selectWidth {
    width: 50%;
    float: right;
    max-width: 48%;
}

.introRow .selectWidth .form-control {
    background: transparent;
    height: 35px;
    border: 1px solid #e1e1e1;
    color: #909090;
    font-size: 14px;
}

.introRow .selectArrow {
    background: transparent var(--sprite2-url) no-repeat scroll -234px -11px;
    position: absolute;
    right: 7px;
    top: 9px;
    width: 19px;
    height: 16px;
    display: inline-block;
    background-size: 500px 600px;
    z-index: -1;
}

.manageClassTable tr th:last-child {
    padding-right: 20px;
}

.manageClassTable tr td:last-child {
    padding-right: 23px;
}

.manageClassTable tr td, .manageClassTable tr th, .manageClassTable tr th {
    height: 65px;
    vertical-align: middle !important;
    padding-left: 20px !important
}

.manageClassTable .ViewBtn {
    background: #fff;
    height: 35px;
    width: 80px;
    border: 1px solid #0f6fb4;
    color: #0f6fb4;
}

.manageClassTable.table>thead>tr>th {
    border-bottom: none;
}

.m-r-10 {
    margin-right: 10px;
}

.editPeriodText {
    position: relative;
}

.editPeriodText span {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -376px -75px;
    display: inline-block;
    height: 30px;
    width: 30px;
    background-size: 500px 600px;
    border: 1px solid #c6c6c7;
    position: relative;
    right: -10px;
    top: -6px;
    margin-bottom: -15px;
    border-radius: 3px;
}

@media only screen and (max-width: 1015px) {
    .respScrollTable {
        width: 100%;
        overflow: auto
    }
    .respScrollTable table {
        width: 1015px;
    }
    /*.introRow .selectWidth { max-width: 48%; float: left }*/
    /*.introRow .selectWidth:last-child { float: right; }*/
    ul.topBcumbsLinks li {
        margin-bottom: 15px;
    }
    .mainFormBox {
        margin: 10px;
    }
    .introRow .selectWidth .form-control {
        padding-right: 25px;
    }
}

@media only screen and (max-width: 590px) {
    /*.introRow .selectWidth { max-width: 47%; float: left }*/
    /*.introRow .selectWidth:last-child { float: right }*/
}

@media only screen and (max-width: 390px) {
    ul.topBcumbsLinks li span {
        white-space: nowrap;
        width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
    }
}

/*Manage class Table CSS ends*/

/* Login CSS START */

.loginPage .topBar {
    background: #0f6fb4;
    height: 22px;
    border-bottom: 15px solid #fff;
}

.loginPage h2.form-signin-heading {
    font-size: 30px;
    color: #5f5f5f;
}

.loginPage .wrapper {
    margin-top: 20px;
    margin-bottom: 80px;
}

.loginPage .form-signin {
    max-width: 480px;
    padding: 35px;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.loginPage .form-signup {
    max-width: 800px;
    padding: 35px;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.loginPage span.keywordTag {
    display: inline-block;
    padding: 7px 12px;
    border: 1px solid #d7d7d7;
    border-radius: 15px;
    color: #0f6fb4;
    margin-top: 10px;
    margin-right: 10px;
}

.loginPage span.keywordTag span.text-cross-icon {
    cursor: pointer;
    padding: 0px 3px;
}

.loginPage .selectArrow {
    background: transparent var(--sprite2-url) no-repeat scroll -234px -11px;
    position: absolute;
    right: 20px;
    top: 13px;
    width: 19px;
    height: 16px;
    display: inline-block;
    background-size: 500px 600px;
}

.loginPage .form-signin-heading, .checkbox {
    margin-bottom: 30px;
}

.loginPage .form-control {
    position: relative;
    font-size: 16px;
    height: auto;
    padding: 10px;
}

/*.loginPage input[type="text"] { margin-bottom: 17px; bheight: 50px;background: #f9f9f9; border-radius:3px;font-size:15px;}*/

.loginPage input[type="text"] {
    margin-bottom: 17px;
    bheight: 50px;
    border-radius: 3px;
    font-size: 15px;
}

.loginPage input[type="password"] {
    margin-bottom: 20px;
    height: 50px;
    border-radius: 3px;
    font-size: 15px;
}

.loginPage .LogoAnch {
    display: block;
    margin: 0 auto
}

.loginPage .LogoAnch .logoClass {
    display: block;
    margin: 0 auto 20px auto
}

.loginPage input[type=checkbox].css-checkbox {
    position: absolute;
    z-index: -1000;
    left: -1000px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

.loginPage input[type=checkbox].css-checkbox+label.css-label {
    padding-left: 32px;
    height: 35px;
    display: inline-block;
    line-height: 21px;
    background-repeat: no-repeat;
    background-position: -470px -3px;
    vertical-align: middle;
    cursor: pointer;
    font-weight: normal;
    background-size: 500px 600px;
}

.loginPage input[type=checkbox].css-checkbox:checked+label.css-label {
    background-position: -470px -36px;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.loginPage label.css-label {
    background-image: var(--sprite2-url);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #909090;
}

.loginPage button.btn.btn-lg.btn-primary.btn-block {
    border-radius: 3px;
    margin-top: 15px;
}

.loginPage button.btn-register {
    width: 100px;
    margin-left: 10px;
}

.loginPage .lastAncBlock {
    margin-top: -185px;
    text-align: center;
}

.loginPage .lastAncBlock .achForm {
    color: #0f6fb4;
    display: inline-block;
}

/* Login CSS END */

/* NEW STUDENT PORTAL CSS **/

/*Main Css*/

.margintoppop {
    margin: 25px 0 0;
}

.marginall {
    margin: 0px 0 20px;
}

.padfif {
    padding: 0px 15px !important;
}

.paddnone {
    padding: 0px !important;
}

.margnnone {
    margin: 0px !important;
}

.text-alignlft {
    text-align: left !important;
}

.text-alignlftdesk {
    text-align: left !important;
}

.text-alignrghdesk {
    text-align: right !important;
}

.text-alignrght {
    text-align: right !important;
}

/*.resmain{ display: none !important; }*/

.bordern {
    border: 0px solid #fff !important;
}

.colorblue {
    color: #0f6fb4;
}

.colorgrey {
    color: #555;
}

.colorgreen {
    color: #138724
}

.coloryekllow {
    color: #dcb359
}

.fontszthr {
    font-size: 12px;
}

.float-lft {
    float: left;
}

.float-rghth {
    float: right;
}

.marginrgt {
    margin-right: 0px !important;
}

.bordernrg {
    border-right: 0px !important;
}

/*.overflww{ overflow: hidden !important; }*/

.paddrhnone {
    padding-right: 0px !important;
}

.margin-tttp {
    margin-top: 0px !important;
}

.text-center {
    text-align: center;
}

/*Main Css*/

/*#row-0-child > td {*/

/*padding-left: 0;*/

/*padding-right: 0;*/

/*width: 100%;*/

/*}*/

#row-0-child .innerTableQues {
    width: 100%;
}

/*tr.booksRow h4, .subGlobHistory.h4, tr.subAlgebra.h4, tr.subUSHistory.h4, tr.subGeometry.h4, tr.subErthSci.h4, tr.subLivEnvi.h4, tr.learning h4, tr.flashCard h4, tr.questionRow h4,tr.learning td div[elipsis="true"], tr.flashCard td div[elipsis="true"], tr.questionRow td div[elipsis="true"]{

    width: 100%;

    white-space: nowrap;

    overflow: hidden;

    text-overflow: ellipsis;

}*/

.lastQuesRedirectStudent .submit {
    font-size: 16px;
    padding-top: 41px;
    text-align: center;
    margin: 3px 10px 10px 0px;
    display: inline-block;
    width: 53px;
    height: 50px;
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -539px -387px / 430px 700px;
}

.lastQuesRedirectStudent .print {
    font-size: 16px;
    padding-top: 41px;
    text-align: center;
    margin: 3px 10px 10px 0px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 36px;
    height: 50px;
    background: url("../images/print.png");
}

.lastQuesRedirectStudent .hint {
    font-size: 16px;
    padding-top: 41px;
    text-align: center;
    margin: 3px 10px 10px 0px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 36px;
    height: 50px;
    background: url("../images/hint.png");
}

.translate {
    padding: 0px 50px 14px 0px;
    display: inline-block;
}

.lastQuesRedirectStudent .pause {
    font-size: 16px;
    padding-top: 41px;
    text-align: center;
    margin: 3px 10px 10px 0px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 42px;
    height: 50px;
    background: url("../images/pause.png");
}

/*.lastQuesRedirectStudent .nextQues{font-size: 16px; padding-top: 41px; text-align:center;margin: 3px 0px 10px 0;display: inline-block; white-space: nowrap; overflow: hidden;text-overflow: ellipsis; width: 36px; height: 55px;  background:rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -546px -174px / 430px 700px; }

.lastQuesRedirectStudent .prevQues{font-size: 16px; padding-top: 41px;  text-align:center;margin: 3px 10px 10px 0px;display: inline-block; white-space: nowrap; overflow: hidden;text-overflow: ellipsis; width: 36px; height: 55px; background:rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -546px -120px / 430px 700px; }*/

.anchorLeftBox.leftBoxWidthMinimize .ArrowExpand {
    width: 44px;
    height: 70px;
    background: transparent var(--student-sprite-url) repeat scroll -252px -244px / 430px 700px;
    left: 5px;
    top: 0px;
}

.anchorLeftBox .ArrowExpand {
    background: white var(--student-sprite-url) repeat scroll -252px -281px / 430px 700px;
    display: inline-block;
    height: 37px;
    width: 35px;
    position: absolute;
    right: 18px;
    top: 12px;
    cursor: pointer;
}

span.subRowToggleStudent {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -263px -152px / 450px 659px;
    display: inline-block;
    height: 27px;
    left: 5px;
    position: relative;
    top: -13px;
    width: 35px;
}

span.subRowToggleStudent.minus {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -262px -200px / 450px 659px;
}

.submitres {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -539px -387px / 430px 700px;
    display: inline-block;
    font-size: 16px;
    height: 55px;
    margin: 13px 10px 10px;
    overflow: hidden;
    padding-top: 41px;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 53px;
}

.printres {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -546px -281px / 430px 700px;
    display: inline-block;
    font-size: 16px;
    height: 55px;
    margin: 13px 0 0;
    overflow: hidden;
    padding-top: 41px;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 36px;
}

.filterBlockss .typeIconsscience {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -203px -228px / 500px 800px;
    display: inline-block;
    float: left;
    height: 49px;
    left: 0;
    margin-right: 6px;
    position: relative;
    top: 1px;
    width: 52px;
}

.filterBlockss .typeIconsgeometry {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -203px -424px / 500px 800px;
    display: inline-block;
    float: left;
    height: 49px;
    left: 0;
    margin-right: 6px;
    position: relative;
    top: 1px;
    width: 52px;
}

.filterBlockss .typeIconshistory {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -220px -917px / 500px 600px;
    display: inline-block;
    float: left;
    height: 49px;
    left: 0;
    margin-right: 6px;
    position: relative;
    top: 1px;
    width: 52px;
}

.filterBlockss .typeIconsenvironment {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -203px -156px / 500px 800px;
    display: inline-block;
    float: left;
    height: 49px;
    left: 0;
    margin-right: 6px;
    position: relative;
    top: 1px;
    width: 52px;
}

.filterBlockss .typeIconshistory {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -203px -293px / 500px 800px;
    display: inline-block;
    float: left;
    height: 49px;
    left: 0;
    margin-right: 6px;
    position: relative;
    top: 1px;
    width: 52px;
}

a.crooss {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -1260px -404px / 350px 650px;
    display: inline-block;
    float: right;
    font-size: 16px;
    height: 20px;
    margin-top: 7px;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 22px;
}

.filterBlockun input.css-checkbox[type="checkbox"]:checked+label.css-label5 {
    background-position: -472px -39px;
}

.filterBlockun label.css-label6 {
    -moz-user-select: none;
    background-image: var(--student-sprite-url);
    background-position: -424px -4px;
    background-repeat: no-repeat;
    background-size: 500px 600px;
    color: #555555;
    cursor: pointer;
    display: inline-block;
    font-size: 15px;
    font-weight: normal;
    height: 24px;
    line-height: 22px;
    padding-left: 30px;
    vertical-align: middle;
    float: left;
}

.filterBlockun input.css-checkbox[type="checkbox"]+label.css-label6 {
    background-position: -428px -581px;
    background-repeat: no-repeat;
    background-size: 450px 750px;
    color: #555555;
    cursor: pointer;
    display: inline-block;
    float: left;
    font-size: 15px;
    font-weight: normal;
    height: 24px;
    line-height: 22px;
    padding-left: 22px;
    vertical-align: middle;
    margin-right: 20px;
}

.filterBlockun input.css-checkbox[type="checkbox"]:checked+label.css-label6 {
    background-position: -424px -126px;
}

.filterBlockun label.css-label7 {
    -moz-user-select: none;
    background-image: var(--student-sprite-url);
}

.filterBlockun input.css-checkbox[type="checkbox"]+label.css-label7 {
    margin-right: 20px;
    background-position: -428px -626px;
    background-repeat: no-repeat;
    background-size: 450px 750px;
    color: #555555;
    cursor: pointer;
    display: inline-block;
    float: left;
    font-size: 15px;
    font-weight: normal;
    height: 24px;
    line-height: 22px;
    padding-left: 22px;
    vertical-align: middle;
}

.filterBlockun input.css-checkbox[type="checkbox"]:checked+label.css-label7 {
    background-position: -424px -126px;
}

.filterBlockun label.css-label8 {
    -moz-user-select: none;
    background-image: var(--student-sprite-url);
}

.filterBlockun input.css-checkbox[type="checkbox"]+label.css-label8 {
    background-position: -428px -538px;
    background-repeat: no-repeat;
    background-size: 450px 750px;
    color: #555555;
    cursor: pointer;
    display: inline-block;
    float: left;
    font-size: 15px;
    font-weight: normal;
    height: 24px;
    line-height: 22px;
    margin-right: 20px;
    padding-left: 22px;
    vertical-align: middle;
}

.filterBlockun input.css-checkbox[type="checkbox"]:checked+label.css-label8 {
    background-position: -428px -538px;
}

.filterBlockss .facesmile {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -438px -354px / 430px 700px;
    display: inline-block;
    float: left;
    height: 49px;
    left: 0;
    margin-right: 6px;
    position: relative;
    top: -3px;
    width: 42px;
}

.filterBlockss .faceoff {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -438px -431px / 430px 700px;
    display: inline-block;
    float: left;
    height: 49px;
    left: 0;
    margin-right: 6px;
    position: relative;
    top: -3px;
    width: 42px;
}

.filterBlockss .facemood {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -438px -372px / 430px 700px;
    display: inline-block;
    float: left;
    height: 49px;
    left: 0;
    margin-right: 6px;
    position: relative;
    top: -3px;
    width: 42px;
}

.btngl {
    background: transparent var(--student-sprite-url) repeat scroll -201px -237px / 350px 500px;
    display: inline-block;
    height: 27px;
    left: 0;
    position: relative;
    top: 6px;
    width: 35px;
}

.filterBlockss .typeIconsorn {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -64px -56px / 470px 700px;
    display: inline-block;
    float: left;
    height: 49px;
    left: 0;
    margin-right: 6px;
    position: relative;
    top: 1px;
    width: 52px;
}

.typeIconsorn {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -64px -56px / 470px 700px;
    display: inline-block;
    float: left;
    height: 49px;
    left: 0;
    margin-right: 6px;
    position: relative;
    top: 1px;
    width: 52px;
}

.filterBlockss .typeIconPrac {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -131px -56px / 470px 700px;
    display: inline-block;
    float: left;
    height: 49px;
    left: 0;
    margin-right: 6px;
    position: relative;
    top: 1px;
    width: 52px;
}

.typeIcondesk {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll 0 -150px / 460px 719px;
    display: inline-block;
    height: 49px;
    left: 0;
    margin-right: 15px;
    position: relative;
    top: 1px;
    width: 52px;
}

.typeIcondesk2 {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll 0 -271px / 860% 1490%;
    display: inline-block;
    height: 49px;
    left: 0;
    margin-right: 15px;
    position: relative;
    top: 1px;
    width: 52px;
}

/** STUDENT PORTAL PRACTICE PAGE CSS **/

/*Practice*/

#SelfStudy .pacbox {
    border-radius: 3px;
    box-shadow: 0 0 2px 0 #8e8e8e;
    display: inline-block;
    float: none;
    padding: 15px;
    width: 100%;
    margin-bottom: 32px;
}

#SelfStudy .pacbox a {
    color: #555
}

#SelfStudy .pacbox a:focus {
    color: #0f6fb4;
    outline: none;
    text-decoration: none;
}

#SelfStudy .filterBlockss .typeIconsscience {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -220px -768px / 500px 600px;
    display: inline-block;
    float: left;
    height: 49px;
    left: 0;
    margin-right: 6px;
    position: relative;
    top: 1px;
    width: 52px;
}

#SelfStudy .filterBlockss .typeIconsgeometry {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -220px -986px / 500px 600px;
    display: inline-block;
    float: left;
    height: 49px;
    left: 0;
    margin-right: 6px;
    position: relative;
    top: 1px;
    width: 52px;
}

#SelfStudy .filterBlockss .typeIconshistory {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -220px -917px / 500px 600px;
    display: inline-block;
    float: left;
    height: 49px;
    left: 0;
    margin-right: 6px;
    position: relative;
    top: 1px;
    width: 52px;
}

#SelfStudy .filterBlockss .typeIconsenvironment {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -220px -847px / 500px 600px;
    display: inline-block;
    float: left;
    height: 49px;
    left: 0;
    margin-right: 6px;
    position: relative;
    top: 1px;
    width: 52px;
}

.font-size>#SelfStudy .filterBlockss .typeIconshistory {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -220px -537px / 500px 600px;
    display: inline-block;
    float: left;
    height: 49px;
    left: 0;
    margin-right: 6px;
    position: relative;
    top: 1px;
    width: 52px;
}

#SelfStudy .filterBlockss .contentType .headsp {
    color: #0f6fb4;
    font-size: 18px;
    line-height: 29px;
}

#SelfStudy .smallblbx {
    color: #0f6fb4;
    font-size: 13px;
}

#SelfStudy a.smallblbx {
    color: #0f6fb4;
    text-decoration: none;
}

#SelfStudy span.subRowToggle2 {
    background: transparent var(--student-sprite-url) repeat scroll -314px -176px / 488px 600px;
    display: inline-block;
    height: 27px;
    position: absolute;
    right: 17px;
    top: 35px;
    width: 35px;
    z-index: 99999;
}

#SelfStudy span.subRowToggle2.minus {
    background: transparent var(--student-sprite-url) repeat scroll -313px -232px / 488px 600px;
}

#SelfStudy ul.topBcumbsLinks {
    margin-top: 10px;
}

#SelfStudy ul.topBcumbsLinks li:first-child {
    color: #0f6fb4;
    margin-right: 5px;
}

#SelfStudy ul.topBcumbsLinks li {
    color: #909090;
    display: inline-block;
}

#SelfStudy ul.topBcumbsLinks li:first-child {
    color: #0f6fb4;
}

#SelfStudy .btnrac {
    background-color: #337ab7;
    border-color: #2e6da4;
    color: #fff;
    border-style: solid none;
    border-width: 1px medium;
    font-weight: bold;
    height: 34px;
    margin-bottom: 0;
    margin-top: 0;
    padding: 0 0 0 30px;
    text-align: left;
    width: 100%;
}

#SelfStudy .leftsi {
    border-bottom: 1px solid #ccc;
    float: left;
    padding: 20px 0;
    width: 100%;
}

#SelfStudy a.blubtnpops {
    background: #43a9f1 none repeat scroll 0 0;
    border-radius: 5px;
    color: #fff;
    float: right;
    font-size: 15px;
    margin-top: 10px;
    padding: 0 10px;
}

#SelfStudy .norstyb {
    background: #f1f9ff none repeat scroll 0 0;
    border-color: currentcolor currentcolor #ccc;
    border-style: none none solid;
    border-width: medium medium 1px;
    font-weight: bold;
    height: 60px;
    margin-bottom: 1px;
    margin-top: 0;
    padding: 0 0 0 30px;
    text-align: left;
    line-height: 56px;
    width: 100%;
}

#SelfStudy a.crooss {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -782px -36px / 500px 600px;
    display: inline-block;
    float: right;
    font-size: 16px;
    height: 20px;
    margin-top: 7px;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 22px;
}

#SelfStudy .maincencl {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #ccc;
    border-radius: 6px;
    float: none;
    margin: 33px auto;
    width: 95%;
    padding: 1%;
    max-width: 1400px
}

#SelfStudy .NiceScroll .eleRow {
    border-bottom: 1px solid #dcdcdd;
    display: block;
    float: left;
    line-height: 40px;
    padding: 14.5px 10px;
    width: 100%;
}

/** STUDENT PORTAL ASSIGNMENTS PAGE CSS **/

.maincolblu {
    background: #f1f9ff none repeat scroll 0 0;
    border: 1px solid #0f6fb4;
    border-radius: 12px;
    color: #505050;
    display: block;
    font-size: 13px;
    line-height: 20px;
    margin: 0;
    text-align: center;
    width: 129px;
}

.btn-sta {
    background: #44a9f2 none repeat scroll 0 0;
    border: medium none;
    border-radius: 18px;
    color: #fff;
    display: block;
    font-size: 14px;
    margin: 0;
    padding: 6px 0;
    text-align: center;
    width: 80px;
}

.maincolorng {
    background: #ff7044 none repeat scroll 0 0;
    border-radius: 12px;
    color: #fff;
    display: block;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    text-align: center;
    width: 129px;
}

span.subRowToggle1 {
    background: transparent var(--student-sprite-url) repeat scroll -314px -176px / 488px 600px;
    display: inline-block;
    height: 27px;
    position: absolute;
    z-index: 99999;
    right: -35px;
    top: -6px;
    width: 50px;
}

span.subRowToggle1.minus {
    background: transparent var(--student-sprite-url) repeat scroll -313px -232px / 488px 600px;
}

/*Deskboad*/

#dashboard .pacboxdes {
    border-radius: 1px;
    box-shadow: 0 0 1px 0 #555;
    display: inline-block;
    float: left;
    margin: 15px;
    padding: 0px;
}

#dashboard .pacboxdes p {
    color: #555;
    float: left;
    line-height: 21px;
    margin: 19px 0;
    padding: 0;
    text-align: left;
}

#dashboard a.boardhead {
    color: #337ab7;
    margin-top: 10px;
    float: left;
    width: 100%;
    text-align: left;
}

#dashboard a.boardheadblf {
    color: #000;
    float: left;
    font-size: 17px;
    margin-top: 26px;
    text-align: left;
    margin-left: 6px;
    width: 100%;
    font-weight: bold;
}

#dashboard a.boardheadact {
    color: #000;
    float: left;
    font-size: 17px;
    margin: 22px 0px 15px 0px;
    text-align: left;
    width: 100%;
}

#dashboard a.lghtblfg {
    color: #43a8ee;
    float: left;
    line-height: 35px;
    text-align: left;
    width: 100%;
}

#dashboard a.boardheadgrey {
    clear: both;
    color: #555;
    float: left;
    font-size: 15px;
    line-height: 24px;
    text-align: left;
    width: 100%;
}

#dashboard a.lghtblfgmng {
    color: #337ab7;
    float: left;
    line-height: 35px;
    text-align: right;
    width: 100%;
}

#dashboard .barchrtlghtblde {
    background: #3fabf4 none repeat scroll 0 0;
    border-radius: 120px;
    height: 9px;
    line-height: 41px;
    margin-top: 9px;
    text-align: center;
}

#dashboard .barchrtlghtblde::after {
    background: rgba(0, 0, 0, 0.3) none repeat scroll 0 0;
    border-radius: 10px;
    content: " ";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 4em;
}

#dashboard .pacboxdes .form-control {
    margin-top: 15px;
}

#dashboard .brdrds {
    border-top: 1px solid #ccc;
    float: left;
    margin: 6px 0 7px 0px;
    width: 100%;
}

#dashboard .lsthed {
    color: #337ab7;
    float: left;
    padding: 10px 0 25px;
    width: 100%;
}

#dashboard .typeIcondesk {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -121px -220px / 500px 600px;
    display: inline-block;
    height: 49px;
    left: 0;
    margin-right: 15px;
    position: relative;
    top: 1px;
    width: 52px;
}

#dashboard .typeIcondesk1 {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll 0 -71px / 500px 600px;
    display: inline-block;
    height: 49px;
    left: 0;
    margin-right: 15px;
    position: relative;
    top: 1px;
    width: 52px;
}

#dashboard .typeIcondesk2 {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -121px -220px / 500px 600px;
    display: inline-block;
    height: 49px;
    left: 0;
    margin-right: 15px;
    position: relative;
    top: 1px;
    width: 52px;
}

#dashboard .pacboxdes .limitFiltersas {
    float: left;
    margin: 10px 0 9px;
}

#dashboard .icondes {
    float: left;
    margin-right: 2%;
    width: 56px;
}

#dashboard .icondeshead {
    float: left;
    margin-right: 1%;
    width: 54%;
}

#dashboard .icondesheadin {
    float: left;
    margin-right: 0%;
    width: 75%;
}

#dashboard .icondesheadsel {
    float: right;
    margin-right: 0;
    width: 26%;
    font-size: 11px;
}

/** MAIN STUDENT PORTAL ASSIGNMENT **/

.filterBlockss {
    background: #fff !important;
    border-right: 1px solid #ddd;
    padding: 0px;
}

.setHeight {
    height: calc(100% - 171px);
}

.setHeightFullScreen {
    height: 100% !important;
}

/* .filterBlockss .SubjectBox {
    padding-left: 18px;
    margin-top: -1px;
} */

.filterBlockss button {
    background: #f1f9ff none repeat scroll 0 0;
    border-color: currentcolor currentcolor #ccc;
    border-style: none none solid;
    border-width: medium medium 1px;
    font-weight: bold;
    height: 60px;
    margin-bottom: 1px;
    margin-top: 0;
    padding: 0 0 0 30px;
    text-align: left;
    width: 100%;
}

.filterBlockss h3 {
    /*float: left;*/
    width: 100%;
    height: 60px;
    background: #f1f9ff;
    border-bottom: 1px solid #ccc;
    padding: 20px 0px 0px 19px;
    margin-top: 0;
    margin-bottom: 15px;
}

.filterBlockss .typeIcons {
    background:  var(--logo2) repeat scroll;
    display: inline-block;
    float: left;
    height: 65px;
    left: 0;
    margin-right: 6px;
    position: relative;
    top: 4px;
    width: 52px;
}

.filterBlockss .typeIconsalgebra {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -203px -434px / 500px 740px;
    display: inline-block;
    float: left;
    height: 49px;
    left: 0;
    margin-right: 6px;
    position: relative;
    top: 1px;
    width: 52px;
}

.filterBlockss .typeIconsUSHistory {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -203px -503px / 500px 740px;
    display: inline-block;
    float: left;
    height: 49px;
    left: 0;
    margin-right: 6px;
    position: relative;
    top: 1px;
    width: 52px;
}

.filterBlockss .contentType {
    display: inline-block;
    /* margin-top: 10px; */
}

.filterBlockss .contentType h4 {
    color: white;
    font-size: 16px;
    margin-bottom: 6px;
}

.filterBlockss .limitFilters {
    /* margin: 10px 0 10px; */
    /* background: rgb(15, 111, 180); */
    overflow: hidden;
}

.filterBlockss .limitFiltersss {
    /* margin: 10px 0 10px; */
    padding-bottom: 5px;
    overflow: hidden;
    background: #337ab7;
}

.filterBlockss .contentTypeSS h4 {
    color: white;
    font-size: 16px;
    margin-bottom: 6px;
    margin-top: 25px
}

.filterBlockss .caret {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px dashed;
    display: inline-block;
    height: 0;
    margin-right: 15px;
    margin-top: 5px;
    position: absolute;
    right: 0;
    vertical-align: middle;
    width: 0;
}

.filterBlockss button.dropdown .caret {
    border-top: 8px dashed !important;
}

.filterBlockss button.dropup .caret {
    border-bottom: 8px dashed !important;
}

.filterBlockss label.css-label1 {
    -moz-user-select: none;
    clear: both;
    display: block;
    float: left;
    /* width: 66%; */
    margin: 15px 0 15px;
    text-align: left;
    background: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.quesAndIconStudent {
    /* padding: 25px 10px; */
    width: 100%;
}

.quesAndIcongl {
    border-top: 1px solid #ddd;
    float: left;
    margin-top: 10px;
    padding: 0 10px;
    width: 100%;
}

.filterBlockss .maincolgreen {
    background: #ebffeb none repeat scroll 0 0;
    border: 1px solid #8bba8b;
    border-radius: 12px;
    color: #505050;
    display: block;
    float: right;
    font-size: 14px;
    line-height: 19px;
    margin: 18px 0px 0 0;
    text-align: center;
}

.filterBlockss .maincolblus {
    border: 1px solid #0f6fb4;
    border-radius: 12px;
    color: #0f6fb4;
    display: block;
    float: right;
    font-size: 14px;
    line-height: 22px;
    margin: 12px 18px 0 0;
    padding: 2px 10px;
    text-align: center;
    width: 69px;
    cursor: pointer;
}

.filterBlockss .maincolblusr {
    background: #44a9f2 none repeat scroll 0 0;
    border: 1px solid #0f6fb4;
    border-radius: 32px;
    color: #fff;
    display: block;
    float: left;
    font-size: 14px;
    line-height: 21px;
    margin: 14px 18px 0 10px;
    padding: 0 6px;
    text-align: center;
    display: none;
}

.pay_label:hover+.maincolblusr {
    display: block;
}

.limitFilters a {
    color: #555;
}

.limitFilters a:focus {
    color: #0f6fb4;
    outline: none;
    text-decoration: none;
}

.maincolblu {
    background: #f1f9ff none repeat scroll 0 0;
    border: 1px solid #0f6fb4;
    color: #505050;
    font-size: 13px;
    border-radius: 12px;
    display: block;
    margin: 0;
    text-align: center;
    width: 129px;
    line-height: 20px;
}

.maincolgreen {
    background: #ebffeb none repeat scroll 0 0;
    border: 1px solid #8bba8b;
    color: #505050;
    font-size: 14px;
    border-radius: 12px;
    display: block;
    margin: 0;
    text-align: center;
    width: 100px;
}

.maincolorng {
    background: #ff7044 none repeat scroll 0 0;
    color: #fff;
    font-size: 14px;
    border-radius: 12px;
    display: block;
    margin: 0;
    text-align: center;
    width: 129px;
    line-height: 20px;
}

.btn-sta {
    background: #44a9f2 none repeat scroll 0 0;
    border: none;
    color: #fff;
    font-size: 14px;
    border-radius: 18px;
    display: block;
    margin: 0;
    text-align: center;
    width: 80px;
    padding: 6px 0px
}

.btn-sta:hover {
    background: #44a9f2 none repeat scroll 0 0;
    text-decoration: none;
}

.btn-sta-spec {
    background: #44a9f2 none repeat scroll 0 0;
    border: none;
    color: #fff;
    font-size: 14px;
    border-radius: 18px;
    display: block;
    margin: 0;
    text-align: center;
    width: 80px;
    padding: 6px 0px;
    margin: 0 0 0 -3px;
    left: 21px;
    position: relative;
}

.BcrumNsteps {
    background: rgb(15, 111, 180);
    color: #fff;
}

.BcrumNsteps .breadCrumbs {
    padding-left: 8px;
}

.BcrumNsteps .breadCrumbs ul {
    padding: 5px 0px;
}

.BcrumNsteps .breadCrumbs ul li {
    display: inline-block;
}

.BcrumNsteps .breadCrumbs ul li a {
    font-size: 14px;
    color: #fff;
    position: relative;
    margin-right: 23px;
    display: block;
}

.BcrumNsteps .breadCrumbs ul li span.currentPage {
    font-size: 14px;
    color: #fff;
}

.BcrumNsteps .breadCrumbs ul li a:after {
    content: "»";
    /* background: transparent var(--student-sprite-url) no-repeat scroll -220px -6px; */
    position: inherit;
    right: -10px;
    top: 0px;
    width: 19px;
    height: 16px;
    transform: rotate(270deg);
    background-size: 500px 600px;
}

/* Bcrumb */

.BcrumNsteps {
    background: rgb(15, 111, 180);
    color: #fff;
    /* height: 64px; */
    /*border-bottom: 1px solid #dddedf;*/
}

.BcrumNsteps .breadCrumbs {
    padding-left: 8px;
}

.BcrumNsteps .breadCrumbs ul {
    padding: 5px 0px;
}

.BcrumNsteps .breadCrumbs ul li {
    display: inline-block;
}

.BcrumNsteps .breadCrumbs ul li span.currentPage {
    font-size: 14px;
    color: #fff;
}

.BcrumNsteps .breadCrumbs ul li a {
    font-size: 14px;
    color: #fff;
    position: relative;
    margin-right: 23px;
    display: block;
}

.BcrumNsteps .breadCrumbs ul li a:after {
    content: "»";
    /* background: transparent var(--student-sprite-url) no-repeat arrow -220px -6px / 600px 800px; */
    position: inherit;
    color: #FFF;
    right: -10px;
    top: 0px;
    width: 19px;
    height: 16px;
    transform: rotate(270deg);
    background-size: 500px 600px;
}

.questionMainBlock {
    background: #fff;
    margin-bottom: 108px;
}

.questionMainBlock .fieldBox {
    float: left;
    width: 100%;
    margin-left: -30px;
    margin-bottom: 8px;
}

.questionMainBlock .actionBlockDiv {
    margin-top: 12px;
    margin-bottom: 20px;
}

.questionMainBlock .actionBlockDiv .detailEditStudent {
    background: transparent var(--student-sprite-url) repeat scroll -428px -83px / 600px 800px;
    cursor: pointer;
    display: inline-block;
    height: 30px;
    left: 5px;
    margin-right: 10px;
    position: relative;
    top: 0;
    width: 38px;
}

.questionMainBlock .actionBlockDiv .detailEditStudent:hover {
    background: transparent var(--student-sprite-url) repeat scroll -428px -83px / 600px 800px;
    cursor: pointer;
    display: inline-block;
    height: 30px;
    left: 5px;
    margin-right: 10px;
    position: relative;
    top: 0;
    width: 38px;
}

.questionMainBlock .actionBlockDiv .detailPrintStudent {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -147px -1150px / 550px 800px;
    display: inline-block;
    height: 48px;
    left: 5px;
    position: relative;
    top: 0px;
    width: 48px;
    margin-right: 10px;
    cursor: pointer;
}

.questionMainBlock .actionBlockDiv .detailPrintStudent:hover {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -147px -1150px / 550px 800px;
}

.questionMainBlock .actionBlockDiv .detailDuplicateStudent {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -327px -1424px / 550px 800px;
    display: inline-block;
    height: 48px;
    left: 5px;
    position: relative;
    top: 0px;
    width: 48px;
    margin-right: 10px;
    cursor: pointer;
}

.questionMainBlock .actionBlockDiv .detailDuplicateStudent:hover {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -327px -1424px / 550px 800px;
}

.questionMainBlock .actionBlockDiv .detailDeleteStudent {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -147px -1286px / 550px 800px;
    display: inline-block;
    height: 48px;
    left: 5px;
    position: relative;
    top: 0px;
    width: 48px;
    margin-right: 10px;
    cursor: pointer;
}

.questionMainBlock .actionBlockDiv .detailDeleteStudent:hover {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -147px -1286px / 550px 800px;
}

.questionMainBlock h3 {
    font-size: 18px;
    color: #1c1c1c;
    margin: 30px 0px 20px 0px;
    font-weight: bold;
    float: left;
    width: 100%;
}

.questionMainBlock .imgurl {
    font-size: 18px;
    color: #1c1c1c;
    margin: 10px 0px 0px 0px;
    font-weight: bold;
    float: left;
    width: 100%
}

.questionMainBlock p {
    color: #505050;
    line-height: 22px;
    font-size: 16px;
    padding: 0px 0px 0px 0px;
    float: left;
    width: 100%;
}

.questionMainBlock input[type=radio].css-checkbox {
    position: absolute;
    z-index: -1000;
    left: -1000px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

/*.questionMainBlock input[type=radio].css-checkbox + label.css-label { padding-left: 32px; height: 35px; display: inline-block; line-height: 21px; background-repeat: no-repeat; background-position: -473px -96px; vertical-align: middle; cursor: pointer; font-weight: normal; background-size: 500px 800px; }

.questionMainBlock input[type=radio].css-checkbox:checked + label.css-label { background-position:-470px -149px; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; }

.questionMainBlock label.css-label { background-image: var(--student-sprite-url); -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; }*/

#viewDetailsPage .lastQuesRedirectStudentStudent {
    width: 97.8% !important;
}

.lastQuesRedirectStudent {
    float: left;
    margin-left: 0px;
    position: fixed;
    bottom: 45px;
    width: 75%;
    padding-right: 30px;
    height: 85px;
    background: #fff;
    z-index: 9999;
    /*height: 80px;border-bottom: 1px solid #dddedf;*/
    border: 1px solid #dddedf;
    margin-top: 10px;
    padding-top: 5px;
    margin-bottom: -45px
}

.lastQuesRedirectStudent .prevQuesStudent {
    display: inline-block;
    font-size: 16px;
    height: 57px;
    margin: 0px 10px 24px 0;
    overflow: hidden;
    padding-top: 42px;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 33px;
}

.lastQuesRedirectStudent .prevQuesStudent {
    background: url("../images/back.png");
    background-size: 34px 48px;
}

.lastQuesRedirectStudent .pause {
    font-size: 16px;
    padding-top: 45px;
    text-align: center;
    margin: 0px 34px 10px 0px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 47px;
    height: 50px;
    background-size: 47px 50px;
}

.lastQuesRedirectStudent .hint {
    font-size: 16px;
    padding-top: 43px;
    text-align: center;
    margin: 0px 21px 10px 0px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 49px;
    height: 48px;
    background-size: 49px 48px;
}

.lastQuesRedirectStudent .print {
    font-size: 16px;
    padding-top: 50px;
    text-align: center;
    margin: 0px 10px 10px 0px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 40px;
    background-size: 40px 50px;
    height: 50px;
}

.lastQuesRedirectStudent .submit {
    font-size: 16px;
    padding-top: 41px;
    text-align: center;
    margin: 0px 0px 10px 0px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 53px;
    height: 50px;
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -538px -428px / 430px 700px;
}

@media screen and (max-width: 420px) {
    .lastQuesRedirectStudent .end {
        margin: 3px 25px 25px 0px !important;
        font-size: 13px !important;
    }
}

.lastQuesRedirectStudent .end {
    font-size: 16px;
    padding-top: 46px;
    text-align: center;
    margin: -3px 25px 25px 0px;
    display: inline-block;
    width: 41px;
    height: 45px;
}

.lastQuesRedirectStudent .end {
    background: url("../images/end.png");
    background-size: 41px 45px;
}

.lastQuesRedirectStudent .prevQuesStudent .caret {
    border-width: 6px;
    transform: rotate(90deg);
    margin-right: 14px;
    margin-top: -4px;
}

.lastQuesRedirectStudent .nextQuesStudent {
    font-size: 16px;
    padding-top: 44px;
    text-align: center;
    margin: 3px 0px 25px 0;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 36px;
    height: 59px;
}

.lastQuesRedirectStudent .nextQuesStudent {
    background: url("../images/submit.png");
    background-size: 36px 50px;
}

.lastQuesRedirectStudent .nextQuesStudent .caret {
    border-width: 6px;
    transform: rotate(-90deg);
    margin-left: 14px;
    margin-top: -1px;
}

.lastQuesRedirectStudent ul {
    margin-top: 19px;
    color: #505050;
}

.lastQuesRedirectStudent ul li {
    display: inline-block;
    margin: 0px 3px;
}

.lastQuesRedirectStudent ul li.num {
    color: #0f6fb4;
}

/* editPopModal */

.editPopModal {}

.editPopModal p {
    color: #505050;
    line-height: 22px;
    font-size: 16px;
    margin-bottom: 20px;
}

.editPopModal .modal-content {
    width: 100%;
    max-width: 440px;
    margin: 0 auto;
    padding: 15px;
}

.editPopModal h4 {
    font-size: 18px;
    color: #1c1c1c;
}

.editPopModal .form-control {
    background: transparent;
    height: 48px;
    border: 1px solid #e1e1e1;
    color: #909090;
    font-size: 14px;
    margin-bottom: 10px;
}

.editPopModal button.cnl {
    border-color: #44a9f2;
    color: #44a9f2;
    width: 78px;
}

.editPopModal button.save {
    border-color: #44a9f2;
    background: #44a9f2;
    color: #fff;
    width: 78px;
}

@media only screen and (max-width: 460px) {
    .BcrumNsteps {
        display: block;
        background: rgb(15, 111, 180);
        color: #fff;
    }
}

.m-t-10 {
    margin-top: 10px;
}

/*left Panel css*/

.anchorLeftBox {
    padding-top: 0px;
    background-color: transparent;
    background: #f7f8f9 !important;
    border: 1px solid #eeeeee;
    border-left: hidden;
    overflow: auto;
    float: left;
    width: 25%;
    position: fixed;
    margin-left: 0px;
    height: calc(100% - 171px)
}

.anchorLeftBoxss {
    padding-top: 0px;
    background-color: transparent;
    background: #f7f8f9 !important;
    border: 1px solid #eeeeee;
    border-left: hidden;
    overflow: auto;
    float: left;
    width: 25%;
    position: fixed;
    margin-left: 0px;
    height: calc(100% - 0px)
}

.anchorLeftBox .ArrowDivCollapse {}

.anchorLeftBox .ArrowExpand {
    background: url("../images/left-arrow3.png");
    display: inline-block;
    height: 33px;
    width: 32px;
    background-size: 32px 33px;
    position: absolute;
    right: 6px;
    top: 16px;
    cursor: pointer;
}

.anchorLeftBoxss .ArrowExpandss {
    background: url("../images/left-arrow3.png");
    display: inline-block;
    height: 30px;
    width: 28px;
    position: absolute;
    background-size: 28px 30px;
    right: 2px;
    top: 22px;
    cursor: pointer;
}

.anchorLeftBoxss.leftBoxWidthMinimize .ArrowDivCollapse {
    width: 65px;
    height: 100%;
    background: #f7f8f9;
    border-right: 1px solid #eaeaea;
}

.anchorLeftBoxss.leftBoxWidthMinimize .ArrowExpandss {
    width: 44px;
    height: 70px;
    background: transparent url(/static/media/sprite-student-portal.3288b41a.png) repeat scroll -252px -244px / 430px 700px;
    left: 5px;
    top: 0px;
}

.anchorLeftBox .ArrowExpandss {
    background: transparent url(/static/media/sprite-student-portal.3288b41a.png) repeat scroll -252px -314px/430px 700px;
    display: inline-block;
    height: 37px;
    width: 35px;
    position: absolute;
    right: 18px;
    top: 12px;
    cursor: pointer;
}

.anchorLeftBoxss .ArrowExpand {
    background: transparent url(/static/media/sprite-student-portal.3288b41a.png) repeat scroll -252px -314px/430px 700px;
    display: inline-block;
    height: 37px;
    width: 35px;
    position: absolute;
    right: 18px;
    top: 12px;
    cursor: pointer;
}

.anchorLeftBox h3 {
    font-size: 16px;
    color: #1c1c1c;
    font-weight: bold;
    margin-bottom: 10px;
    padding-left: 30px;
}

.anchorLeftBox .StdNameBox {}

.anchorLeftBox ul.StdNameList {
    margin-top: 1.2em;
}

.anchorLeftBox ul.StdNameList li {
    height: 40px;
    border-bottom: 1px solid #dcdcdd;
}

.anchorLeftBox ul.StdNameList li:hover, .anchorLeftBox ul.StdNameList li.active {
    background: #44a9f2;
}

.anchorLeftBox ul.StdNameList li:hover a {
    color: #fff;
}

.anchorLeftBox ul.StdNameList li a {
    font-size: 15px;
    color: #555555;
    padding-left: 25px;
    height: 30px;
    width: 100%;
    display: inline-block;
    padding-top: 5px;
}

.anchorLeftBox ul.StdNameList li a:hover, .anchorLeftBox ul.StdNameList li a:active, .anchorLeftBox ul.StdNameList li a:visited, .anchorLeftBox ul.StdNameList li a:focus {
    text-decoration: none;
    outline: none;
}

.anchorLeftBox ul.StdNameList li.active a {
    color: #fff;
}

/*main box css*/

.mainActivBoxRight .col-lg-10Per {
    width: 10%;
    min-width: 130px
}

.mainActivBoxRight {
    width: 75%;
    float: left;
    padding-bottom: 40px;
    margin-left: 25%;
    background: #fff;
    height: 100%;
    position: fixed;
    overflow: auto;
    /*border-left: 1px solid #ccc;*/
}

.mainActivBoxRightfull {
    width: 100%;
    float: left;
    padding-bottom: 0px;
    margin-left: 0%;
    background: #fff;
    height: calc(100% - 225px);
    position: fixed;
    overflow: auto;
    border-left: 1px solid #ccc;
}

.mainActivBoxRight.rightBoxWidthMinimize {
    width: calc(100% - 52px);
    margin-left: 50px;
    height: 100%;
}

.anchorLeftBox.leftBoxWidthMinimize {
    width: 50%;
    overflow: hidden;
}

.anchorLeftBox.leftBoxWidthMinimize .ArrowDivCollapse {
    width: 65px;
    height: 100%;
    background: #f7f8f9;
    border-right: 1px solid #eaeaea;
}

.filterBlockss .typeIcons {
    background: url(/static/media/logo2.828593cd.png) repeat scroll;
    display: inline-block;
    float: left;
    height: 64px;
    left: 0;
    margin-right: 6px;
    position: relative;
    top: 4px;
    width: 52px;
}

.anchorLeftBox.leftBoxWidthMinimize .ArrowExpand {
    width: 44px;
    height: 70px;
    background: transparent var(--student-sprite-url) repeat scroll -252px -244px / 430px 700px;
    left: 5px;
    top: 0px;
}

.mainActivBoxRight .eleRow {
    border-bottom: 1px solid #dcdcdd;
    display: block;
    float: left;
    line-height: 20px;
    padding: 10.5px 10px;
    width: 100%;
}

.mainActivBoxRight .eleRow h3 {
    font-size: 18px;
    color: #1c1c1c;
    margin-top: 5px;
    margin-bottom: 5px;
}

.mainActivBoxRight .eleRow .quesNum {
    font-size: 18px;
    color: #1c1c1c;
}

.mainActivBoxRight .eleRow button.printBtn, .mainActivBoxRight .eleRow button.printBtn:focus {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll 0px -541px;
    display: inline-block;
    height: 44px;
    left: 0px;
    position: relative;
    top: -12px;
    width: 52px;
    margin-right: 1px;
    background-size: 500px 600px;
    border: none;
    outline: none;
}

.mainActivBoxRight .eleRow button.wordBtn, .mainActivBoxRight .eleRow button.wordBtn:focus {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -450px -275px;
    display: inline-block;
    height: 44px;
    left: 0px;
    position: relative;
    top: -13px;
    width: 52px;
    background-size: 500px 600px;
    background-repeat: no-repeat;
    border: none;
    outline: none;
}

.QuesAnsScoreBoxMain .QuesAnsScoreRow.scringTipRow {
    display: none;
}

.QuesAnsScoreBoxMain .QuesAnsScoreRow.scringTipRow.scringTipRowShow {
    display: block;
}

.QuesAnsScoreBoxMain {
    float: left;
    width: 97%;
    margin: 27px 22px;
    border: 1px solid #dcdcdd;
}

.QuesAnsScoreBoxMain .QuesAnsScoreRow {
    float: left;
    width: 100%;
    border-bottom: 1px solid #dcdcdd;
    padding: 22px 0px 22px 11px;
}

.QuesAnsScoreBoxMain .QuesAnsScoreRow:last-child {
    border-bottom: none;
}

.QuesAnsScoreBoxMain .QuesAnsScoreRow h3.lblheading {
    color: #1c1c1c;
    font-size: 18px;
}

.QuesAnsScoreBoxMain .QuesAnsScoreRow p.detailLbl {
    color: #505050;
    line-height: 21px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    max-height: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    position: relative;
    /* padding-right: 75px;  */
}

.QuesAnsScoreBoxMain p.detailLbl span.viewFullPara {
    position: absolute;
    right: 0px;
    bottom: 0px;
    color: #0f6fb4;
    cursor: pointer;
    background: #fff;
    padding-left: 15px;
}

.QuesAnsScoreBoxMain .QuesAnsScoreRow p.detailLbl.seeFullQues {
    max-height: 100%;
    height: auto;
    display: flex;
    padding: 0;
}

.QuesAnsScoreBoxMain .QuesAnsScoreRow .quesScoreField {
    display: inline-block;
    width: 90%;
    background: #fbfbfb;
    border: 1px solid #e1e1e1;
    height: 40px;
}

.QuesAnsScoreBoxMain .QuesAnsScoreRow .quesScoreField:focus {
    box-shadow: none;
}

.QuesAnsScoreBoxMain .QuesAnsScoreRow .outOf {
    display: inline-block;
    color: #909090;
    /* font-size: 22px !important; padding-left: 10px;  */
    font-size: 16px !important;
    /* padding-left: 10px; */
    padding: 5px 10px 5px 15px;
    border: 1px solid #c6c6c7;
    border-radius: 4px;
    border-left: 0;
    left: -3px;
    position: relative;
}

.QuesAnsScoreBoxMain .QuesAnsScoreRow .showScorDivBtn {
    color: #0f6fb4;
    font-size: 16px;
    background: none;
    border: none;
    margin-top: 10px;
}

.QuesAnsScoreBoxMain .QuesAnsScoreRow .showScorDivBtn:focus {
    outline: none;
}

.QuesAnsScoreBoxMain .QuesAnsScoreRow textarea.form-control {
    width: 90%;
    background: #fbfbfb;
    border: 1px solid #e1e1e1;
    height: 80px;
    /* resize: none;  */
    max-width: 700px;
}

.QuesAnsScoreBoxMain .QuesAnsScoreRow textarea.form-control:focus {
    outline: none;
    box-shadow: none
}

.QuesAnsScoreBoxMain .QuesAnsScoreRow .prevQuesBtn {
    background: #44a9f2;
    padding: 12px 29px;
    display: inline-block;
    color: #fff;
    border-radius: 4px;
    border: 1px solid #47a6ea;
    border-bottom-color: #3a8ac4;
}

.QuesAnsScoreBoxMain .QuesAnsScoreRow .nextQuesBtn {
    background: #44a9f2;
    padding: 12px 29px;
    display: inline-block;
    color: #fff;
    border-radius: 4px;
    margin-right: 7px;
    border: 1px solid #47a6ea;
    border-bottom-color: #3a8ac4;
}

.QuesAnsScoreBoxMain .QuesAnsScoreRow .seqNum {
    color: #505050;
    font-size: 15px;
    margin-top: 12px;
    color: #777777;
    font-size: 16px !important;
    margin-top: 12px;
    font-weight: bold !important;
    font-family: monospace;
}

.overFlowScroll-sm {
    padding-top: 0px;
    overflow: initial !important;
    /*border-top: 1px solid #ccc; */
}

/*css fortabs*/

.studentMangeSubProgTab {
    margin: 0px -15px;
}

.studentMangeSubProgTab .nav-tabs>li {
    width: 33.3335%;
    text-align: center;
    border-radius: inherit;
    border: 1px solid #dcdcdd;
    border-top: none;
    border-right: none;
    background: #f8f8f8;
}

.studentMangeSubProgTab .nav-tabs>li a {
    color: #0f6fb4;
    font-size: 18px;
}

.studentMangeSubProgTab .nav-tabs>li a:hover {
    background: #f8f8f8;
    border-color: #f8f8f8
}

.studentMangeSubProgTab .nav-tabs>li.active a {
    color: #ffffff;
    background: #0f6fb4;
    border: none;
    border-radius: 0;
    margin: 0px;
}

.studentMangeSubProgTab .nav-tabs>li.active {
    background: #ccc;
    border: 1px solid #0f6fb4;
}

/*manageTab*/

.manageTab .QuesAnsScoreRow {
    float: left;
    width: 100%;
    border-bottom: 1px solid #dcdcdd;
    padding: 22px 0px 22px 11px;
}

.manageTab .QuesAnsScoreRow:last-child {
    border-bottom: none;
}

.manageTab .QuesAnsScoreRow h3.lblheading {
    color: #1c1c1c;
    font-size: 18px;
}

.manageTab .QuesAnsScoreRow p.detailLbl {
    color: #505050;
    line-height: 21px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    max-height: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    position: relative;
    /*padding-right: 75px;*/
}

.manageTab .QuesAnsScoreRow .quesScoreField {
    display: inline-block;
    width: 90%;
    background: #fbfbfb;
    border: 1px solid #e1e1e1;
    height: 40px;
}

.manageTab h3.topHeading {
    font-size: 20px;
    color: #1c1c1c;
    font-weight: bold;
    padding: 30px 25px;
    border-bottom: 1px solid #dcdcdd;
}

.manageTab .QuesAnsScoreRow .manageSecTxtField, .manageTab .QuesAnsScoreRow .manageSecDropField {
    background: #fbfbfb;
    border: 1px solid #e1e1e1;
    height: 40px;
}

/* progressTab */

.progressTab {}

.progressTab .progressMainBox {
    background: #fff;
    height: 100%;
    position: fixed;
    width: 100%;
}

.progressTab h3.topHeading {
    font-size: 20px;
    color: #1c1c1c;
    font-weight: bold;
    padding: 30px 25px;
    border-bottom: 1px solid #dcdcdd;
}

.progressTab h3.topHeading a.anchvuDet {
    color: #0f6fb4;
    font-size: 15px;
    font-weight: 400;
    float: right;
}

.progressTab h4.tableHeading {
    font-size: 17px;
    color: #1c1c1c;
    margin: 20px 0px;
}

.font10 {
    font-size: 10px !important;
}

.text-warning {
    color: #f69c00;
}

.text-success {
    color: #007f00;
}

.text-danger {
    color: #db5c35;
}

.tableSecCount {
    padding: 0px 20px;
}

.limitTextRes {
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    float: left;
    width: auto;
}

.tableBox {
    width: 100%;
    overflow: auto;
}

.progressTab a.anchvuDet em {
    font-family: cursive;
    font-size: 20px;
    position: relative;
    top: 2px;
    display: inline-block;
    margin-left: 3px;
}

.progressTab .table>thead>tr>th {
    border-bottom: none;
}

@media only screen and (min-width: 1400px) {
    .lastQuesRedirectStudent {
        width: 75%;
        z-index: 2;
    }
}

@media only screen and (max-width: 1169px) and (min-width: 768px) {
    .overFlowScroll-sm {
        float: left;
        height: auto;
        max-height: 100%;
        overflow: auto;
        padding-top: 0px;
        padding-bottom: 0px;
        width: 100%;
    }
    .manageTab .col-lg-10Per {
        width: 23%;
    }
    .fixedBoxManage {
        position: fixed;
        overflow: auto;
        height: calc(100% - 273px);
    }
}

@media only screen and (max-width: 415px) {
    .lastQuesRedirectStudent {
        padding-bottom: -10px;
        bottom: -18px !important;
    }
    .anchorLeftBoxss {
        width: 100% !important;
    }
}

@media only screen and (max-width: 1400px) and (min-width: 1024px) {
    .mainActivBoxRight {
        margin-left: 25%;
        width: 75%;
    }
    .anchorLeftBox {
        width: 25%;
    }
    .manageTab .col-lg-10Per {
        width: 20%;
    }
}

@media only screen and (min-width: 767px) and (max-width: 992px) {
    .mainActivBoxRight {
        margin-left: 30%;
        width: 70%;
    }
    .anchorLeftBox {
        width: 30%;
    }
    .anchorLeftBox .ArrowExpand {
        right: 2px;
    }
    .anchorLeftBox.leftBoxWidthMinimize .ArrowExpand {
        left: 5px;
    }
    .mainActivBoxRight.rightBoxWidthMinimize {
        width: calc(100% - 52px);
        height: calc(100% - 42px);
    }
    .QuesAnsScoreBoxMain .QuesAnsScoreRow .prevQuesBtn, .QuesAnsScoreBoxMain .QuesAnsScoreRow .nextQuesBtn {
        padding: 12px 7px;
        width: 100%;
        text-align: center
    }
    .QuesAnsScoreBoxMain .QuesAnsScoreRow .quesScoreField, .QuesAnsScoreBoxMain .QuesAnsScoreRow textarea.form-control {
        width: 80%;
    }
    .mainActivBoxRight .eleRow {
        padding: 3px 0px 10px 0px;
    }
    .QuesAnsScoreBoxMain {
        margin: 27px 16px;
    }
    .lastQuesRedirectStudent {
        width: 70%;
    }
}

@media only screen and (min-width: 993px) {
    .lastQuesRedirectStudent {
        padding-top: 5px
    }
}

@media only screen and (min-width: 990px) and (max-width: 1023px) {
    .mainActivBoxRight {
        width: 75%;
        margin-left: 25%;
    }
    .anchorLeftBox {
        width: 25%;
    }
    .anchorLeftBox .ArrowExpand {
        right: 12px;
    }
    .anchorLeftBox.leftBoxWidthMinimize .ArrowExpand {
        left: 5px;
    }
    .mainActivBoxRight.rightBoxWidthMinimize {
        width: calc(100% - 52px);
        height: calc(100% - 42px);
    }
    .QuesAnsScoreBoxMain .QuesAnsScoreRow .prevQuesBtn, .QuesAnsScoreBoxMain .QuesAnsScoreRow .nextQuesBtn {
        padding: 12px 7px;
        width: 100%;
        text-align: center
    }
    .QuesAnsScoreBoxMain .QuesAnsScoreRow .quesScoreField, .QuesAnsScoreBoxMain .QuesAnsScoreRow textarea.form-control {
        width: 80%;
    }
}

@media only screen and (max-width: 1300px) {
    .mrgn {
        margin-right: 3%;
    }
    .pacboxdes .limitFiltersas {
        margin: 10px -7px 9px;
    }
    .minusmrdg {
        margin-left: -3%;
    }
}

@media only screen and (max-width: 1200px) {
    .desk2 {
        background: #fff none repeat scroll 0 0;
        margin-right: 0;
        padding: 0 5px;
        right: 0 !important;
        text-align: center;
        top: -20px !important;
    }
    .mrgn {
        margin-right: 3%;
    }
    .pacboxdes .limitFiltersas {
        margin: 10px -7px 9px;
    }
    .minusmrdg {
        margin-left: 0%;
    }
}

.mrgntn {
    margin-right: -10px;
}

@media only screen and (max-width: 820px) {
    .mainActivBoxRight .eleRow .quesNum {
        font-size: 14px;
    }
    .mainActivBoxRight .eleRow h3 {
        font-size: 14px;
    }
    .questionMainBlock p {
        font-size: 14px;
    }
}

@media only screen and (max-width: 541px) {
    .icondeshead {
        width: 47% !important;
    }
    .barchrtlghtblde {
        text-indent: -5000px;
    }
    .minusmrdg {
        margin-left: 5%;
    }
    .pacboxdes {
        margin: 0 !important;
    }
}

@media only screen and (max-width: 400px) {
    .minusmrdg {
        margin-left: 8%;
    }
}

@media only screen and (max-width: 767px) and (min-width: 266px) {
    .navbar {
        position: relative;
        min-height: 50px;
        margin-bottom: 20px;
        border: 1px solid transparent;
        background: rgb(15, 111, 180);
    }
}

@media only screen and (max-width: 767px) {
    a.boardhead, .boardheadblf, .boardheadgrey, .barchrtlghtblde, .lsthed, .lghtblfg, .boardheadact, .lghtblfgmng, .pacboxdes p {
        font-size: 12px !important;
    }
    .minnine {
        margin-left: -9px !important;
    }
    .mrgn {
        margin-right: -5%;
    }
    .lastQuesRedirectStudent {
        width: 94%;
    }
    .imgurl>img {
        width: 42%;
    }
    .mainActivBoxRight {
        width: 75%;
        margin-left: 25%;
    }
    .QuesAnsScoreBoxMain {
        margin: 27px 16px;
    }
    .anchorLeftBox {
        width: 26.5%;
        margin-left: 0px;
    }
    .anchorLeftBox .ArrowExpand {
        right: 24px;
    }
    .anchorLeftBox.leftBoxWidthMinimize .ArrowDivCollapse {
        width: 50px;
        height: 100%;
        position: fixed;
        z-index: 5;
    }
    .anchorLeftBox.leftBoxWidthMinimize .ArrowExpand {
        left: 4px;
    }
    .mainActivBoxRight.rightBoxWidthMinimize {
        width: calc(100% - 52px);
        /* height: calc(100% - 100px);*/
    }
    .QuesAnsScoreBoxMain .QuesAnsScoreRow .prevQuesBtn, .QuesAnsScoreBoxMain .QuesAnsScoreRow .nextQuesBtn {
        padding: 12px 0px;
        width: 100%;
        text-align: center
    }
    .QuesAnsScoreBoxMain .QuesAnsScoreRow .quesScoreField, .QuesAnsScoreBoxMain .QuesAnsScoreRow textarea.form-control {
        width: 80%;
    }
    .QuesAnsScoreBoxMain .QuesAnsScoreRow h3.lblheading {
        margin-bottom: 15px
    }
    .QuesAnsScoreBoxMain {
        margin: 27px 8px;
        width: 96%;
    }
    .studentMangeSubProgTab .nav-tabs>li a {
        padding: 10px;
    }
    .anchorLeftBox {
        width: 100%;
        height: calc(100% - 100px);
    }
    .mainActivBoxRight {
        width: 0%;
        margin-left: 100%;
    }
    .fixedBoxManage {
        position: fixed;
        overflow: auto;
        height: calc(100% - 213px);
    }
}

@media only screen and (max-width: 1199px) {
    .text-alignlft {
        text-align: center !important;
    }
    .greenbtn {
        margin: 0px !important;
    }
}

@media only screen and (min-width: 768px) {
    .pacbox .collapse {
        display: block;
    }
    .pacbox {
        min-height: 254px;
    }
    .pacboxcol .collapse {
        display: block;
    }
    .pacboxcol {
        min-height: 177px;
    }
}

@media only screen and (max-width: 768px) {
    .anchorLeftBox {
        padding-top: 0px !important;
    }
    .filterBlockun input.css-checkbox[type="checkbox"]+label.css-label8 {
        font-size: 14px !important;
    }
    .filterBlockun input.css-checkbox[type="checkbox"]+label.css-label7 {
        font-size: 14px !important;
    }
    .filterBlockun input.css-checkbox[type="checkbox"]+label.css-label6 {
        font-size: 14px !important;
    }
    .bluetxta {
        font-size: 14px !important;
    }
    a.blubtnpops {
        font-size: 11px !important;
        padding: 0 4px !important;
    }
    .pacbox {
        margin: 16px 0px 0px !important
    }
    .pacboxcol {
        margin: 16px 0px 0px !important
    }
    .filterBlockss .contentType .headsp {
        font-size: 15px !important;
    }
    .lastQuesRedirectStudent .prevQuesStudent {
        height: 50x;
        margin: 0px 0px 0px;
    }
    .lastQuesRedirectStudent .pause {
        height: 50px;
        margin: 0px 0px 10px 10px
    }
    .lastQuesRedirectStudent .print {
        height: 50px;
        margin: 3px 0 9px 0px;
    }
    .lastQuesRedirectStudent .submit {
        height: 50px;
        margin: 0px 0 0 -28px
    }
    .fltrghtress {
        float: right;
    }
    .printres {
        background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -546px -310px / 430px 700px;
        display: inline-block;
        font-size: 16px;
        height: 60px;
        margin: 13px 0px 0px;
        overflow: hidden;
        padding-top: 41px;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 36px;
    }
    .submitres {
        background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -537px -428px / 430px 700px;
        display: inline-block;
        font-size: 16px;
        height: 60px;
        margin: 13px 10px 10px;
        overflow: hidden;
        padding-top: 41px;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 55px;
    }
    .resmain {
        display: block !important;
    }
    .text-alignlft {
        text-align: center !important;
    }
    .text-alignrght {
        text-align: center !important;
    }
}

.maincolblures {
    background: #f1f9ff none repeat scroll 0 0;
    border: 1px solid #0f6fb4;
    border-radius: 12px;
    color: #505050;
    display: block;
    float: right;
    font-size: 13px;
    line-height: 18px;
    margin-top: 7px;
    text-align: center;
    width: 100px;
}

.maincolorngres {
    background: #ff7044 none repeat scroll 0 0;
    border: 1px solid #ff7044;
    border-radius: 12px;
    color: #fff;
    display: block;
    float: right;
    font-size: 13px;
    line-height: 18px;
    margin-top: 7px;
    text-align: center;
    width: 100px;
}

@media only screen and (max-width: 590px) {
    .lastQuesRedirectStudent {
        width: 91%;
    }
}

@media only screen and (max-width: 540px) {
    .lastQuesRedirectStudent {
        width: 90%;
    }
}

@media only screen and (max-width: 410px) {
    .QuesAnsScoreBoxMain .QuesAnsScoreRow .seqNum {
        position: relative;
        left: -10px;
    }
    .lastQuesRedirectStudent {
        width: 88%;
    }
}

.bluetxta {
    color: #0f6fb4;
    float: left;
    font-size: 15px;
    margin: 9px 0 18px;
    text-align: center;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    /*line-height: 17px;-*

    /*white-space: nowrap;

    overflow: hidden;

    text-overflow: ellipsis; */
}

.headblue {
    color: #0f6fb4;
    font-size: 30px;
}

.eleRows {
    border-bottom: 1px solid #ccc;
    float: left;
    margin-bottom: 10px;
    padding: 15px 0;
    text-align: center;
    width: 100%;
}

.cntrfonbig {
    float: left;
    font-size: 21px;
    line-height: 36px;
    min-height: 360px;
    padding: 50px 15px;
    width: 100%;
}

@media only screen and (max-width: 415px) {
    .cntrfon {
        float: left;
        font-size: 18px !important;
        line-height: 16px !important;
        padding: 5px 0px !important;
        width: 100%;
        font-weight: 200 !important;
        font-family: Verdana, Geneva, sans-serif !important;
    }
}

.quesAndIconStudent .my {
    max-height: 50vh !important;
}

@media only screen and (min-width:768) and (max-width: 992px) {
    .cntrfon {
        float: left;
        font-size: 18px !important;
        line-height: 26px;
        padding: 15px 0px;
        width: 100%;
        margin-left: -20px;
        margin-bottom: 50px;
        font-weight: 200 !important;
        font-family: Verdana, Geneva, sans-serif !important;
    }
}

.cntrfon {
    float: left;
    font-size: 18px !important;
    line-height: 26px;
    padding: 15px 15px;
    width: 100%;
    margin-bottom: 50px;
    font-weight: 200 !important;
    font-family: Verdana, Geneva, sans-serif !important;
}

.cntrfon p {
    font-size: 18px !important;
}

.cntrfon div {
    font-size: 18px !important;
}

.cntrfon video {
    height: 55vh !important;
}

@media only screen (min-width: 768px) and (max-width: 992px) {
    .cntrfon video {
        height: 50vh !important;
    }
}

/* .quesAndIconStudent .my {
} */

/* .cntrfon br {
    display: none;
} */

/*
.cntrfon b:before {
    content: "\A";
    line-height: 33px;
    white-space: pre;

} */

/* .cntrfon b:after {
    content: "\A";
    white-space: pre;
} */

/* .cntrfon font {
    display: block;
    margin-bottom : 10px;
} */

/* .cntrfon font {
    display: inline-grid;
} */

/*
.cntrfon font font {
    display: block;
    margin: 0px 5px;
} */

.cntrfon br+br {
    display: none;
}

.cntrfon b>br+br {
    display: block;
}
.clearBn {

margin-left: 8px;
}
.cntrfon b>br:first-child {
    display: none;
}

/* .cntrfon br ~ br {
    display: none;
}

.cntrfon  br:first-child {
    display: block;
} */

.btnnotm {
    background: #fff none repeat scroll 0 0;
    border: none;
    border-radius: 4px;
    color: #0f6fb4;
    margin-left: 0;
    padding: 7px;
}

.ttme:before {
    background: transparent var(--student-sprite-url) repeat scroll -64px -97px / 400px 490px;
    content: "";
    height: 48px;
    padding-left: 26px;
    width: 48px;
}

.ccard:before {
    background: transparent var(--student-sprite-url) repeat scroll -64px -135px / 400px 490px;
    content: "";
    height: 48px;
    padding-left: 26px;
    width: 48px;
}

.sscore:before {
    background: transparent var(--student-sprite-url) repeat scroll -64px -182px / 400px 490px;
    content: "";
    height: 48px;
    padding-left: 26px;
    width: 48px;
}

.brdrnam {
    box-shadow: 0 0 4px 1px #ccc;
    float: left;
    margin-top: 30px;
    padding: 15px;
    border-radius: 10px;
    width: 100%;
}

.brdgr {
    float: left;
    margin-top: 30px;
    padding: 0;
    width: 100%;
}

.brdgr h3 {
    font-size: 18px;
}

.greenbtn {
    border: 1px solid;
    border-radius: 20px;
    color: #3c873c;
    display: inline-block;
    margin: 0px 0;
    padding: 12px 30px;
    font-size: 88%;
}

.orngbtn {
    border: 1px solid;
    border-radius: 20px;
    color: #ff7044;
    display: inline-block;
    padding: 12px 30px;
    text-align: center;
    font-size: 88%;
    margin: 0px;
}

/*popup*/

.printrespop {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -828px -406px / 500px 600px;
    display: inline-block;
    font-size: 16px;
    height: 55px;
    margin: 13px 0px 0px;
    overflow: hidden;
    padding-top: 36px;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 36px;
}

.fltrghtress {
    float: right;
}

.popups {
    background: #fff none repeat scroll 0 0;
    float: left;
    width: 100%;
}

.eleRowspop {
    color: #0f6fb4;
    display: inline-block;
    font-size: 18px;
    margin-bottom: 0px;
    padding: 15px 0;
    text-align: left;
}

@media only screen and (max-width: 415px) {
    .eleRowspopcrt {
        color: #0f6fb4;
        display: inline;
        font-size: 12px !important;
        margin-bottom: 0px;
        padding: 5px 0;
        text-align: left;
        width: 725px;
        /* white-space: nowrap; */
        /* overflow: hidden; */
        /* text-overflow: ellipsis; */
    }
    .panel-heading-top {
        position: relative;
        top: 6px;
        font-weight: 200;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        color: white;
        font-size: 14px !important;
    }
}

.eleRowspopcrt {
    color: #0f6fb4;
    display: inline;
    font-size: 16px;
    margin-bottom: 0px;
    padding: 5px 0;
    text-align: left;
    width: 725px;
    /* white-space: nowrap; */
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
}

.eleRowspopbl {
    color: #000;
    display: inline-block;
    font-size: 18px;
    margin-bottom: 10px;
    padding: 15px 0;
    text-align: left;
}

.panel-heading-top {
    position: relative;
    top: 6px;
    font-weight: 200;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: white;
    font-size: 20px;
}

.correctAnswer {
    background: #0f0c29;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to left, #24243e, #302b63, #0f0c29);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to left, #24243e, #302b63, #0f0c29);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    font-weight: 200 !important;
    color: whitesmoke;
}

.wrongAnswer {
    background: #E80000;
    /* fallback for old browsers */
    /* background: -webkit-linear-gradient(to left, #dd1818, #333333); */
    /* Chrome 10-25, Safari 5.1-6 */
    /* background: linear-gradient(to left, #dd1818, #333333); */
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    font-weight: 200 !important;
    /* color: whitesmoke; */
}

.eleRowspoporng {
    color: #f27259;
    float: left;
    font-size: 18px;
    margin-bottom: 0;
    padding: 15px 0;
    text-align: left;
    width: 100%;
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.HideCrosspop {
    background: transparent var(--student-sprite-url) repeat scroll -164px -56px / 400px 609px;
    display: inline-block;
    height: 30px;
    margin-right: 13px;
    margin-top: 15px;
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
}

a.blubtnpop {
    background: #43a9f1 none repeat scroll 0 0;
    border-radius: 5px;
    color: #fff;
    float: right;
    font-size: 15px;
    margin-top: 10px;
    padding: 10px;
}

.expstrong {
    float: left;
    margin-bottom: 10px;
    width: 100%;
}

.seprat {
    border-top: 1px solid #ccc;
    float: left;
    width: 100%;
}

.quesAndIconpop {
    padding: 0 0px;
    width: 100%;
}

.chrt:before {
    background: transparent var(--student-sprite-url) repeat scroll -64px -165px / 400px 600px;
    content: "";
    font-size: 17px;
    height: 48px;
    padding-left: 30px;
    width: 48px;
}

.no:before {
    background: transparent var(--student-sprite-url) repeat scroll -64px -120px / 400px 600px;
    content: "";
    font-size: 17px;
    height: 48px;
    padding-left: 30px;
    width: 48px;
}

.sub1:before {
    background: transparent var(--student-sprite-url) repeat scroll -107px -181px / 500px 600px;
    content: "";
    font-size: 17px;
    height: 48px;
    padding-left: 30px;
    width: 48px;
}

.sub2:before {
    background: transparent var(--student-sprite-url) repeat scroll -64px -222px / 400px 600px;
    content: "";
    font-size: 17px;
    height: 48px;
    padding-left: 30px;
    width: 48px;
}

.barchrtlghtbl {
    background: #3fabf4 none repeat scroll 0 0;
    border-radius: 120px;
    height: 9px;
    margin-top: 9px;
}

.barchrtlghtbl::after {
    background: rgba(0, 0, 0, 0.3) none repeat scroll 0 0;
    border-radius: 10px;
    content: " ";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: attr(result-percent);
}

/*popup*/

span.subRowToggle1 {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -269px -150px / 450px 659px;
    display: inline-block;
    height: 27px;
    position: absolute;
    z-index: 99999;
    right: -35px;
    top: -6px;
    width: 35px;
}

span.subRowToggle1.minus {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -269px -150px / 450px 659px;
}

.learning {
    /*border-bottom: 1px solid #ccc;*/
}

@media only screen and (max-width: 414px) {
    .flipbtntxt {
        background: transparent var(--student-sprite-url) repeat scroll -283px -178px / 409px 600px;
        height: 40px;
        position: absolute;
        right: 5px;
        left: 150px;
        top: -5px;
        width: 70px;
    }
    .eleRows .col-xs-6 {
        width: 100%;
        bottom: 10px;
        word-break: break-word;
    }
    .eleRows .toggle-en {
        display: none;
    }
}

.flipbtntxt {
    background: transparent var(--student-sprite-url) repeat scroll -283px -178px / 409px 600px;
    height: 40px;
    position: absolute;
    right: 5px;
    width: 70px;
}

.glyphiconStudent {
    color: #f4a41b;
    display: inline-block;
    font-family: "Glyphicons Halflings";
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 1;
    margin-top: 20px;
    position: relative;
    top: 1px;
}

/*Global*/

.filterBlockun {
    /*float: left*/
}

.filterBlockun .unSelAll {
    color: #0174c7;
    cursor: pointer;
    display: block;
    font-size: 16px;
    font-weight: 400;
    margin-top: 25px;
}

.filterBlockun input.css-checkbox[type="checkbox"] {
    border: 0 none;
    clip: rect(0px, 0px, 0px, 0px);
    height: auto;
    left: -1000px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 100%;
    z-index: -1000;
}

.filterBlockun label.css-label5 {
    -moz-user-select: none;
    background-image: var(--student-sprite-url);
    background-position: -424px -4px;
    background-repeat: no-repeat;
    background-size: 500px 600px;
    color: #555555;
    cursor: pointer;
    display: inline-block;
    font-size: 15px;
    font-weight: normal;
    height: 24px;
    line-height: 22px;
    padding-left: 30px;
    vertical-align: middle;
    float: left;
}

.filterBlockun input.css-checkbox[type="checkbox"]+label.css-label5 {
    background-position: -373px -181px;
    background-repeat: no-repeat;
    background-size: 502px 618px;
    color: #555555;
    cursor: pointer;
    display: inline-block;
    float: left;
    font-size: 15px;
    font-weight: normal;
    height: 24px;
    line-height: 22px;
    padding-left: 30px;
    vertical-align: middle;
}

.filterBlockun input.css-checkbox[type="checkbox"]:checked+label.css-label5 {
    background-position: -472px -39px;
}

.filterBlockun label.css-label6 {
    -moz-user-select: none;
    background-image: var(--student-sprite-url);
    background-position: -424px -4px;
    background-repeat: no-repeat;
    background-size: 500px 600px;
    color: #555555;
    cursor: pointer;
    display: inline-block;
    font-size: 15px;
    font-weight: normal;
    height: 24px;
    line-height: 22px;
    padding-left: 30px;
    vertical-align: middle;
    float: left;
}

.filterBlockun input.css-checkbox[type="checkbox"]+label.css-label6 {
    background-position: -370px -207px;
    background-repeat: no-repeat;
    background-size: 502px 618px;
    color: #555555;
    cursor: pointer;
    display: inline-block;
    float: left;
    font-size: 15px;
    font-weight: normal;
    height: 24px;
    line-height: 22px;
    padding-left: 22px;
    vertical-align: middle;
    margin-right: 20px;
}

.filterBlockun input.css-checkbox[type="checkbox"]:checked+label.css-label6 {
    background-position: -472px -39px;
}

.filterBlockun label.css-label7 {
    -moz-user-select: none;
    background-image: var(--student-sprite-url);
}

.filterBlockun input.css-checkbox[type="checkbox"]+label.css-label7 {
    margin-right: 20px;
    background-position: -368px -233px;
    background-repeat: no-repeat;
    background-size: 502px 618px;
    color: #555555;
    cursor: pointer;
    display: inline-block;
    float: left;
    font-size: 15px;
    font-weight: normal;
    height: 24px;
    line-height: 22px;
    padding-left: 22px;
    vertical-align: middle;
}

.filterBlockun input.css-checkbox[type="checkbox"]:checked+label.css-label7 {
    background-position: -472px -39px;
}

.filterBlockun label.css-label8 {
    -moz-user-select: none;
    background-image: var(--student-sprite-url);
}

.filterBlockun input.css-checkbox[type="checkbox"]+label.css-label8 {
    background-position: -370px -181px;
    background-repeat: no-repeat;
    background-size: 502px 618px;
    color: #555555;
    cursor: pointer;
    display: inline-block;
    float: left;
    font-size: 15px;
    font-weight: normal;
    height: 24px;
    line-height: 22px;
    margin-right: 20px;
    padding-left: 22px;
    vertical-align: middle;
}

.filterBlockun input.css-checkbox[type="checkbox"]:checked+label.css-label8 {
    background-position: -474px -40px;
}

.filterBlockss .contentTypefd {
    color: #0f6fb4;
    display: inline-block;
    float: right;
    font-size: 15px;
    margin-top: -13px;
    text-align: right;
}

.filterBlockss .caretss {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px dashed;
    display: inline-block;
    height: 0;
    margin-right: 15px;
    margin-top: 0;
    right: 0;
    transform: rotate(-91deg);
    vertical-align: middle;
    width: 0;
}

.colrgrsm {
    color: #553d39;
    font-size: 19px !important;
}

.colrgrsmsm {
    color: #553d39;
    font-size: 15px;
    text-align: left;
}

.collghtbg {
    background: #e8ffea;
    height: 200px
}

.collghtbgbeige {
    background: #f9f2e2;
    height: 200px
}

.collghtbgpink {
    background: #ffcfcf;
    height: 200px
}

.pacboxcol {
    border-radius: 3px;
    box-shadow: 0 0 2px 0 #8e8e8e;
    float: left;
    margin: 20px 0px 10px 0px;
    padding: 15px;
    width: 100%;
}

.btngl {
    background: transparent var(--student-sprite-url) repeat scroll -314px -176px / 488px 600px;
    display: inline-block;
    height: 27px;
    left: 0;
    position: relative;
    top: 6px;
    width: 35px;
}

.paddnogl {
    padding-left: 0px;
    padding-right: 0px;
}

a.blubtnpopsgl {
    background: #43a9f1 none repeat scroll 0 0;
    border-radius: 18px;
    color: #fff;
    float: right;
    font-size: 15px;
    margin-top: 10px;
    padding: 0 10px;
}

a.createNewDeck {
    margin-right: 10px !important;
}

.panel-footer {
    background: none;
    border: none;
}

.boxss {
    display: inline-block;
    margin: 20px 0 -10px;
    padding: 5px;
}

.paginationaa {
    border-radius: 4px;
    display: inline-block;
    margin: -9px 0;
    padding-left: 0;
    width: 100%;
    /*text-align: center;*/
}

.paginationaa>li {
    display: inline;
}

.paginationaa>li>a, .paginationaa>li>span {
    background-color: #fff;
    border: 1px solid #ddd;
    color: #555;
    float: none;
    line-height: 1.42857;
    margin-left: -4px;
    padding: 6px 1.1%;
    position: relative;
    text-decoration: none;
}

.first.disabled a {
    text-align: center;
    width: 7%;
}

.allbtn {
    background-color: #fff;
    border: 1px solid #ddd;
    color: #0f6fb4;
    padding: 6px 30px;
}

@media only screen and (max-width: 1441px) {
    .paginationaa>li>a, .paginationaa>li>span {
        padding: 6px 1.1%
    }
}

/*Practice*/

.pacbox {
    border-radius: 3px;
    box-shadow: 0 0 2px 0 #8e8e8e;
    display: inline-block;
    float: none !important;
    margin: 1% 1% 1%;
    padding: 15px;
}

.pacbox a {
    color: #555
}

.pacbox a:focus {
    color: #0f6fb4;
    outline: none;
    text-decoration: none;
}

.filterBlockss .typeIconsscience {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -203px -160px / 500px 740px;
    display: inline-block;
    float: left;
    height: 49px;
    left: 0;
    margin-right: 6px;
    position: relative;
    top: 1px;
    width: 52px;
}

.filterBlockss .typeIconsgeometry {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -203px -366px / 500px 740px;
    display: inline-block;
    float: left;
    height: 49px;
    left: 0;
    margin-right: 6px;
    position: relative;
    top: 1px;
    width: 52px;
}

.filterBlockss .typeIconshistory {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -220px -917px / 500px 600px;
    display: inline-block;
    float: left;
    height: 49px;
    left: 0;
    margin-right: 6px;
    position: relative;
    top: 1px;
    width: 52px;
}

.filterBlockss .typeIconsenvironment {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -203px -232px / 500px 740px;
    display: inline-block;
    float: left;
    height: 49px;
    left: 0;
    margin-right: 6px;
    position: relative;
    top: 1px;
    width: 52px;
}

.filterBlockss .typeIconshistory {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -203px -298px / 500px 740px;
    display: inline-block;
    float: left;
    height: 49px;
    left: 0;
    margin-right: 6px;
    position: relative;
    top: 1px;
    width: 52px;
}

.filterBlockss .contentType .headsp {
    color: #0f6fb4;
    font-size: 18px !important;
    line-height: 29px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.smallblbx {
    color: #0f6fb4;
    font-size: 13px;
}

a.smallblbx {
    color: #0f6fb4;
    text-decoration: none;
}

span.subRowToggle2 {
    background: transparent var(--student-sprite-url) repeat scroll -267px -132px / 448px 600px;
    display: inline-block;
    height: 27px;
    position: absolute;
    right: 17px;
    top: 35px;
    width: 35px;
    z-index: 99999;
}

span.subRowToggle2.minus {
    background: transparent var(--student-sprite-url) repeat scroll -267px -182px / 448px 600px;
}

ul.topBcumbsLinks {
    margin-top: 10px;
}

ul.topBcumbsLinks li:first-child {
    color: #0f6fb4;
    margin-right: 5px;
}

ul.topBcumbsLinks li {
    color: #909090;
    display: inline-block;
}

ul.topBcumbsLinks li:first-child {
    color: #0f6fb4;
}

.btnrac {
    background-color: #337ab7;
    border-color: #2e6da4;
    color: #fff;
    border-style: solid none;
    border-width: 1px medium;
    font-weight: bold;
    height: 34px;
    margin-bottom: 0;
    margin-top: 0;
    padding: 0 0 0 30px;
    text-align: left;
    width: 100%;
}

.leftsi {
    border-bottom: 1px solid #ccc;
    float: left;
    padding: 20px 0;
    width: 100%;
    background: rgb(51, 122, 183);
}

a.blubtnpops {
    background: #43a9f1 none repeat scroll 0 0;
    border-radius: 5px;
    color: #fff;
    float: right;
    font-size: 15px;
    margin-top: 10px;
    padding: 0 10px;
}

.norstyb {
    background: #f1f9ff none repeat scroll 0 0;
    border-color: currentcolor currentcolor #ccc;
    border-style: none none solid;
    border-width: medium medium 1px;
    font-weight: bold;
    height: 60px;
    margin-bottom: 1px;
    margin-top: 0;
    padding: 0 0 0 30px;
    text-align: left;
    line-height: 56px;
    width: 100%;
}

a.crooss {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -1404px -377px / 390px 550px;
    display: inline-block;
    float: right;
    font-size: 16px;
    height: 20px;
    margin-top: 7px;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 22px;
}

.maincencl {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #ccc;
    border-radius: 6px;
    float: none;
    margin: 33px auto;
    width: 95%;
    padding: 1%;
    max-width: 1400px
}

.NiceScroll .eleRow {
    border-bottom: 1px solid #dcdcdd;
    display: block;
    float: left;
    line-height: 40px;
    padding: 14.5px 10px;
    width: 100%;
}

.glossarytab {
    padding: 10px;
    margin-bottom: -16px;
}

.glossarytab li {
    color: #0f6fb4;
    display: inline-block;
}

.glossarytab li:after {
    content: ' | ';
    color: #909090;
    padding: 0px 10px;
}

.glossarytab li:last-child {
    color: #909090;
}

.glossarytab li:last-child:after {
    content: '';
}

/*Global*/

.filterBlockun {
    /*float: left*/
}

.filterBlockun .unSelAll {
    color: #0174c7;
    cursor: pointer;
    display: block;
    font-size: 16px;
    font-weight: 400;
    margin-top: 25px;
}

.filterBlockun input.css-checkbox[type="checkbox"] {
    border: 0 none;
    clip: rect(0px, 0px, 0px, 0px);
    height: auto;
    left: -1000px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 100%;
    z-index: -1000;
}

.filterBlockun label.css-label5 {
    -moz-user-select: none;
    background-image: var(--student-sprite-url);
    background-position: -424px -4px;
    background-repeat: no-repeat;
    background-size: 500px 600px;
    color: #555555;
    cursor: pointer;
    display: inline-block;
    font-size: 15px;
    font-weight: normal;
    height: 24px;
    line-height: 22px;
    padding-left: 30px;
    vertical-align: middle;
    float: left;
}

.filterBlockun input.css-checkbox[type="checkbox"]+label.css-label5 {
    background-position: -428px -538px;
    background-repeat: no-repeat;
    background-size: 502px 618px;
    color: #555555;
    cursor: pointer;
    display: inline-block;
    float: left;
    font-size: 15px;
    font-weight: normal;
    height: 24px;
    line-height: 22px;
    padding-left: 30px;
    vertical-align: middle;
}

.filterBlockun input.css-checkbox[type="checkbox"]:checked+label.css-label5 {
    background-position: -472px -39px;
}

.filterBlockun label.css-label6 {
    -moz-user-select: none;
    background-image: var(--student-sprite-url);
    background-position: -424px -4px;
    background-repeat: no-repeat;
    background-size: 500px 600px;
    color: #555555;
    cursor: pointer;
    display: inline-block;
    font-size: 15px;
    font-weight: normal;
    height: 24px;
    line-height: 22px;
    padding-left: 30px;
    vertical-align: middle;
    float: left;
}

.filterBlockun input.css-checkbox[type="checkbox"]+label.css-label6 {
    background-position: -413px -650px;
    background-repeat: no-repeat;
    background-size: 450px 750px;
    color: #555555;
    cursor: pointer;
    display: inline-block;
    float: left;
    font-size: 15px;
    font-weight: normal;
    height: 24px;
    line-height: 22px;
    padding-left: 30px;
    vertical-align: middle;
    margin-right: 20px;
}

.filterBlockun input.css-checkbox[type="checkbox"]:checked+label.css-label6 {
    background-position: -423px -140px;
}

.filterBlockun label.css-label7 {
    -moz-user-select: none;
    background-image: var(--student-sprite-url);
}

.filterBlockun input.css-checkbox[type="checkbox"]+label.css-label7 {
    margin-right: 20px;
    background-position: -413px -700px;
    background-repeat: no-repeat;
    background-size: 450px 750px;
    color: #555555;
    cursor: pointer;
    display: inline-block;
    float: left;
    font-size: 15px;
    font-weight: normal;
    height: 24px;
    line-height: 22px;
    padding-left: 22px;
    vertical-align: middle;
}

.filterBlockun input.css-checkbox[type="checkbox"]:checked+label.css-label7 {
    background-position: -423px -140px;
}

.filterBlockun label.css-label8 {
    -moz-user-select: none;
    background-image: var(--student-sprite-url);
}

.filterBlockun input.css-checkbox[type="checkbox"]+label.css-label8 {
    background-position: -413px -603px;
    background-repeat: no-repeat;
    background-size: 450px 750px;
    color: #555555;
    cursor: pointer;
    display: inline-block;
    float: left;
    font-size: 15px;
    font-weight: normal;
    height: 24px;
    line-height: 22px;
    margin-right: 20px;
    padding-left: 30px;
    vertical-align: middle;
}

.filterBlockun input.css-checkbox[type="checkbox"]:checked+label.css-label8 {
    background-position: -423px -140px;
}

.filterBlockss .contentTypefd {
    color: #0f6fb4;
    display: inline-block;
    float: right;
    font-size: 15px;
    margin-top: -13px;
    text-align: right;
}

.filterBlockss .caretss {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px dashed;
    display: inline-block;
    height: 0;
    margin-right: 15px;
    margin-top: 0;
    right: 0;
    transform: rotate(-91deg);
    vertical-align: middle;
    width: 0;
}

.colrgrsm {
    color: #553d39;
    font-size: 19px;
}

.colrgrsmsm {
    color: #553d39;
    font-size: 15px;
    text-align: left;
}

.collghtbg {
    background: #e8ffea;
    height: 200px
}

.collghtbgbeige {
    background: #f9f2e2;
    height: 200px
}

.collghtbgpink {
    background: #ffcfcf;
    height: 200px
}

.btngl {
    background: transparent var(--student-sprite-url) repeat scroll -201px -263px / 350px 500px;
    display: inline-block;
    height: 27px;
    left: 0;
    position: relative;
    top: 6px;
    width: 35px;
}

.paddnogl {
    padding-left: 0px;
    padding-right: 0px;
}

a.blubtnpopsgl {
    background: #43a9f1 none repeat scroll 0 0;
    border-radius: 18px;
    color: #fff;
    float: right;
    font-size: 15px;
    margin-top: 10px;
    padding: 0 10px;
}

.panel-footer {
    background: none;
    border: none;
}

.boxss {
    display: inline-block;
    margin: 20px 0 -10px;
    padding: 5px;
}

.paginationaa {
    border-radius: 4px;
    display: inline-block;
    margin: -9px 0;
    padding-left: 0;
    width: 100%;
    /*text-align: center;*/
}

.paginationaa>li {
    display: inline;
}

.paginationaa>li>a, .paginationaa>li>span {
    background-color: #fff;
    border: 1px solid #ddd;
    color: #555;
    float: none;
    line-height: 1.42857;
    margin-left: -4px;
    padding: 6px 1.1%;
    position: relative;
    text-decoration: none;
}

.first.disabled a {
    text-align: center;
    width: 7%;
}

.allbtn {
    background-color: #fff;
    border: 1px solid #ddd;
    color: #0f6fb4;
    padding: 6px 30px;
}

@media only screen and (max-width: 1441px) {
    .paginationaa>li>a, .paginationaa>li>span {
        padding: 6px 1.1%
    }
}

/*Deskboad*/

a.questionSelfStudy {
    color: rgba(255, 255, 255, 0.932);
    text-decoration: none;
}

.pacboxdes {
    border-radius: 1px;
    box-shadow: 0 0 1px 0 #555;
    display: inline-block;
    float: left;
    margin-bottom: 15px;
    padding: 0px !important;
    padding-left: 0px;
    padding-right: 0px;
    background: #fff
}

.pacboxdes p {
    color: #555;
    float: left;
    line-height: 21px;
    margin: 19px 0;
    padding: 0;
    text-align: left;
}

a.boardhead {
    color: #337ab7;
    float: left;
    width: 100%;
    text-align: left;
    margin: 10px 0 0 0;
    font-size: 15px !important;
    word-break: break-word;
}

a.boardheadblf {
    color: #000;
    float: left;
    font-size: 17px;
    margin-top: 26px;
    text-align: left;
    margin-left: 0px;
    width: 100%;
    font-size: 17px !important;
    font-weight: bold !important;
}

a.boardheadact {
    color: #000;
    float: left;
    font-size: 17px;
    margin: 22px 0px 15px 0px;
    text-align: left;
    width: 100%;
}

a.lghtblfg {
    color: #43a8ee;
    float: left;
    line-height: 35px;
    text-align: left;
    width: 100%;
}

a.boardheadgrey {
    clear: both;
    color: #555;
    float: left;
    font-size: 15px;
    line-height: 24px;
    text-align: left;
    width: 100%;
    margin: 0px;
}

a.lghtblfgmng {
    color: #337ab7;
    float: left;
    line-height: 35px;
    text-align: right;
    width: 100%;
}

.barchrtlghtblde {
    background: #3fabf4 none repeat scroll 0 0;
    border-radius: 120px;
    height: 9px;
    line-height: 41px;
    margin-top: 9px;
    text-align: center;
}

.barchrtlghtblde::after {
    background: rgba(0, 0, 0, 0.3) none repeat scroll 0 0;
    border-radius: 10px;
    content: " ";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
}

.barchrtlghtblde .filled {
    background: rgba(0, 0, 0, 0.3) none repeat scroll 0 0;
    border-radius: 10px;
    content: " ";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
}

.pacboxdes .form-control {
    margin-top: 15px;
}

.brdrds {
    border-top: 1px solid #ccc;
    float: left;
    margin: 6px 0 7px 0px;
    width: 100%;
}

.lsthed {
    color: #337ab7;
    float: left;
    height: 70px;
    line-height: 59px;
    padding: 0;
    width: 100%;
}

.lsthedbtnn {
    background: #44a9f2 none repeat scroll 0 0;
    border: medium none;
    border-radius: 18px;
    color: #fff;
    display: block;
    font-size: 14px;
    line-height: 20px;
    margin: 17px auto;
    padding: 6px 0;
    text-align: center;
    width: 80px;
}

.typeIcondesk {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -4px -95px / 470px 700px;
    display: inline-block;
    height: 49px;
    left: 0;
    margin-right: 15px;
    position: relative;
    top: 1px;
    width: 52px;
}

.typeIcondesk1 {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll 0 -71px / 500px 600px;
    display: inline-block;
    height: 49px;
    left: 0;
    margin-right: 15px;
    position: relative;
    top: 1px;
    width: 52px;
}

.typeIcondesk2 {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -4px -162px / 470px 700px;
    display: inline-block;
    height: 49px;
    left: 0;
    margin-right: 15px;
    position: relative;
    top: 1px;
    width: 52px;
}

.pacboxdes .limitFiltersas {
    float: left;
    margin: 10px 0 9px;
}

.icondes {
    float: left;
    margin-right: 2%;
    width: 56px;
}

.icondeshead {
    float: left;
    margin-right: 1%;
    width: 47%;
    margin-top: -5px;
}

.icondesheadin {
    float: left;
    margin-right: 0%;
    width: 75%;
}

.icondesheadsel {
    float: right;
    margin-right: 0;
    width: 26%;
    font-size: 11px;
}

.icondesheadin-btn {
    /*float: left; margin-right: 0%; width: 87%;*/
}

.icondesheadin-btn .icondeshead-btn {
    float: left;
    margin-right: 1%;
    margin-top: 25px;
    text-align: left;
    width: 34%;
}

.desk2 {
    font-size: 13px;
    position: absolute;
    right: -33px;
    top: -16px;
}

.filterBlockss .maincolblusdesk {
    border: 1px solid #0f6fb4;
    border-radius: 12px;
    color: #0f6fb4;
    display: block;
    float: right;
    font-size: 14px;
    line-height: 22px;
    margin: 20px 28px 0 0;
    padding: 2px 10px;
    text-align: center;
}

.filterBlockss .maincolbladesk {
    border-radius: 12px;
    color: #000;
    display: block;
    float: right;
    font-size: 13px;
    line-height: 22px;
    margin: 25px 9px 0 3px;
    padding: 2px 10px;
    text-align: center;
}

.float-rghthbt {
    float: right;
    width: 51%;
}

.rightBoxWidthMinimize .lastQuesRedirectStudent {
    width: 97%;
    padding-right: 30px;
    z-index: 9999;
    margin-bottom: -20px;
}

.dasboxss {
    display: inline-block;
    margin: 0px;
    padding: 20px;
}

.dasboxss .form-control {
    margin-top: 15px;
}

.dasboxss img {
    max-width: 100%;
}

#submission .contentType {
    display: inline-block;
    margin-top: 14px;
    float: left;
    width: 74%;
    color: white;
    padding-left: 15px;
    text-align: left;
}

#submission .contentType h4 {
    display: inline-block;
    margin-top: 12px;
    float: left;
    width: 75%;
    text-align: left;
}

#submission .contentType div {
    display: table-row;
    color: white;
}

#submission .questionMainBlock {
    border: none;
}

/*.lastQuesRedirectStudent{border-left: 0px solid #ccc; border-right: 0px solid #ccc; border-bottom: 0px solid #ccc;}*/

#expColBtn.glyphiconStudent {
    margin-top: -50px !important;
    margin-bottom: 20px !important;
}

.breadCrumbs ul {
    margin-bottom: 5px !important;
}

@media only screen and (max-width: 1200px) {
    #submission .contentType h4 {
        width: 13vw
    }
}

@media only screen and (min-width: 1600px) {
    #submission .contentType h4 {
        width: 85%;
    }
}

.reportspc {
    display: inline-block;
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    float: left;
    width: 180px;
    text-align: left;
}

.studentViewQuestionContainer {
    background: #fff;
    padding: 0px 20px 10px 20px;
    margin: 1px 0px 0px 0px;
    border-radius: 10px;
    max-height: 770px;
    max-width: 1116px;
}

/* .studentViewQuestionContainer div {
    padding-bottom: 7px;
} */

.choicesText {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
}

.studentViewQuestionContainer img {
    width: auto;
    max-width: 100%;
}

.studentViewQuestionContainer td {
    padding-top: 17px;
}

.studentViewQuestionContainer label img {
    width: auto;
    max-width: 100%;
}

.studentViewQuestionContainer font img {
    width: auto;
    max-width: 100%;
}

.studentViewQuestionContainer font span {
    font-size: inherit !important
}

.studentViewQuestionContainer font div {
    font-size: inherit !important
}

.studentViewQuestionContainer font p {
    font-size: inherit !important;
    -webkit-margin-before: 1em;
}

/*.studentViewQuestionContainer font {display: inline-block; padding-top:5px; padding-bottom:5px;}*/

.studentViewQuestionContainer ul {
    display: block;
    list-style-type: disc;
    left: 2%;
    position: relative;
    width: 93%;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 20px;
}

.studentViewQuestionContainer ol {
    display: block;
    list-style-type: decimal;
    left: 2%;
    position: relative;
    width: 93%;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 20px;
}

@-moz-document url-prefix() {
    .studentViewQuestionContainer ol {
        left: 4%;
        position: relative;
        width: 93%;
    }
}

.studentViewQuestionContainer ul {
    display: block;
    list-style-type: disc;
    left: 2%;
    position: relative;
    width: 93%;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 20px;
}

@-moz-document url-prefix() {
    .studentViewQuestionContainer ul {
        left: 4%;
        position: relative;
        width: 93%;
        margin-top: 1em;
        margin-bottom: 1em;
        -moz-margin-start: 0px;
        -moz-margin-end: 0px;
        /* -moz-padding-start: 20px; */
    }
}

.studentViewQuestionContainer em {
    font-style: italic;
}

#questionPreviewBox em {
    font-style: italic;
}

#questionPreviewBox ul {
    display: block;
    list-style-type: disc;
    left: 4%;
    position: relative;
    width: 93%;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 20px;
}

@-moz-document url-prefix() {
    #questionPreviewBox ul {
        left: 6%;
        position: relative;
        width: 93%;
    }
}

#questionPreviewBox ol {
    display: block;
    list-style-type: decimal;
    left: 4%;
    position: relative;
    width: 93%;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 20px;
}

@-moz-document url-prefix() {
    #questionPreviewBox ol {
        left: 6%;
        position: relative;
        width: 93%;
    }
}

@media only screen and (min-height: 966px) {
    #check_answer_modal .modal-body {
        height: 531px !important;
    }
    /*.explanation_box .body {height: 531px !important;}
    .explanation_box {max-height: 650px !important}*/
}

#check_answer_modal .modal-dialog {
    max-width: 768px;
    max-height: 600px;
    width: 100%;
    height: 100%;
    margin: 150px auto !important;
}

#check_answer_modal .modal-body {
    padding-top: 0px;
    height: 400px;
    max-height: 90%;
    overflow: auto;
}

#check_answer_modal .eleRowspoporng {
    color: #f27259;
    font-size: 20px !important;
    margin-bottom: 0;
    padding: 15px 0;
    text-align: left;
    width: 95%;
    margin-top: -12px;
    display: inherit;
    font-weight: 700 !important
}

#check_answer_modal .eleRowspop {
    color: #0f6fb4;
    font-size: 20px !important;
    margin-bottom: 0;
    padding: 15px 0;
    text-align: left;
    width: 100%;
    margin-top: -10px;
    display: inherit;
    font-weight: 700 !important
}

#check_answer_modal hr {
    border-top: 2px solid #eee !important;
}

#check_answer_modal .modal-header {
    margin-bottom: 0px;
    padding-bottom: 0px;
}

#check_answer_modal video {
    max-width: 725px !important;
}

#check_answer_modal img {
    width: auto;
    max-width: 100%;
}

#check_answer_modal label img {
    width: auto;
    max-width: 100%;
}

#check_answer_modal font img {
    width: auto;
    max-width: 100%;
}

#check_answer_modal font span {
    font-size: inherit !important
}

#check_answer_modal font div {
    font-size: inherit !important
}

#check_answer_modal font p {
    font-size: inherit !important
}

/*#check_answer_modal font {padding-top:5px; padding-bottom:5px;}*/

#check_answer_modal ul {
    display: block;
    list-style-type: disc;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 20px;
}

.explanation_box {
    max-width: 768px;
    /* max-height: 520px;*/
    width: 100%;
    /*height: 100%;*/
    margin: -12px auto;
    border: 1px solid #ccc;
    border-radius: 10px;
    background: white;
    cursor: move;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.explanation_box .body {
    padding-top: 0px;
    /*height: 400px;*/
    max-height: 90%;
    overflow: auto;
    display: inline-block;
}

.explanation_box .eleRowspoporng {
    color: #f27259;
    font-size: 20px !important;
    margin-bottom: 0;
    padding: 15px 0;
    text-align: left;
    width: 95%;
    margin-top: -12px;
    display: inherit;
    font-weight: 700 !important
}

.explanation_box .eleRowspop {
    color: #0f6fb4;
    font-size: 20px !important;
    margin-bottom: 0;
    padding: 15px 0;
    text-align: left;
    width: 100%;
    margin-top: -10px;
    display: inherit;
    font-weight: 700 !important
}

.explanation_box hr {
    border-top: 2px solid #eee !important;
    margin-bottom: 15px !important;
}

.explanation_box .modal-header {
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.explanation_box video {
    max-width: 725px !important;
}

.explanation_box img {
    width: auto;
    max-width: 100%;
    /* height: 42px; */
}

.topDiv img {
    width: auto;
    max-width: 100%;
    height: 42px;
}

/* .explanation_box #IFRAME_DIV p img {
	height : auto !important;
} */

.explanation_box label img {
    width: auto;
    max-width: 100%;
}

.explanation_box font img {
    width: auto;
    max-width: 100%;
}

.explanation_box font span {
    font-size: inherit !important
}

.explanation_box font div {
    font-size: inherit !important
}

.explanation_box font p {
    font-size: inherit !important
}

/*#check_answer_modal font {padding-top:5px; padding-bottom:5px;}*/

.explanation_box ul {
    display: block;
    list-style-type: disc;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 20px;
}

.dashboardWidgetContainer {
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    display: block;
    float: left;
    width: 100%;
}

#assign-dialog .modal-dialog {
    width: 768px;
    margin: 150px auto !important;
}

#assign-dialog .contentType {
    display: inline-block;
}

#assign-dialog .subUSHistory .typeIcon {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -220px -536px / 500px 600px;
    display: inline-block;
    height: 49px;
    left: 0;
    margin-right: 15px;
    position: relative;
    top: 4px;
    width: 52px;
}

#assign-dialog .subGlobHistory .typeIcon {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -220px -316px / 500px 600px;
    display: inline-block;
    height: 49px;
    left: 0;
    margin-right: 15px;
    position: relative;
    top: 4px;
    width: 52px;
}

#assign-dialog .subLivEnvi .typeIcon {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -220px -246px / 500px 600px;
    display: inline-block;
    height: 49px;
    left: 0;
    margin-right: 15px;
    position: relative;
    top: 1px;
    width: 52px;
}

#assign-dialog .subGeometry .typeIcon {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -220px -384px / 500px 600px;
    display: inline-block;
    height: 49px;
    left: 0;
    margin-right: 15px;
    position: relative;
    top: 4px;
    width: 52px;
}

#assign-dialog .subErthSci .typeIcon {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -220px -167px / 500px 600px;
    display: inline-block;
    height: 49px;
    left: 0;
    margin-right: 15px;
    position: relative;
    top: 4px;
    width: 52px;
}

#assign-dialog .subAlgebra .typeIcon {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -220px -466px / 500px 600px;
    display: inline-block;
    height: 49px;
    left: 0;
    margin-right: 15px;
    position: relative;
    top: 1px;
    width: 52px;
}

#assign-dialog .booksRow .typeIcon {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -121px -220px / 500px 600px;
    display: inline-block;
    height: 49px;
    left: 0;
    margin-right: 15px;
    position: relative;
    top: 1px;
    width: 52px;
}

#assign-dialog .questionRow .typeIcon {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 0px -71px;
    display: inline-block;
    height: 49px;
    left: 0px;
    position: relative;
    top: 1px;
    width: 52px;
    margin-right: 15px;
    background-size: 500px 600px;
}

#assign-dialog .flashCard .typeIcon {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 0px -150px;
    display: inline-block;
    height: 49px;
    left: 0px;
    position: relative;
    top: 4px;
    width: 52px;
    margin-right: 15px;
    background-size: 500px 600px;
}

#assign-dialog .learning .typeIcon {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 0px -222px;
    display: inline-block;
    height: 49px;
    left: 0px;
    position: relative;
    top: 4px;
    width: 52px;
    margin-right: 15px;
    background-size: 500px 600px;
}

.booksRow .typeIcon {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -121px -220px / 500px 600px;
    display: inline-block;
    height: 49px;
    left: 0;
    margin-right: 15px;
    position: relative;
    top: 1px;
    width: 52px;
}

.filterBlockss .subUSHistory {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -220px -536px / 500px 600px;
    display: inline-block;
    height: 49px;
    left: 0;
    margin-right: 15px;
    position: relative;
    top: 4px;
    width: 52px;
}

.filterBlockss .subGlobHistory {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -220px -316px / 500px 600px;
    display: inline-block;
    height: 49px;
    left: 0;
    margin-right: 15px;
    position: relative;
    top: 4px;
    width: 52px;
}

.filterBlockss .subLivEnvi {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -220px -246px / 500px 600px;
    display: inline-block;
    height: 49px;
    left: 0;
    margin-right: 15px;
    position: relative;
    top: 1px;
    width: 52px;
}

.filterBlockss .subGeometry {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -220px -384px / 500px 600px;
    display: inline-block;
    height: 49px;
    left: 0;
    margin-right: 15px;
    position: relative;
    top: 4px;
    width: 52px;
}

.filterBlockss .subErthSci {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -220px -167px / 500px 600px;
    display: inline-block;
    height: 49px;
    left: 0;
    margin-right: 15px;
    position: relative;
    top: 4px;
    width: 52px;
}

.filterBlockss .subAlgebra {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -220px -466px / 500px 600px;
    display: inline-block;
    height: 49px;
    left: 0;
    margin-right: 15px;
    position: relative;
    top: 1px;
    width: 52px;
}

.filterBlockss .booksRow {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -121px -220px / 500px 600px;
    display: inline-block;
    height: 49px;
    left: 0;
    margin-right: 15px;
    position: relative;
    top: 1px;
    width: 52px;
}

.filterBlockss .questionRow {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 0px -71px;
    display: inline-block;
    height: 49px;
    left: 0px;
    position: relative;
    top: 1px;
    width: 52px;
    margin-right: 15px;
    background-size: 500px 600px;
}

.filterBlockss .flashCard {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 0px -150px;
    display: inline-block;
    height: 49px;
    left: 0px;
    position: relative;
    top: 4px;
    width: 52px;
    margin-right: 15px;
    background-size: 500px 600px;
}

.filterBlockss .learning {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 0px -222px;
    display: inline-block;
    height: 49px;
    left: 0px;
    position: relative;
    top: 4px;
    width: 52px;
    margin-right: 15px;
    background-size: 500px 600px;
}

.dashboardWidgetContainer .booksRow {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -121px -220px / 500px 600px;
    display: inline-block;
    height: 49px;
    left: 0;
    margin-right: 15px;
    position: relative;
    top: 1px;
    width: 52px;
}

.dashboardWidgetContainer .questionRow {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 0px -71px;
    display: inline-block;
    height: 49px;
    left: 0px;
    position: relative;
    top: 1px;
    width: 52px;
    margin-right: 15px;
    background-size: 500px 600px;
}

.dashboardWidgetContainer .flashCard {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 0px -150px;
    display: inline-block;
    height: 49px;
    left: 0px;
    position: relative;
    top: 4px;
    width: 52px;
    margin-right: 15px;
    background-size: 500px 600px;
}

.dashboardWidgetContainer .learning {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 0px -222px;
    display: inline-block;
    height: 49px;
    left: 0px;
    position: relative;
    top: 4px;
    width: 52px;
    margin-right: 15px;
    background-size: 500px 600px;
}

.dashboardCreateAssignmentBtn {
    border: 1px solid #0caa41;
    color: #0caa41;
    padding: 5px;
    border-radius: 3px;
    cursor: pointer;
}

.dashboardCreateAssignmentBtn:hover {
    background: #0caa41;
    color: #fff !important;
}

.flashCardWordCount {
    display: inline-block;
    float: right;
    bottom: 0px;
    margin-top: 9px;
}

audio {
    display: block !important;
}

.lp_explanation {
    padding-bottom: 10px;
    font-size: 18px !important;
}

i {
    font-style: italic;
}

a:focus {
    outline: none !important;
}

.loginPage .form-signup .control-label {
    font-weight: 700 !important
}

.fdef {
    font-size: 16px !important;
    line-height: 24px;
}

.activityTypeDisplay {
    padding-left: 10px;
    margin-top: -10px;
    font-style: italic;
    font-size: 12px !important;
    padding-bottom: 5px;
    z-index: -100;
    text-align: left;
}

.react-rater a.will-be-active {
    color: rgb(255, 242, 80) !important;
}

.react-rater a.is-active {
    color: rgb(255, 242, 80) !important;
}

.pauseButton i {
    color: #0f6fb4;
    margin: 13px 0px 13px 21px;
}

.activityLeftPanel {
    overflow: hidden !important;
    /* width: 50%; */
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
}

.ques_act_start_message {
    text-align: center;
    padding-top: 10px;
    font-size: 16px !important;
    width: 100%;
}

/*.activityLeftPanel:hover {
    overflow: visible !important;
    white-space: normal !important;
}*/

#welcome_modal .modal-dialog {
    width: 1005px;
    max-width: 100%;
    margin: 150px auto !important;
    height: 100%
}

#welcome_modal .modal-dialog .modal-content {
    height: auto
}

.filterBoxStyle {
    background: white;
    padding: 15px;
    border: 1px solid #ddd;
    margin-right: 15px;
}

.tableBoxStyle {
    margin: 20px;
    background: white;
    border: 1px solid #ddd;
    /*width: 80vw;*/
}

.smallOnwerTag {
    font-size: 11px !important;
}

.searchCrtBar .delete {
    padding: 7px 15px 9px 31px !important;
    height: 38px;
    margin-top: 7px;
    /*background-color: #44a9f2; border-color: #44a9f2;*/
    background-color: indianred;
    border-color: indianred;
    float: right;
    line-height: 3px;
    width: 95%;
    margin-left: -10px;
    position: relative;
    min-width: 120px;
}

.searchCrtBar .delete span {
    margin-right: 0px;
    position: absolute;
    left: 5px;
    background: rgba(0, 0, 0, 0) var(--sprite-url) repeat scroll -287px -70px;
    width: 20px;
    height: 20px;
    top: 6px;
}

.searchCrtBar button.btn.btn-primary.delete.delete:hover {
    background-color: #F44336;
    border-color: #F44336;
}

.searchCrtBar button.btn.btn-primary.delete.delete:focus {
    background-color: #F44336;
    border-color: #F44336;
}

.ml-beta {
    width: 50px;
    height: 50px;
    margin-left: 10px;
    padding-top: 20;
    position: relative;
    left: 0px;
    bottom: 15px;
}

@media screen and (min-width: 769px) {
    .rightBoxWidthMinimize .lastQuesRedirectStudent {
        width: 96%;
        padding-right: 30px;
        z-index: 9999;
        margin-bottom: -50px;
    }
}

@media screen and (max-width: 360px) {
    .rightBoxWidthMinimize .lastQuesRedirectStudent {
        width: 101%;
        padding-right: 30px;
        z-index: 9999;
        margin-bottom: 19px !important;
    }
}

@media screen and (max-width: 415px) {
    .rightBoxWidthMinimize .lastQuesRedirectStudent {
        margin-bottom: 18px;
    }
    .mainActivBoxRight.rightBoxWidthMinimize {
        height: 100vh !important;
    }
    .studentViewQuestionContainer {
        margin: 1px 0px 100px 0px !important;
        padding: 0px 20px 10px 0px;
    }
    .studentViewQuestionContainer .row {
        margin-bottom: 40px;
    }
    .loginPage input[type="text"] {
        font-size: 16px;
    }
    body, div, p, nav, article, a, label {
        font-size: 16px !important;
    }
    body {
        font-size: 16px !important;
        ;
    }
    .loginPage input[type="password"] {
        font-size: 16px !important;
    }
    questionMainBlock {
        margin-top: 5px !important;
        margin-bottom: 20px !important;
    }
    .container-fluid .questionMainBlock {
        margin-top: 15px !important;
    }
    .filterBlockss .typeIconsorn {
        left: 6px;
    }
    .form-control {
        font-size: 16px !important;
    }
    .eleRows .col-xs-6 {
        width: 100%;
    }
}

@-moz-document url-prefix() {
    .quesText ul {
        left: 6%;
        position: relative;
        width: 93%;
        list-style-type: disc;
    }
}

.quesText ul {
    left: 6%;
    width: 93%;
    position: relative;
    list-style-type: disc;
}

@-moz-document url-prefix() {
    .quesText ol {
        left: 6%;
        position: relative;
        width: 93%;
        list-style-type: decimal;
    }
}

.quesText ol {
    left: 6%;
    width: 93%;
    position: relative;
    list-style-type: decimal;
}

/* .innerQuestionBox .space-align2 .question-and-choice p{
    margin-left: 25px;
    background-color: #0174c7;
} */

/* .ml-beta {
    color: red;
    font-size: 16px;
    font-style: italic;
    text-transform: lowercase;
    font-family : Circular,"Segoe UI",Helvetica,Arial,sans-serif;
    font-weight: bolder;
    cursor: pointer;
}

.ml-beta .ml-tooltip {
    visibility: hidden;
    width: 250px;
    background-color: #fff;
    color: #929090;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    bottom: 4px;
    left: 0px;
    z-index: 1;
    border: 1px solid;
    padding: 3px 5px;
    font-size: 15px !important;
    line-height: 23px;
    margin-left: 29px;
}

  .ml-beta:hover .ml-tooltip {
    visibility: visible;
  }

  .modal-backdrop .fade .in {
      z-index : 1060;
  }


 */

/*
 @media screen and (max-width: 400px) {
    .explanation_box {
        position : relative !important;
        bottom: 4vh !important;
        padding-bottom: 10px !important;
    }
}
#checkAnsFooter button {
    margin-top: 20px!important;
} */

@media only screen and (min-width: 768px) and (max-width: 1199px) {
    .logoClass {
        width: 120px;
        margin-top: 6px;
    }
}

#tab-tab-answerCorrect {
    background: blue;
}

#tab-tab-answerWrong {
    background: red;
}

/* #tab-tab-question{
   width:10%
}
#tab-tab-explanation{
    width:10%
} */

.imgHover:hover {
    color: rgb(15, 111, 180);
    text-align: left;
}

.imgHover {
    color: white;
    font-size: 10px !important;
    text-align: left;
    margin-left:1px;
    cursor: pointer;
    }

/* @media only screen and  (max-width: 971px) {
    #tab-tab-answerCorrect{
        background: blue;
        width: 70vw;
    }
    #tab-tab-answerWrong{
        background: red;
        width: 70vw;
    }
}

@media only screen and  (min-width: 972px) and  (max-width: 1119px) {
    #tab-tab-answerCorrect{
        background: blue;
        width: 73vw;
    }
    #tab-tab-answerWrong{
        background: red;
        width: 73vw;
    }
} */

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .searchCrtBar {
        height: 85px;
        background: rgb(15, 111, 180);
        color: #fff;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-bottom: 1px solid #ddd;
        color: #fff;
    }
}

.tab {
    height: 0px;
}

.explanation_tab_body {
    max-height: 200px;
    height: 200px;
}
.customAlert {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;

    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;

    margin-left: 80px;

    width: 210px;
    height: 160px;
    z-index: 400;
    position: fixed;
    top: 137px;
    text-align: center;
    font-size: 14px !important;
}
@media only screen and (max-width: 767px) {
    .audioplayerHeight {
        width: 75% !important;
        margin: calc(50% - 10px) auto auto;
    }
}

.audioplayerHeight {
    width: 50%;
}

.full-wdith-blue-top {
    height: 5px;
    background: #0f6fb4;
}

.nav-tabs>li {
    float: none !important;
    display: table-cell !important
}

/* #tab{width:99%;} */

.nav-tabs>li.disabled {
    width: 100%;
}